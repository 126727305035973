import styled from 'styled-components';

const CardFooter = styled.div`
    padding: ${props => props.theme.layout.padding.medium};
    background: ${props => props.theme.colors.subtle.light};
    display: flex;
    justify-content: flex-end;
`;

export default CardFooter;
