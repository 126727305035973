import React from 'react';
import styled from 'styled-components';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Toggle = styled.input.attrs<InputProps>(props => ({
    type: 'checkbox',
}))`
    outline: none;
    -webkit-appearance: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 5rem;
    position: relative;
    background: #bbb;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:before {
        position: absolute;
        content: '';
        left: 0;
        height: 100%;
        width: 1.5rem;
        background: linear-gradient(#fff, #f2f2f2, #e6e6e6, #d9d9d9);
        border-radius: 5rem;
        transform: scale(0.85);
        transition: left 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    &:hover {
        cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
    }
    &:checked {
        background: ${props =>
            props.disabled ? props.theme.colors.disabled : props.theme.colors.primary};
        &:before {
            left: 1.5rem;
        }
    }
`;

export default Toggle;
