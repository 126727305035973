import React from 'react';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { ConnectedStore } from '../../types/ConnectedStore';
import { useAppState } from '../../store/appstate';
import Search from '../../components/molecules/Order/Search';
import Spinner from '../../components/atoms/Spinner/Spinner';

const OrderSearch: React.FC = () => {
    document.title = 'Merchant Hub - Orders';

    const availableStores = useAppState<ConnectedStore[]>(s => s.session.availableStores) ?? [];
    const isLoadingOrg = useAppState<boolean>(s => s.session.isLoadingOrganization);

    return (
        <>
            {isLoadingOrg && (
                <SpinnerWrapper>
                    <Spinner text="Loading..." loading={isLoadingOrg} />
                </SpinnerWrapper>
            )}

            {!isLoadingOrg && <Search availableStores={availableStores} />}
        </>
    );
};

const SpinnerWrapper = styled.div`
    position: absolute;
    background: ${props => transparentize(0.5, props.theme.colors.light)};
    left: 50%;
    top: 25%;
    margin-left: -1rem;
    margin-top: 10rem;
`;

export default OrderSearch;
