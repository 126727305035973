import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import App from './containers/App';
import store from './store';
import 'normalize.css';
import ErrorBoundary from './errorBoundary';
import { PublicClientApplication } from '@azure/msal-browser';
import { config } from './config';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

const configuration = {
    auth: {
        clientId: config.Auth.ClientId,
        authority: config.Auth.Authority + config.Auth.DefaultFlow,
        knownAuthorities: config.Auth.KnownAuthorities,
        redirectUri: window.location.origin,
    },

    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

const pca = new PublicClientApplication(configuration);

const Root = () => (
    <ErrorBoundary>
        <Provider store={store}>
            <Router>
                <MsalProvider instance={pca}>
                    <App />
                </MsalProvider>
            </Router>
        </Provider>
    </ErrorBoundary>
);

root.render(<Root />);
