/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '../../index';
import api from '../../../services/api';
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import ResponseError from '../../../types/response/ResponseError';
import { Settlement } from '../../../types/report/Settlement';
import { SettlementSearchResponse } from '../../../types/report/SettlementPagingResponseData';
import { ResponseData } from '../../../types/response/ResponseData';
import { SearchTerm } from '../../../types/response/SearchTerm';
import {
    settlementSearchStarted,
    settlementSearchFailed,
    settlementSearchResultsSucceeded,
    settlementSearchTermsSucceeded,
    settlementSearchTermsFailed,
} from './settlementSlice';

const search =
    (searchQueryString: string | undefined): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        if (store.getState().report.settlement.isSearching) return;

        let query = searchQueryString?.replace('?', '');
        if (query === '') query = 'query=&page=1&perPage=30';

        dispatch(settlementSearchStarted(query || ''));

        try {
            const response = await api.get<SettlementSearchResponse<Settlement[]>>(
                `settlements?${query}`,
                undefined,
                {
                    Accept: 'application/vnd.merchanthub.settlement+json',
                }
            );

            dispatch(settlementSearchResultsSucceeded(response));
        } catch (error) {
            dispatch(settlementSearchFailed(error as ResponseError));
        }
    };

const terms = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    try {
        const response = await api.get<ResponseData<SearchTerm[], undefined, undefined>>(
            `settlements/aggregation?aggregation=settlement_terms`
        );

        dispatch(settlementSearchTermsSucceeded(response.data));
    } catch (error) {
        dispatch(settlementSearchTermsFailed());
    }
};

export default {
    search,
    terms,
};
