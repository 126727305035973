import React from 'react';
import styled from 'styled-components';
import { useAppState } from '../../../store/appstate';
import usePermissions from '../../../services/hooks/usePermissions';
import sessionActions from '../../../store/session/actions';
import { SessionState } from '../../../store/session/sessionSlice';
import { useAppDispatch } from '../../../store';
import SearchSelect from '../../atoms/Input/SearchSelect';
import { useOrganizationsStore } from '../../../store/admin/organizations/organizationsStore';

type SelectUserType = {
    value: string;
    label: string;
};

const SelectOrganization: React.FC = () => {
    const dispatch = useAppDispatch();

    const { user, selectedOrganization, selectedOrganizationPending } = useAppState<SessionState>(
        s => s.session
    );
    const { organizations } = useOrganizationsStore();
    const { hasRead } = usePermissions(['usermanagement-all-organizationlist']);

    const handleSelectChange = (selected: string) => {
        dispatch(sessionActions.setSelectedOrganization(selected));
    };

    const sortedOrganizations =
        [...organizations].sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    const CollectorItem = {
        id: '0a452fea-17f7-460d-a52b-0f58c7478c1e',
        name: 'Collector Bank AB',
        blockInvites: false,
        clientId: '',
        maxApiKeys: 2,
        connectedStores: [],
    };

    const Collector = organizations.find(org => org.name === 'Collector AB') || CollectorItem;
    sortedOrganizations.unshift(Collector);

    const selectedOrganizationId = selectedOrganization ? selectedOrganization.id : '';

    if (!hasRead || !user?.isInternal) return <></>;

    return (
        <SelectStoreWrapper>
            <SearchSelect
                dataTestId="select-organization"
                disabled={organizations.length <= 1 || selectedOrganizationPending}
                placeholder="Select Organization"
                value={selectedOrganizationId}
                defaultValue={selectedOrganizationId}
                options={sortedOrganizations.map(
                    x =>
                        ({
                            value: x.id,
                            label: x.name,
                        } as SelectUserType)
                )}
                onChange={s => handleSelectChange(s)}
            />
        </SelectStoreWrapper>
    );
};

const SelectStoreWrapper = styled.div`
    width: 20rem;
    justify-content: flex-end;
    margin-left: auto;
    align-self: center;
`;

export default SelectOrganization;
