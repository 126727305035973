/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
    CSSProperties,
    Dispatch,
    forwardRef,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    Row,
    RowData,
    SortingState,
    TableOptions,
    Updater,
    useReactTable,
} from '@tanstack/react-table';
import { checkMatchByLowercase } from '../../../services/helpers/orderColumnFilter';
import { TableRowContent } from '../../../types/Layout/TableRowContent';
import TableHeaderTypes from '../../../types/tableHeaderType';
import MenuLineIcon from 'remixicon-react/MenuLineIcon';
import styled from 'styled-components';
import * as skin from './Table.skin';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import SearchFilterMenu from '../../molecules/SearchFilterMenu/SearchFilterMenu';
import SidebarMenu from '../Menu/SidebarMenu';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import CheckBox from '../Checkbox/Checkbox';
import Pagination from '../../../types/table/Pagination';
import AddNewRow from '../../../types/table/AddNewRow';
import RowStyles from './RowStyles';
import Sort from './Sort';
import Flex from '../Box/Flex';

interface TableProps<T extends TableRowContent> {
    data: Array<T>;
    columns: Array<ColumnDef<T>>;
    savedColumns?: string[];
    allColumns?: Array<ColumnDef<T>>;
    pagination?: Pagination;
    sort?: Sort<T>;
    onRowClicked?: (row: T, newTab?: boolean) => void;
    onSelectedRowsChange?: (rows: T[]) => void;
    skipPageReset?: boolean;
    stateFulData?: boolean;
    disableTable?: boolean;
    selectableProperty?: (original: T) => string | undefined;
    getRowStyles?: (row: T) => RowStyles;
    columnMenu?: boolean;
    expandable?: Expandable<T>;
    newRowProperties?: AddNewRow;
    setColumns?: Dispatch<SetStateAction<ColumnDef<T>[]>>;
    defaultColumns?: string[];
    tableHeaderType?: TableHeaderTypes;
    initialCheckAll?: boolean;
    uncheckAllDelegate?: React.Dispatch<SetStateAction<() => void>>;
}

type Expandable<T> = {
    expandableContent: (row: T) => JSX.Element;
};

declare module '@tanstack/table-core' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ColumnMeta<TData extends RowData, TValue> {
        style?: {
            textAlign?: string;
            width?: string;
            rowColor?: string;
        };
        sortKey?: string;
        hideHeader?: boolean;
    }
}

const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
    const targetRef = useRef();

    useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                // eslint-disable-next-line no-param-reassign
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, any>(
    ({ indeterminate, ...rest }: any, ref: React.Ref<HTMLInputElement>) => {
        const defaultRef = useRef(null) as React.MutableRefObject<any>;
        const resolvedRef = useCombinedRefs(ref, defaultRef);

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return <CheckBox dataTestId="activate-checkbox" setRef={resolvedRef} {...rest} />;
    }
);

function Table<T extends TableRowContent>({
    data,
    sort,
    columns,
    savedColumns,
    stateFulData,
    pagination,
    onRowClicked,
    onSelectedRowsChange,
    newRowProperties,
    expandable,
    selectableProperty,
    disableTable,
    columnMenu,
    setColumns,
    allColumns,
    defaultColumns,
    tableHeaderType,
    initialCheckAll,
    uncheckAllDelegate,
    getRowStyles,
}: TableProps<T>) {
    const getColumsToUse: () => (ColumnDef<T> | undefined)[] = () => {
        const savedTableColumns =
            savedColumns &&
            allColumns &&
            savedColumns.map(x =>
                allColumns.find(y => y.header === x || checkMatchByLowercase(y.header as string, x))
            );

        const columnsToUse =
            savedTableColumns && savedTableColumns.length !== 0 && columns === savedTableColumns
                ? savedTableColumns
                : columns;

        return columnsToUse;
    };

    const getColumsUseCallback = useCallback(getColumsToUse, [columns]);

    const [toggleSortMenu, setToggleSortMenu] = useState(false);
    const [statefulTableColumns] = useState(getColumsUseCallback());

    const tableData = useMemo(() => data, [data]);
    const tableColumns = useMemo(() => getColumsUseCallback(), [getColumsUseCallback]);
    const [sortId, direction] = sort?.initialSortbyState?.split('-') || ['', ''];

    const id: string = useMemo(
        () => tableColumns.find(c => c && c.meta?.sortKey === sortId)?.id ?? sortId,
        [tableColumns]
    );

    const initialSortState = [{ id, desc: direction === 'desc' }];
    const [sorting, setSorting] = useState<SortingState>(initialSortState);
    const [shouldSort, setShouldSort] = useState(false);

    const injectSorting = (options: TableOptions<T>): TableOptions<T> => {
        return sort
            ? {
                  ...options,
                  state: { sorting, ...options.state },
                  onSortingChange: (sortingState: Updater<SortingState>) => {
                      setSorting(sortingState);
                      setShouldSort(true);
                  },
              }
            : { ...options, getSortedRowModel: getSortedRowModel() };
    };

    const opt = injectSorting({
        data: tableData as any,
        state: {
            pagination: pagination && {
                pageSize: parseInt(pagination?.pageSize),
                pageIndex: 0,
            },
        },
        sortDescFirst: false,
        columns: tableColumns as ColumnDef<T>[],
        getCoreRowModel: getCoreRowModel(),
        manualSorting: sort ? true : false,
    });
    const table = useReactTable(opt);

    const sortArrowStyle = (sortedDesc: boolean | undefined): CSSProperties => {
        return {
            transform: sortedDesc ? 'rotate(-360deg)' : 'rotate(180deg)',
            marginTop: sortedDesc ? '0' : '-0.3rem',
        };
    };

    const onMiddleMouseButtonClick = (event: any, row: any) => {
        if (event.button === 1 && onRowClicked) onRowClicked(row, true);
    };

    const checkRowClickable = (e: { target: HTMLInputElement }) => {
        if (e.target.tagName !== 'INPUT') return true;
        return false;
    };

    const rowClickRedirect = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => {
        if ((e.metaKey || e.altKey || e.ctrlKey) && onRowClicked) {
            onRowClicked(row, true);
            return;
        }

        if (
            checkRowClickable(e as unknown as { target: HTMLInputElement }) &&
            onRowClicked !== undefined
        )
            onRowClicked(row);
    };

    const nextPage = () => {
        table.toggleAllRowsSelected(false);
        if (pagination) pagination.nextPage();
    };

    const previousPage = () => {
        table.toggleAllRowsSelected(false);
        if (pagination) pagination.previousPage();
    };

    useEffect(() => {
        table.toggleAllRowsSelected(initialCheckAll ?? false);

        if (uncheckAllDelegate) uncheckAllDelegate(() => () => table.toggleAllRowsSelected(false));
    }, [table.toggleAllRowsSelected, uncheckAllDelegate]);

    useEffect(() => {
        if (onSelectedRowsChange) {
            onSelectedRowsChange(
                table.getSelectedRowModel().flatRows.map((r: { original: T }) => {
                    return r.original;
                })
            );
        }
    }, [onSelectedRowsChange, table.getSelectedRowModel().flatRows]);

    useEffect(() => {
        const getSort = () => {
            if (sorting.length === 0) return sorting;

            const [firstSort] = sorting;
            const { meta: { sortKey = '' } = {} } =
                tableColumns.find(x => x?.id == firstSort.id) || {};

            if (sortKey.length > 0) return [{ id: sortKey, desc: firstSort.desc }];

            return sorting;
        };

        if (sort && shouldSort) {
            Promise.resolve().then(() => {
                sort.sortFunction(getSort());
                setShouldSort(false);
            });
        }
    }, [sorting, shouldSort]);


    interface Props {
        position: string;
    }

    const Pagination = ({ position }: Props) => {
        return (
            pagination &&
            data?.length > 0 && (
                <PaginationStyle>
                    {pagination.metaData?.range && (
                        <Results>
                            {pagination.metaData?.range.from}-{pagination.metaData?.range.to} of{' '}
                            {new Intl.NumberFormat().format(
                                pagination.metaData?.totalNumberOfRecords
                            )}
                        </Results>
                    )}
                    <PrevButton
                        data-testid={position + '-table-prev-page-button'}
                        onClick={() => previousPage()}
                        disabled={
                            pagination.metaData.numberOfPages === 1 || !pagination.links?.prev
                        }
                    >
                        <ArrowLeftSLineIcon size="17" />
                    </PrevButton>
                    <NextButton
                        data-testid={position + '-table-next-page-button'}
                        onClick={() => nextPage()}
                        disabled={
                            pagination.metaData.numberOfPages === 1 || !pagination.links?.next
                        }
                    >
                        <ArrowRightSLineIcon size="17" />
                    </NextButton>
                </PaginationStyle>
            )
        );
    };

    return (
        <>
            {toggleSortMenu && (
                <SidebarMenu isVisible={toggleSortMenu} toggle={() => setToggleSortMenu(false)}>
                    <SearchFilterMenu
                        close={() => setToggleSortMenu(false)}
                        columns={
                            (stateFulData ? statefulTableColumns : tableColumns) as ColumnDef<T>[]
                        }
                        allColumns={allColumns ?? columns}
                        setColumns={setColumns}
                        defaultColumns={defaultColumns ?? []}
                        tableHeaderType={tableHeaderType}
                    />
                </SidebarMenu>
            )}
            <Pagination position="upper" />
            <TableWrapper>
                <Container disabled={disableTable}>
                    <THead>
                        {table.getHeaderGroups().map((headerGroup, headerIndex) => {
                            const rowKey = `tr${headerIndex}`;
                            return (
                                <Tr key={rowKey}>
                                    {onSelectedRowsChange && (
                                        <Th>
                                            <IndeterminateCheckbox
                                                {...{
                                                    checked: table.getIsAllRowsSelected(),
                                                    indeterminate: table.getIsSomeRowsSelected(),
                                                    onChange:
                                                        table.getToggleAllRowsSelectedHandler(),
                                                }}
                                            />
                                        </Th>
                                    )}
                                    {headerGroup.headers.map((header, i) => {
                                        const { column } = header;
                                        const key = `header-${column.id}-${i}`;
                                        const isSortable = column.getCanSort();

                                        return (
                                            <React.Fragment key={key}>
                                                {!column.columnDef.meta?.hideHeader && (
                                                    <Th
                                                        isSortable={isSortable}
                                                        style={
                                                            column.columnDef.meta
                                                                ?.style as CSSProperties
                                                        }
                                                    >
                                                        <Flex
                                                            data-testid="sort-header"
                                                            onClick={
                                                                column.id !== 'selection' &&
                                                                isSortable
                                                                    ? column.getToggleSortingHandler()
                                                                    : () => {}
                                                            }
                                                            style={{
                                                                justifyContent:
                                                                    column.columnDef.meta?.style
                                                                        ?.textAlign,
                                                            }}
                                                        >
                                                            {flexRender(
                                                                column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            <span>
                                                                {column.getIsSorted() ? (
                                                                    <SortArrow
                                                                        style={sortArrowStyle(
                                                                            column.getIsSorted() ===
                                                                                'desc'
                                                                        )}
                                                                    >
                                                                        <ArrowDownSLineIcon size="17" />
                                                                    </SortArrow>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </span>
                                                        </Flex>
                                                    </Th>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                    {columnMenu && (
                                        <ThIcon onClick={() => setToggleSortMenu(true)}>
                                            <StyledMenuLineIcon size="17" />
                                        </ThIcon>
                                    )}
                                </Tr>
                            );
                        })}
                    </THead>

                    <tbody>
                        {table.getRowModel().rows.map((row: Row<T>, rowIndex: number) => {
                            const expandableKey = `expandable-${rowIndex}`;

                            return (
                                <React.Fragment key={expandableKey}>
                                    <Tr
                                        data-testid={`table-row-${rowIndex}`}
                                        isRowClickable={!!onRowClicked}
                                        onClick={e => rowClickRedirect(e, row.original)}
                                        onMouseDown={e => onMiddleMouseButtonClick(e, row.original)}
                                        isCrossedOver={row.original.isCrossedOver}
                                        isModified={row.original.isChanged || row.original.isAdded}
                                    >
                                        {(onSelectedRowsChange || selectableProperty) && (
                                            <Td onClick={e => e.stopPropagation()}>
                                                <IndeterminateCheckbox
                                                    id={
                                                        selectableProperty
                                                            ? selectableProperty(row.original)
                                                            : undefined
                                                    }
                                                    checked={row.getIsSelected()}
                                                    indeterminate={row.getIsSomeSelected()}
                                                    onChange={row.getToggleSelectedHandler()}
                                                />
                                            </Td>
                                        )}

                                        {row.getVisibleCells().map((cell, i) => {
                                            const cellKey = `cell${i}`;

                                            return (
                                                <Td
                                                    style={{
                                                        ...(cell.column.columnDef.meta
                                                            ?.style as CSSProperties),
                                                        ...(getRowStyles &&
                                                            getRowStyles(row.original)),
                                                    }}
                                                    key={cellKey}
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            );
                                        })}

                                        {columnMenu && <Td />}
                                    </Tr>
                                    {row.original.isExpanded && (
                                        <Tr key={expandableKey} isExpanded>
                                            <Td colSpan={row.getVisibleCells().length}>
                                                {expandable?.expandableContent(row.original)}
                                            </Td>
                                        </Tr>
                                    )}
                                </React.Fragment>
                            );
                        })}

                        {newRowProperties !== undefined && newRowProperties.newRowAdded && (
                            <Tr isExpanded>
                                <Td
                                    colSpan={
                                        table.getRowModel().rows.length > 0
                                            ? table.getRowModel().rows[0].getAllCells().length
                                            : undefined
                                    }
                                >
                                    {newRowProperties.newRowContent}
                                </Td>
                            </Tr>
                        )}
                    </tbody>
                </Container>
            </TableWrapper>
            <Pagination position="lower" />
        </>
    );
}

export default Table;

const SortArrow = styled.div`
    width: 100%;
`;

interface TableSkinProps {
    disabled?: boolean;
}

const TableWrapper = styled.div`
    overflow-x: auto;
    width: 100%;
`;

const Container = styled.table<TableSkinProps>`
    ${props => skin.Container()}
    ${props =>
        props.disabled &&
        `
        opacity: 0.5;
        pointer-events: none;
    `}
`;

const THead = styled.thead`
    background-color: ${props => props.theme.colors.subtle.light};
`;

const StyledMenuLineIcon = styled(MenuLineIcon)`
    transform: rotate(90deg);
    color: ${props => props.theme.colors.veryDark};
`;

const Tr = styled.tr<skin.StyledProps>`
    ${props => skin.Tr()};
`;

const Th = styled.th<skin.StyledProps>`
    ${props => skin.Th()}
`;
const Td = styled.td<skin.StyledProps>`
    padding: ${props =>
        `${props.theme.layout.padding.medium} ${props.theme.layout.padding.medium}`};
`;

const PaginationStyle = styled.div`
    ${() => skin.Pagination()}
`;
const Results = styled.div`
    ${() => skin.Td()}
`;
const NextPrevButton = styled.button`
    ${() => skin.NextPrevButton()}
    display: flex;
    align-items: center;

    &:hover {
        cursor: default;
        ${props =>
            !props.disabled &&
            `
        background-color: ${props.theme.colors.subtle.lightHover};
        cursor: pointer;
        `}
    }
`;

const PrevButton = styled(NextPrevButton)`
    border-radius: 0.4rem 0 0 0.4rem;
    border-right: none;
`;
const NextButton = styled(NextPrevButton)`
    border-radius: 0 0.4rem 0.4rem 0;
`;

const ThIcon = styled.th`
    cursor: pointer;
`;
