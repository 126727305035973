import styled from 'styled-components';

const CenterContent = styled.div`
    padding: 0 2.5rem;
    margin: 0 auto;
    max-width: 144rem;
    @media (max-width: 50rem) {
        max-width: 100rem;
    }
    transition: max-width 0.5s;
`;

export default CenterContent;
