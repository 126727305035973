import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreConfiguration } from '../../../types/admin/StoreConfiguration';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';

export interface StoresState {
    isLoading: boolean;
    isLoaded: boolean;
    store: ResponseData<StoreConfiguration>;
    getError?: ResponseError;
    patchError?: ResponseError;
    postLogoError?: ResponseError;
}

const initialState: StoresState = {
    isLoading: false,
    isLoaded: false,
    store: {} as ResponseData<StoreConfiguration>,
};

export const slice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
        getStarted: state => ({
            ...state,
            isLoading: true,
            isLoaded: false,
            store: {} as ResponseData<StoreConfiguration>,
            getError: undefined,
        }),
        getSucceeded: (state, action: PayloadAction<ResponseData<StoreConfiguration>>) => ({
            ...state,
            isLoading: false,
            isLoaded: true,
            store: action.payload,
        }),
        getFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isLoading: false,
            getError: action.payload,
        }),
        patchStarted: state => ({
            ...state,
            patchError: undefined,
        }),
        patchSucceeded: state => ({
            ...state,
        }),
        patchFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            patchError: action.payload,
        }),
        postLogoStarted: state => ({
            ...state,
            postLogoError: undefined,
        }),
        postLogoSucceeded: state => ({
            ...state,
        }),
        postLogoFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            postLogoError: action.payload,
        }),
    },
});

export const {
    getStarted,
    getSucceeded,
    getFailed,
    patchStarted,
    patchSucceeded,
    patchFailed,
    postLogoStarted,
    postLogoSucceeded,
    postLogoFailed,
} = slice.actions;

export default slice.reducer;
