import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../../services/helpers/dateTimeFormats';
import { formatNumber } from '../../../services/helpers/numberFormats';
import getStoreInfo from '../../../services/helpers/storeHelper';
import { Account } from '../../../types/account/Account';
import { ConnectedStore } from '../../../types/ConnectedStore';
import { CustomerDetails } from '../../../types/customer/CustomerDetails';
import InvoiceState from '../../../types/invoice/InvoiceState';
import InvoiceStatus from '../../../types/invoice/InvoiceStatus';
import StatusTypes from '../../../types/StatusTypes';
import NotificationState from '../../atoms/NotificationState/NotificationState';
import Status from '../../atoms/Status/Status';
import ColumnStoreLabel from '../../atoms/Store/ColumnStoreLabel';
import TwoRowCell from '../../atoms/Table/TwoRowCell';

const generateAccountColumnHeaders = (availableStores: ConnectedStore[]): ColumnDef<Account>[] => {
    return [
        {
            header: 'Invoice no',
            enableSorting: false,
            accessorKey: 'notifications',
            cell: (props: { row: { original: Account } }) => {
                if (props.row.original.notifications.length === 0) return <span>-</span>;

                const notification = props.row.original.notifications[0].data;

                return <span>{notification.invoiceNumber}</span>;
            },
        },
        {
            header: 'Invoice date',
            enableSorting: false,
            accessorKey: 'notifications',
            cell: (props: { row: { original: Account } }) => {
                if (props.row.original.notifications.length === 0) return <span>-</span>;

                const notification = props.row.original.notifications[0].data;

                const timeArray = formatDate(new Date(notification.notificationDate));
                return (
                    <>
                        {notification.notificationDate ? (
                            <TwoRowCell upper={timeArray.yyyyMMdd} lower={timeArray.HHmm} />
                        ) : (
                            <span>-</span>
                        )}
                    </>
                );
            },
        },
        {
            header: 'Customer',
            id: 'customerDetails',
            enableSorting: false,
            accessorFn: (props: { customerDetails: CustomerDetails }) => {
                return <span>{props.customerDetails.name}</span>;
            },
            cell: (props: { row: { original: Account } }) => {
                const account = props.row.original;

                return (
                    <CustomerWrapper>
                        <span>
                            {account.customerDetails.name !== ' '
                                ? account.customerDetails.name
                                : account.customerDetails.email}
                        </span>
                    </CustomerWrapper>
                );
            },
        },
        {
            header: 'Store',
            enableSorting: false,
            accessorKey: 'storeId',
            meta: {
                style: {
                    textAlign: 'left',
                },
            },
            cell: (props: { row: { original: Account } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return storeInfo && storeInfo.storeName ? (
                    <ColumnStoreLabel store={storeInfo} />
                ) : (
                    <span>{storeId}</span>
                );
            },
        },
        {
            header: 'Account type',
            accessorKey: 'type',
            enableSorting: false,
            cell: (props: { row: { original: Account } }) => {
                return <span>{props.row.original.type}</span>;
            },
        },
        {
            header: 'Payment status',
            enableSorting: false,
            accessorKey: 'notifications',
            meta: {
                style: {
                    textAlign: 'left',
                },
            },
            cell: (props: { row: { original: Account } }) => {
                if (props.row.original.notifications.length === 0) return <span>-</span>;

                const notification = props.row.original.notifications[0].data;

                if (notification.state === InvoiceState.CreditNote) return <span>-</span>;

                const paymentStatus =
                    notification.canceled &&
                    notification.paymentStatus !== InvoiceStatus.FullyPaid &&
                    notification.paymentStatus !== InvoiceStatus.PartiallyPaid
                        ? InvoiceStatus.Canceled
                        : notification.paymentStatus;

                return <Status status={paymentStatus} type={StatusTypes.Invoice} />;
            },
        },
        {
            header: 'Invoice state',
            enableSorting: false,
            accessorKey: 'notifications',
            cell: (props: { row: { original: Account } }) => {
                if (props.row.original.notifications.length === 0) return <span>-</span>;

                const notification = props.row.original.notifications[0].data;

                return (
                    <NotificationState
                        state={notification.state}
                        canceled={notification.canceled}
                    />
                );
            },
        },
        {
            header: 'Left to pay',
            enableSorting: false,
            accessorKey: 'leftToPay',
            cell: (props: { row: { original: Account } }) => {
                const notification = props.row.original;
                const totalAmountFormats = formatNumber(notification.leftToPay ?? 0);
                return (
                    <TwoRowCell
                        upper={totalAmountFormats}
                        lower={notification.currency}
                        align="right"
                    />
                );
            },
        },
    ];
};

const defaultAccountColumnHeaders = ['AccountId'];

export { generateAccountColumnHeaders, defaultAccountColumnHeaders };

const CustomerWrapper = styled.div`
    display: inline-flex;
    gap: 0.5rem;

    span {
        line-height: 2.5rem;
    }
`;
