import api from '../../services/api';
import { LinkData } from '../../types/response/LinkData';
import { ResponseData } from '../../types/response/ResponseData';
import { AppThunkAction, AppThunkDispatch } from '../thunk';
import { Account } from '../../types/account/Account';
import ResponseError from '../../types/response/ResponseError';
import { NotificationRow } from '../../types/account/NotificationRow';
import { showProcessingNotComplete } from '../session/sessionSlice';
import {
    getAccountStarted,
    getAccountSucceeded,
    getAccountFailed,
    accountExtendDueDateStarted,
    accountExtendDueDateSucceeded,
    accountExtendDueDateFailed,
    clearExtendDueDateError,
    resendInvoiceStarted,
    resendInvoiceFailed,
    resendInvoiceSucceeded,
    clearResendInvoiceError,
    creditFeeStarted,
    creditFeeFailed,
    creditFeeSucceeded,
    clearCreditFeeError,
} from './accountSlice';

const getAccount =
    (accountId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getAccountStarted());

        try {
            const response = await api.get<
                ResponseData<Account, Record<string, unknown>, Record<string, LinkData>>
            >(`accounts/${accountId}`);
            dispatch(getAccountSucceeded(response));
        } catch (error) {
            dispatch(getAccountFailed(error as ResponseError));
        }
    };

const extendAccount =
    (extendLink: LinkData): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(clearExtendDueDateError());
        dispatch(accountExtendDueDateStarted());

        try {
            const response = await api.callLink<
                unknown,
                ResponseData<Account, Record<string, unknown>, Record<string, LinkData>>
            >(() => dispatch(showProcessingNotComplete(true)), extendLink);

            dispatch(accountExtendDueDateSucceeded(response));
        } catch (error) {
            dispatch(accountExtendDueDateFailed(error as ResponseError));
        }
    };

const resendInvoice =
    (resendLink: LinkData, deliveryMethod: string, recipient: string | null): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(clearResendInvoiceError());
        dispatch(resendInvoiceStarted());

        const resendData = {
            deliveryMethod,
            recipient,
        };

        try {
            const response = await api.callLink<
                unknown,
                ResponseData<Account, Record<string, unknown>, Record<string, LinkData>>
            >(() => dispatch(showProcessingNotComplete(true)), resendLink, resendData);

            dispatch(resendInvoiceSucceeded(response));
        } catch (error) {
            dispatch(resendInvoiceFailed(error as ResponseError));
        }
    };

const creditFee =
    (creditFeeLink: LinkData, creditFees: NotificationRow[]): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(clearCreditFeeError());
        dispatch(creditFeeStarted());

        const transactionIds = creditFees
            .flatMap(x => x.feeTransactions?.map(t => t.transactionId))
            .concat(creditFees.map(x => x.transactionId))
            .filter(x => x != null);

        try {
            const response = await api.callLink<
                unknown,
                ResponseData<Account, Record<string, unknown>, Record<string, LinkData>>
            >(() => dispatch(showProcessingNotComplete(true)), creditFeeLink, {
                transactionIds,
            });

            dispatch(creditFeeSucceeded(response));
        } catch (error) {
            dispatch(creditFeeFailed(error as ResponseError));
        }
    };

const creditFeeOld =
    (creditFeeLink: LinkData, creditFees: NotificationRow[]): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(clearCreditFeeError());
        dispatch(creditFeeStarted());

        const transactionIds = creditFees.map(x => x.transactionId);

        try {
            const response = await api.callLink<
                unknown,
                ResponseData<Account, Record<string, unknown>, Record<string, LinkData>>
            >(() => dispatch(showProcessingNotComplete(true)), creditFeeLink, {
                transactionIds,
            });

            dispatch(creditFeeSucceeded(response));
        } catch (error) {
            dispatch(creditFeeFailed(error as ResponseError));
        }
    };

export default {
    getAccount,
    extendAccount,
    resendInvoice,
    creditFee,
    creditFeeOld,
};
