/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserLineIcon from 'remixicon-react/UserLineIcon';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Spinner from '../../../atoms/Spinner/Spinner';
import { useAppState } from '../../../../store/appstate';
import { User } from '../../../../types/User';
import Button from '../../../atoms/Button/Button';
import { SessionOrganization } from '../../../../types/SessionUser';
import DateTime from '../../../atoms/DateTime/DateTime';
import useModal from '../../../../services/hooks/useModal';
import { Modal } from '../../../atoms/Modal/Modal';
import DeleteUserModal from './DeleteUserModal';
import userActions from '../../../../store/admin/users/actions';
import Card from '../../../atoms/Card/Card';
import CardBody from '../../../atoms/Card/CardBody';
import { UsersState } from '../../../../store/admin/users/usersSlice';
import EmptyState from '../../../atoms/Information/EmptyState';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import { useAppDispatch } from '../../../../store';
import Table from '../../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import DecoratorInput from '../../../atoms/Input/DecoratorInput';
import FilterLineIcon from 'remixicon-react/Filter3LineIcon';
import useFilter from '../../../../services/hooks/useFilter';

interface SearchResultsProps {
    hasWrite: boolean;
    data: User[];
    theme: DefaultTheme;
    toggleInvite: () => void;
}

const UserSearchResults: React.FC<SearchResultsProps> = ({
    hasWrite,
    data,
    toggleInvite,
    theme,
}: SearchResultsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isShown, toggle } = useModal();

    const { isSearching, error } = useAppState<UsersState>(s => s.admin.users);
    const organization = useAppState<SessionOrganization>(s => s.session.selectedOrganization);

    const filterUsers = (x: User, filterBySearchWord: (property: string) => boolean) =>
        filterBySearchWord(x.givenName) ||
        filterBySearchWord(x.name) ||
        x.emails?.some(email => filterBySearchWord(email));

    const { search, setSearch, showing, showFilter } = useFilter(data, filterUsers);
    const [userToDelete, setUserToDelete] = useState('');

    const onRowClick = (row: User) => {
        navigate(`/admin/users/${row.id}`);
    };

    const handleUserDelete = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        setUserToDelete(id);
        toggle();
    };

    const onRemoveUser = (deleteId: string) => {
        dispatch(userActions.deleteUser(organization.id, deleteId));
        setUserToDelete('');
    };

    const columns: ColumnDef<User>[] = [
        {
            header: 'Name',
            accessorKey: 'name',
            cell: (props: { row: { original: User } }) => {
                return <div>{props.row.original.name}</div>;
            },
        },
        {
            header: 'Email',
            accessorKey: 'emails',
            cell: (props: { row: { original: User } }) => {
                const { emails } = props.row.original;
                return <div>{emails.join(', ')}</div>;
            },
        },
        {
            header: 'Latest activity',
            accessorKey: 'latestActivityAt',
            cell: (props: { row: { original: User } }) => {
                return (
                    <>
                        {props.row.original.latestActivityAt && (
                            <DateTime flat>{props.row.original.latestActivityAt}</DateTime>
                        )}
                    </>
                );
            },
        },
        {
            header: '',
            id: 'removeUserId',
            accessorKey: 'id',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: User } }) => {
                return (
                    <Button
                        distinct
                        onClick={e => handleUserDelete(e, props.row.original.id)}
                        disabled={!hasWrite}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    const getUserName = () => {
        const user = data.find(x => x.id === userToDelete);
        return user?.name ?? '';
    };

    const renderSearchResults = () => {
        if (error) return <ErrorMessage error={error} errorHeader={ErrorType.LoadUsers} />;

        if (showing.length === 0 && search === '') {
            return (
                <EmptyState
                    title="It's empty over here!"
                    icon={
                        <UserLineIcon size={theme.icon.size.xlarge} color={theme.colors.accent3} />
                    }
                >
                    <div>
                        You haven&apos;t invited any users to this organization yet. Get started by
                        inviting users.
                    </div>
                    <Button onClick={() => toggleInvite()}>Invite user</Button>
                </EmptyState>
            );
        }

        return (
            <>
                {showFilter && (
                    <SearchWrapper>
                        <DecoratorInput
                            type="text"
                            name="filterUsers"
                            onChange={e => setSearch(e.target.value)}
                            value={search}
                            placeholder="Filter users"
                            id="filter-users"
                            decorator={<FilterLineIcon size="17" />}
                        />
                    </SearchWrapper>
                )}

                <Card>
                    <CardBody>
                        <Table<User> data={showing} columns={columns} onRowClicked={onRowClick} />
                    </CardBody>
                </Card>

                <Modal small isShown={isShown} hide={toggle}>
                    <DeleteUserModal
                        hide={toggle}
                        name={getUserName()}
                        id={userToDelete}
                        onRemoveUser={onRemoveUser}
                    />
                </Modal>
            </>
        );
    };

    return isSearching ? <Spinner loading={isSearching} /> : renderSearchResults();
};

export default withTheme(UserSearchResults) as React.ComponentType<
    Omit<SearchResultsProps, 'theme'>
>;

const SearchWrapper = styled.div`
    margin-bottom: 1rem;
    background: white;
`;
