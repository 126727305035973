import api from '../../../services/api';
import { StoreConfiguration } from '../../../types/admin/StoreConfiguration';
import { PatchOperation } from '../../../types/requests/PatchOperation';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import {
    getStarted,
    getSucceeded,
    getFailed,
    patchStarted,
    patchSucceeded,
    patchFailed,
    postLogoStarted,
    postLogoSucceeded,
    postLogoFailed,
} from './storesSlice';

const getStore =
    (storeId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getStarted());

        if (!storeId) {
            return;
        }

        try {
            const response = await api.get<ResponseData<StoreConfiguration>>(`stores/${storeId}`);

            dispatch(getSucceeded(response));
        } catch (error) {
            dispatch(getFailed(error as ResponseError));
        }
    };

const patchStore =
    (storeId: string, profile: string, color: string, termsUrl: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(patchStarted());

        try {
            await api.patch<PatchOperation[]>(`stores/${storeId}/profiles/${profile}`, [
                { op: 'replace', path: '/colorSettings/actionColor', value: color },
                { op: 'replace', path: '/terms/merchantTermsUri', value: termsUrl },
            ]);

            dispatch(patchSucceeded());
        } catch (error) {
            dispatch(patchFailed(error as ResponseError));
        }
    };

const postLogo =
    (storeId: string, logoUrl: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(postLogoStarted());

        try {
            await api.post<{ logoUrl: string }>(`stores/${storeId}/logo`, { logoUrl });

            dispatch(postLogoSucceeded());
        } catch (error) {
            dispatch(postLogoFailed(error as ResponseError));
        }
    };

export default {
    getStore,
    patchStore,
    postLogo,
};
