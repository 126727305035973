import styled, { css } from 'styled-components';

interface TitleProps {
    primary?: boolean;
}

const Title = styled.h2<TitleProps>`
    margin: 0;
    padding: 0;
    color: ${props => props.theme.colors.text.primary};
    ${props =>
        props.primary &&
        css`
            background: ${props.theme.colors.primary};
            color: white;
            font-family: ${props.theme.text.font.medium};
        `};
`;

export default Title;
