import React, { ReactNode } from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { scaleOrdinal } from '@visx/scale';
import { LegendOrdinal, LegendItem } from '@visx/legend';
import { DefaultTheme, withTheme } from 'styled-components';
import Flex from '../Box/Flex';
import styled from 'styled-components';

export interface DonutGraphProps<T> {
    data: T[];
    xAccessor: (x: T) => Date | string | number | undefined;
    yAccessor: (x: T) => Date | string | number | undefined;
    height: number;
    theme: DefaultTheme;
}

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

function DonutGraph<T extends object>({
    data,
    xAccessor,
    yAccessor,
    height,
    theme,
}: DonutGraphProps<T>) {
    const donutThickness = 60;
    const pieSortValues = (a: number, b: number) => b - a;

    const total = data.reduce((x, y) => x + (yAccessor(y) as number), 0);
    const getLabel = (l: T) => (
        <AlignedLabel>
            <Label title={xAccessor(l) as string}>{xAccessor(l) as string}</Label>&nbsp;
            <div>{(((yAccessor(l) as number) / total) * 100).toFixed(2)}%</div>
        </AlignedLabel>
    );

    const formatedLabels = data.reduce(
        (x, y) => ({ ...x, [xAccessor(y) as string]: getLabel(y) }),
        {} as Record<string, ReactNode>
    );

    const getLetterFrequencyColor = scaleOrdinal({
        range: [
            theme.colors.primary,
            theme.colors.accent2,
            theme.colors.accent3,
            theme.colors.accent4,
            theme.colors.accent5,
            theme.colors.otherPrimary,
            theme.colors.otherAccent2,
            theme.colors.otherAccent3,
            theme.colors.otherAccent4,
            theme.colors.otherAccent5,
        ],
    });

    const margin = defaultMargin;

    return (
        <GraphContainer row alignItems="center">
            <div style={{ width: '60%' }}>
                <ParentSize>
                    {({ width }) => {
                        const innerWidth = width - margin.left - margin.right;
                        const innerHeight = height - margin.top - margin.bottom;
                        const radius = Math.min(innerWidth, innerHeight) / 2;
                        const centerY = innerHeight / 2;
                        const centerX = innerWidth / 2;
                        const top = centerY + margin.top;
                        const left = centerX + margin.left;

                        return (
                            <svg width={width} height={height}>
                                <Group top={top} left={left}>
                                    <Pie
                                        data={data}
                                        pieValue={d => yAccessor(d) as number}
                                        pieSortValues={pieSortValues}
                                        outerRadius={radius}
                                        innerRadius={radius - donutThickness}
                                    >
                                        {pie => {
                                            return pie.arcs.map(arc => {
                                                const value = xAccessor(arc.data) as string;

                                                const arcPath = pie.path(arc) ?? undefined;
                                                const arcFill = getLetterFrequencyColor(value);
                                                return (
                                                    <g key={`arc-${value}`}>
                                                        <path d={arcPath} fill={arcFill} />
                                                    </g>
                                                );
                                            });
                                        }}
                                    </Pie>
                                </Group>
                            </svg>
                        );
                    }}
                </ParentSize>
            </div>
            <LabelContainer>
                <LegendOrdinal scale={getLetterFrequencyColor}>
                    {labels => (
                        <Flex column gap={theme.layout.gap.xsmall}>
                            {labels.map(label => {
                                return (
                                    <LegendItem
                                        key={`legend-quantile-${label.text}`}
                                        margin="0 5px"
                                    >
                                        <ColoredCircle color={label.value ?? ''} />
                                        <LabelValue>{formatedLabels[label.text]}</LabelValue>
                                    </LegendItem>
                                );
                            })}
                        </Flex>
                    )}
                </LegendOrdinal>
            </LabelContainer>
        </GraphContainer>
    );
}

const ColoredCircle = styled.div`
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: ${({ color }) => color};
    border-radius: 50%;
`;

const Label = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    max-width: 15rem;
`;

const AlignedLabel = styled.div`
    display: flex;
    justify-content: space-between;
`;

const LabelContainer = styled.div`
    width: 40%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;

    > div {
        width: 100%;
    }

    @media (max-width: 50rem) {
        width: 100%;
    }
`;

const LabelValue = styled.div`
    display: block;
    width: 100%;
    margin-left: 0.5rem;
`;

const GraphContainer = styled(Flex)`
    height: 100%;
    @media (max-width: 50rem) {
        flex-direction: column;
    }
`;

export default withTheme(DonutGraph) as <T>(
    props: Partial<DonutGraphProps<T>>
) => React.ReactElement;
