import { lighten, transparentize } from 'polished';

export const walleyLightTheme = {
    background: '#fff',
    text: {
        size: {
            xxsmall: '1rem',
            xsmall: '1.2rem',
            small: '1.3rem',
            medium: '1.4rem',
            large: '1.6rem',
            xlarge: '2rem',
            xxlarge: '4rem',
        },
        type: {
            bold: 'HKGrotesk-Bold',
            medium: 'HKGrotesk-Medium',
        },
        font: {
            regular: 'HKGrotesk-Regular',
            medium: 'HKGrotesk-Medium',
            bold: 'HKGrotesk-Bold',
        },
    },
    icon: {
        size: {
            xxsmall: '8',
            xsmall: '12',
            small: '16', // Standard
            normal: '24',
            large: '32',
            xlarge: '40',
            xxlarge: '48',
        },
    },
    timeout: {
        short: 300,
        medium: 600,
        long: 1000,
        veryLong: 6000,
    },
    colors: {
        primary: '#3D388B',
        lightPrimary: transparentize(0.9, '#3D388B'),
        mediumPrimary: transparentize(0.7, '#3D388B'),
        hoverPrimary: transparentize(0.2, '#3D388B'),
        otherPrimary: '#7374CD',
        success: '#41A664',
        accent: lighten(0.57, '#3D388B'),
        accent2: '#3DA8B8',
        lightAccent2: transparentize(0.9, '#3DA8B8'),
        lighterAccent2: '#DEF1F4',
        otherAccent2: '#1A90A0',
        lighterAccent3: '#FDF3E1',
        accent3: '#F2B94C',
        otherAccent3: '#A67700',
        accent4: '#EAB3B5',
        otherAccent4: '#A37173',
        accent5: '#F94D3D',
        otherAccent5: '#E33528',
        error: '#F02C2C',
        info: '#5bc0de',
        warning: '#f0ad4e',
        disabled: '#d6d4d4',
        light: '#ffffff',
        medium: '#f4f4f4',
        dark: '#C7C7C7',
        veryDark: '#737272',
        black: 'black',
        defaultIcon: '#b9b9b9',
        payment: '#f5f5f5',
        background: '#f9fcff',
        shadow: 'rgba(183, 183, 183, 0.2)',
        subtle: {
            regular: '#a3a3a4',
            light: '#f9fafc',
            lightHover: '#edeef0',
            lighter: '#fdfdfd',
        },
        text: {
            primary: '#333',
            secondary: '#888',
            subtleSecondary: '#b5b5b5',
            subtle: '#efefef',
        },
        status: {
            activated: '#0ebd0e',
            partActivated: '#3087ff',
            notActivated: '#ffbe2c',
            closed: '#888888',
            onHold: '#760ebd',
            paid: '#0ebd0e',
            partiallyPaid: '#3087ff',
            notPaid: '#ffbe2c',
            canceled: '#888888',
            default: 'gray',
        },
        apiSecret: {
            status: {
                expired: '#E34234',
                expiresSoon: '#ffbe2c',
                activate: '#0ebd0e',
            },
        },
        toast: {
            info: '#ededf8',
        },
        snackbar: {
            normal: '#ededf8',
            error: {
                background: '#ff8c8c',
                text: '#fff',
            },
        },
        tag: {
            default: '#f7f7f7',
        },
        card: {
            border: '#e3e3e3',
        },
        border: '#e4e4e4',
        input: {
            border: '#bababa',
        },
        navBar: {
            background: '#040e28',
            shineThrough: '#646d8280',
            active: '#8c96af80',
            foreground: '#fff',
        },
        table: {
            row: '#fff',
            modifiedRow: '#d7d5ee',
        },
        button: {
            light: '#F0F0F0',
            medium: '#DEDEDE',
            primary: {
                text: '#fff',
            },
            secondary: {
                color: '#fff',
                border: '#bfd4d9',
                hover: '#F9F9F9',
                text: '#000000',
            },
        },
        skeletonLoader: {
            primary: '#ebebeb',
            secondary: '#f3f2f2',
        },
    },
    layout: {
        padding: {
            xxsmall: '0.3rem',
            xsmall: '0.5rem',
            small: '1rem',
            medium: '1.5rem',
            large: '2rem',
            xxl: '3rem',
        },
        margin: {
            xxsmall: '0.3rem',
            xsmall: '0.5rem',
            small: '1rem',
            xmedium: '1.25rem',
            medium: '1.5rem',
            large: '2rem',
            xLarge: '3rem',
        },
        gap: {
            xxsmall: '0.1rem',
            xsmall: '0.5rem',
            small: '1rem',
            medium: '2rem',
            large: '3rem',
            xLarge: '4rem',
        },
    },
};

export const themeForTests = walleyLightTheme;
