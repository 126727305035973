import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

type Props = {
    dueDate: Date;
    theme: DefaultTheme;
};

const DueIn: React.FC<Props> = ({ dueDate, theme }: Props) => {
    const timeDifference = () => {
        const maxDays = 31;
        const msInOneDay = 86400000;
        const diff = dueDate.valueOf() - new Date().valueOf();
        const diffInDays = Math.abs(Math.floor(diff / msInOneDay));

        if (diffInDays > maxDays) return ``;

        if (diff < 0 && diffInDays === 1) return `1 day overdue`;

        if (diff < 0) return `${diffInDays} days overdue`;

        if (diffInDays === 0) return `Today`;

        if (diffInDays === 1) return `Tomorrow`;

        return `In ${diffInDays} days`;
    };
    return (
        <ExpireTime theme={theme} data-testid="due-in">
            {timeDifference()}
        </ExpireTime>
    );
};

interface ExpireTimeProps {
    theme: DefaultTheme;
}

const ExpireTime = styled.div<ExpireTimeProps>`
    font-family: ${props => props.theme.text.font.medium};
`;

export default DueIn;
