import React from 'react';
import styled, { css } from 'styled-components';

interface InputProps {
    primary?: boolean;
    setRef?: (instance: HTMLInputElement | null) => void;
    validationError?: boolean;
    focus?: boolean;
    background?: boolean;
}
type InputType = InputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input: React.FC<InputType> = ({
    primary,
    disabled,
    type,
    name,
    id,
    placeholder,
    setRef,
    onChange,
    onFocus,
    onBlur,
    focus,
    validationError,
    autoComplete = 'off',
    value,
    background,
    min,
    max,
    step,
    defaultValue,
}: InputType) => {
    return (
        <StyledInput
            primary={primary}
            disabled={disabled}
            type={type}
            name={name}
            id={id}
            placeholder={placeholder}
            ref={setRef}
            onChange={onChange}
            focus={focus}
            validationError={validationError}
            autoComplete={autoComplete}
            value={value}
            background={background}
            max={max}
            min={min || 0}
            step={step}
            defaultValue={defaultValue}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
};

const StyledInput = styled.input<InputProps>`
    background: ${props => (props.background ? 'white' : 'transparent')};
    border-radius: 0.3rem;
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    box-sizing: border-box;
    border-style: solid;
    outline: none;
    border-width: 0.1rem;
    border: 0.1rem solid ${props => props.theme.colors.border};
    &:focus {
        border: 0.1rem solid ${props => props.theme.colors.primary};
        outline: none;
    }
    &:disabled {
        opacity: 0.5;
    }
    ${props =>
        props.validationError &&
        css`
            &:focus {
                border: 0.2rem solid ${props.theme.colors.error};
                outline: none;
            }
        `}
`;

export default Input;
