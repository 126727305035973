export type DateRangeState = {
    to: string;
    from: string;
    focusedInput: string;
    fromSet: boolean;
    toSet: boolean;
    fromDate: Date;
    toDate: Date;
    currentMonth: number | null;
    currentYear: number | null;
};
export enum DateRangeConstants {
    FOCUS_CHANGE = 'focusChange',
    FROM_CHANGE = 'fromChange',
    TO_CHANGE = 'toChange',
    RESET_STATE = 'resetState',
    FROM_DATE = 'fromDate',
    TO_DATE = 'toDate',
    RESET_FROM = 'resetFrom',
    RESET_TO = 'resetTO',
    APPLY = 'apply',
    SET_MONTH = 'setMonth',
    CHANGE_MONTH = 'changeMonth',
}

const { FOCUS_CHANGE, FROM_CHANGE, TO_CHANGE, RESET_STATE, RESET_FROM, RESET_TO, SET_MONTH } =
    DateRangeConstants;

interface DateRangeFocusChange {
    type: DateRangeConstants.FOCUS_CHANGE;
    payload: string;
}
interface DateRangeFromChange {
    type: DateRangeConstants.FROM_CHANGE;
    payload: string;
}
interface DateRangeToChange {
    type: DateRangeConstants.TO_CHANGE;
    payload: string;
}
interface DateRangeResetState {
    type: DateRangeConstants.RESET_STATE;
    payload: DateRangeState;
}

interface DateRangeResetFrom {
    type: DateRangeConstants.RESET_FROM;
}

interface DateRangeResetTo {
    type: DateRangeConstants.RESET_TO;
}

interface DateRangeSetMonth {
    type: DateRangeConstants.SET_MONTH;
    payload: { month: number | null; year: number | null };
}

export type DateRangeActions =
    | DateRangeFocusChange
    | DateRangeFromChange
    | DateRangeToChange
    | DateRangeResetState
    | DateRangeResetFrom
    | DateRangeResetTo
    | DateRangeSetMonth;

export const isValidDateString = (value: string) => {
    return (
        value.length === 10 &&
        new Date(value).toDateString() !== 'Invalid Date' &&
        !Number.isNaN(new Date(value).getTime()) &&
        new Date(value).getFullYear() >= 1900
    );
};

export const reducer = (state: DateRangeState, action: DateRangeActions) => {
    switch (action.type) {
        case FOCUS_CHANGE:
            return { ...state, focusedInput: action.payload };
        case FROM_CHANGE:
            return {
                ...state,
                from: action.payload,
                fromDate: new Date(action.payload),
                fromSet: action.payload !== '',
            };
        case TO_CHANGE:
            return {
                ...state,
                to: action.payload,
                toDate: new Date(action.payload),
                toSet: action.payload !== '',
            };
        case RESET_STATE:
            return {
                ...action.payload,
            };
        case RESET_FROM:
            return {
                ...state,
                from: '',
                fromSet: false,
                focusedInput: 'fromDate',
            };
        case RESET_TO:
            return {
                ...state,
                to: '',
                toSet: false,
                focusedInput: 'toDate',
                currentMonth: null,
            };
        case SET_MONTH:
            return {
                ...state,
                currentMonth: action.payload.month,
                currentYear: action.payload.year,
            };
        default:
            return {} as DateRangeState;
    }
};
