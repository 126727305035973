import { ReportColumnType } from '../../types/report/ReportColumnType';

export const ordersReportColumns = {
    'Placed At': ReportColumnType.PlacedAt,
    'Expires At': ReportColumnType.ExpiresAt,
    Reference: ReportColumnType.OrderNumber,
    Country: ReportColumnType.StoreCountry,
    Store: ReportColumnType.StoreId,
    'Customer Name': ReportColumnType.CustomerName,
    Status: ReportColumnType.Status,
    'Payment Method': ReportColumnType.PaymentMethod,
    'Total Amount': ReportColumnType.TotalAmount,
    'Initial Amount': ReportColumnType.InitialAmount,
    Currency: ReportColumnType.Currency,
    'Customer Type': ReportColumnType.CustomerType,
    'Customer Registration Number': ReportColumnType.CustomerRegistrationNumber,
    'Customer Email': ReportColumnType.CustomerEmail,
    'Product Code': ReportColumnType.ProductCode,
    'Cost Center': ReportColumnType.CostCenter,
    'Reference Person': ReportColumnType.ReferencePerson,
    'Commitment Alias': ReportColumnType.CommitmentAlias,
    'Walley Order Id' : ReportColumnType.OrderId,
};
