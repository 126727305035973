import styled from 'styled-components';

const Column = styled.div`
    padding: 0 ${props => props.theme.layout.padding.medium};
    display: flex;
    flex-direction: column;
    border-right: 0.2rem solid ${props => props.theme.colors.text.subtle};
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
`;

export default Column;
