import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ButtonTabProps {
    tabKey: string;
    text?: string;
    setTab: (key: string) => void;
    currentTab: string;
    subLabel?: string;
    onClick?: (arg0?: string) => void;
    large?: boolean;
    children?: ReactNode;
    disabled?: boolean;
}

const ButtonTab: React.FC<ButtonTabProps> = ({
    tabKey,
    text,
    setTab,
    currentTab,
    subLabel,
    onClick,
    large,
    children,
    disabled,
}: ButtonTabProps) => {
    const handleClick = () => {
        if (onClick) onClick();

        setTab(tabKey);
    };
    return (
        <StyledButtonTab
            disabled={disabled}
            onClick={disabled ? () => {} : () => handleClick()}
            aria-current={currentTab === tabKey || undefined}
            large={large}
        >
            {children && children}
            {text && <Label>{text}</Label>}
            {subLabel && <SubLabelText>{subLabel}</SubLabelText>}
        </StyledButtonTab>
    );
};

export default ButtonTab;

interface StyledButtonTabProps {
    large?: boolean;
    disabled?: boolean;
}

const StyledButtonTab = styled.div<StyledButtonTabProps>`
    font-size: ${props => props.theme.text.size.large};
    color: ${props => props.theme.colors.text.secondary};
    display: flex;
    flex-direction: column;
    padding: ${props => (props.large ? '0.8rem 4rem' : '1rem 1rem;')};
    position: relative;
    text-decoration: none;
    min-width: 8rem;
    align-items: center;
    justify-content: center;
    ${props => !props.disabled && 'border-radius: 0.5rem'};

    &:hover {
        cursor: ${props => (!props.disabled ? 'pointer' : 'default')};
    }

    &:hover * {
        color: ${props => !props.disabled && props.theme.colors.text.primary};
    }

    &[aria-current] {
        background: ${props => props.theme.colors.light};
        box-shadow: 0 1rem 1.2rem 0 ${props => props.theme.colors.shadow};
        color: ${props => props.theme.colors.text.primary};

        &:hover {
            cursor: pointer;
        }
    }

    &:last-of-type {
        border-radius: 0 0.5rem 0.5rem 0;
    }

    &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
    }

    ${props => props.disabled && `color: ${props.theme.colors.disabled}`};
`;

const SubLabelText = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${props => props.theme.text.size.xxsmall};
`;

const Label = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    font-size: ${props => props.theme.text.size.medium};
`;
