import styled, { css } from 'styled-components';
import { darken } from 'polished';

interface ButtonProps {
    color?: string;
    large?: boolean;
    distinct?: boolean;
    type?: string;
    transparent?: boolean;
    disabled?: boolean;
}

const SquareIconLinkButton = styled.a<ButtonProps>`
    cursor: ${props => (props.disabled ? 'no-drop;' : 'pointer')};
    display: flex;
    flex-direction: row;
    border-radius: 0.3rem;
    align-items: flex-end;
    border: 0;
    color: white;
    font-size: 1.2rem;
    transition: 0.3s;
    padding: 0.5rem;
    width: 6rem;
    outline: none;
    margin: 0.2rem;
    justify-content: space-evenly;
    text-decoration: none;
    background: ${props =>
        (props.disabled && props.theme.colors.disabled) ||
        (props.transparent && 'transparent !important')};

    &:hover {
        background: ${props =>
            props.disabled
                ? props.theme.colors.disabled
                : darken(0.03, props.color === 'primary' ? props.theme.colors.accent : '#c6afda')};
    }
    ${props =>
        props.color === 'primary'
            ? css`
                  color: white;
                  background: ${props.disabled
                      ? props.theme.colors.disabled
                      : props.theme.colors.primary};
              `
            : css`
                  color: ${props.theme.colors.primary};
                  background: ${props.disabled
                      ? props.theme.colors.disabled
                      : props.theme.colors.accent};
              `}

    &:active {
        background: ${props =>
            props.color === 'primary' ? props.theme.colors.primary : props.theme.colors.accent};
    }
`;

export default SquareIconLinkButton;
