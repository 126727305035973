import api from '../../services/api';
import { Account } from '../../types/account/Account';
import { CustomerDetailsResponseData } from '../../types/customer/CustomerDetailsResponseData';
import { Order } from '../../types/order/Order';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import ResponseError from '../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../thunk';

import {
    getCustomerStarted,
    getCustomerSucceeded,
    getCustomerFailed,
    getOrdersSucceeded,
    getOrdersFailed,
    getAccountsFailed,
    getAccountsSucceeded,
    getOrdersStarted,
    getAccountsStarted,
} from './customerSlice';

const getCustomerDetails =
    (customerId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getCustomerStarted());

        try {
            const response = await api.get<CustomerDetailsResponseData>(`customers/${customerId}`);

            dispatch(getCustomerSucceeded(response));
        } catch (error) {
            if ((error as ResponseError).status === 404) {
                window.location.assign(`/orders?query=customerDetails.customerId:"${customerId}"`);
            }
            dispatch(getCustomerFailed(error as ResponseError));
        }
    };

const getOrders =
    (orderLink?: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        if (!orderLink) return;

        dispatch(getOrdersStarted());

        try {
            const response = await api.get<PagingResponseData<Order[]>>(orderLink);

            dispatch(getOrdersSucceeded(response));
        } catch (error) {
            dispatch(getOrdersFailed(error as ResponseError));
        }
    };

const getAccounts =
    (accountLink?: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        if (!accountLink) return;

        dispatch(getAccountsStarted());

        try {
            const response = await api.get<PagingResponseData<Account[]>>(accountLink);

            dispatch(getAccountsSucceeded(response));
        } catch (error) {
            dispatch(getAccountsFailed(error as ResponseError));
        }
    };

export default { getCustomerDetails, getOrders, getAccounts };
