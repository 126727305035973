import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import CheckoutSessionRow from '../../../types/paylink/CheckoutSessionRow';
import Money from '../../atoms/Money/Money';
import VAT from '../../atoms/Money/VAT';

const PaylinkDetailsColumns: ColumnDef<CheckoutSessionRow>[] = [
    {
        header: 'Description',
        accessorKey: 'description',
        cell: (props: { row: { original: CheckoutSessionRow } }) => {
            return <span>{props.row.original.description}</span>;
        },
    },
    {
        header: 'Article Number',
        accessorKey: 'id',
        cell: (props: { row: { original: CheckoutSessionRow } }) => {
            return <span>{props.row.original.id}</span>;
        },
    },
    {
        header: 'Quantity',
        meta: {
            style: {
                textAlign: 'center',
            },
        },
        accessorKey: 'quantity',
        cell: (props: { row: { original: CheckoutSessionRow } }) => {
            return <span>{props.row.original.quantity}</span>;
        },
    },
    {
        meta: {
            style: {
                textAlign: 'right',
            },
        },
        header: 'Unit price',
        accessorKey: 'unitPrice',
        cell: (props: { row: { original: CheckoutSessionRow } }) => {
            return <Money>{props.row.original.unitPrice}</Money>;
        },
    },
    {
        meta: {
            style: {
                textAlign: 'right',
            },
        },
        header: 'VAT',
        accessorKey: 'vat',
        cell: (props: { row: { original: CheckoutSessionRow } }) => {
            return (
                <VAT
                    amount={props.row.original.vatAmount ?? 0}
                    rate={props.row.original.vat ?? 0}
                />
            );
        },
    },
    {
        meta: {
            style: {
                textAlign: 'right',
            },
        },
        header: 'Amount',
        accessorKey: 'amount',
        cell: (props: { row: { original: CheckoutSessionRow } }) => {
            return <Money>{props.row.original.amount}</Money>;
        },
    },
];

export default PaylinkDetailsColumns;
