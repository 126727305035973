enum DashboardGraphType {
    TWO_LINE_PERCENT_STACK = 'TWO_LINE_PERCENT_STACK',
    DONUT = 'DONUT',
    BARS = 'BARS',
    BARS_DATE = 'BARS_DATE',
    BARS_STACK = 'BARS_STACK',
    LINE_DATE = 'LINE_DATE',
}

export default DashboardGraphType;
