import React, { useRef, useState } from 'react';
import styled, { css, DefaultTheme, withTheme } from 'styled-components';
import FocusLock from 'react-focus-lock';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import useOnClickOutside from '../atoms/Dropdown/useOnClickOutside';
import { ConnectedStore } from '../../types/ConnectedStore';
import CustomerIcon from '../atoms/CustomerIcon/CustomerIcon';
import getCustomerType from '../../services/helpers/customerTypeHelper';
import FlagIcon from '../atoms/FlagIcon/FlagIcon';
import DecoratorInput from '../atoms/Input/DecoratorInput';
import useRenderVisibleListScroll from '../../services/hooks/useRenderVisibleListScroll';
import SubLabel from '../atoms/Text/SubLabel';

interface SearchSelectProps {
    placeholderRaised?: boolean;
    dataTestId?: string;
    onChange: (value: string | number) => void;
    value: string | number;
    placeholder: string;
    id?: string;
    availableStores: ConnectedStore[];
    width?: string;
    theme: DefaultTheme;
}

interface StyledProps {
    isActive: boolean;
    hasValue: boolean;
    placeholderRaised?: boolean;
}
interface SelectProps {
    isActive: boolean;
    width?: string;
}
interface OptionProps {
    chosen: boolean;
}

type GridInputType = SearchSelectProps;

const StoreSelect: React.FC<GridInputType> = ({
    id,
    onChange,
    placeholderRaised,
    dataTestId,
    value,
    placeholder,
    availableStores,
    width,
    theme,
}: GridInputType) => {
    const ref = useRef(null);
    const openRef = useRef<HTMLDivElement>(null);

    const itemHeight = 47.5333;
    const visibleItems = 10;

    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectChosen] = useState(0);
    const [search, setSearch] = useState<string>('');

    const filter = (input: string | undefined, property: string) => {
        return input && input.toLocaleLowerCase().includes(property.toLocaleLowerCase());
    };

    const options = React.useMemo(
        () =>
            availableStores
                .filter(
                    x =>
                        !search ||
                        filter(x.storeName, search) ||
                        x.id.startsWith(search) ||
                        filter(x.selfReference, search)
                )
                .map(x => {
                    return {
                        label: (
                            <Store
                                title={`${x.storeName} (${x.countryCode} - ${
                                    x.customerSalesType
                                }) ${x.selfReference ?? ''}`}
                            >
                                <LeftStoreContent>{x.storeName}</LeftStoreContent>
                                <MiddelStoreContent>
                                    <SubLabel>{x.selfReference}</SubLabel>
                                </MiddelStoreContent>
                                <RightStoreContent>
                                    {x.id}

                                    {x.customerSalesType && (
                                        <CustomerIcon
                                            customerType={getCustomerType(x.customerSalesType)}
                                        />
                                    )}
                                    {x.countryCode && <FlagIcon countryCode={x.countryCode} />}
                                </RightStoreContent>
                            </Store>
                        ),
                        value: x.id,
                    };
                }),
        [availableStores, search]
    );

    const { scrollState, scrollPos, resetScroll, scrollItemHeight } = useRenderVisibleListScroll({
        visibleItems,
        itemHeight,
        openRef,
        items: options,
    });

    const handleClickOutside = () => {
        if (open) setOpen(!open);

        setActive(false);
        resetScroll();
        setSearch('');
    };

    useOnClickOutside(ref, handleClickOutside);

    const parseValue = () => {
        const obj = options.find(x => x.value === value);
        return obj?.label ?? null;
    };

    const onClick = () => {
        setActive(!active);
        setOpen(!open);
        resetScroll();
        setSearch('');
    };

    return (
        <Select
            tabIndex={0}
            data-testid={dataTestId}
            id={id}
            isActive={!!active}
            onClick={() => onClick()}
            ref={ref}
            width={width}
        >
            {open && availableStores.length > 5 ? (
                <FocusLock>
                    <DecoratorInput
                        dataTestId="filterStore"
                        type="text"
                        name="filterStore"
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Search"
                        id="filterStore"
                        onMouseDown={e => e.preventDefault()}
                        onTouchStart={e => e.preventDefault()}
                    />
                </FocusLock>
            ) : (
                <>
                    <Label
                        isActive={!!value}
                        hasValue={!!value}
                        placeholderRaised={placeholderRaised}
                    >
                        {placeholder}
                    </Label>
                    <Value>{parseValue()}</Value>
                </>
            )}

            <IconWrapper>
                {!active ? (
                    <ArrowDownSLineIcon size={theme.icon.size.normal} />
                ) : (
                    <ArrowUpSLineIcon size={theme.icon.size.normal} />
                )}
            </IconWrapper>
            {open && (
                <Dropdown ref={openRef} onScroll={scrollPos}>
                    {options.slice(scrollState.start, scrollState.end).map((x, index) => {
                        return (
                            <Option
                                data-testid={`option-${x.value}`}
                                chosen={selectChosen === index}
                                onClick={() => onChange(x.value)}
                                key={`select-label-${x.value}`}
                                style={scrollItemHeight(index)}
                            >
                                {x.label}
                            </Option>
                        );
                    })}
                </Dropdown>
            )}
        </Select>
    );
};

export default withTheme(StoreSelect) as React.ComponentType<Omit<GridInputType, 'theme'>>;

const Store = styled.div`
    display: flex;
    gap: 0.5rem;
    width: 100%;
    font-size: ${props => props.theme.text.size.small};
    justify-content: space-between;
`;

const RightStoreContent = styled.div`
    flex: 2;
    display: flex;
    gap: 0.5rem;
    justify-content: end;
`;

const LeftStoreContent = styled.div`
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 40rem;
`;

const MiddelStoreContent = styled.div`
    flex: 2;
`;

const Value = styled.div`
    margin-right: 3.7rem;
    padding: 1.2rem 0 1rem 1.8rem;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: ${props => props.theme.colors.subtle};
`;

const Label = styled.label<StyledProps>`
    position: absolute;
    font-size: ${props => props.theme.text.size.medium};
    top: 1.3rem;
    left: 2rem;
    transition: all 0.15s ease-in-out;
    opacity: 0.6;

    ${props =>
        (props.isActive || props.hasValue || props.placeholderRaised) &&
        css`
            display: none;
        `};
`;

const Select = styled.div<SelectProps>`
    background-color: ${props => props.theme.colors.light};
    height: 4rem;
    width: ${props => props.width ?? ' 100%'};
    position: relative;
    display: ${props => (props.hidden ? 'none' : 'inline-block')};

    ${props => props.isActive && `outline: 0.1rem solid ${props.theme.colors.primary} !important;`};

    &:focus {
        outline: 0.1rem solid ${props => props.theme.colors.primary};
    }
    outline: 0.1rem solid ${props => props.theme.colors.input.border};
    border-radius: 0.4rem;
    &:hover {
        cursor: default;
    }
`;

const Dropdown = styled.div`
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
    background-color: #f9f9f9;
    overflow-y: scroll;
    position: absolute;
    max-height: 25rem;
    height: initial;
    z-index: 2;
    width: 100%;
    top: 100%;
    height: 40rem;
`;

const Option = styled.div<OptionProps>`
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    padding: 1.5rem 1rem;
    color: ${props => props.theme.colors.text.primary};
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};

    &:hover {
        background-color: ${props => props.theme.colors.text.subtle};
        cursor: pointer;
    }

    ${props =>
        props.chosen &&
        `background-color: ${props.theme.colors.text.subtle};;
        outline: none;`};
`;
