export interface ExtendedWindow extends Window {
    MERCHANTHUB: unknown;
}

interface AuthConfig {
    Authority: string;
    DefaultFlow: string;
    PasswordResetFlow: string;
    ClientId: string;
    KnownAuthorities: string[];
    ApiScopes: string[];
    Flows: Record<string, string>;
}

export interface MerchantHubConfig {
    Environment: string;
    ApiHost: string;
    WalleyApiHost: string;
    Auth: AuthConfig;
    InternalOrganization: string;
    LaunchDarklyApiKey: string | undefined;
    AnnouncekitWidgetId: string | undefined;
    WalleyCoroebus: string;
    WebhookApiHost: string;
}

declare let window: ExtendedWindow;

export const config = window.MERCHANTHUB as MerchantHubConfig;
