/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import FocusLock from 'react-focus-lock';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import Keys from '../../../types/Keys';

export interface LockedModalProps {
    children: JSX.Element;
    large?: boolean;
    medium?: boolean;
    small?: boolean;
    isShown: boolean;
    hide: () => void;
    focusLock?: boolean;
    dataTestId?: string;
}
export interface StyledProps {
    large?: boolean;
    medium?: boolean;
    small?: boolean;
}

interface StyledModalProps {
    styled?: string;
}

export const LockedModal: React.FC<LockedModalProps> = ({
    isShown,
    hide,
    large,
    medium,
    small,
    children,
    focusLock,
    dataTestId,
}) => {
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === Keys.ESCAPE && isShown) {
                hide();
            }
        };

        isShown
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset');

        document.addEventListener('keydown', onKeyDown, false);
        if (isShown) document.documentElement.style.overflowY = 'hidden';

        return () => {
            if (isShown) document.documentElement.style.overflowY = 'initial';

            document.removeEventListener('keydown', onKeyDown, false);
            document.body.style.overflow = 'unset';
        };
    }, [isShown, hide]);

    const modal = (
        <>
            <Backdrop />
            <FocusLock disabled={!focusLock}>
                <Wrapper
                    data-testid={dataTestId}
                    large={large}
                    medium={medium}
                    small={small}
                    aria-modal
                    tabIndex={-1}
                    role="dialog"
                >
                    <StyledModal>{children}</StyledModal>
                </Wrapper>
                <CloseButton onClick={hide}>
                    <CloseLineIcon size="17" />
                </CloseButton>
            </FocusLock>
        </>
    );

    return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

const Wrapper = styled.div<StyledProps>`
    position: fixed;
    top: 50%;
    left: 50%;
    ${props =>
        props.large &&
        `
        height: calc(100vh - 15rem);
        min-width: 85vw;
        max-width: 144rem;
    `};

    ${props =>
        props.medium &&
        `
        width: 55rem;
    `};

    ${props =>
        props.small &&
        `
        width: 45rem;
    `};

    ${props =>
        !props.large &&
        !props.medium &&
        !props.small &&
        `
        max-height: calc(100vh - 30rem);
    `};
    outline: 0;
    border-radius: 0.8rem;
    transform: translate(-50%, -50%);
    background: ${props => props.theme.colors.light};
    z-index: 10;
`;

const Backdrop = styled.div<StyledProps>`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${props => (props.large ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.6)')};
    z-index: 8;
`;

const StyledModal = styled.div<StyledModalProps>`
    display: grid;
    grid-template-areas: 'header' 'content' 'error' 'footer';
    grid-template-rows: 6rem auto auto 8rem;
    height: 100%;
    margin: auto;
    ${props => props.styled && 'padding: 3rem'};
    background: white;
    border-radius: 0.8rem;
    z-index: 10;
`;

const CloseButton = styled.button<StyledModalProps>`
    ${props =>
        !props.styled &&
        `position: absolute; right: ${props.theme.layout.padding.large}; top: ${props.theme.layout.padding.large}`}
    right: ${props => props.theme.layout.padding.large};
    top: ${props => props.theme.layout.padding.large};
    background: transparent;
    border: none;
    border-radius: 0.3rem;
    z-index: 1;
    :hover {
        cursor: pointer;
        color: ${props => props.theme.colors.primary};
    }
    :focus {
        border: none;
        outline: 0;
    }
`;
