import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export interface TabContent {
    key: string;
    text: string;
    subLabel?: string;
    isVisible?: boolean;
}

interface TabsProps {
    children: (React.ReactElement | undefined | false)[];
    tabsList: TabContent[];
    startTab: string;
    onTabChange?: (key: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabsList, startTab, children, onTabChange }: TabsProps) => {
    const [currentTab, setCurrentTab] = useState(startTab);

    useEffect(() => {
        setCurrentTab(startTab);
    }, [startTab]);

    return (
        <>
            <TabsWrapper>
                {tabsList
                    .filter(tab => tab.isVisible ?? true)
                    .map(tab => (
                        <Tab
                            onClick={() => {
                                setCurrentTab(tab.key);

                                if (onTabChange && tab.key !== currentTab) onTabChange(tab.key);
                            }}
                            key={tab.text}
                            aria-current={tab.key === currentTab || undefined}
                            data-testid={`tab-${tab.key}`}
                        >
                            {tab.text}
                            {tab.subLabel && <SubLabelText>{tab.subLabel}</SubLabelText>}
                        </Tab>
                    ))}
            </TabsWrapper>
            {children?.filter(child => child && child.key === currentTab) ?? <></>}
        </>
    );
};

const TabsWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    min-height: 5rem;
    width: 100%;
    border-bottom: 0.2rem solid ${props => props.theme.colors.border};
`;

const Tab = styled.div`
    font-size: ${props => props.theme.text.size.large};
    color: ${props => props.theme.colors.text.primary};
    min-width: fit-content;
    padding: 0 1.5rem 1rem 1.2rem;
    bottom: -0.2rem;
    position: relative;
    border-bottom: 0.4rem solid transparent;
    text-align: center;
    text-decoration: none;
    &:first-child {
        padding-left: 1.7rem;
    }

    &:hover {
        border-bottom: 0.4rem ${props => props.theme.colors.accent} solid;
        cursor: pointer;
    }
    &[aria-current] {
        border-bottom: 0.4rem ${props => props.theme.colors.primary} solid;
    }
`;

const SubLabelText = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${props => props.theme.text.size.xxsmall};
`;

export default Tabs;
