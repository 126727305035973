import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface Props {
    children: ReactNode;
}

const CardBody: React.FC<Props> = ({ children }: Props) => {
    return <Container>{children}</Container>;
};

const Container = styled.div`
    padding: 0;
`;

export default CardBody;
