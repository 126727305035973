import React from 'react';
import { useParams } from 'react-router-dom';
import useLogTimeSpentOnPage from '../../services/hooks/useLogTimeSpentOnPage';
import { useAppState } from '../../store/appstate';
import { ConnectedStore } from '../../types/ConnectedStore';
import CustomerDetails from './CustomerDetails';

const CustomerDetailsPage: React.FC = () => {
    const { customerId = '' } = useParams();
    const availableStores = useAppState<ConnectedStore[]>(s => s.session.availableStores) ?? [];

    document.title = 'Merchant Hub - Customer';

    useLogTimeSpentOnPage('customerDetails');

    return <CustomerDetails customerId={customerId} availableStores={availableStores} />;
};

export default CustomerDetailsPage;
