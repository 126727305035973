/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import FileCopyLine from 'remixicon-react/FileCopyLineIcon';

interface StyledLinkWithCopyProps {
    href: string;
    children: ReactNode;
    theme: DefaultTheme;
}

const StyledLinkWithCopy = ({ href, children, theme }: StyledLinkWithCopyProps) => {
    const [copied, setCopied] = React.useState(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (copied) setCopied(false);
        }, theme.timeout.long);

        return () => clearTimeout(timeout);
    }, [copied]);

    return (
        <>
            <Wrapper>
                <StyledLink href={href} target="_blank">
                    {children}
                </StyledLink>
                <div
                    onClick={e => {
                        setCopied(true);
                        e.stopPropagation();
                        navigator.clipboard.writeText(href);
                    }}
                >
                    <StyledFileCopyLine size={theme.icon.size.small} />
                </div>
            </Wrapper>
            {copied && (
                <BubbleWrapper>
                    <Bubble>Copied</Bubble>
                </BubbleWrapper>
            )}
        </>
    );
};

export default withTheme(StyledLinkWithCopy) as React.ComponentType<
    Omit<StyledLinkWithCopyProps, 'theme'>
>;

const Wrapper = styled.div`
    gap: 1rem;
    display: flex;
    align-content: center;
    color: ${props => props.theme.colors.primary};
`;

const StyledLink = styled.a`
    color: ${props => props.theme.colors.primary};
`;

const StyledFileCopyLine = styled(FileCopyLine)`
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.hoverPrimary};
    }
    transition: 'all 300ms ease-in-out';
`;

const BubbleWrapper = styled.div`
    position: absolute;
    margin-top: -5.5rem;
    margin-left: 1rem;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.4rem;
`;

const Bubble = styled.div`
    position: relative;
    background-color: ${props => props.theme.colors.accent};
    padding: 0.5rem 1rem;
    font-size: ${props => props.theme.text.size.small};

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 1rem solid transparent;
        border-top-color: ${props => props.theme.colors.accent};
        border-bottom: 0;
        margin-left: -1rem;
        margin-bottom: -1rem;
    }
`;
