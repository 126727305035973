import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Logger from '../../../services/logging/logger';
import { useLocation } from 'react-router-dom';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import Button from '../../atoms/Button/Button';
import EmotionSad from 'remixicon-react/EmotionSadLineIcon';
import EmotionUnhappy from 'remixicon-react/EmotionUnhappyLineIcon';
import EmotionNormal from 'remixicon-react/EmotionNormalLineIcon';
import EmotionHappy from 'remixicon-react/EmotionHappyLineIcon';
import Emotion from 'remixicon-react/EmotionLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import IconButton from '../../atoms/Button/IconButton';
import { useAppState } from '../../../store/appstate';
import { SessionState } from '../../../store/session/sessionSlice';
import sessionActions from '../../../store/session/actions';
import { useDispatch } from 'react-redux';
import SlideUpAnimation from '../../atoms/Animations/SlideUpAnimation';
import TextArea from '../../atoms/Input/TextArea';
import HeartLineIcon from 'remixicon-react/HeartLineIcon';
import confetti from 'canvas-confetti';
import Flex from '../../atoms/Box/Flex';
import {
    getRandomDaysToWaitTime,
    getRandomNumberBetween,
} from '../../../services/helpers/randomGenerators';

interface Comment {
    question: string;
    freeText: boolean;
}

interface SurveyActivatedOnProps {
    path: string;
    exact: boolean;
    title: string;
    comment: Comment;
    tag: string;
}

interface SurveyProps {
    surveyActivatedOn: SurveyActivatedOnProps[];
    theme: DefaultTheme;
}

const scoreList = [
    <EmotionSad key="em-1" />,
    <EmotionUnhappy key="em-2" />,
    <EmotionNormal key="em-3" />,
    <EmotionHappy key="em-4" />,
    <Emotion key="em-5" />,
];

const Survey: React.FC<SurveyProps> = ({ surveyActivatedOn, theme }: SurveyProps) => {
    const daysForWaitInTime = getRandomDaysToWaitTime(14, 25);
    const popupTime = getRandomNumberBetween(8000, 10000);

    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const activeSurvey = surveyActivatedOn.find(x => pathname.includes(x.path));
    const regex = new RegExp(`${activeSurvey && activeSurvey.path}\/(.*)`);
    const tag = activeSurvey?.tag ?? '';

    const {
        cesMultiSurvey: {
            [tag]: {
                tab: surveyTab = false,
                show: showSurvey = false,
                expiryTime: surveyExpireTime,
            } = {
                tab: false,
                show: false,
                expiryTime: undefined,
            },
        } = {},
    } = useAppState<SessionState>(s => s.session);

    const [comment, setComment] = useState('');
    const [step, setStep] = useState(0);
    const [thankYouSurvey, setThankYouSurvey] = useState(false);

    const onTabClick = () => {
        dispatch(
            sessionActions.setCesSurvey({
                survey: {
                    show: true,
                    tab: !surveyTab,
                },
                key: tag,
            })
        );
    };

    const closeSurvey = () => {
        dispatch(
            sessionActions.setCesSurvey({
                survey: {
                    expiryTime: daysForWaitInTime,
                    show: false,
                },
                key: tag,
            })
        );
        setStep(0);
    };

    const onClose = () => {
        Logger.information('closed survey', {
            type: 'survey',
            page: pathname,
            Tag: activeSurvey?.tag,
        });

        closeSurvey();
    };

    const SendSurvey = (answer?: number) => {
        setStep(step + 1);

        if (step === 0 && answer) {
            Logger.information('CES', {
                type: 'survey',
                page: pathname,
                Score: answer,
                Tag: activeSurvey?.tag,
            });
        }

        if (step === 1) {
            Logger.information('CES', {
                type: 'survey',
                page: pathname,
                comment: comment,
                Tag: activeSurvey?.tag,
            });

            confetti({
                particleCount: 100,
                spread: 50,
                origin: { y: 1.2, x: 0 },
            });

            closeSurvey();
            setThankYouSurvey(true);
        }
    };

    useEffect(() => {
        if (thankYouSurvey) {
            const surveyTimeout = setTimeout(() => {
                setThankYouSurvey(false);
            }, 7000);

            return () => clearTimeout(surveyTimeout);
        }
    }, [thankYouSurvey]);

    const renderScoring = (scores: JSX.Element[]) => {
        return (
            <ScoringContainer>
                <Scores>
                    {scores.map((x, index) => {
                        const emotionIndex = index + 1;
                        const key = `emotion-scoring-${emotionIndex}`;

                        return (
                            <NumberScore
                                key={key}
                                data-testid={key}
                                onClick={() => SendSurvey(emotionIndex)}
                            >
                                {x}
                            </NumberScore>
                        );
                    })}
                </Scores>
                <ScoringText>
                    <ExplanationText>Very difficult</ExplanationText>
                    <ExplanationText>Very easy</ExplanationText>
                </ScoringText>
            </ScoringContainer>
        );
    };

    useEffect(() => {
        const now = new Date().getTime();
        if (!activeSurvey) return;

        if (!activeSurvey.exact && !regex.test(pathname)) return;
        if (activeSurvey.exact && pathname !== `${activeSurvey.path}`) return;
        if (
            (!showSurvey && !surveyExpireTime) ||
            (surveyExpireTime !== undefined && surveyExpireTime < now)
        ) {
            const timeout = setTimeout(() => {
                dispatch(
                    sessionActions.setCesSurvey({
                        survey: {
                            show: true,
                            tab: false,
                        },
                        key: tag,
                    })
                );
            }, popupTime);

            return () => clearTimeout(timeout);
        }
    }, [pathname]);

    if (!activeSurvey) return <></>;
    if (!activeSurvey.exact && !regex.test(pathname)) return <></>;
    if (activeSurvey.exact && pathname !== `${activeSurvey.path}`) return <></>;

    const renderSurvery = () => {
        return (
            <>
                {thankYouSurvey ? (
                    <ThankYou column>
                        <HeartIcon color={theme.colors.error} size={30} />
                        <FeedbackText>Thank you for answering, we love feedback!</FeedbackText>
                    </ThankYou>
                ) : (
                    <Container
                        data-testid="ces-survey"
                        isCollapsed={surveyTab}
                        isDisplayed={showSurvey || (showSurvey && !surveyExpireTime)}
                    >
                        <Tab title="tab-survey" onClick={() => onTabClick()}>
                            {surveyTab ? <ArrowUpSLineIcon /> : <ArrowDownSLineIcon />}
                        </Tab>

                        <SurveyContainer>
                            <ContentWrapper>
                                <IconButtonWrapper>
                                    <IconButton
                                        onClick={() => onClose()}
                                        transparent
                                        dataTestId="close-ces-survey"
                                        title="close-ces-survey"
                                    >
                                        <CloseLineIcon />
                                    </IconButton>
                                </IconButtonWrapper>

                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        SendSurvey();
                                    }}
                                    id="ces-survey-form"
                                    name="ces-survey-form"
                                >
                                    <Content>
                                        {step === 0 && (
                                            <>
                                                <Question> {activeSurvey?.title}</Question>
                                                <>{renderScoring(scoreList)}</>
                                            </>
                                        )}
                                        {step === 1 && (
                                            <>
                                                <Question>
                                                    {activeSurvey?.comment.question}
                                                </Question>
                                                {activeSurvey.comment.freeText ? (
                                                    <TextArea
                                                        dataTestId={'survey-comment'}
                                                        maxLength={100}
                                                        onChange={e => setComment(e.target.value)}
                                                    />
                                                ) : (
                                                    <>{renderScoring(scoreList)}</>
                                                )}
                                                <ButtonWrapper>
                                                    <ProceedButton
                                                        form="ces-survey-form"
                                                        type="submit"
                                                        tabIndex={0}
                                                        primary
                                                    >
                                                        Send Feedback
                                                    </ProceedButton>
                                                </ButtonWrapper>
                                            </>
                                        )}
                                    </Content>
                                </form>
                            </ContentWrapper>
                        </SurveyContainer>
                    </Container>
                )}
            </>
        );
    };

    return <>{renderSurvery()}</>;
};

interface ContainerProps {
    isCollapsed: boolean;
    isDisplayed: boolean;
}

const Container = styled.div<ContainerProps>`
    margin-left: 2rem;
    position: fixed;
    z-index: 4;
    bottom: 0;
    left: 0;
    transition: 0.5s;
    animation: ${SlideUpAnimation} 2.5s ease-in;

    ${props => props.isCollapsed && 'bottom: -20rem'};

    ${props =>
        !props.isDisplayed &&
        `
        display: none;
    `};
`;

const DropShadow = styled.div`
    border-radius: 0.8rem 0.8rem 0 0;
    position: relative;

    &::after {
        box-shadow: 0 0.5rem 1.3rem 0 #cccccc;
        border-radius: 0.8rem 0.8rem 0 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        content: '';
        left: 0;
        top: 0;
    }
`;

const ThankYou = styled(Flex)`
    position: fixed;
    z-index: 4;
    bottom: 0;
    left: 0;
    width: 25rem;
    height: 10rem;
    box-shadow: 0 0.5rem 1.3rem 0 #cccccc;
    border-radius: 0.8rem;
    background: white;
    padding: 1.5rem;
    margin: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HeartIcon = styled(HeartLineIcon)`
    align-self: center;
`;

const FeedbackText = styled.div`
    font-family: ${props => props.theme.text.font.medium};
`;

const SurveyContainer = styled(DropShadow)`
    width: 28rem;
    height: 20rem;
    background: white;
    text-align: center;
    padding: 0.5rem 1rem;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProceedButton = styled(Button)`
    bottom: 1rem;
`;

const Content = styled(DropShadow)`
    display: flex;
    flex-direction: column;
    padding: 0 1.6rem;
    gap: 1.5rem;
`;

const Tab = styled(DropShadow)`
    width: 4.5rem;
    height: 2.5rem;
    background: white;
    margin-right: auto;
    margin-left: 2rem;
    border-radius: 0.4rem 0.4rem 0 0;
    display: flex;
    align-content: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
`;

const Question = styled.div`
    text-align: center;
    font-family: ${props => props.theme.text.font.medium};
`;

const IconButtonWrapper = styled.div`
    align-self: flex-end;
`;

const ScoringContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ScoringText = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
`;

const ExplanationText = styled.div`
    text-align: center;
    font-family: ${props => props.theme.text.font.medium};
    font-size: ${props => props.theme.text.size.xsmall};
    margin-top: 0.5rem;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const NumberScore = styled.div`
    width: 4rem;
    height: 3rem;
    background: ${props => props.theme.colors.medium};
    color: ${props => props.theme.colors.text.primary};
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: ${props => props.theme.colors.mediumPrimary};
        cursor: pointer;
        transition: 0.3s;
    }
`;

const Scores = styled.div`
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    justify-content: space-between;
`;

export default withTheme(Survey) as React.ComponentType<Omit<SurveyProps, 'theme'>>;
