import React, { ReactNode } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { ErrorType } from '../../../types/response/ErrorCodes';
import ResponseError from '../../../types/response/ResponseError';
import Button from '../Button/Button';
import ErrorMessage from '../Message/Error/ErrorMessage';
import SuccessMessage from '../Message/Success/SuccessMessage';
import Spinner from '../Spinner/Spinner';
import FooterButtonContainer from './FooterButtonContainer';
import HeaderTitle from './HeaderTitle';
import ModalContainer from './ModalContainer';

interface ConfirmationModalProps {
    title: string;
    label: string;
    labelAction: string;
    action: (...args: any[]) => void;
    children: ReactNode;
    hideConfirmation: boolean;
    error: ResponseError | undefined;
    theme: DefaultTheme;
    isSuccessful: boolean;
    isProcessing: boolean;
    hide: () => void;
    errorType: ErrorType;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    title,
    label,
    labelAction,
    action,
    children,
    hideConfirmation,
    error,
    theme,
    isSuccessful,
    isProcessing,
    hide,
    errorType,
}: ConfirmationModalProps) => {
    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>{title}</HeaderTitle>
            </ModalContainer>
            <ModalContainer position="content">
                {!isSuccessful && <MessageContainer>{children}</MessageContainer>}

                {hideConfirmation && (
                    <SuccessMessage>
                        {' '}
                        {labelAction} {label}
                    </SuccessMessage>
                )}
            </ModalContainer>

            {error && (
                <ModalContainer position="error">
                    <ErrorMessage error={error} errorHeader={errorType} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button disabled={isProcessing} onClick={hide} large>
                        {isSuccessful || error ? 'Close' : 'No'}
                    </Button>

                    {!isSuccessful && (
                        <Button
                            tabIndex={0}
                            onClick={action}
                            primary
                            large
                            disabled={isProcessing || isSuccessful}
                        >
                            {isProcessing ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : error ? (
                                <span>Retry</span>
                            ) : (
                                <span>Yes</span>
                            )}
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(ConfirmationModal) as React.ComponentType<
    Omit<ConfirmationModalProps, 'theme'>
>;

const MessageContainer = styled.div`
    justify-content: center;
    max-width: 40rem;
    margin-right: ${props => props.theme.layout.padding.large};
`;
