import api from '../../services/api';
import { parseSearchFilters } from '../../services/helpers/queryStringHelpers';
import DashboardEntityType from '../../types/dashboard/DashboardEntityType';
import SearchAggregate from '../../types/dashboard/SearchAggregate';
import { Order } from '../../types/order/Order';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import ResponseError from '../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../thunk';

import {
    dashboardSearchSucceeded,
    dashboardSearchAggregateStarted,
    dashboardSearchAggregateSucceeded,
    dashboardSearchResultsFailed,
    dashboardSearchAggregateFailed,
} from './dashboardSlice';

const dashboardSearch =
    (id: string, searchQueryString: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const query = searchQueryString?.replace('?', '');

        try {
            const response = await api.get<PagingResponseData<Order[]>>(`orders?${query}`);

            dispatch(dashboardSearchSucceeded({ id, results: response }));
        } catch {
            dispatch(dashboardSearchResultsFailed());
        }
    };

const dashboardAggregate =
    (id: string, searchQueryString: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const query = searchQueryString?.replace('?', '').replace('&&', '&');

        dispatch(dashboardSearchAggregateStarted({ id }));

        try {
            const response = await api.get<PagingResponseData<SearchAggregate[]>>(
                `/orders/aggregation?${query}`
            );

            dispatch(dashboardSearchAggregateSucceeded({ id, results: response }));
        } catch (error) {
            dispatch(dashboardSearchAggregateFailed({ id, error: error as ResponseError }));
        }
    };

const dashboardAggregateData =
    (
        id: string,
        aggregation: string,
        filters: Map<string, string[]>,
        interval?: string,
        defaultEmptyValue?: string,
        entity?: DashboardEntityType
    ): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(dashboardSearchAggregateStarted({ id }));

        const modifiedFilters = new Map<string, string[]>(filters);

        if (entity === DashboardEntityType.Settlements && modifiedFilters.has('placedAt')) {
            modifiedFilters.set('startDate', modifiedFilters.get('placedAt') ?? []);
            modifiedFilters.delete('placedAt');
        }

        try {
            const response = await api.get<PagingResponseData<SearchAggregate[]>>(
                `/${entity ?? 'orders'}/aggregation?aggregation=${aggregation}${parseSearchFilters(
                    modifiedFilters
                )}${interval ? `&interval=${interval}` : ''}${
                    defaultEmptyValue ? `&defaultEmptyValue=${defaultEmptyValue}` : ''
                }`
            );

            dispatch(dashboardSearchAggregateSucceeded({ id, results: response }));
        } catch (error) {
            dispatch(dashboardSearchAggregateFailed({ id, error: error as ResponseError }));
        }
    };

export default {
    dashboardSearch,
    dashboardAggregate,
    dashboardAggregateData,
};
