import React, { useEffect, useReducer, useRef } from 'react';
import { DateRangeConstants, DateRangeState, reducer } from '../DateRange/DateRangeStateHelper';
import Calendar from './Calendar';
import styled from 'styled-components';
import useOnScreen from '../Dropdown/useOnScreen';

export interface DatePeriod {
    start: string;
    end: string;
}

interface DateRangePickerProps {
    onChange: (period: DatePeriod) => void;
    onEnter: () => void;
    isOpen: boolean;
    periodValueTo: string;
    periodValueFrom: string;
    height: string;
    minDate?: Date;
    maxDate?: Date;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
    onChange,
    onEnter,
    isOpen,
    periodValueTo,
    periodValueFrom,
    height,
    minDate,
    maxDate,
}: DateRangePickerProps) => {
    const today = new Date();
    const current = !!periodValueFrom
        ? new Date(periodValueFrom)
        : !!periodValueTo
        ? new Date(periodValueTo)
        : new Date();

    const initialState: DateRangeState = {
        to: periodValueTo || '',
        from: periodValueFrom || '',
        focusedInput: 'fromDate',
        fromSet: !!periodValueFrom,
        toSet: !!periodValueTo,
        fromDate: !!periodValueFrom ? new Date(periodValueFrom) : today,
        toDate: !!periodValueTo ? new Date(periodValueTo) : today,
        currentMonth: current.getMonth(),
        currentYear: current.getFullYear(),
    };
    const [state, actionDispatch] = useReducer(reducer, initialState);

    const { to, from, focusedInput } = state;
    const dateRangePickerId = 'Date';

    const containerRef = useRef<HTMLDivElement>(null);
    const { outsideIntersecion } = useOnScreen(containerRef);

    useEffect(() => {
        const element = document.getElementById(
            `${dateRangePickerId}_${focusedInput === DateRangeConstants.FROM_DATE ? 'from' : 'to'}`
        );
        if (isOpen && element) {
            element.focus();
        }
    }, [focusedInput, isOpen]);

    useEffect(() => {
        if (periodValueFrom === '' && periodValueTo === '') {
            actionDispatch({
                type: DateRangeConstants.RESET_STATE,
                payload: initialState,
            });
        }
    }, [periodValueFrom, periodValueTo]);

    useEffect(() => {
        if (from !== periodValueFrom || to !== periodValueTo) {
            onChange({
                start: from,
                end: to,
            });
        }
    }, [from, to]);

    return (
        <CalendarContainer
            ref={containerRef}
            $height={height}
            $offsetHeight={outsideIntersecion.bottom}
        >
            <Calendar
                currentState={state}
                minDate={minDate}
                maxDate={maxDate}
                actionDispatch={actionDispatch}
                onEnter={onEnter}
                label={dateRangePickerId}
            />
        </CalendarContainer>
    );
};

export default DateRangePicker;

const CalendarContainer = styled.div<{ $height: string; $offsetHeight: number }>`
    background-color: #f9f9f9;
    height: ${props => `calc( ${props.$height} - ${props.$offsetHeight}px )`};
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
