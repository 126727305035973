import api from '../../services/api';
import { ResponseData } from '../../types/response/ResponseData';
import ResponseError from '../../types/response/ResponseError';
import { Organization } from '../../types/Organization';

export const listOrganizations = async (
    getOrganizationsSucceeded: (response: Organization[]) => void,
    getOrganizationsFailed: (error: ResponseError) => void
) => {
    try {
        const response = await api.get<ResponseData<Organization[]>>('organizations');
        getOrganizationsSucceeded(response.data);
    } catch (error) {
        getOrganizationsFailed(error as ResponseError);
    }
};

export const enableOrganization = async (
    id: string,
    enableOrganizationStarted: () => void,
    enableOrganizationSucceeded: () => void,
    enableOrganizationFailed: (error: ResponseError) => void
) => {
    enableOrganizationStarted();

    try {
        await api.post(`organizations/${id}/features/checkout/enable`, {});

        enableOrganizationSucceeded();
    } catch (error) {
        enableOrganizationFailed(error as ResponseError);
    }
};

export const deleteOrganization = async (
    organizationId: string,
    organizationName: string,
    deleteOrganizationStarted: () => void,
    deleteOrganizationSucceeded: (organization: string) => void,
    deleteOrganizationFailed: (error: ResponseError) => void
) => {
    deleteOrganizationStarted();

    try {
        await api.del(`organizations/${organizationId}`);

        deleteOrganizationSucceeded(organizationName);
    } catch (error) {
        deleteOrganizationFailed(error as ResponseError);
    }
};
