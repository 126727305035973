import { SortingRule } from 'react-table';
import { createFilterMap } from './filterHelpers';
import OrderSearchTabType from '../../types/order/OrderTabType';

const parseSearchFilters = (filterMap: Map<string, string[]>) => {
    const filters = Array.from(filterMap.entries()).map(([key, value]) =>
        value.some(f => f !== '') ? `${key}:${JSON.stringify(value)}` : ''
    );

    return filters && filters.length > 0 && filters.some(x => x !== '')
        ? `&${filters
              .filter(x => x !== '')
              .map(x => `filter=${x}`)
              .join('&')}`
        : '';
};

const createEncodedSearchQueryString = (
    query: string | null | undefined,
    filterMap: Map<string, string[]>,
    sort: string | null,
    perPage: string,
    columns: string[],
    page?: string | null,
    tab?: string
) => {
    const filterString = parseSearchFilters(filterMap);

    const sortString = sort ? `&sort=${sort}` : '';
    const columnString = columns.length > 0 ? `&columns=${columns.join(',')}` : '';

    const perPageValue = perPage !== '' ? `&page=${page ?? '1'}&perPage=${perPage}` : '';
    const tabQuery = tab ? `&tab=${tab}` : '';

    return (
        `query=${encodeURIComponent(query || '')}` +
        encodeURI(`${perPageValue}${filterString}${sortString}${tabQuery}${columnString}`)
    );
};

const createSearchQueryString = (
    query: string | null | undefined,
    filterMap: Map<string, string[]>,
    sort: string | null,
    perPage: string,
    columns: string[],
    page?: string | null,
    tab?: string
) => {
    const filters = Array.from(filterMap.entries()).map(([key, value]) =>
        value.some(f => f !== '') ? `${key}:${JSON.stringify(value)}` : ''
    );

    const filterString =
        filters && filters.length > 0 && filters[0] !== '""'
            ? `&${filters
                  .filter(x => x !== '')
                  .map(x => `filter=${x}`)
                  .join('&')}`
            : '';

    const sortString = sort ? `&sort=${sort}` : '';
    const columnString = columns.length > 0 ? `&columns=${columns.join(',')}` : '';

    const encodedQuery = query ? encodeURIComponent(query) : '';
    const perPageValue = perPage !== '' ? `&page=${page ?? '1'}&perPage=${perPage}` : '';
    const tabQuery = tab ? `&tab=${tab}` : '';

    return `query=${encodedQuery}${perPageValue}${filterString}${sortString}${tabQuery}${columnString}`;
};

const parseQueryColumns = (columns: string | null) => {
    return columns ? columns.split(',') : [];
};

const createSearchQueryStringWithExistingFilters = (query: string, startColumns: string[]) => {
    const queryWithoutFilter = query.substring(0, query.lastIndexOf('columns')).replace('?', '');
    const filters = startColumns.join(',');

    return `${queryWithoutFilter}columns=${filters}`;
};

const parseQuery = (queryString: string, exactSearch: boolean) => {
    const decodedQuery = queryString ?? '';

    if (decodedQuery === '') return '';
    if (decodedQuery.includes(':')) return decodedQuery;

    if (
        /'([^']+)'/.test(decodedQuery) ||
        /"([^"]+)"/.test(decodedQuery) ||
        (!decodedQuery.includes(`"`) && exactSearch)
    ) {
        return `"${decodedQuery.replace(/"/g, '')}"`;
    }
    return decodedQuery;
};

const generateQuoteSearch = (searchQuery: string) => {
    return `"${searchQuery}"`;
};

const queryStringContainDates = (query: string) => {
    return query.includes('-');
};

const generateColumnString = (columns: string[]) => {
    return `&columns=${columns.join(',')}`;
};

const sortColumns = <T,>(sortby: Array<SortingRule<T>>, sortQuery: string | undefined) => {
    const params = new URLSearchParams(sortQuery);
    const sort = sortby.length > 0 ? `${sortby[0].id}-${!sortby[0].desc ? 'asc' : 'desc'}` : '';

    if (sort) params.set('sort', sort);
    else params.delete('sort');

    return params.toString();
};

const parseSearchUrl = (url: string, sessionColumns?: string[]) => {
    const query = new URLSearchParams(url);
    const parsedColumns = parseQueryColumns(query.get('columns'));

    return {
        query: query.get('query') ?? '',
        filterMap: createFilterMap(query.getAll('filter'), new Map<string, string[]>()),
        sort: query.get('sort'),
        perPage: query.get('perPage') ?? '30',
        columns: parsedColumns.length > 0 ? parsedColumns : sessionColumns ?? [],
        page: query.get('page') ?? '1',
        tab: query.get('tab') ?? OrderSearchTabType.Orders,
    };
};

export {
    createSearchQueryString,
    createEncodedSearchQueryString,
    createSearchQueryStringWithExistingFilters,
    parseQuery,
    parseQueryColumns,
    queryStringContainDates,
    sortColumns,
    parseSearchFilters,
    parseSearchUrl,
    generateColumnString,
    generateQuoteSearch,
};
