import styled, { DefaultTheme, withTheme } from 'styled-components';
import React, { ReactNode } from 'react';
import Flex from '../Box/Flex';

interface CircleWithIconProps {
    icon: ReactNode;
    color: string;
    title?: string;
    children?: ReactNode;
    theme: DefaultTheme;
}

const CircleWithIcon: React.FC<CircleWithIconProps> = ({
    icon,
    color,
    title,
    children,
    theme,
}: CircleWithIconProps) => {
    return (
        <Wrapper
            row
            justifyContent="center"
            alignItems="center"
            color={color}
            gap={theme.layout.gap.small}
            title={title}
        >
            <Circle row justifyContent="center" alignItems="center" color={color}>
                {icon}
            </Circle>
            {children}
        </Wrapper>
    );
};

export default withTheme(CircleWithIcon) as React.ComponentType<Omit<CircleWithIconProps, 'theme'>>;

interface CircleProps {
    color?: string;
}

const Circle = styled(Flex)<CircleProps>`
    background-color: ${props => props.color ?? 'transparent'};
    color: ${props => props.theme.colors.light};
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
`;

const Wrapper = styled(Flex)<CircleProps>`
    font-family: ${props => props.theme.text.font.medium};
    font-size: ${props => props.theme.text.size.xlarge};
    color: ${props => props.color};
`;
