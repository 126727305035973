import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '../../store/appstate';
import { Order } from '../../types/order/Order';
import OrderDetails from './OrderDetails';

const OrderDetailsPage: React.FC = () => {
    const { orderId = '' } = useParams();
    const order = useAppState<Order | undefined>(s => s.order.order);

    if (order) document.title = `Merchant Hub - Order - ${order.orderNumber ?? order.id}`;

    return <OrderDetails orderId={orderId} />;
};

export default OrderDetailsPage;
