import styled from 'styled-components';

const HeaderTitle = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 2rem;
    font-size: ${props => props.theme.text.size.large};
    line-height: 2.5rem;
`;

export default HeaderTitle;
