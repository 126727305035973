/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupPermission } from '../../../types/admin/GroupPermission';
import { PatchOperation } from '../../../types/requests/PatchOperation';
import { PostResponseData } from '../../../types/response/PostResponseData';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';
import { User } from '../../../types/User';
import { UserGroup } from '../../../types/UserGroup';

export interface GroupsState {
    groupSearchResults: ResponseData<UserGroup[]>;
    groupListUsersResponse: ResponseData<User[]>;
    group: UserGroup;
    isSearching: boolean;
    isLoadingOrganization: boolean;
    error?: ResponseError;
    phrase?: string;
    organization?: string;
    availablePermissions?: Record<string, string[]>;
    groupPermissions: GroupPermission[] | undefined;
    inviteSucceded?: boolean;
    groupCreatedResponse: PostResponseData;
    groupCreateName?: string | undefined;
    groupWasCreated: boolean;
    groupId: string | undefined;
    fetchedUsersInGroup: boolean;
    groupAddUserToResponse: PostResponseData;
    userId: string;
    isGroupCreating: boolean;
    searchError?: ResponseError;
    createError?: ResponseError;
}

const initialState: GroupsState = {
    groupSearchResults: {} as ResponseData<UserGroup[]>,
    groupListUsersResponse: {} as ResponseData<User[]>,
    group: {} as UserGroup,
    availablePermissions: {} as Record<string, string[]>,
    groupPermissions: undefined,
    isSearching: false,
    isLoadingOrganization: false,
    organization: '',
    inviteSucceded: false,
    error: undefined,
    groupCreatedResponse: {} as PostResponseData,
    groupCreateName: undefined,
    groupWasCreated: false,
    groupId: undefined,
    isGroupCreating: false,
    fetchedUsersInGroup: false,
    groupAddUserToResponse: {} as PostResponseData,
    userId: '',
    searchError: undefined,
    createError: undefined,
};

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        groupSearchStarted: (state, action: PayloadAction<string>) => {
            state.isSearching = true;
            state.phrase = action.payload;
            state.searchError = undefined;
        },
        groupSearchSucceeded: (state, action: PayloadAction<ResponseData<UserGroup[]>>) => {
            state.isSearching = false;
            state.groupSearchResults = action.payload;
        },
        groupSearchFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.groupSearchResults = {} as ResponseData<UserGroup[]>;
            state.searchError = action.payload;
        },
        groupPageStarted: state => {
            state.isSearching = true;
        },
        getGroupDetailsStarted: state => {
            state.isSearching = true;
            state.error = undefined;
            state.groupPermissions = undefined;
        },
        getGroupDetailsSucceeded: (state, action: PayloadAction<UserGroup>) => {
            state.isSearching = false;
            state.group = action.payload;
            state.error = undefined;
        },
        getGroupDetailsFailed: state => {
            state.isSearching = false;
            state.group = {} as UserGroup;
        },
        getOrganizationPermissionsStarted: state => {
            state.isSearching = true;
        },
        getOrganizationPermissionsSucceeded: (
            state,
            action: PayloadAction<Record<string, string[]>>
        ) => {
            state.isSearching = false;
            state.availablePermissions = action.payload;
        },
        getOrganizationPermissionsFailed: state => {
            state.isSearching = false;
            state.availablePermissions = {} as Record<string, string[]>;
        },
        clearOrganizationPermissions: state => {
            state.availablePermissions = {} as Record<string, string[]>;
            state.group = {} as UserGroup;
        },
        groupPermissionsToggleStarted: (state, action: PayloadAction<PatchOperation[]>) => {
            state.group = {
                ...state.group,
                isModifyingPermissions: true,
                payload: action.payload,
            };
        },
        groupPermissionsToggleSucceeded: (
            state,
            action: PayloadAction<ResponseData<UserGroup>>
        ) => {
            state.group = {
                ...state.group,
                isModifyingPermissions: false,
                permissions: action.payload.data.permissions,
            };
            state.error = undefined;
        },
        permissionAdded: (state, action: PayloadAction<string>) => {
            state.group = {
                ...state.group,
                permissions: [...state.group.permissions, action.payload],
            };
            state.error = undefined;
        },
        permissionRemoved: (state, action: PayloadAction<string>) => {
            state.group = {
                ...state.group,
                permissions: state.group.permissions.filter(item => item !== action.payload),
            };
            state.error = undefined;
        },
        patchGroupFailed: (state, action: PayloadAction<ResponseError>) => {
            state.error = action.payload;
            state.group = {
                ...state.group,
                isModifyingPermissions: false,
            };
        },
        groupStoreAdded: (state, action: PayloadAction<string>) => {
            state.group = {
                ...state.group,
                connectedStores: [...state.group.connectedStores, action.payload],
            };
            state.error = undefined;
        },
        groupStoreRemoved: (state, action: PayloadAction<string>) => {
            state.group = {
                ...state.group,
                connectedStores: state.group.connectedStores.filter(
                    item => item !== action.payload
                ),
            };
            state.error = undefined;
        },
        deleteGroupStarted: state => {
            state.isSearching = true;
        },
        deleteGroupSucceeded: (state, action: PayloadAction<string>) => {
            state.isSearching = false;
            state.groupSearchResults = {
                ...state.groupSearchResults,
                data: state.groupSearchResults.data.filter(group => group.id !== action.payload),
            } as ResponseData<UserGroup[]>;
            state.error = undefined;
        },
        deleteGroupFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.error = action.payload;
        },
        createGroupStarted: (state, action: PayloadAction<string>) => {
            state.groupCreateName = action.payload;
            state.isGroupCreating = true;
        },
        createGroupSucceeded: (state, action: PayloadAction<PostResponseData>) => {
            state.groupCreatedResponse = action.payload;
            state.groupWasCreated = true;
            state.isGroupCreating = false;
        },
        createGroupFailed: (state, action: PayloadAction<ResponseError>) => {
            state.createError = action.payload;
            state.isGroupCreating = false;
        },
        resetCreateGroup: state => {
            state.isGroupCreating = false;
            state.groupWasCreated = false;
            state.createError = undefined;
        },
        getGroupListUsersStarted: (
            state,
            action: PayloadAction<{
                organization: string;
                groupId: string;
            }>
        ) => {
            state.organization = action.payload.organization;
            state.groupId = action.payload.groupId;
            state.fetchedUsersInGroup = false;
            state.error = undefined;
        },
        getGroupListUsersSucceeded: (state, action: PayloadAction<ResponseData<User[]>>) => {
            state.groupListUsersResponse = action.payload;
            state.fetchedUsersInGroup = true;
        },
        getGroupListUsersFailed: (state, action: PayloadAction<ResponseError>) => {
            state.error = action.payload;
            state.fetchedUsersInGroup = false;
        },
        addUserToGroupStarted: (
            state,
            action: PayloadAction<{
                groupId: string;
                userId: string;
                organizationId: string;
            }>
        ) => {
            state.isSearching = true;
            state.groupId = action.payload.groupId;
            state.userId = action.payload.userId;
            state.organization = action.payload.organizationId;
        },
        addUserToGroupSucceeded: (
            state,
            action: PayloadAction<{
                response: PostResponseData;
                user: User;
            }>
        ) => {
            state.isSearching = false;
            state.groupAddUserToResponse = action.payload.response;
            state.groupListUsersResponse = {
                data: [...state.groupListUsersResponse.data, action.payload.user],
                metaData: state.groupListUsersResponse.metaData,
                links: state.groupListUsersResponse.links,
            };
        },
        addUserToGroupFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.error = action.payload;
        },
        removeUserFromGroupStarted: (
            state,
            action: PayloadAction<{
                groupId: string;
                userId: string;
                organizationId: string;
            }>
        ) => {
            state.groupId = action.payload.groupId;
            state.userId = action.payload.userId;
            state.organization = action.payload.organizationId;
            state.isSearching = true;
        },
        removeUserFromGroupSucceeded: state => {
            state.groupListUsersResponse = {
                data: [...state.groupListUsersResponse.data.filter(usr => usr.id !== state.userId)],
                metaData: state.groupListUsersResponse.metaData,
                links: state.groupListUsersResponse.links,
            };
            state.isSearching = false;
        },
        removeUserFromGroupFailed: (state, action: PayloadAction<ResponseError>) => {
            state.error = action.payload;
            state.isSearching = false;
        },
    },
});

export const {
    groupSearchStarted,
    groupSearchSucceeded,
    groupSearchFailed,
    groupPageStarted,
    getGroupDetailsStarted,
    getGroupDetailsSucceeded,
    getGroupDetailsFailed,
    getOrganizationPermissionsStarted,
    getOrganizationPermissionsSucceeded,
    getOrganizationPermissionsFailed,
    clearOrganizationPermissions,
    groupPermissionsToggleStarted,
    groupPermissionsToggleSucceeded,
    permissionAdded,
    permissionRemoved,
    patchGroupFailed,
    groupStoreAdded,
    groupStoreRemoved,
    deleteGroupStarted,
    deleteGroupSucceeded,
    deleteGroupFailed,
    createGroupStarted,
    createGroupSucceeded,
    createGroupFailed,
    resetCreateGroup,
    getGroupListUsersStarted,
    getGroupListUsersSucceeded,
    getGroupListUsersFailed,
    addUserToGroupStarted,
    addUserToGroupSucceeded,
    addUserToGroupFailed,
    removeUserFromGroupStarted,
    removeUserFromGroupSucceeded,
    removeUserFromGroupFailed,
} = groupsSlice.actions;

export default groupsSlice.reducer;
