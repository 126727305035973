import { combineReducers } from 'redux';
import settlementSliceReducer from './settlement/settlementSlice';
import periodicReportsSliceReducer from './periodicReports/periodicReportsSlice';

const reportStateReducer = combineReducers({
    settlement: settlementSliceReducer,
    periodicReports: periodicReportsSliceReducer,
});

export default reportStateReducer;
