import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NoneStyleLink = styled(Link)`
    text-decoration: none;
    color: initial;
    font-weight: inherit;
`;

export default NoneStyleLink;
