import { keyframes } from 'styled-components';

const SlideUpAnimation = keyframes`
    0%,
    50% {
      transform: translateY(100%);
      opacity: 0;
    }
     
    60%,
    100% {
      transform: translateY(0);
      opacity: 1;
    
    }
`;

export default SlideUpAnimation;
