import { useState } from 'react';

interface InfiniteScrollProps<T> {
    visibleItems: number;
    itemHeight: number;
    openRef: React.RefObject<HTMLDivElement>;
    items: Array<T>;
}

function useRenderVisibleListScroll<T>({
    visibleItems,
    itemHeight,
    openRef,
    items,
}: InfiniteScrollProps<T>) {
    const [scrollState, setScrollState] = useState({ start: 0, end: visibleItems });

    const scrollPos = () => {
        if (!openRef.current) return;
        const currentIndx = Math.trunc(openRef.current?.scrollTop / itemHeight);

        const start =
            currentIndx - visibleItems >= items.length ? currentIndx - visibleItems : currentIndx;
        const end = start + visibleItems >= items.length ? items.length : start + visibleItems;

        if (start !== scrollState.start) setScrollState({ start, end });
    };

    const scrollItemHeight = (index: number) => {
        return {
            top: (scrollState.start + index) * itemHeight,
        };
    };

    const resetScroll = (start?: number) => {
        setScrollState({ start: start ?? 0, end: visibleItems });
    };

    return {
        scrollState,
        scrollPos,
        resetScroll,
        scrollItemHeight,
    };
}

export default useRenderVisibleListScroll;
