import React from 'react';
import styled from 'styled-components';
import Spinner from '../../../atoms/Spinner/Spinner';
import { useAppState } from '../../../../store/appstate';
import { useManagementTags } from '../../../../services/hooks/useTags';
import usePermissions from '../../../../services/hooks/usePermissions';
import groupsActions from '../../../../store/admin/groups/actions';
import { GroupStore } from '../../../../types/admin/GroupStore';
import CardBody from '../../../atoms/Card/CardBody';
import Card from '../../../atoms/Card/Card';
import { SessionOrganization } from '../../../../types/SessionUser';
import CheckBox from '../../../atoms/Checkbox/Checkbox';
import getCustomerType from '../../../../services/helpers/customerTypeHelper';
import FlagIcon from '../../../atoms/FlagIcon/FlagIcon';
import CustomerIcon from '../../../atoms/CustomerIcon/CustomerIcon';
import { config } from '../../../../config';
import { useAppDispatch } from '../../../../store';
import Table from '../../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import useFilter from '../../../../services/hooks/useFilter';
import DecoratorInput from '../../../atoms/Input/DecoratorInput';
import FilterLineIcon from 'remixicon-react/Filter3LineIcon';

interface StoresForGroupProps {
    stores: GroupStore[];
    organization: SessionOrganization;
}

const StoresForGroup: React.FC<StoresForGroupProps> = ({
    stores,
    organization,
}: StoresForGroupProps) => {
    const dispatch = useAppDispatch();

    const { userManagementGroupTags } = useManagementTags();
    const { hasWrite } = usePermissions(userManagementGroupTags);

    const filterStoresForGroups = (
        x: GroupStore,
        filterBySearchWord: (property: string) => boolean
    ) =>
        filterBySearchWord(x.storeName) ||
        filterBySearchWord(x.storeId) ||
        filterBySearchWord(x.selfReference ?? '');

    const { search, setSearch, showing, showFilter } = useFilter(stores, filterStoresForGroups);

    const isInternal = organization && organization.id === config.InternalOrganization;
    const loading = useAppState<boolean>(
        s => s.admin.groups.isSearching && s.admin.groups.isLoadingOrganization
    );

    const onTogglePermission = (e: React.ChangeEvent<HTMLInputElement>, storeId: string) => {
        dispatch(groupsActions.toggleStore(organization.id, storeId, e.target.checked));
    };

    const columns: ColumnDef<GroupStore>[] = [
        {
            id: 'storeId',
            header: 'Store id',
            cell: (props: { row: { original: GroupStore } }) => {
                return <span>{props.row.original.storeId}</span>;
            },
        },
        {
            id: 'storeName',
            header: 'Store name',
            cell: (props: { row: { original: GroupStore } }) => {
                return <span>{props.row.original.storeName}</span>;
            },
        },
        {
            id: 'selfReference',
            header: 'Self reference',
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            cell: (props: { row: { original: GroupStore } }) => {
                return (
                    <span>
                        {props.row.original.selfReference !== ''
                            ? props.row.original.selfReference
                            : '-'}
                    </span>
                );
            },
        },
        {
            id: 'salesType',
            header: 'Sales type',
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            cell: (props: { row: { original: GroupStore } }) => {
                const customer =
                    props.row.original.customerSalesType &&
                    getCustomerType(props.row.original.customerSalesType);
                return customer ? <CustomerIcon customerType={customer} /> : <span>-</span>;
            },
        },
        {
            id: 'countryCode',
            header: 'Country',
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            cell: (props: { row: { original: GroupStore } }) => {
                const country = props.row.original.countryCode;

                return country ? <FlagIcon countryCode={country} /> : <span>-</span>;
            },
        },
        {
            header: 'Filter access to store',
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            cell: (props: { row: { original: GroupStore } }) => {
                const store = props.row.original;
                return (
                    <CheckBox
                        type="checkbox"
                        id={store.storeId}
                        onChange={e => onTogglePermission(e, store.storeId)}
                        defaultChecked={store.checked}
                        disabled={!hasWrite}
                    />
                );
            },
        },
    ];

    if (loading || !stores)
        return (
            <SpinnerContainer>
                <Spinner text="Loading stores" loading />
            </SpinnerContainer>
        );

    return (
        <>
            {stores.length > 0 && !isInternal ? (
                <>
                    {showFilter && (
                        <SearchWrapper>
                            <DecoratorInput
                                dataTestId="filter-stores-for-groups"
                                type="text"
                                name="filterStoresForGroups"
                                onChange={e => setSearch(e.target.value)}
                                value={search}
                                placeholder="Filter stores"
                                id="filter-stores-for-groups"
                                decorator={<FilterLineIcon size="17" />}
                            />
                        </SearchWrapper>
                    )}

                    <Card>
                        <CardBody>
                            <Table<GroupStore> data={showing} columns={columns} />
                        </CardBody>
                    </Card>
                </>
            ) : (
                <NoStores>No stores are configured for this organization</NoStores>
            )}
        </>
    );
};

const SearchWrapper = styled.div`
    margin-bottom: 1rem;
    background: white;
`;

const SpinnerContainer = styled.div`
    margin-top: 2rem;
`;

const NoStores = styled.div`
    margin: 2rem;
`;

export default StoresForGroup;
