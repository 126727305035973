const getTimeOfDayMessage = () => {
    const timeMessagePairings = [
            [18, 'Good evening'],
            [12, 'Good afternoon'],
            [5, 'Good morning'],
        ],
        hr = new Date().getHours();

    const timeAtDayMessage = timeMessagePairings.find(x => hr >= (x[0] as number));

    return timeAtDayMessage ? timeAtDayMessage[1] : 'Welcome';
};

export default getTimeOfDayMessage;
