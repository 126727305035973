import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import AtLineIcon from 'remixicon-react/AtLineIcon';
import MailLineIcon from 'remixicon-react/MailLineIcon';

import ResponseError from '../../types/response/ResponseError';

import { LinkData } from '../../types/response/LinkData';
import HeaderTitle from '../../components/atoms/Modal/HeaderTitle';
import ModalContainer from '../../components/atoms/Modal/ModalContainer';
import GridInput from '../../components/atoms/Input/GridInput';
import FooterButtonContainer from '../../components/atoms/Modal/FooterButtonContainer';
import { Address } from '../../types/account/Notification';
import accountActions from '../../store/account/actions';
import { emailValidationConfig } from '../../types/ValidationTypes';
import Spinner from '../../components/atoms/Spinner/Spinner';
import ValidationError from '../../components/atoms/Validation/ValidationError';
import { ErrorType } from '../../types/response/ErrorCodes';
import ErrorMessage from '../../components/atoms/Message/Error/ErrorMessage';
import SuccessMessage from '../../components/atoms/Message/Success/SuccessMessage';
import CenterContent from '../../components/atoms/Layout/CenterContent';
import FormRow from '../../components/atoms/Form/FormRow';
import Button from '../../components/atoms/Button/Button';
import { useAppDispatch } from '../../store';

interface ResendInvoiceModalProps {
    identifier: string;
    resendLink?: LinkData;
    hide: () => void;
    theme: DefaultTheme;
    error: ResponseError | undefined;
    address: Address;
    isResending: boolean;
    isResent: boolean;
    resendFailed: boolean;
}

enum ResendType {
    EMAIL = 'email',
    POST = 'post',
}

type Inputs = {
    email: string;
};

const ResendInvoiceModal: React.FC<ResendInvoiceModalProps> = ({
    identifier,
    resendLink,
    hide,
    theme,
    error,
    address,
    isResending,
    isResent,
    resendFailed,
}: ResendInvoiceModalProps) => {
    const dispatch = useAppDispatch();
    const [selectedValue, setSelectedValue] = useState(ResendType.EMAIL);
    const [email, setEmail] = useState(address ? address.email : '');
    const [sent, setSent] = useState(false);
    const emailAddress = (address && address.email) ?? '';
    const hasEmail = email && emailValidationConfig.emailRegExp.test(email);

    const resendInvoice = () => {
        setSent(true);

        if ((hasEmail && selectedValue === ResendType.EMAIL) || selectedValue === ResendType.POST) {
            const recipient = selectedValue === ResendType.EMAIL ? email : null;

            if (resendLink)
                dispatch(accountActions.resendInvoice(resendLink, selectedValue, recipient));
        }
    };
    const { handleSubmit } = useForm<Inputs>();

    useEffect(() => {
        const emailInput = document.getElementById('emailInput');

        if (emailInput && selectedValue === 'email') {
            emailInput.focus();
        }
    });

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Resend notification {identifier}</HeaderTitle>
            </ModalContainer>
            <ModalContainer position="content">
                <form onSubmit={handleSubmit(resendInvoice)} id="resend-form">
                    {!isResent && (
                        <>
                            <ChoosableCard
                                data-testid="resend-notification-by-email"
                                chosen={selectedValue === ResendType.EMAIL}
                                onClick={() => setSelectedValue(ResendType.EMAIL)}
                            >
                                <ChoosableCardHeader>
                                    <Icon>
                                        <AtLineIcon size={theme.icon.size.small} />
                                    </Icon>
                                    Send by email
                                </ChoosableCardHeader>

                                <Content>
                                    <FormRow first last>
                                        <GridInput
                                            tabIndex={0}
                                            id="emailInput"
                                            name={ResendType.EMAIL}
                                            type={ResendType.EMAIL}
                                            defaultValue={emailAddress}
                                            placeholder="Email"
                                            disabled={selectedValue !== ResendType.EMAIL}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </FormRow>
                                </Content>
                                <ValidationError>
                                    <p>
                                        {!hasEmail &&
                                            selectedValue === ResendType.EMAIL &&
                                            sent &&
                                            'Email is a required value and needs to be valid'}
                                    </p>
                                </ValidationError>
                            </ChoosableCard>

                            <ChoosableCard
                                chosen={selectedValue === ResendType.POST}
                                onClick={() => setSelectedValue(ResendType.POST)}
                                data-testid="resend-notification-by-post"
                            >
                                <ChoosableCardHeader>
                                    <Icon>
                                        <MailLineIcon size={theme.icon.size.small} />
                                    </Icon>
                                    Send by post
                                </ChoosableCardHeader>
                                <Content>
                                    The invoice will be sent to the customer by post
                                    {address && (
                                        <AddressContainer>
                                            <AddressLabel>{address.name}</AddressLabel>
                                            <AddressLabel>{address.addressLine1}</AddressLabel>
                                            <AddressLabel>{address.coAddress}</AddressLabel>
                                            <AddressLabel>
                                                {address.postalCode}, {address.city}
                                            </AddressLabel>
                                            <Country>{address.country}</Country>
                                        </AddressContainer>
                                    )}
                                </Content>
                            </ChoosableCard>
                        </>
                    )}

                    {!isResending && isResent && (
                        <CenterContent>
                            <SuccessMessage>
                                Invoice has been resent by {selectedValue}!
                            </SuccessMessage>
                        </CenterContent>
                    )}
                </form>
            </ModalContainer>

            {resendFailed && (
                <ModalContainer position="error">
                    <ErrorMessage error={error} errorHeader={ErrorType.ResendInvoice} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large disabled={isResending}>
                        Close
                    </Button>

                    {!isResent && (
                        <Button
                            data-testid="resend-notification"
                            type="submit"
                            form="resend-form"
                            tabIndex={0}
                            primary
                            large
                            disabled={!address || isResending || resendFailed}
                        >
                            {isResending ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : (
                                <span>Send</span>
                            )}
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const AddressContainer = styled.div`
    box-shadow: 0 0 0 0.1rem ${props => props.theme.colors.card.border};
    border-radius: 0.2rem;
    margin-top: 2rem;
    padding: 2rem;
`;

const ChoosableCard = styled.div<{ chosen: boolean }>`
    cursor: pointer;
    padding: 2rem;
    border-radius: 0.5rem;
    border: 0.15rem solid
        ${props => (props.chosen ? props.theme.colors.primary : props.theme.colors.card.border)};
    text-align: left;
    margin-bottom: 2rem;
`;

const Content = styled.div`
    padding-top: 2rem;
`;

const ChoosableCardHeader = styled.div`
    display: flex;
    align-items: start;
`;

const Icon = styled.div`
    color: grey;
    margin-right: 1rem;
`;

const AddressLabel = styled.div`
    margin: 0.2rem;
`;

const Country = styled(AddressLabel)`
    text-transform: capitalize;
`;

export default withTheme(ResendInvoiceModal) as React.ComponentType<
    Omit<ResendInvoiceModalProps, 'theme'>
>;
