import React from 'react';
import styled from 'styled-components';
import Radio from '../Input/Radio';
import SelectableNumberInput from '../Input/SelectableNumberInput';
import RelativeOperationTypes from '../../../types/RelativeOperation';

interface RelativeRangeProps {
    relativeChoice: {
        selected: string;
        customValue: number;
        customDecorator: string;
        to: string;
        from: string;
        relative: string;
    };
    setRelativeChoice: (choice: {
        selected: string;
        customValue: number;
        customDecorator: string;
        to: string;
        from: string;
        relative: string;
    }) => void;
    ranges: { name: string; label: string; value: string }[];
    relativeLabel: { type: string; operation: RelativeOperationTypes };
    labelType: string;
    onEnter: () => void;
}

type RangeInput = {
    name: string;
    label: string;
    value: string;
};

const RelativeRange: React.FC<RelativeRangeProps> = ({
    relativeChoice,
    setRelativeChoice,
    ranges,
    labelType,
    relativeLabel,
    onEnter,
}: RelativeRangeProps) => {
    const { selected, customValue, customDecorator } = relativeChoice;

    const handleRadioChange = (toSetObj: RangeInput) => {
        setRelativeChoice({
            ...relativeChoice,
            selected: `${labelType}-${toSetObj.name}`,
            relative: toSetObj.value,
        });
    };

    const parseCustomDateToRelative = (decorator: string, value: number) => {
        const range = decorator.charAt(0).toLowerCase();
        return `${relativeLabel.operation}${value}${range}`;
    };

    const handleCustomRangeChange = (value: number, decorator: string) => {
        setRelativeChoice({
            ...relativeChoice,
            customDecorator: decorator,
            customValue: value,
            relative: parseCustomDateToRelative(decorator, value),
        });
    };

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onEnter();
        }
    };

    return (
        <RangeContainer>
            {ranges.map(x => {
                const uniqueLabel = `${labelType}-${x.name}`;

                return (
                    <React.Fragment key={uniqueLabel}>
                        <Radio
                            id={uniqueLabel}
                            name={uniqueLabel}
                            value={uniqueLabel}
                            onChange={() => handleRadioChange(x)}
                            onClick={() => handleRadioChange(x)}
                            checked={selected === uniqueLabel}
                            dataTestId="relative-range-radio"
                        >
                            {x.label}
                        </Radio>
                    </React.Fragment>
                );
            })}

            <GridWrapper>
                <SelectableNumberInput
                    name="range"
                    id="range"
                    placeholder={relativeLabel.type}
                    selectValues={['Days', 'Weeks', 'Months']}
                    disabled={selected !== `${labelType}-custom`}
                    focus={selected === `${labelType}-custom`}
                    handleChange={handleCustomRangeChange}
                    changeValue={{
                        decorator: customDecorator,
                        value: customValue,
                    }}
                    onKeyDown={e => keyDown(e)}
                    dataTestId="relative-range-custom-input"
                    min={1}
                    max={1000}
                />
            </GridWrapper>
        </RangeContainer>
    );
};

const RangeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem 2rem 4rem 1.5rem;
    padding-bottom: 3.2rem;
    flex: 1;
`;

const GridWrapper = styled.div`
    margin-left: 2.35rem;
`;

export default RelativeRange;
