import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useAppState } from '../../store/appstate';
import orderActions from '../../store/order/actions';

import { Order } from '../../types/order/Order';
import { OrderArticleRow } from '../../types/order/OrderArticleRow';
import OrderArticleStatus from '../../types/order/OrderArticleStatus';
import OrderStatus from '../../types/order/OrderStatus';

import { formatNumber } from '../../services/helpers/numberFormats';
import usePermissions from '../../services/hooks/usePermissions';

import Spinner from '../../components/atoms/Spinner/Spinner';
import Money from '../../components/atoms/Money/Money';
import Card from '../../components/atoms/Card/Card';
import CardHeader from '../../components/atoms/Card/CardHeader';
import CardFooter from '../../components/atoms/Card/CardFooter';
import CardBody from '../../components/atoms/Card/CardBody';
import VAT from '../../components/atoms/Money/VAT';
import Total from '../../components/atoms/Money/Total';
import SubTotal from '../../components/atoms/Money/SubTotal';
import StickyNote from '../../components/atoms/StickyNote/StickyNote';

import Timeline from '../../components/molecules/Timeline/Timeline';
import NotAuthorized from '../../components/molecules/NotAuthorized';
import { PurchaseResponseData } from '../../types/response/PurchaseResponseData';
import PurchaseActions from '../../components/molecules/Order/PurchaseActions';
import InvoiceSidebar from '../../components/molecules/Invoice/InvoiceSidebar';
import PaymentMethod from '../../types/order/PaymentMethod';
import HeaderContent from '../../components/atoms/Layout/HeaderContent';
import CenterContent from '../../components/atoms/Layout/CenterContent';
import OrderDetailsHeader from '../../components/molecules/Order/OrderDetailsHeader';
import OrderArticleRowSource from '../../types/order/OrderArticleRowSource';
import { ConnectedStore } from '../../types/ConnectedStore';
import { ErrorType } from '../../types/response/ErrorCodes';
import OrderCustomer from '../../components/molecules/Customer/OrderCustomer';
import NoTranslateLabel from '../../components/atoms/Label/NoTranslateLabel';
import { OrderState } from '../../store/order/orderSlice';
import EmptyHeaderErrorMessageProps from '../../components/molecules/Error/EmptyHeaderErrorMessage';
import EmptyHeader from '../../components/atoms/Header/EmptyHeader';
import { useAppDispatch } from '../../store';
import { ColumnDef } from '@tanstack/react-table';
import Table from '../../components/atoms/Table/Table';
import InvoiceState from '../../types/invoice/InvoiceState';
import useLogTimeSpentOnPage from '../../services/hooks/useLogTimeSpentOnPage';
import InformationMessage from '../../components/atoms/Message/Information/InformationMessage';
import { RenderRecurringPayment } from '../../components/molecules/Invoice/RecurringPayment';
import { OrderEvent } from '../../types/order/OrderEvent';

interface OrderDetailProps {
    orderId: string;
}

const columns: ColumnDef<OrderArticleRow>[] = [
    {
        header: 'Description',
        accessorKey: 'description',
        cell: (props: { row: { original: OrderArticleRow } }) => {
            return <OrderName>{props.row.original.description ?? '-'}</OrderName>;
        },
    },
    {
        header: 'Article number',
        accessorKey: 'articleNumber',
    },
    {
        meta: {
            style: {
                textAlign: 'center',
            },
        },
        header: 'Quantity',
        accessorKey: 'quantity',
    },
    {
        meta: {
            style: {
                textAlign: 'right',
            },
        },
        header: 'Unit price',
        accessorKey: 'price',
        cell: (props: { row: { original: OrderArticleRow } }) => {
            return <Money>{props.row.original.price ?? 0}</Money>;
        },
    },
    {
        meta: {
            style: {
                textAlign: 'right',
            },
        },
        header: 'VAT',
        accessorKey: 'vatRate',
        cell: (props: { row: { original: OrderArticleRow } }) => {
            return (
                <VAT
                    amount={props.row.original.vatAmount ?? 0}
                    rate={props.row.original.vatRate ?? 0}
                />
            );
        },
    },
    {
        meta: {
            style: {
                textAlign: 'right',
            },
        },
        header: 'Amount',
        accessorKey: 'totalPrice',
        cell: (props: { row: { original: OrderArticleRow } }) => {
            return <Money>{props.row.original.totalPrice ?? 0}</Money>;
        },
    },
];

const noOrderEvents: OrderEvent[] = [];
const noConnectedStores: ConnectedStore[] = [];

const OrderDetails: React.FC<OrderDetailProps> = ({ orderId }: OrderDetailProps) => {
    const { isFetching, order, error } = useAppState<OrderState>(s => s.order);

    const events = useAppState<OrderEvent[]>(s => s.order.order?.events ?? noOrderEvents);

    const orderValue = formatNumber(order?.totalAmount ?? 0);
    const { hasRead, hasWrite } = usePermissions(order?.tags);

    const availableStores =
        useAppState<ConnectedStore[]>(s => s.session.availableStores) ?? noConnectedStores;

    const dispatch = useAppDispatch();
    useLogTimeSpentOnPage('orderDetails');

    const calculateTotals = (rows: OrderArticleRow[]) => {
        const totalExclVat = rows
            .map(r => (r.totalPrice || 0) / (1 + r.vatRate / 100))
            .reduce((s, rowPrice) => s + rowPrice, 0);

        const totalInclVat = rows
            .map(r => r.totalPrice || 0)
            .reduce((s, rowPrice) => s + rowPrice, 0);

        const vatAmount = totalInclVat - totalExclVat;

        return { totalExclVat, totalInclVat, vatAmount };
    };

    const renderRowsWithStatus = (
        purchase: PurchaseResponseData,
        status: string,
        filter?: (x: OrderArticleRow) => boolean
    ) => {
        const articles = purchase.data.rows
            .filter(x => x.status === status)
            .filter(filter || (_ => true));
        const totals = calculateTotals(articles);

        return (
            articles &&
            articles.length > 0 && (
                <CardWrapper key={purchase.data.purchaseId}>
                    <Card>
                        <CardHeader
                            information={<>{purchase.data.purchaseId}</>}
                            status={status}
                            statusText={
                                order?.status === OrderStatus.Activated ? 'Activation' : undefined
                            }
                        >
                            {order && hasWrite && (
                                <CardHeaderPurchaseActions>
                                    <PurchaseActions
                                        order={order}
                                        purchase={purchase}
                                        purchaseStatus={status}
                                    />
                                </CardHeaderPurchaseActions>
                            )}
                        </CardHeader>
                        <CardBody>
                            <Table<OrderArticleRow> data={articles} columns={columns} />
                        </CardBody>
                        <CardFooter>
                            <BlockContainer>
                                <SubTotal
                                    amount={totals.totalInclVat}
                                    vat={totals.vatAmount}
                                    label="Total (VAT)"
                                />
                            </BlockContainer>
                        </CardFooter>
                    </Card>
                </CardWrapper>
            )
        );
    };

    const renderExpiredRows = (purchase: PurchaseResponseData) => {
        return renderRowsWithStatus(purchase, OrderArticleStatus.Expired);
    };

    const renderPendingRows = (purchase: PurchaseResponseData) => {
        return renderRowsWithStatus(purchase, OrderArticleStatus.NotActivated);
    };

    const renderActivation = (purchase: PurchaseResponseData) => {
        const hideStatus = [
            OrderArticleStatus.NotActivated,
            OrderArticleStatus.Removed,
            OrderArticleStatus.Canceled,
            OrderArticleStatus.Returned,
        ];

        return renderRowsWithStatus(
            purchase,
            OrderArticleStatus.Activated,
            r => !hideStatus.includes(r.status)
        );
    };

    const renderPaymentMethod = (orderItem: Order) => {
        switch (orderItem?.paymentMethod) {
            case PaymentMethod.Invoice:
            case PaymentMethod.Account:
            case PaymentMethod.Installment:
                return <InvoiceSidebar order={orderItem} />;
            default:
                return (
                    <>
                        <div>
                            <NoMarginLabel>Payment method</NoMarginLabel>
                            <RightSideContent>
                                {orderItem.paymentMethod && (
                                    <NoTranslateLabel>{orderItem.paymentMethod}</NoTranslateLabel>
                                )}
                            </RightSideContent>
                        </div>
                        <div>
                            <NoMarginLabel>Purchase identifiers</NoMarginLabel>
                            <RightSideContent>
                                {orderItem.purchases?.map(x => x.data.purchaseId).join(', ')}
                            </RightSideContent>
                        </div>
                    </>
                );
        }
    };

    const renderMoneyCalculations = () => {
        if (!order) return <></>;

        return (
            <MoneyCalculations>
                {order.originalOrderAmount !== undefined && (
                    <MoneyTotal>
                        <Label>Original order amount</Label>
                        <Money>{order.originalOrderAmount}</Money>
                    </MoneyTotal>
                )}

                {order.notActivatedAmount !== undefined && order.notActivatedAmount > 0 && (
                    <MoneyTotal>
                        <Label>Not activated</Label>
                        <Money>{order.notActivatedAmount}</Money>
                    </MoneyTotal>
                )}

                {order.activatedAmount !== undefined && order.activatedAmount > 0 && (
                    <MoneyTotal>
                        <Label>Activated</Label>
                        <Money>{order.activatedAmount}</Money>
                    </MoneyTotal>
                )}

                {adjustedRowsTot !== 0 && (
                    <MoneyTotal>
                        <Label>Adjusted</Label>
                        <Money>{adjustedRowsTot}</Money>
                    </MoneyTotal>
                )}

                {order.status === OrderStatus.Returned && returnedPurchaseRowsTot !== 0 && (
                    <MoneyTotal>
                        <Label>Returned</Label>
                        <Money testId="returned-amount">{returnedPurchaseRowsTot}</Money>
                    </MoneyTotal>
                )}

                {order.status != OrderStatus.Returned && returnedRowsTot !== 0 && (
                    <MoneyTotal>
                        <Label>Returned</Label>
                        <Money testId="returned-amount">{returnedRowsTot}</Money>
                    </MoneyTotal>
                )}
            </MoneyCalculations>
        );
    };

    useEffect(() => {
        dispatch(orderActions.getOrder(orderId));
    }, [dispatch, orderId]);

    const getTotalOf = (type: OrderArticleStatus, rowSource?: OrderArticleRowSource) =>
        order?.purchases
            ?.map(x => x.data.rows)
            .flat()
            .filter(x => x.status === type && (rowSource ? x.rowSource === rowSource : true))
            .reduce((sum, current) => sum + current.price * current.quantity, 0) || 0;

    const adjustedRowsTot =
        order?.purchases
            ?.map(x => x.data.rows)
            .flat()
            .filter(
                x =>
                    x.status !== OrderArticleStatus.Removed &&
                    x.status !== OrderArticleStatus.Returned &&
                    x.status !== OrderArticleStatus.Canceled &&
                    x.rowSource === OrderArticleRowSource.ADJUSTMENT
            )
            .reduce((sum, current) => sum + current.price * current.quantity, 0) || 0;

    const returnedRowsTot = getTotalOf(OrderArticleStatus.Returned) * -1;
    const returnedPurchaseRowsTot =
        getTotalOf(OrderArticleStatus.Returned, OrderArticleRowSource.PURCHASE) * -1;

    const orderStoreId = (order?.storeId && order?.storeId.toString()) ?? '';
    const store = availableStores && order && availableStores.find(x => x && x.id === orderStoreId);

    if (isFetching || (!isFetching && !order && !error)) {
        return <Spinner text="Fetching order..." loading={isFetching} />;
    }

    if (!order && error !== undefined) {
        return (
            <EmptyHeaderErrorMessageProps
                error={error}
                keyWord="order"
                errorType={ErrorType.Order}
            />
        );
    }

    const isBeforeProductCode = order && new Date(order.placedAt) < new Date('2021-09-30');
    const hasNotifications =
        order &&
        order.accounts &&
        order.accounts.length > 0 &&
        order.accounts.some(a => a.invoices && a.invoices.length > 0);

    const filteredTimelineEvents =
        (order?.events &&
            order?.events.filter(
                x =>
                    x.metaData.invoiceState !== InvoiceState.TerminationNotice &&
                    x.metaData.invoiceState !== InvoiceState.CollectionNotice
            )) ??
        events;

    return hasRead && order ? (
        <>
            <HeaderContent>
                <CenterContent>
                    <OrderDetailsHeader order={order} store={store} />
                </CenterContent>
            </HeaderContent>

            <CenterContent>
                <Grid>
                    <OrderInfo>
                        {order &&
                            order.purchases &&
                            [...order.purchases]
                                .sort()
                                .reverse()
                                .map((purchase: PurchaseResponseData) => {
                                    return renderPendingRows(purchase);
                                })}

                        {order &&
                            order.purchases &&
                            [...order.purchases]
                                .sort()
                                .reverse()
                                .map((purchase: PurchaseResponseData) => {
                                    return renderExpiredRows(purchase);
                                })}

                        {order &&
                            order.status === OrderStatus.Returned &&
                            order.purchases &&
                            [...order.purchases]
                                .sort()
                                .reverse()
                                .map((purchase: PurchaseResponseData) => {
                                    return renderRowsWithStatus(
                                        purchase,
                                        OrderArticleStatus.Returned
                                    );
                                })}

                        {order &&
                            order.status === OrderStatus.Closed &&
                            order.purchases &&
                            [...order.purchases]
                                .sort()
                                .reverse()
                                .map((purchase: PurchaseResponseData) => {
                                    return renderRowsWithStatus(
                                        purchase,
                                        OrderArticleStatus.Canceled
                                    );
                                })}

                        {order &&
                            order.status !== OrderStatus.Closed &&
                            order.purchases &&
                            [...order.purchases]
                                .sort((a, b) => {
                                    return (
                                        parseInt(b.data.purchaseId, 10) -
                                        parseInt(a.data.purchaseId, 10)
                                    );
                                })
                                .map((purchase: PurchaseResponseData) => {
                                    return renderActivation(purchase);
                                })}
                        <OrderEvents>
                            <Timeline
                                events={filteredTimelineEvents ?? events}
                                currency={order.currency}
                            />
                        </OrderEvents>
                    </OrderInfo>

                    <RightPanelContainer>
                        <StickyNote padding={false}>
                            <TotalContainer>
                                <Label>Total order amount</Label>
                                <TotalOrderValue>
                                    <Total>{orderValue}</Total>
                                    <TotVat>
                                        (<Money>{order.totalVat}</Money> VAT)
                                    </TotVat>
                                </TotalOrderValue>
                            </TotalContainer>

                            {renderMoneyCalculations()}
                        </StickyNote>
                        {order.customerDetailsPurged ? (
                            <InformationMessage
                                messages={[
                                    "We've removed all personal data for this customer in accordance with GDPR.",
                                    'Contact help@walley.se for more information.',
                                ]}
                                messageHeader="Personal data removed"
                            />
                        ) : (
                            order.customerDetails && (
                                <OrderCustomer
                                    customer={order.customerDetails}
                                    deliveryAddress={order.deliveryAddress}
                                    invoiceAddress={order.invoiceAddress}
                                    orderStatus={order.status ?? ''}
                                    referencePerson={order.referencePerson}
                                    authorizedBuyer={order.commitmentAlias}
                                    costCenter={order.costCenter}
                                    orderId={order.id}
                                    tags={order.tags}
                                />
                            )
                        )}
                        <PaymentMethodContainer hasNotifications={hasNotifications}>
                            {order.customerToken && (
                                <RenderRecurringPayment token={order.customerToken} />
                            )}
                            {!isBeforeProductCode && !order.isPrepaidPaymentMethod && (
                                <div>
                                    <NoMarginLabel>Product code</NoMarginLabel>
                                    <Content>{order.productCode ?? '-'}</Content>
                                </div>
                            )}
                            {renderPaymentMethod(order)}
                        </PaymentMethodContainer>
                    </RightPanelContainer>
                </Grid>
            </CenterContent>
        </>
    ) : (
        <>
            <HeaderContent>
                <EmptyHeader />
            </HeaderContent>
            <CenterContent>
                <NotAuthorized componentName="Order Details" />
            </CenterContent>
        </>
    );
};

interface PaymentMethodContainerProps {
    hasNotifications?: boolean;
}

const TotalContainer = styled.div`
    padding: 2rem 2rem 1.5rem 2rem;
`;

const TotalOrderValue = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const TotVat = styled.div`
    margin: 0 0.5rem 0 0.5rem;
`;

const MoneyCalculations = styled.div`
    padding-bottom: 1rem;
`;

const MoneyTotal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    align-items: center;
`;

const Label = styled.label`
    line-height: 2.5rem;
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    margin-top: 1rem;
    display: block;
`;

const NoMarginLabel = styled(Label)`
    margin-top: 0;
`;

const Grid = styled.div`
    display: grid;
    grid-template-areas: 'content right';
    grid-template-columns: auto 40rem;
    grid-column-gap: 2rem;
    height: 100vh;
`;

const RightSideContent = styled.div`
    font-size: ${props => props.theme.text.size.medium};
`;

const CardWrapper = styled.div`
    background-color: ${props => props.theme.colors.light};
    margin-bottom: ${props => props.theme.layout.margin.large};
`;

const OrderInfo = styled.div`
    margin-top: 2rem;
    grid-area: content;
    margin-bottom: 4rem;
`;

const RightPanelContainer = styled.div`
    grid-area: right;
    padding: 2rem;
    height: 100%;
`;
const PaymentMethodContainer = styled.div<PaymentMethodContainerProps>`
    gap: 2rem;
    padding: 2rem;
    ${props => props.hasNotifications && 'padding-bottom: 0'};
    background-color: #fff;
    border: 0.1rem solid ${props => props.theme.colors.border};
    display: flex;
    flex-direction: column;
`;

const OrderEvents = styled.div`
    border: 0.1rem solid ${props => props.theme.colors.border};
    border-radius: 0.5rem;
    background-color: ${props => props.theme.colors.light};
    margin-top: 3rem;
    grid-area: events;
    padding: 1rem 2rem 2rem 2rem;
`;

const OrderName = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 15rem;
`;

const BlockContainer = styled.div`
    display: block;
`;

const CardHeaderPurchaseActions = styled.div`
    margin-left: auto;
    align-self: center;
`;

const Content = styled.div`
    color: ${props => props.theme.colors.text.primary};
`;

export default OrderDetails;
