import React from 'react';
import styled from 'styled-components';
import CustomerIcon from '../CustomerIcon/CustomerIcon';
import FlagIcon from '../FlagIcon/FlagIcon';
import getCustomerType from '../../../services/helpers/customerTypeHelper';
import { ConnectedStore } from '../../../types/ConnectedStore';

interface CompactStoreLabelProps {
    store: ConnectedStore;
}

const CompactStoreLabel: React.FC<CompactStoreLabelProps> = ({ store }: CompactStoreLabelProps) => {
    const customerIcon = store.customerSalesType && getCustomerType(store.customerSalesType);

    return (
        <StoreContainer>
            <IconContainer>
                {customerIcon && <CustomerIcon customerType={customerIcon} />}
                <FlagIcon countryCode={store.countryCode} />
            </IconContainer>

            <StoreLabelContainer
                title={`${store.id} ${store.storeName}${
                    store.selfReference ? ` ${store.selfReference}` : ''
                }`}
            >
                <Label>
                    {store.selfReference && <Reference>{store.selfReference}</Reference>}
                    <span>{store.storeName}</span>
                </Label>
            </StoreLabelContainer>
        </StoreContainer>
    );
};

const Label = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 100%;
    max-width: 20rem;
`;

const Reference = styled.span`
    color: ${props => props.theme.colors.text.secondary};
    margin-right: 0.5rem;
`;

const IconContainer = styled.div`
    display: flex;
`;

const StoreContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

const StoreLabelContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export default CompactStoreLabel;
