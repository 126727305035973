enum DashboardCardDisplayType {
    PERCENT = 'Percent',
    PERCENT_INVERTED = 'Percent_Inverted',
    NUMBER = 'Number',
    FREQUENCE = 'Frequence',
    THUMBUPDOWN = 'Thumbupdown',
    MONEY = 'Money',
}

export default DashboardCardDisplayType;
