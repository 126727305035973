import { combineReducers } from 'redux';
import adminStateReducer from './admin/reducer';
import reportStateReducer from './report/reducer';
import marketingSliceReducer from './marketing/marketingSlice';
import userSettingsSliceReducer from './userSettings/userSettingsSlice';
import searchSliceReducer from './search/searchSlice';
import sessionSliceReducer from './session/sessionSlice';
import accountSliceReducer from './account/accountSlice';
import dashboardSliceReducer from './dashboard/dashboardSlice';
import orderSliceReducer from './order/orderSlice';
import customerSliceReducer from './customer/customerSlice';
import checkoutSliceReducer from './checkout/checkoutSlice';

const rootReducer = combineReducers({
    session: sessionSliceReducer,
    userSettings: userSettingsSliceReducer,
    search: searchSliceReducer,
    order: orderSliceReducer,
    admin: adminStateReducer,
    account: accountSliceReducer,
    report: reportStateReducer,
    dashboard: dashboardSliceReducer,
    marketing: marketingSliceReducer,
    customer: customerSliceReducer,
    checkout: checkoutSliceReducer,
});

export default rootReducer;
