import React from 'react';
import styled from 'styled-components';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import ResponseError, { ErrorCode } from '../../../../types/response/ResponseError';
import NotAuthorized from '../../../molecules/NotAuthorized';
import Button from '../../Button/Button';
import MessageContainer from '../MessageContainer';
import { useAppDispatch } from '../../../../store';
import sessionActions from '../../../../store/session/actions';

interface ErrorMessageProps {
    error?: ResponseError | undefined;
    errorHeader: ErrorType;
    errorMessage?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
    error,
    errorHeader,
    errorMessage,
}: ErrorMessageProps) => {
    const dispatch = useAppDispatch();

    const mapComponent = (x: ErrorType) => {
        switch (x) {
            case ErrorType.FetchAccount:
            case ErrorType.CreditFee:
            case ErrorType.BulkError:
            case ErrorType.OrderUpdate:
            case ErrorType.ActivateInvoice:
            case ErrorType.Search:
            case ErrorType.CancelOrder:
            case ErrorType.Extend:
            case ErrorType.ResendInvoice:
            case ErrorType.Order:
                return 'Order management';
            case ErrorType.Invite:
            case ErrorType.LoadUsers:
            case ErrorType.LoadGroups:
                return 'Access management';
            default:
                return '';
        }
    };

    if (error && error.code === ErrorCode.USER_UNAUTHORIZED) {
        return <NotAuthorized componentName={mapComponent(errorHeader)} />;
    }

    return (
        <>
            {error && error.title && (
                <MessageContainer type="Error" header={errorHeader}>
                    {error.code === ErrorCode.CLIENT_CONNECTION_ERROR && (
                        <p>
                            Your action might have been performed, but due to a technical error we
                            could not verify this.
                            <br />
                            Please refresh this page to fetch the latest data and verify that your
                            changes have been applied.
                        </p>
                    )}

                    <Explanation>{error.detail === '' ? error.title : error.detail}</Explanation>

                    {error.errors && Array.isArray(error.errors) && error.errors.length > 0 && (
                        <AdditionalErrors>
                            <ul>
                                {error.errors &&
                                    error.errors.map(err => (
                                        <li key={err.message}>{err.message}</li>
                                    ))}
                            </ul>
                        </AdditionalErrors>
                    )}
                    {error.errors && !Array.isArray(error.errors) && (
                        <AdditionalErrors>
                            <ul>
                                {error.errors &&
                                    Object.entries(error.errors).flatMap(([key, value]) =>
                                        value.map(err => <li key={`${key}-${err}`}>{err}</li>)
                                    )}
                            </ul>
                        </AdditionalErrors>
                    )}
                    {error.status === 401 && (
                        <Button
                            type="button"
                            onClick={() => {
                                dispatch(sessionActions.userUnauthorized());
                                window.location.reload();
                            }}
                        >
                            Reload page
                        </Button>
                    )}
                </MessageContainer>
            )}
            {error && !error?.title && (
                <MessageContainer type="Error" header={errorHeader}>
                    <Explanation>{errorMessage}</Explanation>
                </MessageContainer>
            )}
        </>
    );
};

const Explanation = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin: 1rem 0;
`;

const AdditionalErrors = styled.div`
    font-size: ${props => props.theme.text.size.xsmall};
`;

export default ErrorMessage;
