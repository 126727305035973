/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from '../../types/order/Order';
import { OrderPatchRequest } from '../../types/requests/OrderPatchRequest';
import { MultistatusResponseData } from '../../types/response/MultistatusResponseData';
import { OrderDetailsLinks } from '../../types/response/OrderDetailsLinks';
import { OrderDetailsResponseData } from '../../types/response/OrderDetailsResponseData';
import ResponseError from '../../types/response/ResponseError';
import Multistatus from '../../types/response/Multistatus';

export interface OrderState {
    isFetching: boolean;
    isBusy: boolean;
    isCanceling: boolean;
    cancelSuccessful: boolean;
    order?: Order;
    orderLinks?: OrderDetailsLinks;
    error?: ResponseError;
    isActivated: boolean;
    isExtended: boolean;
    isExtending: boolean;
    extendFailed: boolean;
    isUpdated: boolean;
    orderPatchRequest?: OrderPatchRequest[];
    bulkOrderProcessing: boolean;
    bulkError?: ResponseError[];
    bulkMultiStatus: Multistatus[];
    bulkSucceeded: boolean;
    changingInvoiceAddressSuccessful: boolean;
    changingInvoiceAddressFailed: boolean;
    invoiceAddressError?: ResponseError;
    isChangingInvoiceAddress: boolean;
}

const initialState: OrderState = {
    isFetching: false,
    isBusy: false,
    isCanceling: false,
    cancelSuccessful: false,
    order: undefined,
    orderLinks: undefined,
    error: undefined,
    isActivated: false,
    isExtended: false,
    isExtending: false,
    extendFailed: false,
    isUpdated: false,
    orderPatchRequest: [],
    bulkOrderProcessing: false,
    bulkError: undefined,
    bulkMultiStatus: [],
    bulkSucceeded: false,
    isChangingInvoiceAddress: false,
    changingInvoiceAddressSuccessful: false,
    changingInvoiceAddressFailed: false,
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        getOrderStarted: state => {
            state.isFetching = true;
            state.isCanceling = false;
            state.cancelSuccessful = false;
            state.error = undefined;
        },
        getOrderSucceeded: (state, action: PayloadAction<OrderDetailsResponseData>) => {
            state.order = action.payload.data;
            state.orderLinks = action.payload.links;
            state.isFetching = false;
            state.error = undefined;
        },
        getOrderFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isFetching = false;
            state.order = undefined;
            state.error = action.payload;
        },
        activateOrderStarted: state => {
            state.isBusy = true;
            state.error = undefined;
        },
        activateOrderSucceeded: (state, action: PayloadAction<OrderDetailsResponseData>) => {
            state.order = action.payload.data;
            state.orderLinks = action.payload.links;
            state.isActivated = true;
            state.isBusy = false;
            state.error = undefined;
        },
        activateOrderFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isBusy = false;
            state.error = action.payload;
        },
        clearOrderActivation: state => {
            state.isActivated = false;
            state.error = undefined;
        },
        cancelOrderStarted: state => {
            state.isCanceling = true;
        },
        cancelOrderSucceeded: (state, action: PayloadAction<OrderDetailsResponseData>) => {
            state.isCanceling = false;
            state.cancelSuccessful = true;
            state.order = action.payload.data;
            state.orderLinks = action.payload.links;
        },
        cancelOrderFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isCanceling = false;
            state.error = action.payload;
        },
        clearOrderCancelError: state => {
            state.error = undefined;
            state.isCanceling = false;
            state.cancelSuccessful = false;
        },
        extendDueDateStarted: state => {
            state.isExtending = true;
        },
        extendDueDateSucceeded: (state, action: PayloadAction<OrderDetailsResponseData>) => {
            state.isExtending = false;
            state.isExtended = true;
            state.order = action.payload.data;
            state.orderLinks = action.payload.links;
        },
        extendDueDateFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isExtending = false;
            state.extendFailed = true;
            state.error = action.payload;
        },
        clearExtendDueDate: state => {
            state.isExtending = false;
            state.isExtended = false;
            state.extendFailed = false;
            state.error = undefined;
        },
        updateOrderStarted: (state, action: PayloadAction<OrderPatchRequest[]>) => {
            state.isBusy = true;
            state.orderPatchRequest = action.payload;
        },
        updateOrderSucceeded: (state, action: PayloadAction<OrderDetailsResponseData>) => {
            state.isBusy = false;
            state.isUpdated = true;
            state.order = action.payload.data;
        },
        updateOrderFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isBusy = false;
            state.error = action.payload;
        },
        clearUpdateOrder: state => {
            state.isBusy = false;
            state.error = undefined;
            state.isUpdated = false;
        },
        bulkOrderStarted: state => {
            state.bulkOrderProcessing = true;
        },
        bulkOrderSucceeded: (state, action: PayloadAction<MultistatusResponseData>) => {
            const existing = state.bulkMultiStatus ?? [];
            const multiStatusPayload = action.payload?.multistatus ?? [];

            state.bulkOrderProcessing = false;
            state.bulkMultiStatus = [...existing, ...multiStatusPayload];
            state.bulkSucceeded = true;
        },
        bulkOrderFailed: (state, action: PayloadAction<ResponseError>) => {
            const existing = state.bulkError ?? [];

            state.bulkOrderProcessing = false;
            state.bulkError = [...existing, action.payload];
        },
        clearBulkOrder: state => {
            state.bulkError = undefined;
            state.bulkSucceeded = false;
        },
        updateOrderInvoiceAddressStarted: state => {
            state.isChangingInvoiceAddress = true;
            state.invoiceAddressError = undefined;
            state.changingInvoiceAddressSuccessful = false;
            state.changingInvoiceAddressFailed = false;
        },
        updateOrderInvoiceAddressSucceeded: (
            state,
            action: PayloadAction<OrderDetailsResponseData>
        ) => {
            state.isChangingInvoiceAddress = false;
            state.changingInvoiceAddressSuccessful = true;
            state.order = action.payload.data;
        },
        updateOrderInvoiceAddressFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isChangingInvoiceAddress = false;
            state.changingInvoiceAddressFailed = true;
            state.invoiceAddressError = action.payload;
        },
        clearUpdateOrderInvoiceAddress: state => {
            state.invoiceAddressError = undefined;
            state.changingInvoiceAddressSuccessful = false;
            state.changingInvoiceAddressFailed = false;
            state.isChangingInvoiceAddress = false;
        },
    },
});

export const {
    getOrderStarted,
    getOrderSucceeded,
    getOrderFailed,
    activateOrderStarted,
    activateOrderSucceeded,
    activateOrderFailed,
    clearOrderActivation,
    cancelOrderStarted,
    cancelOrderSucceeded,
    cancelOrderFailed,
    clearOrderCancelError,
    extendDueDateStarted,
    extendDueDateSucceeded,
    extendDueDateFailed,
    clearExtendDueDate,
    updateOrderStarted,
    updateOrderSucceeded,
    updateOrderFailed,
    clearUpdateOrder,
    bulkOrderStarted,
    bulkOrderSucceeded,
    bulkOrderFailed,
    clearBulkOrder,
    updateOrderInvoiceAddressStarted,
    updateOrderInvoiceAddressSucceeded,
    updateOrderInvoiceAddressFailed,
    clearUpdateOrderInvoiceAddress,
} = orderSlice.actions;

export default orderSlice.reducer;
