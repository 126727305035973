import BucketSize from '../../types/dashboard/BucketSize';
import { calculateDiffBetweenTwoDates } from './dateTimeHelper';

function monthDiff(dateFrom: Date, dateTo: Date) {
    return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
}

const getBucketSizeAndLimitations = (range: (Date | null)[]) => {
    if (!range[0] || !range[1])
        return {
            defaultSize: BucketSize.Hour,
            limitation: [BucketSize.Hour, BucketSize.Day, BucketSize.Month, BucketSize.Year],
        };
    const days = calculateDiffBetweenTwoDates(range[0], range[1]);
    if (days <= 1)
        return {
            defaultSize: BucketSize.Hour,
            limitation: [BucketSize.Hour],
        };
    if (days <= 31)
        return {
            defaultSize: BucketSize.Day,
            limitation: [BucketSize.Hour, BucketSize.Day],
        };
    const months = monthDiff(range[0], range[1]);
    if (months <= 18)
        return { defaultSize: BucketSize.Month, limitation: [BucketSize.Day, BucketSize.Month] };

    if (months > 18)
        return { defaultSize: BucketSize.Year, limitation: [BucketSize.Month, BucketSize.Year] };

    return {
        defaultSize: BucketSize.Hour,
        limitation: [BucketSize.Hour, BucketSize.Day, BucketSize.Month, BucketSize.Year],
    };
};

export { getBucketSizeAndLimitations };
