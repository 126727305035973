import React, { MouseEvent } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import IconButton from '../Button/IconButton';
import { CalendarState } from './CalendarState';

interface CalendarHeaderProps {
    month: number;
    year: number;
    setCalendarState: (value: Partial<CalendarState>) => void;
    setCurrentMonth: (month: number, year: number) => void;
    theme: DefaultTheme;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
    month,
    year,
    setCalendarState,
    setCurrentMonth,
    theme,
}: CalendarHeaderProps) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const decreaseMonth = (event: MouseEvent) => {
        event.preventDefault();
        let monthToSet = Math.abs((month + 12 - 1) % 12);

        if (month === 0) {
            monthToSet = Math.abs((month + 12 - 1) % 12);

            setCalendarState({
                month: monthToSet,
                year: year - 1,
            });
            setCurrentMonth(monthToSet, year - 1);
            return;
        }

        setCalendarState({ month: monthToSet });
        setCurrentMonth(monthToSet, year);
    };

    const increaseMonth = (event: MouseEvent) => {
        event.preventDefault();
        let monthToSet = Math.abs((month + 1) % 12);

        if (month === 11) {
            monthToSet = Math.abs((month + 1) % 12);

            setCalendarState({
                month: monthToSet,
                year: year + 1,
            } as CalendarState);
            setCurrentMonth(monthToSet, year + 1);
            return;
        }

        setCalendarState({ month: monthToSet } as CalendarState);
        setCurrentMonth(monthToSet, year);
    };

    return (
        <CalendarHeaderContainer data-testid="calendar-header">
            <IconButton
                dataTestId="decrease-month"
                transparent
                type="button"
                color="secondary"
                onClick={e => decreaseMonth(e)}
            >
                <ArrowLeftSLineIcon size={theme.icon.size.small} />
            </IconButton>
            <HeaderMonthYear>
                {months[month]} {year}
            </HeaderMonthYear>
            <IconButton
                dataTestId="increase-month"
                transparent
                type="button"
                color="secondary"
                onClick={e => increaseMonth(e)}
            >
                <ArrowRightSLineIcon size={theme.icon.size.small} />
            </IconButton>
        </CalendarHeaderContainer>
    );
};

const HeaderMonthYear = styled.div`
    align-self: center;
`;

const CalendarHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
`;

export default withTheme(CalendarHeader) as React.ComponentType<Omit<CalendarHeaderProps, 'theme'>>;
