import { useAppState } from '../../store/appstate';
import SessionUserPermissons from '../../types/SessionUserPermissions';

const useSpecificPermission = (permission: string) => {
    const permissions = useAppState<SessionUserPermissons[]>(
        s => s.session.user?.permissions || []
    );

    const read = permissions.some(x => x.operation === 'read' && x.tag === permission);
    const write = permissions.some(x => x.operation === 'read' && x.tag === permission);

    return {
        hasReadAndWrite: read && write,
        hasRead: read,
        hasWrite: write,
    };
};

export default useSpecificPermission;
