import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    title: string;
    children: ReactNode;
    icon: ReactElement;
}

const EmptyState: React.FC<Props> = ({ children, title, icon }: Props) => {
    return (
        <Container>
            {icon}
            <Title>{title}</Title>
            {children}
        </Container>
    );
};

const Title = styled.div`
    font-family: ${props => props.theme.text.font.bold};
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 5rem;
`;

export default EmptyState;
