import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../atoms/Modal/Modal';
import Button from '../../atoms/Button/Button';
import { Order } from '../../../types/order/Order';
import useModal from '../../../services/hooks/useModal';
import BulkOrderModal from './BulkOrderModal';
import { clearBulkOrder } from '../../../store/order/orderSlice';
import { useAppDispatch } from '../../../store';

interface BulkOrderActionsProps {
    selectedOrders: Order[];
    executed: () => void;
}
const BulkOrderActions: React.FC<BulkOrderActionsProps> = ({
    selectedOrders,
    executed,
}: BulkOrderActionsProps) => {
    const dispatch = useAppDispatch();
    const [bulkAction, setBulkAction] = useState('');
    const [progress, setProgress] = useState(0);

    const bulkActivateModal = useModal();

    const onBulkClick = (action: string) => {
        setBulkAction(action);
        bulkActivateModal.toggle();
    };

    let id: ReturnType<typeof setTimeout>;

    const hideBulkOrdersModal = (reason: string) => {
        clearTimeout(id);
        dispatch(clearBulkOrder());
        bulkActivateModal.toggle();
        setProgress(0);
        setBulkAction('');

        if (reason === 'executed') executed();
    };

    const handleFakeLoading = () => {
        const seconds = 30;

        id = setTimeout(() => {
            setProgress(progress + 2);
        }, (seconds * 1000) / 100);

        if (progress >= 100) {
            clearTimeout(id);
        }
    };

    return (
        <>
            <BulkButtons>
                <Button
                    data-testid="bulk-action-activate-button"
                    onClick={() => onBulkClick('Activate')}
                    disabled={selectedOrders.length === 0}
                >
                    Activate selected
                </Button>
                <Button
                    data-testid="bulk-action-cancel-button"
                    onClick={() => onBulkClick('Cancel')}
                    disabled={selectedOrders.length === 0}
                >
                    Cancel selected
                </Button>
            </BulkButtons>

            {selectedOrders && (
                <Modal
                    medium
                    isShown={bulkActivateModal.isShown}
                    hide={() => hideBulkOrdersModal('hide')}
                >
                    <BulkOrderModal
                        handleFakeLoading={handleFakeLoading}
                        progress={progress}
                        items={selectedOrders}
                        action={bulkAction}
                        hide={hideBulkOrdersModal}
                    />
                </Modal>
            )}
        </>
    );
};

const BulkButtons = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
`;

export default BulkOrderActions;
