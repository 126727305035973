import api from '../../../services/api';
import { ApiSecret } from '../../../types/ApiSecret';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import {
    searchStarted,
    searchSucceeded,
    searchFailed,
    creating,
    creatingFailed,
    created,
    createdCleared,
    removing,
    removed,
    removeFailed,
    removedCleared,
} from './apiSecretsSlice';

const defaultPageSize = 30;

interface ApiSecretData {
    label: string;
    expirationDate: Date | null;
}

const listApiSecrets =
    (organizationId: string, pageSize: number = defaultPageSize): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(searchStarted());

        if (!organizationId) {
            return;
        }

        try {
            const response = await api.get<ResponseData<ApiSecret[]>>(
                `organizations/${organizationId}/apisecrets`
            );

            dispatch(searchSucceeded(response));
        } catch (error) {
            dispatch(searchFailed(error as ResponseError));
        }
    };

const createApiSecret =
    (organizationId: string, label: string, expirationDate: Date | null): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(creating());

        try {
            const response = await api.post<ApiSecretData>(
                `organizations/${organizationId}/apisecrets`,
                { label, expirationDate }
            );

            dispatch(created(response.metadata));
            await dispatch(listApiSecrets(organizationId));
        } catch (error) {
            dispatch(creatingFailed(error as ResponseError));
        }
    };

const removeApiSecret =
    (organizationId: string, secretId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(removing());

        try {
            await api.del(`organizations/${organizationId}/apisecrets/${secretId}`);

            dispatch(removed());
            await dispatch(listApiSecrets(organizationId));
        } catch (error) {
            dispatch(removeFailed(error as ResponseError));
        }
    };

const clearCreate = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    dispatch(createdCleared());
};

const clearRemoved = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    dispatch(removedCleared());
};

export default {
    listApiSecrets,
    createApiSecret,
    clearCreate,
    removeApiSecret,
    clearRemoved,
};
