const checkForNewVersion = (showUpdateAvailable: () => void) => {
    const regex = /static\/js\/main\.([a-zA-Z0-9]+).js/g;
    const localVersion = window.document.head.innerHTML.match(regex) ?? [];

    fetch(window.location.href)
        .then(response => {
            return response.text();
        })
        .then(html => {
            const fetchVersion = html.match(regex) ?? [];

            if (
                fetchVersion.length > 0 &&
                localVersion.length > 0 &&
                fetchVersion[0] !== localVersion[0]
            ) {
                showUpdateAvailable();
            }
        });
};

export default checkForNewVersion;
