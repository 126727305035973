import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import { useDispatch } from 'react-redux';

import rootReducer from './rootReducer';
import { AppThunkDispatch } from './thunk';

const createStoreWithMiddleware = composeWithDevTools(
    applyMiddleware(save({ states: ['session', 'userSettings'], disableWarnings: true }), thunk)
)(createStore);

const store = createStoreWithMiddleware(
    rootReducer,
    load({ states: ['session', 'userSettings'], disableWarnings: true })
);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();

export default store;
