/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '../../types/account/Account';
import { ResponseData } from '../../types/response/ResponseData';
import ResponseError from '../../types/response/ResponseError';

export interface AccountState {
    account?: Account;
    isExtending: boolean;
    isExtended: boolean;
    isBusy: boolean;
    fetchAccountFailed: boolean;
    error?: ResponseError;
    extendFailed: boolean;
    resendFailed: boolean;
    isResending: boolean;
    isResent: boolean;
    isCrediting: boolean;
    isCredited: boolean;
    creditFeeFailed: boolean;
}

const initialState: AccountState = {
    account: undefined,
    isBusy: false,
    fetchAccountFailed: false,
    isExtending: false,
    isExtended: false,
    extendFailed: false,
    resendFailed: false,
    isResending: false,
    isResent: false,
    isCrediting: false,
    isCredited: false,
    creditFeeFailed: false,
    error: undefined,
};
export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        getAccountStarted: state => {
            state.isBusy = true;
            state.fetchAccountFailed = false;
        },
        getAccountSucceeded: (state, action: PayloadAction<ResponseData<Account>>) => {
            state.isBusy = false;
            state.account = action.payload.data;
        },
        getAccountFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isBusy = false;
            state.error = action.payload;
            state.fetchAccountFailed = true;
        },
        accountExtendDueDateStarted: state => {
            state.isExtending = true;
            state.extendFailed = false;
        },
        accountExtendDueDateSucceeded: (state, action: PayloadAction<ResponseData<Account>>) => {
            state.isExtending = false;
            state.isExtended = true;
            state.account = action.payload.data;
        },
        accountExtendDueDateFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isExtending = false;
            state.isExtended = false;
            state.extendFailed = true;
            state.error = action.payload;
        },
        clearExtendDueDateError: state => {
            state.isExtending = false;
            state.extendFailed = false;
            state.error = undefined;
        },
        resendInvoiceStarted: state => {
            state.isResending = true;
            state.isResent = false;
        },
        resendInvoiceFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isResending = false;
            state.isResent = false;
            state.resendFailed = true;
            state.error = action.payload;
        },
        resendInvoiceSucceeded: (state, action: PayloadAction<ResponseData<Account>>) => {
            state.isResending = false;
            state.isResent = true;
            state.account = action.payload.data;
        },
        clearResendInvoiceError: state => {
            state.isResending = false;
            state.isResent = false;
            state.resendFailed = false;
            state.error = undefined;
        },
        creditFeeStarted: state => {
            state.isCrediting = true;
            state.isCredited = false;
        },
        creditFeeFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isCrediting = false;
            state.isCredited = false;
            state.creditFeeFailed = true;
            state.error = action.payload;
        },
        creditFeeSucceeded: (state, action: PayloadAction<ResponseData<Account>>) => {
            state.isCrediting = false;
            state.isCredited = true;
            state.account = action.payload.data;
        },
        clearCreditFeeError: state => {
            state.isCrediting = false;
            state.isCredited = false;
            state.creditFeeFailed = false;
            state.error = undefined;
        },
    },
});

export const {
    getAccountStarted,
    getAccountSucceeded,
    getAccountFailed,
    accountExtendDueDateStarted,
    accountExtendDueDateSucceeded,
    accountExtendDueDateFailed,
    clearExtendDueDateError,
    resendInvoiceStarted,
    resendInvoiceFailed,
    resendInvoiceSucceeded,
    clearResendInvoiceError,
    creditFeeStarted,
    creditFeeFailed,
    creditFeeSucceeded,
    clearCreditFeeError,
} = accountSlice.actions;

export default accountSlice.reducer;
