import React from 'react';
import useLogTimeSpentOnPage from '../../services/hooks/useLogTimeSpentOnPage';
import Settlements from '../../components/molecules/Reports/Settlements';

const Reports: React.FC = () => {
    document.title = 'Merchant Hub - Reports';

    useLogTimeSpentOnPage('reports');

    return <Settlements />;
};

export default Reports;
