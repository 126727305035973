import React from 'react';
import styled from 'styled-components';
import CustomerIcon from '../CustomerIcon/CustomerIcon';
import FlagIcon from '../FlagIcon/FlagIcon';
import getCustomerType from '../../../services/helpers/customerTypeHelper';

export enum LabelTypes {
    TABLE = 'table',
    LABEL = 'label',
}
interface StoreLabelProps {
    storeId?: string;
    storeName?: string;
    countryCode?: string;
    customerSalesType?: string;
    selfReference?: string;
    labelType?: LabelTypes;
}

const StoreLabel: React.FC<StoreLabelProps> = ({
    storeId,
    storeName,
    countryCode,
    customerSalesType,
    selfReference,
    labelType,
}: StoreLabelProps) => {
    const customerIcon = customerSalesType && getCustomerType(customerSalesType);
    const isTable = labelType === LabelTypes.TABLE;

    return (
        <StoreContainer isTable={isTable} title={`${storeName} ${selfReference ?? ''}`}>
            <TitleContainer key="store">
                {storeId && !storeName && <StoreId onlyStoreId={!storeName}>{storeId}</StoreId>}

                <StoreName
                    refExist={!!selfReference}
                    isTable={isTable}
                    title={`${storeId} ${storeName}`}
                >
                    {storeName && storeName}
                </StoreName>

                <RightContent>
                    {selfReference && (
                        <SelfReference title={selfReference} isTable={isTable}>
                            {selfReference}
                        </SelfReference>
                    )}

                    <IconContainer key="icon">
                        {customerIcon && <CustomerIcon customerType={customerIcon} />}
                        {countryCode && <FlagIcon countryCode={countryCode} />}
                    </IconContainer>
                </RightContent>
            </TitleContainer>
        </StoreContainer>
    );
};

interface StoreIdProps {
    onlyStoreId: boolean;
}

interface IsTableProps {
    isTable: boolean;
    refExist?: boolean;
}

const StoreContainer = styled.div<IsTableProps>`
    display: grid;
    grid-template-columns: 95% 2rem;
    gap: 0.5rem;
    width: 100%;
`;

const StoreName = styled.div<IsTableProps>`
    align-items: ${props => (props.isTable ? 'flex-start' : 'flex-end')};
    text-decoration: none;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`;

const IconContainer = styled.div`
    display: flex;
    margin-top: -0.5rem;
`;

const RightContent = styled.div`
    display: flex;
`;

const StoreId = styled.div<StoreIdProps>`
    color: ${props => (props.onlyStoreId ? '' : props.theme.colors.subtle.regular)};
    align-self: center;
`;

const SelfReference = styled.div<IsTableProps>`
    color: ${props => props.theme.colors.subtle.regular};
    align-self: center;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin-right: 1rem;
`;

const TitleContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export default StoreLabel;
