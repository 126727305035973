const lastDayRelativeRanges = [
    { name: 'today', label: 'Today', value: '0d' },
    { name: 'yesterday', label: 'Yesterday', value: 'yesterday' },
    { name: 'last7days', label: 'Last 7 days', value: '-7d' },
    { name: 'last30days', label: 'Last 30 days', value: '-30d' },
    {
        name: 'custom',
        label: 'Custom',
        value: '-1d',
    },
];

export { lastDayRelativeRanges };
