import React from 'react';
import styled from 'styled-components';
import CheckBox from '../Checkbox/Checkbox';

interface Props {
    text: string;
    checked: boolean;
    onChange: () => void;
}

const ListItem: React.FC<Props> = ({ text, checked, onChange }: Props) => {
    return (
        <Item>
            <CheckBox checked={checked} onChange={onChange}>
                {text}
            </CheckBox>
        </Item>
    );
};

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 0.2rem solid ${props => props.theme.colors.border};
    margin-bottom: 0.3rem;
    background: white;
    height: 2.5rem;
`;

export default ListItem;
