export class ErrorMessage {
    public title: string;

    constructor(title: string) {
        this.title = title;
    }
}

export type ErrorCodeMap = Record<string, ErrorMessage>;

export enum ErrorType {
    BulkError = 'Could not update orders!',
    OrderUpdate = 'Could not update order!',
    ActivateInvoice = 'Could not activate order!',
    LoadUsers = 'Could not load users!',
    LoadGroups = 'Group details error!',
    DeleteGroupUser = 'Could not delete user from group!',
    Order = 'Could not get Order Details',
    Account = 'Could not get Account Details',
    FetchAccount = 'Unable to load account!',
    RedirectInvite = 'Could not redirect invite!',
    Search = 'Could not perform search!',
    CancelOrder = 'Could not cancel order!',
    Extend = 'Could not extend!',
    Invite = 'Could not invite user!',
    Validation = 'Validation error!',
    ResendInvoice = 'Could not resend invoice',
    CreditFee = 'Could not credit selected fees',
    SingleDownload = 'Could not download file, please try again later.',
    ChangeInvoiceAddress = 'Could not change invoice address',
    DashboardAggregate = 'Could not show data',
    Customer = 'Could not get customer',
    ApiSecret = 'Something went wrong with the api keys',
    RemoveApiSecret = 'Could not remove api key. Please try again later.',
    CreatePaylink = 'Something went wrong when creating the pay link!',
    SendPaylink = 'Could not send pay link. Please try again later.',
    DeletePaylink = 'Could not delete pay link.',
    LoadStore = 'Store could not be loaded',
    PatchStore = 'Store could not be changed',
    SearchPaylink = 'Could not perform pay link search!',
    PaylinkDetails = 'Could not show Pay Link details.',
    EnableCheckoutApi = 'Could not enable checkout api.',
    DeleteOrganization = 'Could not delete organization',
    GenerateReport = 'Could not create report',
    LoadReports = 'Could not get reports',
    Webhook = 'Something went wrong with the webhooks',
    UpdatePaylinkOrderNumber = 'Could not update reference. Please try again later.',
}

const errorMap: ErrorCodeMap = {
    ADJUST_INVALID_TRANSACTION_AMOUNT: new ErrorMessage(ErrorType.OrderUpdate),
    REPLACE_TRANSACTION_AMOUNT_INVALID: new ErrorMessage(ErrorType.OrderUpdate),
    REPLACE_TOTAL_AMOUNT_MUST_BE_POSITIVE: new ErrorMessage(ErrorType.OrderUpdate),
    EDIT_ACCOUNT_TOTAL_AMOUNT_MUST_BE_POSITIVE: new ErrorMessage(ErrorType.OrderUpdate),
    REPLACE_INVOICE_NOT_FOUND: new ErrorMessage(ErrorType.OrderUpdate),
    ACTIVATE_INVOICE_NOT_FOUND: new ErrorMessage(ErrorType.ActivateInvoice),
    USER_MUST_HAVE_GROUP: new ErrorMessage(ErrorType.DeleteGroupUser),
};

export default errorMap;
