import { useEffect } from 'react';

const useFocusInputById = (elementId: string) => {
    useEffect(() => {
        const input = document.getElementById(elementId);

        if (input) {
            input.focus();
        }
    }, []);
};

export default useFocusInputById;
