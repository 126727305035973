const searchValidationConfig = {
    required: {
        value: false,
        message: 'required field',
    },
};

const emailValidationConfig = {
    required: {
        value: true,
        message: 'required field',
    },
    emailRegExp: new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
};

const inviteUserNameValidationConfig = {
    required: {
        value: true,
        message: 'required field',
    },
};
const groupNameValidationConfig = {
    required: {
        value: true,
        message: 'required field',
    },
};

const requiredValueConfig = {
    required: {
        value: true,
        message: 'Required field',
    },
};

const requireValueAsNumberConfig = {
    ...requiredValueConfig,
    valueAsNumber: true,
};

const requiredWithMinValue = {
    ...requireValueAsNumberConfig,
    min: {
        value: 1,
        message: 'Minimum input number is 1',
    },
};

const descriptionValidationConfig = {
    required: {
        value: true,
        message: 'Description is required',
    },
};

const articleNumberValidationConfig = {
    required: {
        value: true,
        message: 'Article number is required',
    },
};

const quantityValidationConfig = {
    required: {
        value: true,
        message: 'Quantity is required',
    },
    valueAsNumber: true,
    min: {
        value: 1,
        message: 'Minimum input number is 1',
    },
};

const priceValidationConfig = {
    required: {
        value: true,
        message: 'Price is required',
    },
    valueAsNumber: true,
};

const amountValidationConfig = {
    required: {
        value: true,
        message: 'Amount is required',
    },
    valueAsNumber: true,
};

const vatRateValidationConfig = {
    required: {
        value: true,
        message: 'VAT rate is required',
    },
    valueAsNumber: true,
};

const genericPhoneNumberValidationConfig = {
    pattern: {
        message: 'Please enter a valid phone number',
        value: /^\+[1-9]\d{6,15}$/,
    },
};

/*
 Types setup for validation of 'react-hook-form'
 Here we can build functions, regex or length checks to validate inputs
 */
type RefReturn =
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;

export type SearchValidationConfigType = ({
    required,
    maxLength,
    validate,
}: {
    required?: { value: boolean; message: string };
    maxLength?: number;
    validate?: (searchPhrase: string) => boolean | string;
}) => RefReturn;

export type EmailInviteValidationConfigType = ({
    required,
    emailRegExp,
    validate,
}: {
    required?: { value: boolean; message: string };
    emailRegExp?: RegExp;
    validate?: (email: string) => boolean | string;
}) => RefReturn;

export type InviteUserNameValidationConfigType = ({
    required,
    validate,
}: {
    required: { value: boolean; message: string };
    validate?: (groupName: string) => boolean | string;
}) => RefReturn;

export type GroupCreationValidationConfigType = ({
    required,
    validate,
}: {
    required: { value: boolean; message: string };
    validate?: (groupName: string) => boolean | string;
}) => RefReturn;

export {
    searchValidationConfig,
    emailValidationConfig,
    groupNameValidationConfig,
    inviteUserNameValidationConfig,
    requiredValueConfig,
    requiredWithMinValue,
    requireValueAsNumberConfig,
    descriptionValidationConfig,
    articleNumberValidationConfig,
    quantityValidationConfig,
    priceValidationConfig,
    amountValidationConfig,
    vatRateValidationConfig,
    genericPhoneNumberValidationConfig,
};
