import React from 'react';
import OrderDetailsPage from './containers/orders/OrderDetailsPage';
import AdminUserDetails from './containers/admin/usermanagement/user/UserDetails';
import AdminManageAcess from './containers/admin/usermanagement/ManageAccess';
import AdminGroupDetails from './containers/admin/usermanagement/group/GroupDetails';
import Dashboard from './containers/dashboard/Dashboard';
import Reports from './containers/report/Reports';
import AccountDetailsPage from './containers/accounts/AccountDetailsPage';
import OrderSearch from './containers/orders/OrderSearch';
import Marketing from './containers/Marketing/Marketing';
import CustomerDetailsPage from './containers/customers/CustomerDetailsPage';
import PaylinkDetails from './containers/paylinks/PaylinkDetails';
import ManageStores from './containers/admin/stores/ManageStores';
import StoreDetails from './containers/admin/stores/StoreDetails';
import PaylinkList from './containers/paylinks/PaylinkSearch';

const routes = [
    {
        path: '/orders',
        component: <OrderSearch />,
        breadcrumb: 'Search',
    },
    { path: '/orders/:orderId', component: <OrderDetailsPage />, breadcrumb: 'Order' },

    { path: '/dashboard', component: <Dashboard />, breadcrumb: 'Dashboard' },

    { path: '/accounts/:accountId', component: <AccountDetailsPage />, breadcrumb: 'Account' },
    { path: '/customers/:customerId', component: <CustomerDetailsPage />, breadcrumb: 'Customer' },
    { path: '/paylinks', component: <PaylinkList />, breadcrumb: 'ListPaylinks' },
    {
        path: '/paylinks/:privateId',
        component: <PaylinkDetails />,
        breadcrumb: 'Paylink',
    },
    { path: '/reports', component: <Reports />, breadcrumb: 'Reports' },
    { path: '/admin/users/:userId', component: <AdminUserDetails /> },
    {
        path: '/admin/manage-access',
        component: <AdminManageAcess />,
        breadcrumb: 'Manage Access',
    },
    {
        path: '/admin/manage-organization',
        component: <AdminManageAcess />,
        breadcrumb: 'Manage Organization',
    },
    {
        path: '/admin/groups/:groupId',
        component: <AdminGroupDetails />,
    },
    {
        path: '/admin/manage-stores/:storeId',
        component: <StoreDetails />,
    },
    { path: '/admin/manage-stores', component: <ManageStores /> },
    { path: '/marketing', component: <Marketing /> },
];

export default routes;
