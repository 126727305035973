import React, { useEffect } from 'react';
import styled from 'styled-components';
import usersActions from '../../store/admin/users/actions';
import { useAppState } from '../../store/appstate';
import ResponseError from '../../types/response/ResponseError';
import Spinner from '../../components/atoms/Spinner/Spinner';
import ErrorMessage from '../../components/atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../types/response/ErrorCodes';
import { useAppDispatch } from '../../store';

interface InviteLandingPageProps {
    invitationId: string;
}

const InviteLandingPage: React.FC<InviteLandingPageProps> = ({
    invitationId,
}: InviteLandingPageProps) => {
    const dispatch = useAppDispatch();
    const inviteRedirectError = useAppState<ResponseError>(s => s.admin.users.inviteRedirectError);

    useEffect(() => {
        dispatch(usersActions.sendRedirectInvite(invitationId));
    }, [dispatch, invitationId]);

    const showMessage = (error: ResponseError) => {
        switch (error.status) {
            case 200:
                return <Spinner loading text="Redirecting to sign-up page" />;
            case 404:
                return <h1>Invitation was not found.</h1>;
            case 422:
                return <ErrorMessage error={error} errorHeader={ErrorType.RedirectInvite} />;

            default:
                return <Spinner loading text="Redirecting to sign-up page" />;
        }
    };

    return <Container>{showMessage(inviteRedirectError)}</Container>;
};

const Container = styled.div`
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
`;

export default InviteLandingPage;
