import { config } from '../../../config';
import api from '../../../services/api';
import { Webhook } from '../../../types/Webhook';
import { PatchOperation } from '../../../types/requests/PatchOperation';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import {
    created,
    creating,
    creatingFailed,
    searchFailed,
    searchStarted,
    searchSucceeded,
    createdCleared,
    patching,
    patched,
    patchFailed,
    deleting,
    deleted,
    deleteFailed,
    testing,
    tested,
    testFailed,
    testedCleared,
} from './webhooksSlice';

export interface WebhookData {
    url: string;
    subscribedEvents: string[];
    storeIds: string[];
    basicAuth?: string;
    enabled: boolean;
}

export interface WebHookCreatedData {
    entityId: string;
    hmacKey: string;
}

export interface WebHookTestData {
    data: string;
}
export interface WebHookTestResponse {
    data: number;
}

const listWebhooks =
    (organizationId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(searchStarted());

        if (!organizationId) {
            return;
        }

        try {
            const response = await api.get<ResponseData<Webhook[]>>(
                `organizations/${organizationId}/webhooks`,
                undefined,
                undefined,
                undefined,
                config.WebhookApiHost
            );

            dispatch(searchSucceeded(response));
        } catch (error) {
            dispatch(searchFailed(error as ResponseError));
        }
    };

const createWebhook =
    (organizationId: string, data: WebhookData): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(creating());

        try {
            const response = await api.postWithResponseType<WebhookData, WebHookCreatedData>(
                `organizations/${organizationId}/webhooks`,
                data,
                config.WebhookApiHost
            );

            dispatch(created(response));
            await dispatch(listWebhooks(organizationId));
        } catch (error) {
            dispatch(creatingFailed(error as ResponseError));
        }
    };

const toggleWebhook =
    (webhook: Webhook): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(patching());
        try {
            await api.patch<PatchOperation[]>(
                `organizations/${webhook.organization}/webhooks/${webhook.id}`,
                [
                    {
                        op: 'replace',
                        path: '/Enabled',
                        value: !webhook.enabled,
                    },
                ],
                config.WebhookApiHost
            );
            dispatch(patched());
            await dispatch(listWebhooks(webhook.organization));
        } catch (error) {
            dispatch(patchFailed(error as ResponseError));
        }
    };

const deleteWebhook =
    (webhook: Webhook): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(deleting());
        try {
            await api.del(
                `organizations/${webhook.organization}/webhooks/${webhook.id}`,
                config.WebhookApiHost
            );
            dispatch(deleted());
            await dispatch(listWebhooks(webhook.organization));
        } catch (error) {
            dispatch(deleteFailed(error as ResponseError));
        }
    };

const testWebhook =
    (webhook: Webhook, data: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(testing());

        try {
            const response = await api.postWithResponseType<WebHookTestData, WebHookTestResponse>(
                `organizations/${webhook.organization}/webhooks/${webhook.id}/test`,
                { data: data },
                config.WebhookApiHost
            );

            dispatch(tested(response));
        } catch (error) {
            dispatch(testFailed(error as ResponseError));
        }
    };

const clearCreate = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    dispatch(createdCleared());
};

const clearTest = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    dispatch(testedCleared());
};

export default {
    listWebhooks,
    createWebhook,
    clearCreate,
    toggleWebhook,
    deleteWebhook,
    testWebhook,
    clearTest,
};
