import styled from 'styled-components';

interface FlexProps {
    gap?: string;
    rowGap?: string;
    center?: boolean;
    column?: boolean;
    row?: boolean;
    justifyContent?: string;
    alignItems?: string;
    flexWrap?: string;
    width?: string;
    height?: string;
    grow?: number;
}

const Flex = styled.div<FlexProps>`
    display: flex;
    ${props => props.gap && `gap: ${props.gap};`}
    ${props => props.rowGap && `row-gap: ${props.rowGap};`}
    ${props => props.column && 'flex-direction: column;'}
    ${props => props.row && 'flex-direction: row;'}
    ${props =>
        props.alignItems &&
        `
        align-items: ${props.alignItems};
    `}
    ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
    ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
    ${props => props.width && `width: ${props.width};`}
    ${props => props.height && `height: ${props.height};`}
    ${props => props.grow && `flex-grow: ${props.grow};`}
`;

export default Flex;
