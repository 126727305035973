import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import Download2LineIcon from 'remixicon-react/Download2LineIcon';
import styled from 'styled-components';
import { formatShortDate, formatDate } from '../../../../services/helpers/dateTimeFormats';
import getStoreInfo from '../../../../services/helpers/storeHelper';
import { ConnectedStore } from '../../../../types/ConnectedStore';
import { Settlement } from '../../../../types/report/Settlement';
import { LinkData } from '../../../../types/response/LinkData';
import SquareIconButton from '../../../atoms/Button/SquareIconButton';
import Money from '../../../atoms/Money/Money';
import ColumnStoreLabel from '../../../atoms/Store/ColumnStoreLabel';
import TwoRowCell from '../../../atoms/Table/TwoRowCell';
import { SettlementSummationValueWithCount } from '../../../../types/report/SettlementSummationValueWithCount';
import { SettlementSummationValue } from '../../../../types/report/SettlementSummationValue';

const generateSettlementColumnHeaders = (
    availableStores: ConnectedStore[],
    onRowDownloadClicked: (csvLink: LinkData) => void
): ColumnDef<Settlement>[] => {
    const renderSummationValueWithCountAmount = (x?: SettlementSummationValueWithCount) =>
        x ? <Money>{x.amount}</Money> : '-';

    const renderSummationValueCount = (x?: SettlementSummationValueWithCount) =>
        x ? <div>{x.count}</div> : '-';

    const renderSummationValueAmount = (x?: SettlementSummationValue) =>
        x ? <Money>{x.amount}</Money> : '-';

    return [
        {
            header: 'Id',
            accessorKey: 'id',
            enableSorting: false,
        },
        {
            id: 'period',
            header: 'Period',
            accessorKey: 'period',
            meta: {
                sortKey: 'startDate',
            },
            cell: (props: { row: { original: Settlement } }) => {
                const { period } = props.row.original;

                const start = period && period.start ? formatShortDate(new Date(period.start)) : '';
                const end = period && period.start ? formatShortDate(new Date(period.end)) : '';

                return <TwoRowCell upper={`${start} -`} lower={end} />;
            },
        },
        {
            id: 'storeId',
            header: 'Store',
            accessorFn: (props: Settlement) => {
                return <span>{props.storeId}</span>;
            },
            meta: {
                sortKey: 'storeId',
                style: {
                    textAlign: 'left',
                },
            },
            cell: (props: { row: { original: Settlement } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return storeInfo && storeInfo.storeName ? (
                    <ColumnStoreLabel store={storeInfo} />
                ) : (
                    <span>{storeId}</span>
                );
            },
        },
        {
            header: 'Currency',
            accessorKey: 'currency',
        },
        {
            header: 'Ingoing balance',
            accessorKey: 'ingoingBalance',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) => (
                <Money>{props.row.original.ingoingBalance}</Money>
            ),
        },
        {
            header: 'Net sales',
            accessorKey: 'netSales',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) => (
                <Money>{props.row.original.netSales}</Money>
            ),
        },
        {
            header: 'Compensations',
            accessorKey: 'compensations',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) => (
                <Money>{props.row.original.compensations}</Money>
            ),
        },
        {
            header: 'Adjustments',
            accessorKey: 'adjustments',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) => (
                <Money>{props.row.original.adjustments}</Money>
            ),
        },
        {
            header: 'Amount',
            accessorKey: 'totalAmount',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) => (
                <Money>{props.row.original.totalAmount ?? '-'}</Money>
            ),
        },
        {
            header: 'Paid out at',
            accessorKey: 'paidOutAt',
            cell: (props: { row: { original: Settlement } }) => {
                const settlement = props.row.original;

                return settlement.paidOutAt ? (
                    <TwoRowCell
                        upper={formatDate(new Date(settlement.paidOutAt)).yyyyMMdd}
                        lower={formatDate(new Date(settlement.paidOutAt)).HHmm}
                    />
                ) : (
                    <span>-</span>
                );
            },
        },
        {
            header: 'Download',
            accessorFn: (props: Settlement) => {
                return props.id;
            },
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            enableSorting: false,
            cell: (props: { row: { original: Settlement } }) => {
                return (
                    <DownloadButtons data-testid="settlements-download-buttons">
                        {props.row.original.links.csv && (
                            <SquareIconButton
                                title="Download csv"
                                onClick={() => onRowDownloadClicked(props.row.original.links.csv)}
                            >
                                <Download2LineIcon size={15} />
                                Csv
                            </SquareIconButton>
                        )}

                        {props.row.original.links.pdf && (
                            <SquareIconButton
                                title="Download pdf"
                                onClick={() => onRowDownloadClicked(props.row.original.links.pdf)}
                            >
                                <Download2LineIcon size={15} />
                                Pdf
                            </SquareIconButton>
                        )}
                    </DownloadButtons>
                );
            },
        },
        {
            header: 'Reference',
            accessorKey: 'reference',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) => {
                const referenceExist = props.row.original.hasReference
                    ? props.row.original.reference
                    : '-';

                return (
                    <span>
                        {props.row.original.reference !== '' ? referenceExist : '[Reference-Empty]'}
                    </span>
                );
            },
        },
        {
            header: 'Account Number',
            accessorKey: 'payoutAccountNumber',
            enableSorting: false,
            cell: (props: { row: { original: Settlement } }) => {
                return (
                    <span>
                        {props.row.original.paidOutToAccountNumber !== ''
                            ? props.row.original.paidOutToAccountNumber
                            : '-'}
                    </span>
                );
            },
        },
        {
            header: 'Reference Number',
            accessorKey: 'payoutReference',
            enableSorting: false,
            cell: (props: { row: { original: Settlement } }) => {
                return (
                    <span>
                        {props.row.original.paidOutWithReference !== ''
                            ? props.row.original.paidOutWithReference
                            : '-'}
                    </span>
                );
            },
        },
        {
            header: 'Purchases',
            accessorKey: 'purchasesAmount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueWithCountAmount(props.row.original.summations.purchases),
        },
        {
            header: 'Quantity - Purchases',
            accessorKey: 'purchasesCount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueCount(props.row.original.summations.purchases),
        },
        {
            header: 'Returns',
            accessorKey: 'returnsAmount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueWithCountAmount(props.row.original.summations.returns),
        },
        {
            header: 'Quantity - Returns',
            accessorKey: 'returnsCount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueCount(props.row.original.summations.returns),
        },
        {
            header: 'Purchase Amount Adjustments',
            accessorKey: 'adjustmentsAmount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueWithCountAmount(
                    props.row.original.summations.purchaseAmountAdjustments
                ),
        },
        {
            header: 'Quantity - Purchase Amount Adjustments',
            accessorKey: 'adjustmentsCount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueCount(props.row.original.summations.purchaseAmountAdjustments),
        },
        {
            header: 'Manual Purchases',
            accessorKey: 'manualPurchasesAmount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueWithCountAmount(props.row.original.summations.manualPurchases),
        },
        {
            header: 'Quantity - Manual Purchases',
            accessorKey: 'manualPurchasesCount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueCount(props.row.original.summations.manualPurchases),
        },
        {
            header: 'Manual Returns',
            accessorKey: 'manualReturnsAmount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueWithCountAmount(props.row.original.summations.manualReturns),
        },
        {
            header: 'Quantity - Manual Returns',
            accessorKey: 'manualReturnsCount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueCount(props.row.original.summations.manualReturns),
        },
        {
            header: 'Manual Purchase Amount Adjustments',
            accessorKey: 'manualAdjustmentAmount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueWithCountAmount(
                    props.row.original.summations.manualPurchaseAmountAdjustments
                ),
        },
        {
            header: 'Quantity - Manual Purchase Amount Adjustments',
            accessorKey: 'manualAdjustmentCount',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueCount(
                    props.row.original.summations.manualPurchaseAmountAdjustments
                ),
        },
        {
            header: 'Service fee',
            accessorKey: 'serviceFee',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.serviceFee),
        },
        {
            header: 'Transaction fee',
            accessorKey: 'transactionFee',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.transactionFee),
        },
        {
            header: 'AddInvoice fee',
            accessorKey: 'addInvoiceFee',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.addInvoiceFee),
        },
        {
            header: 'VAT',
            accessorKey: 'vat',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.vat),
        },
        {
            header: 'Commission',
            accessorKey: 'commision',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.commission),
        },
        {
            header: 'Kickback',
            accessorKey: 'kickback',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.kickback),
        },
        {
            header: 'Kickback %',
            accessorKey: 'kickbackPercentage',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.kickbackPercentage),
        },
        {
            header: 'Direct Payment',
            accessorKey: 'directPayment',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.directPayment),
        },
        {
            header: 'Notification Print Cost',
            accessorKey: 'notificationPrintCost',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.notificationPrintCost),
        },
        {
            header: 'Credited Customer Fee Cost',
            accessorKey: 'creditedCustomerFeeCost',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Settlement } }) =>
                renderSummationValueAmount(props.row.original.summations.creditedCustomerFeeCost),
        },
    ];
};

const defaultSettlementColumnHeaders = [
    'Period',
    'Store',
    'Customer type',
    'Currency',
    'Ingoing balance',
    'Net sales',
    'Compensations',
    'Adjustments',
    'Amount',
    'Paid out at',
    'Download',
] as string[];

export { generateSettlementColumnHeaders, defaultSettlementColumnHeaders };

const DownloadButtons = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
`;
