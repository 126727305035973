import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import Button from '../../../atoms/Button/Button';
import { useAppState } from '../../../../store/appstate';
import Spinner from '../../../atoms/Spinner/Spinner';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import ModalContainer from '../../../atoms/Modal/ModalContainer';
import HeaderTitle from '../../../atoms/Modal/HeaderTitle';
import FormRow from '../../../atoms/Form/FormRow';
import GridInput from '../../../atoms/Input/GridInput';
import FooterButtonContainer from '../../../atoms/Modal/FooterButtonContainer';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import { ApiSecretsState } from '../../../../store/admin/apiSecrets/apiSecretsSlice';
import CopyToClipboard from '../../../atoms/CopyToClipboard/CopyToClipboard';
import SelectInput from '../../../atoms/Input/SelectInput';
import { SessionState } from '../../../../store/session/sessionSlice';

interface FormProps {
    onSave: (label: string, expirationDate: Date | null) => void;
    hide: () => void;
    theme: DefaultTheme;
    locked: boolean;
}

type Inputs = {
    label: string;
};

const CreateApiSecretForm: React.FC<FormProps> = ({ onSave, hide, theme, locked }: FormProps) => {
    const { isCreating, created, createdError } = useAppState<ApiSecretsState>(
        s => s.admin.apiSecrets
    );
    const { register, handleSubmit } = useForm<Inputs>();
    const label = register('label');
    const [expirationDate, setExpirationDate] = useState<string>('');

    const { selectedOrganization } = useAppState<SessionState>(s => s.session);

    const onSelect = (value: string) => {
        setExpirationDate(value);
    };

    const calculateDate = (currentValue: string) => {
        const today = new Date();

        if (!currentValue || currentValue === '') return null;

        if (currentValue === '6m') {
            today.setMonth(today.getMonth() + 6);
        } else if (currentValue === '1y') {
            today.setFullYear(today.getFullYear() + 1);
        } else if (currentValue === '2y') {
            today.setFullYear(today.getFullYear() + 2);
        } else if (currentValue === 'u') {
            today.setFullYear(today.getFullYear() + 99);
        }

        return today;
    };

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Your new api key</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content" noScroll>
                {locked && !created && (
                    <Content>
                        <IconContainer>
                            <ErrorWarningLineIcon
                                color={theme.colors.accent3}
                                size={theme.icon.size.normal}
                            />{' '}
                            Oops!
                        </IconContainer>
                        <div>
                            It seems like your organization already have a maximum of{' '}
                            <b>{selectedOrganization.maxApiKeys}</b> Api keys. Please remove one and
                            try again.
                        </div>
                    </Content>
                )}
                {!created && !locked && (
                    <>
                        <strong>Key label</strong>
                        <Information>
                            The label will be used to identify the api key when viewing and deleting
                            it.
                        </Information>

                        <strong>Expiration time</strong>
                        <Information>
                            Expiration time will default to 6 months if no option is chosen.
                        </Information>
                        <form
                            onSubmit={handleSubmit((data: Inputs) =>
                                onSave(data.label, calculateDate(expirationDate))
                            )}
                            data-testid="form"
                            id="apisecret-form"
                        >
                            <FormRow first>
                                <GridInput
                                    type="text"
                                    placeholder="Label (optional)"
                                    setRef={label.ref}
                                    name={label.name}
                                    onChange={label.onChange}
                                    disabled={isCreating}
                                />
                            </FormRow>
                            <FormRow>
                                <SelectInput
                                    placeholder="Expiration date (optional)"
                                    onChange={onSelect}
                                    defaultValue="No date chosen"
                                    value={expirationDate}
                                    options={[
                                        {
                                            label: '6 Months',
                                            value: '6m',
                                        },
                                        {
                                            label: '1 Year',
                                            value: '1y',
                                        },
                                        {
                                            label: '2 Years',
                                            value: '2y',
                                        },
                                        {
                                            label: 'Unlimited',
                                            value: 'u',
                                        },
                                    ]}
                                />
                            </FormRow>
                            {expirationDate === 'u' && (
                                <Warning>
                                    <ErrorWarningLineIcon size={theme.icon.size.xlarge} />
                                    <div>
                                        Unlimited is not a recommended expire time. We recommend
                                        implementing rotation of keys with expiration time of 6
                                        months.
                                    </div>
                                </Warning>
                            )}
                        </form>
                    </>
                )}

                {created && (
                    <>
                        <ResultContainer>
                            <strong>Store your Secret in a safe location</strong>
                            <p>
                                Make sure you copy and store your key secret in a safe location. You
                                will not be able to retrieve the secret again.
                            </p>

                            <FormRow first>
                                <GridInput
                                    type="text"
                                    placeholder="Client ID"
                                    value={created.clientId}
                                    readOnly
                                    placeholderRaised
                                    decorator={<CopyToClipboard value={created.clientId} />}
                                />
                            </FormRow>
                            <FormRow last>
                                <GridInput
                                    type="text"
                                    placeholder="Secret"
                                    value={created.apiSecret}
                                    readOnly
                                    placeholderRaised
                                    decorator={<CopyToClipboard value={created.apiSecret} />}
                                />
                            </FormRow>
                        </ResultContainer>
                    </>
                )}
            </ModalContainer>

            {createdError && (
                <ModalContainer position="error">
                    <ErrorMessage error={createdError} errorHeader={ErrorType.ApiSecret} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large disabled={isCreating}>
                        Close
                    </Button>
                    {!created && (
                        <Button
                            tabIndex={0}
                            form="apisecret-form"
                            type="submit"
                            primary
                            large
                            disabled={isCreating || locked}
                        >
                            {isCreating ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : (
                                <span>Create</span>
                            )}
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const ResultContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    text-align: center;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const Information = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin: 1rem 0 2rem 0;
`;

const Warning = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin: 2rem 0 1rem 0;
    color: ${props => props.theme.colors.error};
    display: flex;
    gap: 0.5rem;
`;

export default withTheme(CreateApiSecretForm) as React.ComponentType<Omit<FormProps, 'theme'>>;
