import React from 'react';
import styled from 'styled-components';
import { formatNumberWithoutDecimals } from '../../../services/helpers/numberFormats';

interface TeamFragmentContainerProps {
    value?: string;
    count: number;
}

const TermFragmentContainer: React.FC<TeamFragmentContainerProps> = ({
    value,
    count,
}: TeamFragmentContainerProps) => {
    return (
        <FragmentContainer>
            <ValueContainer>{value}</ValueContainer>
            {count !== 0 && <CountContainer>{formatNumberWithoutDecimals(count)}</CountContainer>}
        </FragmentContainer>
    );
};

const FragmentContainer = styled.div`
    display: grid;
    grid-template-columns: 13rem 7rem;
    gap: 0.5rem;
`;

const ValueContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
`;

const CountContainer = styled.div`
    color: ${props => props.theme.colors.subtle.regular};
    text-align: right;
`;

export default TermFragmentContainer;
