import React, { useEffect } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Card from '../../atoms/Card/Card';
import {
    formatNumberWithDecimals,
    formatNumberWithoutDecimals,
} from '../../../services/helpers/numberFormats';
import DashboardCardDisplayType from '../../../types/dashboard/DashboardCardDisplayType';
import Flex from '../../atoms/Box/Flex';
import Logger from '../../../services/logging/logger';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import CircleWithIcon from '../../atoms/Icons/CircleWithIcon';
import ThumbDownLineIcon from 'remixicon-react/ThumbDownLineIcon';
import ThumbUpLineIcon from 'remixicon-react/ThumbUpLineIcon';
import { AppThunkAction } from '../../../store/thunk';
import { useAppDispatch } from '../../../store';
import Money from '../../atoms/Money/Money';
import Loader from '../../atoms/SkeletonLoader/Loader';
import DashboardStatistics from '../../../types/dashboard/DashboardStatistics';
import { darken, transparentize } from 'polished';
import { ErrorType } from '../../../types/response/ErrorCodes';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';

interface DashboardCardProps {
    title: string;
    type: DashboardCardDisplayType;
    onClick?: () => void;
    theme: DefaultTheme;
    highlighted?: boolean;
    flex?: boolean;
    background?: string;
    width?: string;
    value?: number;
    disabled?: boolean;
    information?: string;
    dispatchAction?: AppThunkAction<Promise<void>>;
    filterChange: Map<string, string[]>;
    statistics: DashboardStatistics;
}

interface SkeletonLoaderCardProps {
    theme: DefaultTheme;
    flex?: boolean;
    title?: string;
    subTitle?: string;
    minWidth?: string;
    information?: string;
    dataTestId: string;
}

export const SkeletonLoaderCard: React.FC<SkeletonLoaderCardProps> = ({
    flex,
    theme,
    title,
    subTitle,
    information,
    dataTestId,
}: SkeletonLoaderCardProps) => {
    return (
        <SkeletonCard data-testid={dataTestId} column gap={theme.layout.gap.small} flex={flex}>
            <Flex row justifyContent="space-between">
                {title ? (
                    <Title>{title}</Title>
                ) : (
                    <Loader width={'10rem'} height={'2rem'} round></Loader>
                )}

                {information && (
                    <div>
                        <InformationLineIcon color={theme.colors.subtle.regular} size="20" />
                    </div>
                )}
            </Flex>

            {subTitle ? (
                <div>{subTitle}</div>
            ) : (
                <Loader width={'20rem'} height={'1.5rem'} round></Loader>
            )}
            <Loader width={'18rem'} height={'3.2rem'} round></Loader>
        </SkeletonCard>
    );
};

const DashboardCard: React.FC<DashboardCardProps> = ({
    theme,
    title,
    type,
    onClick,
    highlighted = true,
    flex = false,
    background,
    width,
    value,
    disabled = false,
    information,
    dispatchAction,
    filterChange,
    statistics,
}: DashboardCardProps) => {
    const dispatch = useAppDispatch();
    const { data = undefined, isLoading = false, error } = statistics || {};

    useEffect(() => {
        if (!disabled && dispatchAction) dispatch(dispatchAction);
    }, [filterChange]);

    const noDataText = 'No data';

    const renderFormattedDisplayNumber = (
        displayType: DashboardCardDisplayType,
        numberToFormat?: number
    ) => {
        const toPercentage = (i: number): string => (i * 100).toFixed(0);

        switch (displayType) {
            case DashboardCardDisplayType.MONEY:
                const money = data ? (data[0].value as number) : numberToFormat;

                if (money === null) return <Money>{0}</Money>;
                if (money === undefined) return noDataText;

                return <Money>{money}</Money>;
            case DashboardCardDisplayType.NUMBER:
                const number = data ? (data[0].value as number) : numberToFormat;

                if (number === undefined || number === null) return noDataText;

                return <>{formatNumberWithoutDecimals(number)}</>;
            case DashboardCardDisplayType.FREQUENCE:
                const frequency = data
                    ? (data[1].value as number) / (data[0].value as number)
                    : value;

                if (data && !data[0].value) return noDataText;

                return <>{frequency ? formatNumberWithDecimals(frequency, 2) : numberToFormat} </>;
            case DashboardCardDisplayType.PERCENT:
                const percentage = data
                    ? toPercentage((data[1].value as number) / (data[0].value as number))
                    : numberToFormat;

                if (data && !data[0].value) return noDataText;

                return <>{`${percentage}%`}</>;
            case DashboardCardDisplayType.PERCENT_INVERTED:
                const percentageInverted = data
                    ? toPercentage(Math.abs((data[0].value as number) / (data[1].value as number)))
                    : numberToFormat;

                if (data && !data[1].value) return noDataText;

                return <>{`${percentageInverted}%`}</>;
            case DashboardCardDisplayType.THUMBUPDOWN:
                const aggregate = data && data.length > 0 && data[0].count ? data[0] : undefined;

                if (!aggregate) return noDataText;

                const positive = aggregate ? toPercentage(aggregate.average ?? 0) : 0;

                const negative = aggregate ? 100 - +positive : 0;

                const positiveTotal = aggregate ? aggregate.sum : 0;

                const negativeTotal = aggregate ? aggregate.count - (aggregate.sum ?? 0) : 0;

                return (
                    <Flex row gap={theme.layout.gap.small}>
                        <CircleWithIcon
                            icon={<ThumbUpLineIcon />}
                            color={theme.colors.accent2}
                            title={`${positiveTotal} answer(s)`}
                        >
                            {positive}%
                        </CircleWithIcon>

                        <CircleWithIcon
                            icon={<ThumbDownLineIcon />}
                            color={theme.colors.accent3}
                            title={`${negativeTotal} answer(s)`}
                        >
                            {negative}%
                        </CircleWithIcon>
                    </Flex>
                );
            default:
                break;
        }
    };

    const handleOnClick = () => {
        if (onClick && !disabled) onClick();
        Logger.information('Clicked on dashboardcard {Reason}', {
            Reason: title,
        });
    };

    if (isLoading)
        return (
            <SkeletonLoaderCard
                dataTestId="skeleton-dashboard-card"
                information={information}
                flex
                title={title}
                theme={theme}
            />
        );

    if (error) {
        return (
            <Card flex={flex} width={width}>
                <CornerWarning>
                    <Triangle>
                        <CornerIcon
                            size={theme.icon.size.normal}
                            color={darken(0.2, theme.colors.error)}
                        />
                    </Triangle>
                </CornerWarning>
                <ErrorContainer
                    justifyContent="center"
                    column
                    alignItems="center"
                    gap={theme.layout.gap.small}
                >
                    <div>{ErrorType.DashboardAggregate}</div>
                </ErrorContainer>
            </Card>
        );
    }

    return (
        <>
            <Card
                flex={flex}
                dataTestId="dashboard-card"
                onClick={() => handleOnClick()}
                width={width}
                borderColor={highlighted ? theme.colors.primary : undefined}
            >
                <Container
                    column
                    gap={theme.layout.gap.small}
                    highlighted={highlighted}
                    background={background ? background : theme.colors.light}
                    disabled={disabled}
                >
                    <Flex row justifyContent="space-between">
                        <Title>{title}</Title>

                        {information && (
                            <div title={information}>
                                <InformationLineIcon
                                    color={theme.colors.subtle.regular}
                                    size="20"
                                />
                            </div>
                        )}
                    </Flex>

                    <DisplayNumber>{renderFormattedDisplayNumber(type, value)}</DisplayNumber>
                </Container>
            </Card>
        </>
    );
};

export default withTheme(DashboardCard) as React.ComponentType<Omit<DashboardCardProps, 'theme'>>;

interface DashboardCardContainer {
    highlighted?: boolean;
    background?: string;
    disabled?: boolean;
}

interface CardProps {
    flex?: boolean;
}

export const Container = styled(Flex)<DashboardCardContainer>`
    background: ${props => (props.background ? props.background : props.theme.colors.light)};
    color: ${props => props.theme.colors.text.secondary};
    ${props => props.disabled && 'opacity: 0.1;'};
    padding: 2.5rem;
    width: 100%;
    height: 10rem;
`;

const Title = styled.div`
    font-family: ${props => props.theme.text.font.bold};
    font-size: ${props => props.theme.text.size.large};
    color: ${props => props.theme.colors.text.primary};
`;

const DisplayNumber = styled.div`
    font-family: ${props => props.theme.text.font.bold};
    color: ${props => props.theme.colors.text.primary};
    font-size: 2.5rem;
`;

const SkeletonCard = styled(Flex)<CardProps>`
    border: 0.1rem solid ${props => props.theme.background};
    padding: 2.5rem;
    background: ${props => props.theme.background};
    border-radius: 0.5rem;
    height: 10rem;
    ${props =>
        props.flex &&
        `
        display: flex;
        flex-grow: 1;
    `};
    min-height: 6rem;
    overflow: hidden;
`;

const ErrorContainer = styled(Flex)`
    min-height: 10rem;
    padding: 2.5rem;
    text-align: center;
    width: 100%;
    color: ${props => darken(0.2, props.theme.colors.error)};
`;

const CornerWarning = styled.div`
    width: 2rem;
    height: 2rem;
    position: relative;
`;

const Triangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5rem solid ${props => transparentize(0.9, props.theme.colors.error)};
    border-bottom: 5rem solid transparent;
    left: 0;
`;

const CornerIcon = styled(ErrorWarningLineIcon)`
    position: absolute;
    top: 0.2rem;
    left: -4.8rem;
    display: block;
`;
