import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';
import { Webhook } from '../../../types/Webhook';
import { WebHookCreatedData, WebHookTestResponse } from './actions';

export interface WebhookState {
    isSearching: boolean;
    isLoaded: boolean;
    searchResults: ResponseData<Webhook[]>;
    created?: WebHookCreatedData;
    isCreating: boolean;
    isPatching: boolean;
    isDeleting: boolean;
    createdError?: ResponseError;
    searchError?: ResponseError;
    patchError?: ResponseError;
    deleteError?: ResponseError;
    isTesting: boolean;
    tested?: WebHookTestResponse;
    testError?: ResponseError;
}

const initialState: WebhookState = {
    isSearching: false,
    isLoaded: false,
    isCreating: false,
    isPatching: false,
    isDeleting: false,
    searchResults: {} as ResponseData<Webhook[]>,
    created: undefined,
    createdError: undefined,
    searchError: undefined,
    patchError: undefined,
    deleteError: undefined,
    isTesting: false,
    tested: undefined,
    testError: undefined,
};

export const slice = createSlice({
    name: 'webhooks',
    initialState,
    reducers: {
        searchStarted: state => ({ ...state, isSearching: true }),
        searchSucceeded: (state, action: PayloadAction<ResponseData<Webhook[]>>) => ({
            ...state,
            isSearching: false,
            isLoaded: true,
            searchResults: action.payload,
        }),
        searchFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isSearching: false,
            searchError: action.payload,
        }),
        creating: state => ({
            ...state,
            isCreating: true,
            createdError: undefined,
        }),
        created: (state, action: PayloadAction<WebHookCreatedData>) => ({
            ...state,
            created: action.payload,
            isCreating: false,
        }),
        creatingFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isCreating: false,
            createdError: action.payload,
        }),
        createdCleared: state => ({
            ...state,
            created: undefined,
            isCreating: false,
            createdError: undefined,
        }),
        patching: state => ({
            ...state,
            isPatching: true,
            patchError: undefined,
        }),
        patched: state => ({
            ...state,
            isPatching: false,
            patchError: undefined,
        }),
        patchFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isPatching: false,
            patchError: action.payload,
        }),
        deleting: state => ({
            ...state,
            isDeleting: true,
            deleteError: undefined,
        }),
        deleted: state => ({
            ...state,
            isDeleting: false,
            deleteError: undefined,
        }),
        deleteFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isDeleting: false,
            deleteError: action.payload,
        }),
        testing: state => ({
            ...state,
            isTesting: true,
            testError: undefined,
        }),
        tested: (state, action: PayloadAction<WebHookTestResponse>) => ({
            ...state,
            tested: action.payload,
            isTesting: false,
        }),
        testFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isTesting: false,
            testError: action.payload,
        }),
        testedCleared: state => ({
            ...state,
            tested: undefined,
            isTesting: false,
            testError: undefined,
        }),
    },
});

export const {
    searchStarted,
    searchSucceeded,
    searchFailed,
    creating,
    created,
    creatingFailed,
    createdCleared,
    patching,
    patched,
    patchFailed,
    deleting,
    deleted,
    deleteFailed,
    testing,
    tested,
    testFailed,
    testedCleared,
} = slice.actions;

export default slice.reducer;
