import React from 'react';
import styled from 'styled-components';
import useModal from '../../../services/hooks/useModal';
import { ConnectedStore } from '../../../types/ConnectedStore';
import SearchFilterButton from '../Button/SearchFilterButton';
import FilterDropdown from '../Dropdown/FilterDropdown';
import StoreMultiSelect from '../StoreMultiSelect/StoreMultiSelect';

interface TermProps {
    availableStores?: ConnectedStore[];
    currentFilters: string[];
    onTermFilterChange: (termKey: string, termFilter: string[]) => void;
    position?: string;
}

const StoreTerm: React.FC<TermProps> = ({
    availableStores,
    currentFilters,
    onTermFilterChange,
    position = 'left',
}) => {
    const termKey = 'storeId';

    const { isShown, toggle } = useModal();

    const filterExists = currentFilters.length !== 0;

    const onClear = () => {
        onTermFilterChange(termKey, []);
        if (isShown) {
            toggle();
        }
    };

    const getTermStatus = () => {
        if (currentFilters.length === 0) {
            return 'All';
        }
        if (currentFilters.length === 1) {
            return currentFilters[0];
        }
        return currentFilters.length;
    };

    return (
        <Terms>
            <FilterDropdown
                toggle={toggle}
                isShown={isShown}
                initiator={
                    <SearchFilterButton
                        modalToggled={isShown}
                        onClick={toggle}
                        onClear={onClear}
                        filterExists={filterExists}
                        criteria={'Stores'}
                    >
                        <span data-testid="store-term-value">{getTermStatus()}</span>
                    </SearchFilterButton>
                }
                position={position}
            >
                <TermValues>
                    <StoreMultiSelect
                        availableStores={availableStores ?? []}
                        selectedStores={currentFilters}
                        onStoreChange={x => onTermFilterChange(termKey, x)}
                    />
                </TermValues>
            </FilterDropdown>
        </Terms>
    );
};

const Terms = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`;

const TermValues = styled.div`
    width: 33rem;
`;

export default StoreTerm;
