import errorMap from './ErrorCodes';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Errors {
    name: string;
    code: string;
    message: string;
}

export enum ErrorCode {
    CLIENT_CONNECTION_ERROR = 'CLIENT_CONNECTION_ERROR',
    USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED',
    USER_UNAUTHORIZED = 'USER_UNAUTHORIZED',
    NOT_FOUND = 'NOT_FOUND',
    CLIENT_CANCELED_ERROR = 'CLIENT_CANCELED_ERROR',
    BAD_REQUEST = 'BAD_REQUEST',
}

class ResponseError extends Error {
    public errors: Errors[] | Record<string, string[]>;

    public code: string;

    public type: string;

    public title: string;

    public status: number;

    public detail: string;

    public instance: unknown;

    public extensions: any;

    constructor(
        errors: Errors[] | Record<string, string[]>,
        code: string,
        type: string,
        title: string,
        status: number,
        detail: string,
        instance: unknown,
        extensions: any
    ) {
        super();
        this.errors = errors;
        this.code = code;
        this.type = type;
        this.title = title;
        this.status = status;
        this.detail = detail;
        this.instance = instance;
        this.extensions = extensions;
    }

    static fromConnectionError(message: string): ResponseError {
        return new this(
            [] as Errors[],
            ErrorCode.CLIENT_CONNECTION_ERROR,
            ErrorCode.CLIENT_CONNECTION_ERROR,
            "Don't worry, we got this!",
            0,
            `${message}`,
            null,
            {}
        );
    }

    static fromCanceledError(message: string): ResponseError {
        return new this(
            [] as Errors[],
            ErrorCode.CLIENT_CANCELED_ERROR,
            ErrorCode.CLIENT_CANCELED_ERROR,
            "Don't worry, we got this!",
            0,
            `${message}`,
            null,
            {}
        );
    }

    static fromUnauthenticatedException(responseStatus: number): ResponseError {
        return new this(
            [] as Errors[],
            ErrorCode.USER_UNAUTHENTICATED,
            ErrorCode.USER_UNAUTHENTICATED,
            'Sorry for the inconvenience, but you need to reload the page',
            responseStatus,
            'Your login has expired and you need to reload the page and try again',
            null,
            {}
        );
    }

    static fromUnauthorizedException(responseStatus: number): ResponseError {
        return new this(
            [] as Errors[],
            ErrorCode.USER_UNAUTHORIZED,
            ErrorCode.USER_UNAUTHORIZED,
            'Permission denied',
            responseStatus,
            'You do not have permission to see this page',
            null,
            {}
        );
    }

    static fromNotFoundException(responseStatus: number): ResponseError {
        return new this(
            [] as Errors[],
            ErrorCode.NOT_FOUND,
            ErrorCode.NOT_FOUND,
            'Not found',
            responseStatus,
            '',
            null,
            {}
        );
    }

    static fromUnhandledException(data: any, responseStatus: number): ResponseError {
        const { type, title, traceId } = data;
        return new this(
            [] as Errors[],
            title,
            type,
            'Sorry, but something went wrong',
            responseStatus,
            `${title}. TraceId: ${traceId}`,
            null,
            {}
        );
    }

    static fromBadRequestException(data: any): ResponseError {
        if (data.error) {
            const { message, code, errors } = data.error;
            return new this(errors as Errors[], '', '', message, code, message, null, {});
        }

        if (data.errors && !Array.isArray(data.errors)) {
            const { title, errors } = data;
            return new this(
                errors as Record<string, string[]>,
                '',
                '',
                'Sorry, but something went wrong',
                400,
                title,
                null,
                {}
            );
        }

        const { type, title, traceId } = data;
        return new this(
            [] as Errors[],
            title,
            type,
            'Sorry, but something went wrong',
            400,
            `${title}. TraceId: ${traceId}`,
            null,
            {}
        );
    }

    static fromHandledException(data: any, responseStatus: number): ResponseError {
        if (responseStatus === 900 && data.error) {
            const { code, errors, message } = data.error;
            return new this(errors as Errors[], '', '', message, code, '', null, {});
        }

        if (responseStatus === 409 && data.error) {
            const { code, errors, message } = data.error;
            return new this(errors as Errors[], '', '', message, code, '', null, {});
        }

        const { code, type, title, errors } = data;
        const mappedError = errorMap[code];

        return new this(
            (errors as Errors[]) ?? ([] as Errors[]),
            code,
            type,
            mappedError ? mappedError.title : code,
            responseStatus,
            title,
            null,
            {}
        );
    }

    static requestMissingHeaders(): ResponseError {
        return new this(
            [] as Errors[],
            ErrorCode.BAD_REQUEST,
            ErrorCode.BAD_REQUEST,
            'An unexpected error occured',
            400,
            'Sorry, but something went wrong',
            null,
            {}
        );
    }
}

export default ResponseError;
