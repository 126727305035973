import { useState } from 'react';

const useModalWithId = () => {
    const [shown, setIsShown] = useState<string>('');
    const toggleWithId = (compareId: string) => {
        return () => setIsShown(shown !== compareId ? compareId : '');
    };

    const isShownWithId = (compareId: string) => {
        return shown === compareId;
    };

    const close = () => {
        setIsShown('');
    };

    return {
        isShownWithId,
        close,
        toggleWithId,
    };
};

export default useModalWithId;
