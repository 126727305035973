export enum FilterTerm {
    PlacedAt = 'placedAt',
    ExpiresAt = 'expiresAt',
    StoreId = 'storeId',
    Status = 'status',
    Currency = 'currency',
    CreatedAt = 'createdAt',
    PaymentCompletedAt = 'occurredAt.paymentCompleted',
}

export default FilterTerm;
