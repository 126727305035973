/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import api from '../../services/api';
import { Order } from '../../types/order/Order';
import { AppThunkAction, AppThunkDispatch } from '../thunk';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import { SearchTerm } from '../../types/response/SearchTerm';
import { ResponseData } from '../../types/response/ResponseData';
import ResponseError, { ErrorCode } from '../../types/response/ResponseError';

import {
    searchStarted,
    searchFailed,
    searchSucceeded,
    searchCanceled,
    searchTermsSucceeded,
    searchTermsFailed,
    searchAccountStarted,
    searchAccountSucceeded,
    searchAccountFailed,
    searchAccountCanceled,
} from './searchSlice';
import { Account } from '../../types/account/Account';

const searchOrders =
    (searchQueryString: string | undefined): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const source = axios.CancelToken.source();

        let query = searchQueryString?.replace('?', '').replace('%252C%2520', '');
        if (query === '') query = 'query=&page=1&perPage=30';

        dispatch(searchStarted({ phrase: query || '', cancelToken: source }));

        try {
            const response = await api.get<PagingResponseData<Order[]>>(
                `orders?${query}`,
                source.token
            );

            dispatch(searchSucceeded(response));
        } catch (error) {
            const parsedError = error as ResponseError;

            if (parsedError.code === ErrorCode.CLIENT_CANCELED_ERROR) {
                dispatch(searchCanceled());
            } else {
                dispatch(searchFailed(error as ResponseError));
            }
        }
    };

const searchAccounts =
    (searchQueryString: string | undefined): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const source = axios.CancelToken.source();

        let query = searchQueryString?.replace('?', '');
        if (query === '') query = 'query=&page=1&perPage=30';

        dispatch(searchAccountStarted({ cancelToken: source }));

        try {
            const response = await api.get<PagingResponseData<Account[]>>(`/accounts?${query}`);

            dispatch(searchAccountSucceeded(response));
        } catch (error) {
            const parsedError = error as ResponseError;

            if (parsedError.code === ErrorCode.CLIENT_CANCELED_ERROR) {
                dispatch(searchAccountCanceled());
            } else {
                dispatch(searchAccountFailed(error as ResponseError));
            }
        }
    };

const terms = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    try {
        const response = await api.get<ResponseData<SearchTerm[], undefined, undefined>>(
            `orders/aggregation?aggregation=order_terms`
        );

        dispatch(searchTermsSucceeded(response.data));
    } catch (error) {
        dispatch(searchTermsFailed());
    }
};

export default { searchOrders, searchAccounts, terms };
