import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ResendInvoiceModal from '../../../containers/accounts/ResendInvoiceModal';
import { AccountState } from '../../../store/account/accountSlice';
import { useAppState } from '../../../store/appstate';
import DeliveryMethod from '../../../types/account/DeliveryMethod';
import { Links, Notification } from '../../../types/account/Notification';
import { NotificationDelivery } from '../../../types/account/NotificationDelivery';
import { NotificationEmailRecipient } from '../../../types/account/NotificationEmailRecipient';
import { NotificationPostalRecipient } from '../../../types/account/NotificationPostalRecipient';
import InvoiceStatus from '../../../types/invoice/InvoiceStatus';
import Button from '../../atoms/Button/Button';
import DateTime from '../../atoms/DateTime/DateTime';
import { Modal } from '../../atoms/Modal/Modal';
import ExtendDate from '../ExtendDate';
import DueIn from '../Invoice/DueIn';
import {
    clearExtendDueDateError,
    clearResendInvoiceError,
} from '../../../store/account/accountSlice';
import { useAppDispatch } from '../../../store';
import useModalWithId from '../../../services/hooks/useModalWithId';
import StatusTypes from '../../../types/StatusTypes';
import { transparentize } from 'polished';

interface Props {
    notification: Notification;
    links: Links;
    isCreditNote: boolean;
    openCreditFees: (notification: Notification, links: Links) => void;
    theme: DefaultTheme;
}

const NotificaitonInformationAndActions: React.FC<Props> = ({
    notification,
    links,
    isCreditNote,
    openCreditFees,
    theme,
}: Props) => {
    const invoiceStatus = () => {
        if (
            notification.paymentStatus === InvoiceStatus.FullyPaid ||
            notification.paymentStatus === InvoiceStatus.PartiallyPaid
        )
            return notification.paymentStatus;

        if (notification.canceled) return InvoiceStatus.Canceled;

        return notification.paymentStatus;
    };

    const dispatch = useAppDispatch();
    const extendInvoiceModal = useModalWithId();
    const resendInvoiceModal = useModalWithId();

    const paymentStatusInvalidDueInArray = Object.values(InvoiceStatus)
        .flatMap(e => e)
        .filter(s => s !== InvoiceStatus.NotPaid)
        .map(s => s.valueOf());

    const { error, isExtending, isExtended, extendFailed, isResending, isResent, resendFailed } =
        useAppState<AccountState>(s => s.account);

    const hasExtendable = links.extend;
    const hasResend = links.resend;
    const hasCredit = links.credit;

    const notificationId = notification.notificationId.toString();
    const showDueIn = !paymentStatusInvalidDueInArray.includes(invoiceStatus());

    const onCloseExtendModal = () => {
        extendInvoiceModal.close();
        dispatch(clearExtendDueDateError());
    };

    const onCloseResendInvoice = () => {
        resendInvoiceModal.close();
        dispatch(clearResendInvoiceError());
    };

    const renderPostal = (postal: NotificationPostalRecipient) => {
        return (
            <NotificationAddress>
                {postal && (
                    <>
                        <NotificationAddressLine>{postal.name}</NotificationAddressLine>
                        <NotificationAddressLine>{postal.addressLine1}</NotificationAddressLine>
                        <NotificationAddressLine>{postal.addressLine2}</NotificationAddressLine>
                        <NotificationAddressLine>{postal.coAddress}</NotificationAddressLine>
                        <NotificationAddressLine>
                            {postal.postalCode} {postal.city}
                        </NotificationAddressLine>{' '}
                    </>
                )}
            </NotificationAddress>
        );
    };

    const renderRecipient = (delivery: NotificationDelivery) => {
        switch (delivery.method) {
            case DeliveryMethod.Post:
            case DeliveryMethod.Print:
                return renderPostal(delivery.recipient as NotificationPostalRecipient);
            case DeliveryMethod.Email:
                return (
                    <NotificationAddressLine>
                        {(delivery.recipient as NotificationEmailRecipient).email}
                    </NotificationAddressLine>
                );
            default:
                return null;
        }
    };

    const renderLatestResend = (deliveries: NotificationDelivery[]) => {
        const lastIndex = deliveries.length - 1;
        const latestResend = deliveries[lastIndex];

        return (
            <>
                <Information>
                    <Label>Sent as</Label>
                    <Value>{latestResend.method}</Value>
                </Information>
                <Information>
                    <Label>Sent to</Label>
                    <Value>{renderRecipient(latestResend)}</Value>
                </Information>
            </>
        );
    };

    return (
        <Wrapper>
            <InformationContainer>
                <Information>
                    <Label>Invoice number:</Label>
                    <Value>{notification.invoiceNumber}</Value>
                </Information>
                {!isCreditNote && (
                    <Information>
                        <Label>Payment reference:</Label>
                        <Value>{notification.paymentReference}</Value>
                    </Information>
                )}

                <Information>
                    <Label>Created:</Label>
                    <Value>
                        <DateTime>{new Date(notification.notificationDate)}</DateTime>
                    </Value>
                </Information>
                {!isCreditNote && (
                    <Information>
                        <Label>Due date:</Label>
                        <Value>
                            {showDueIn && (
                                <DueIn theme={theme} dueDate={new Date(notification.dueDate)} />
                            )}
                            <DateTime onlyDate>{new Date(notification.dueDate)}</DateTime>
                        </Value>
                    </Information>
                )}
                {notification.deliveries &&
                    notification.deliveries.length !== 0 &&
                    renderLatestResend(notification.deliveries)}
            </InformationContainer>
            <NotificationActions>
                <NotificationButtons>
                    {hasExtendable && (
                        <Button onClick={extendInvoiceModal.toggleWithId(notificationId)}>
                            Extend
                        </Button>
                    )}
                    {hasResend && (
                        <Button onClick={resendInvoiceModal.toggleWithId(notificationId)}>
                            Resend
                        </Button>
                    )}
                    {hasCredit && (
                        <Button primary onClick={() => openCreditFees(notification, links)}>
                            Credit fees
                        </Button>
                    )}
                </NotificationButtons>
                {notification.url && (
                    <NotificationLinks>
                        <PdfLink href={notification.url} target="_blank">
                            Show notification as pdf
                        </PdfLink>
                    </NotificationLinks>
                )}
            </NotificationActions>

            {hasExtendable && extendInvoiceModal.isShownWithId(notificationId) && (
                <Modal
                    small
                    isShown={extendInvoiceModal.isShownWithId(notificationId)}
                    hide={onCloseExtendModal}
                >
                    <ExtendDate
                        hide={onCloseExtendModal}
                        identifier={notification.invoiceNumber}
                        extendLink={links.extend}
                        type={StatusTypes.Invoice}
                        isExtending={isExtending}
                        isExtended={isExtended}
                        error={error}
                        extendFailed={extendFailed}
                    />
                </Modal>
            )}

            {hasResend && resendInvoiceModal.isShownWithId(notificationId) && (
                <Modal
                    medium
                    isShown={resendInvoiceModal.isShownWithId(notificationId)}
                    hide={onCloseResendInvoice}
                >
                    <ResendInvoiceModal
                        address={notification.address}
                        hide={onCloseResendInvoice}
                        identifier={notification.invoiceNumber}
                        resendLink={links.resend}
                        error={error}
                        isResending={isResending}
                        isResent={isResent}
                        resendFailed={resendFailed}
                    />
                </Modal>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    padding-bottom: ${props => props.theme.layout.padding.small};
`;

const InformationContainer = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
`;

const Information = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${props => props.theme.layout.margin.small};
    padding-right: ${props => props.theme.layout.padding.small};
`;

const NotificationActions = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-self: right;
    justify-content: space-between;
    gap: 1rem;
`;

const NotificationButtons = styled.div`
    display: inline-flex;
    text-align: right;
    gap: 0.5rem;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

const NotificationLinks = styled.div`
    text-align: right;
    margin-bottom: ${props => props.theme.layout.margin.small};
`;

const NotificationAddress = styled.div`
    display: flex;
    flex-direction: column;
`;

const NotificationAddressLine = styled.span``;

const Label = styled.div`
    flex: 1;
    margin-right: ${props => props.theme.layout.margin.small};
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
`;

const Value = styled.div`
    flex: 2;
    font-size: ${props => props.theme.text.size.medium};
`;

const PdfLink = styled.a`
    color: ${props => props.theme.colors.primary};
    &:hover {
        color: ${props => transparentize(0.3, props.theme.colors.primary)};
    }
`;

export default withTheme(NotificaitonInformationAndActions) as React.ComponentType<
    Omit<Props, 'theme'>
>;
