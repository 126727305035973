import React from 'react';
import styled from 'styled-components';
import Card from '../../atoms/Card/Card';
import Money from '../../atoms/Money/Money';

interface SummaryCardProps {
    totalGrossSales: number;
    totalRefunds: number;
    totalNetSales: number;
    totalCompensation: number;
    totalAdjustment: number;
    totalAmount: number;
    currancy: string;
    payouts: number;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
    totalGrossSales,
    totalRefunds,
    totalNetSales,
    totalCompensation,
    totalAdjustment,
    totalAmount,
    currancy,
    payouts,
}: SummaryCardProps) => {
    return (
        <CardWrapper>
            <Card>
                <Container data-testid={`summary-card-${currancy}`}>
                    <HeadRow>
                        <Currancy>{currancy}</Currancy>
                        <Payouts>{payouts ?? 0} payouts</Payouts>
                    </HeadRow>
                    <CardRow>
                        <div>Gross sales</div>
                        <Money>{totalGrossSales ?? 0}</Money>
                    </CardRow>
                    <CardRow>
                        <div>Refunds</div>
                        <Money>{totalRefunds ?? 0}</Money>
                    </CardRow>
                    <CardRow>
                        <div>Net sales</div>
                        <Money>{totalNetSales ?? 0}</Money>
                    </CardRow>
                    <CardRow>
                        <div>Walley fees</div>
                        <Money>{totalCompensation ?? 0}</Money>
                    </CardRow>
                    <CardRow>
                        <div>Settlement adjustments</div>
                        <Money>{totalAdjustment ?? 0}</Money>
                    </CardRow>
                    <CardRow>
                        <BoldText>Total amount</BoldText>
                        <Money>{totalAmount ?? 0}</Money>
                    </CardRow>
                </Container>
            </Card>
        </CardWrapper>
    );
};

export default SummaryCard;

const CardWrapper = styled.div`
    width: 40rem;
`;

const Container = styled.div`
    padding: 2rem;
    display: grid;
    flex-direction: column;
`;

const CardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;

    &:last-child {
        border-top: 0.2rem solid ${props => props.theme.colors.border};
    }
`;

const HeadRow = styled(CardRow)`
    margin-bottom: 0.5rem;
`;

const Currancy = styled.div`
    font-family: ${props => props.theme.text.font.bold};
    text-transform: uppercase;
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
`;

const Payouts = styled.div`
    color: ${props => props.theme.colors.subtle.regular};
`;
