enum OrderArticleStatus {
    NotActivated = 'NotActivated',
    Activated = 'Activated',
    Expired = 'Expired',
    Canceled = 'Canceled',
    Removed = 'Removed',
    Returned = 'Returned',
    Unknown = '',
}

export default OrderArticleStatus;
