import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme, withTheme, css } from 'styled-components';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import useOnClickOutside from '../Dropdown/useOnClickOutside';
import Keys from '../../../types/Keys';

interface GridInputProps {
    primary?: boolean;
    setRef?: (instance: HTMLDivElement | null) => void;
    validationError?: boolean;
    background?: boolean;
    placeholderRaised?: boolean;
    noFocusBorder?: boolean;
    dataTestId?: string;
    isFocused?: boolean;
    options: { label: string | ReactNode; value: string }[];
    onChange: (value: string) => void;
    value: string;
    placeholder: string;
    id?: string;
    defaultValue: string;
    theme: DefaultTheme;
    disabled?: boolean;
}

interface SelectProps {
    $isActive: boolean;
    $validationError: boolean;
    $disabled: boolean;
}
interface OptionProps {
    $isChosen: boolean;
}

interface StyledLabelProps {
    $isActive: boolean;
    $hasValue: boolean;
}

type GridInputType = GridInputProps;

const SelectInput: React.FC<GridInputType> = ({
    id,
    setRef,
    onChange,
    dataTestId,
    options,
    value,
    placeholder,
    validationError,
    disabled,
    theme,
}: GridInputType) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const selectedRef = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectChosen, setSelectChosen] = useState(0);

    const handleClickOutside = () => {
        if (open) {
            setOpen(!open);
            setActive(!active);
        }
    };

    useOnClickOutside(ref, handleClickOutside);

    useEffect(() => {
        if (open && selectedRef.current?.scrollIntoView) {
            selectedRef.current?.scrollIntoView({
                block: 'nearest',
                inline: 'nearest',
            });
        }
    }, [selectChosen, open]);

    useEffect(() => {
        if (disabled) {
            setOpen(false);
        }
    }, [disabled]);

    const parseValue = () => {
        const obj = options.find(x => x.value === value);

        return obj?.label ?? null;
    };

    const onClick = () => {
        if (disabled) {
            return;
        }

        setActive(!active);
        setOpen(!open);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (disabled) {
            return;
        }

        switch (event.key) {
            case Keys.SPACE:
                onClick();
                break;
            case Keys.ARROW_UP:
                if (open) {
                    setSelectChosen(selectChosen === 0 ? 0 : selectChosen - 1);
                } else {
                    onClick();
                }
                break;
            case Keys.ARROW_DOWN:
                if (open) {
                    setSelectChosen(selectChosen === options.length - 1 ? 0 : selectChosen + 1);
                } else {
                    onClick();
                }
                break;
            case Keys.ENTER:
                if (open) {
                    onChange(options[selectChosen]?.value);
                }
                onClick();
                break;
            case Keys.ESCAPE:
                if (open) {
                    event.stopPropagation();
                    onClick();
                }
                break;
            case Keys.TAB:
                if (open) {
                    onClick();
                }
                break;
        }
    };

    return (
        <Select
            ref={node => {
                if (setRef) setRef(node);
                ref.current = node;
            }}
            onKeyDown={onKeyDown}
            tabIndex={disabled ? undefined : 0}
            data-testid={dataTestId}
            id={id}
            $isActive={!!active}
            $validationError={validationError ?? false}
            $disabled={disabled ?? false}
            onClick={() => onClick()}
        >
            <Label $isActive={!!value} $hasValue={!!value}>
                {placeholder}
            </Label>
            <Value>{parseValue()}</Value>
            <IconWrapper>
                {!active ? (
                    <ArrowDownSLineIcon size={theme.icon.size.normal} />
                ) : (
                    <ArrowUpSLineIcon size={theme.icon.size.normal} />
                )}
            </IconWrapper>

            {open && (
                <Dropdown>
                    {options.map((x, index) => {
                        return (
                            <Option
                                ref={selectChosen === index ? selectedRef : undefined}
                                $isChosen={selectChosen === index}
                                onClick={() => onChange(x.value)}
                                key={`select-label-${x.value}`}
                            >
                                {x.label}
                            </Option>
                        );
                    })}
                </Dropdown>
            )}
        </Select>
    );
};

export default withTheme(SelectInput) as React.ComponentType<Omit<GridInputType, 'theme'>>;

const Value = styled.div`
    position: absolute;
    top: 1rem;
    padding: 1rem 0 1rem 1.8rem;
    &:hover {
        cursor: pointer;
    }
`;

const Label = styled.label<StyledLabelProps>`
    position: absolute;
    font-size: ${props => props.theme.text.size.small};
    transform: translate(1.7rem, 2.3rem) scale(1);
    transform-origin: top left;
    transition: all 0.15s ease-in-out;
    opacity: 0.6;
    ${props =>
        (props.$isActive || props.$hasValue) &&
        css`
            transform: translate(1.7rem, 0.8rem) scale(0.75);
            color: ${props.theme.colors.primary};
            opacity: 1;
        `};
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 1.65rem;
    right: 1.5rem;
    color: ${props => props.theme.colors.veryDark};
`;

const Select = styled.div<SelectProps>`
    cursor: pointer;
    background-color: ${props => props.theme.colors.light};
    height: 6rem;
    position: relative;
    display: ${props => (props.hidden ? 'none' : 'inline-block')};
    width: 100%;
    border: 0.1rem solid ${props => props.theme.colors.input.border};
    align-items: center;
    ${props =>
        props.$isActive &&
        `
        border: 0.1rem solid ${props.theme.colors.primary} !important;
        outline: none;
        `};

    &:focus {
        border: 0.1rem solid ${props => props.theme.colors.primary};
        outline: none;
    }

    ${props =>
        props.$validationError &&
        css`
            &:focus {
                border: 0.2rem solid ${props.theme.colors.error};
                outline: none;
            }
        `}

    ${props =>
        props.$disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;

            &:focus {
                border-color: ${props.theme.colors.input.border} !important;
            }
        `}
`;

const Dropdown = styled.div`
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
    width: 100%;
    margin-top: 6.2rem;
    max-height: 20rem;
    overflow: auto;
`;

const Option = styled.div<OptionProps>`
    padding: 1.5rem 2rem;
    color: ${props => props.theme.colors.text.primary};
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};

    &:hover {
        background-color: ${props => props.theme.colors.text.subtle};
        cursor: pointer;
    }

    ${props =>
        props.$isChosen &&
        `background-color: ${props.theme.colors.text.subtle};
        outline: none;`};
`;
