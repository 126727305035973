import React from 'react';

import { useAppState } from '../../store/appstate';

interface RegistrationNumberFormatterProps {
    registrationNumber: string;
}

const RegistrationNumberFormatter: React.FC<RegistrationNumberFormatterProps> = ({
    registrationNumber,
}: RegistrationNumberFormatterProps) => {
    const isInternal = useAppState<boolean>(s => s.session.user?.isInternal ?? false);

    const formatRegistationNumber = (regNumber: string) => {
        if (!regNumber) return '[Not set]';
        if (!regNumber.startsWith('G')) return regNumber;
        if (isInternal && regNumber.startsWith('G')) return regNumber;

        return '[Not set]';
    };

    return <>{formatRegistationNumber(registrationNumber)}</>;
};

export default RegistrationNumberFormatter;
