import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import HeaderTitle from '../../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../../atoms/Modal/ModalContainer';
import FooterButtonContainer from '../../../atoms/Modal/FooterButtonContainer';
import { useAppState } from '../../../../store/appstate';
import Spinner from '../../../atoms/Spinner/Spinner';
import Button from '../../../atoms/Button/Button';

interface DeleteUserModalProps {
    hide: () => void;
    name: string;
    id: string;
    theme: DefaultTheme;
    onRemoveUser: (id: string) => void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
    hide,
    name,
    id,
    theme,
    onRemoveUser,
}: DeleteUserModalProps) => {
    const isBusy = useAppState<boolean>(s => s.admin.users.isSearching);

    const onSubmit = () => {
        onRemoveUser(id);
        hide();
    };

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Delete user</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content">
                Are you sure you would like to delete <DeleteUser>{name}</DeleteUser>?
                <form onSubmit={onSubmit} data-testid="form" id="delete-user-form" />
            </ModalContainer>

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large>
                        No
                    </Button>

                    <Button
                        tabIndex={0}
                        form="delete-user-form"
                        type="submit"
                        primary
                        large
                        data-testid="delete-user-button"
                    >
                        {isBusy ? (
                            <Spinner color={theme.colors.light} size={8} loading />
                        ) : (
                            <span>Yes</span>
                        )}
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(DeleteUserModal) as React.ComponentType<
    Omit<DeleteUserModalProps, 'theme'>
>;

const DeleteUser = styled.span`
    font-family: ${props => props.theme.text.font.medium};
`;
