import React from 'react';
import styled from 'styled-components';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';

import IconButton from '../Button/IconButton';
import Button from '../Button/Button';
import { setNewVersionAvailable } from '../../../store/userSettings/userSettingsSlice';
import { useAppDispatch } from '../../../store';

const RefreshMessage: React.FC = () => {
    const dispatch = useAppDispatch();

    const refreshToNewVersion = () => {
        dispatch(setNewVersionAvailable(false));
        window.location.reload();
    };

    const close = () => {
        dispatch(setNewVersionAvailable(false));
    };

    return (
        <Container>
            <UpdateText>
                <InformationLineIcon size="17" /> We have updated the Merchant Hub! Refresh to get
                the latest version.
                <Button primary onClick={() => refreshToNewVersion()}>
                    Refresh
                </Button>
            </UpdateText>
            <ButtonContainer>
                <IconButton transparent onClick={() => close()}>
                    <CloseLineIcon size="17" />
                </IconButton>
            </ButtonContainer>
        </Container>
    );
};

const UpdateText = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-left: auto;
    align-items: center;
`;

const Container = styled.div`
    background: ${props => props.theme.colors.toast.info};
    height: 3rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
`;

const ButtonContainer = styled.div`
    justify-content: flex-end;
    align-self: center;
    margin-left: auto;
`;

export default RefreshMessage;
