/* eslint-disable no-param-reassign */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

export type UserSettingsState = {
    orderColumnFilters: string[];
    settlementColumnFilters: string[];
    error: string;
    flow?: string;
    exactSearch?: boolean;
    perPage?: string;
    newVersionAvailable: boolean;
    orderAccountSearchTab?: string;
};

const initialState: UserSettingsState = {
    orderColumnFilters: [],
    settlementColumnFilters: [],
    error: '',
    flow: '',
    exactSearch: false,
    perPage: '',
    newVersionAvailable: false,
    orderAccountSearchTab: undefined,
};

export const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        saveColumnFiltersRequested: state => {},
        saveColumnFiltersSucceeded: (
            state,
            action: PayloadAction<{
                tableHeaderType: string;
                columnFilters: string[];
            }>
        ) => {
            const newState = {
                ...current(state),
                [action.payload.tableHeaderType]: action.payload.columnFilters,
            };

            return newState;
        },
        saveColumnFiltersFailed: state => {
            state.error = '';
        },
        setUserFlow: (state, action: PayloadAction<string>) => {
            state.flow = action.payload;
        },
        saveExactSearch: (state, action: PayloadAction<boolean>) => {
            state.exactSearch = action.payload;
        },
        setPerPage: (state, action: PayloadAction<string>) => {
            state.perPage = action.payload;
        },
        setNewVersionAvailable: (state, action: PayloadAction<boolean>) => {
            state.newVersionAvailable = action.payload;
        },
        setOrderAccountSearchTab: (state, action: PayloadAction<string>) => {
            state.orderAccountSearchTab = action.payload;
        },
    },
});

export const {
    saveColumnFiltersRequested,
    saveColumnFiltersSucceeded,
    saveColumnFiltersFailed,
    setUserFlow,
    saveExactSearch,
    setPerPage,
    setNewVersionAvailable,
    setOrderAccountSearchTab,
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
