import React, { useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import useOnClickOutside from '../Dropdown/useOnClickOutside';
import Keys from '../../../types/Keys';
import useRenderVisibleListScroll from '../../../services/hooks/useRenderVisibleListScroll';
import { v4 as uuidv4 } from 'uuid';

interface GridInputProps {
    primary?: boolean;
    setRef?: (instance: HTMLInputElement | null) => void;
    validationError?: boolean;
    background?: boolean;
    placeholderRaised?: boolean;
    noFocusBorder?: boolean;
    dataTestId?: string;
    isFocused?: boolean;
    options: { label: string; value: string }[];
    onChange: (value: string) => void;
    value: string;
    placeholder: string;
    id?: string;
    defaultValue: string;
    theme: DefaultTheme;
    disabled?: boolean;
    large?: boolean;
}

interface SelectProps {
    isActive: boolean;
    large?: boolean;
    disabled?: boolean;
}
interface OptionProps {
    chosen: boolean;
    height: number;
    selected: boolean;
}

interface PlaceholderProps {
    hasValue: boolean;
    large?: boolean;
}

interface Props {
    large?: boolean;
    disabled?: boolean;
}

type GridInputType = GridInputProps;

const SearchSelect: React.FC<GridInputType> = ({
    id,
    onChange,
    dataTestId,
    options,
    value,
    placeholder,
    theme,
    disabled,
    large,
}: GridInputType) => {
    const itemHeight = 35;
    const visibleItems = 20;

    const ref = useRef(null);
    const openRef = useRef<HTMLDivElement>(null);

    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [show, setShow] = useState(options);

    const { scrollState, scrollPos, scrollItemHeight, resetScroll } = useRenderVisibleListScroll({
        visibleItems,
        itemHeight,
        openRef,
        items: show,
    });

    const sliced = show.slice(scrollState.start, scrollState.end);
    const [selectChosen, setSelectChosen] = useState(options.length > 0 ? options[0].value : '');

    const handleClickOutside = () => {
        if (open) {
            setOpen(!open);
            setActive(!active);
            resetScroll();
        }
        setSearch('');
    };

    useOnClickOutside(ref, handleClickOutside);

    const parseValue = () => {
        const obj = options.find(x => x.value === value);

        return obj?.label ?? null;
    };

    const onClick = () => {
        setActive(!active);
        setOpen(!open);
        resetScroll();
        setSelectChosen(options[0].value);
        setSearch('');
    };

    const setScrollTop = (descending: boolean) => {
        const scrollToAdd = descending ? 15 : -15;
        if (openRef.current) openRef.current.scrollTop = openRef.current.scrollTop + scrollToAdd;
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!open) {
            setOpen(true);
        }

        const showLength = show.length - 1;

        const nextUp = show.findIndex(x => x.value == selectChosen) - 1;

        if (event.key === Keys.ARROW_UP && nextUp >= 0) {
            setScrollTop(false);
            setSelectChosen(nextUp === 0 ? show[0].value : show[nextUp].value);
        }

        const nextDown = sliced.findIndex(x => x.value == selectChosen) + 1;

        if (event.key === Keys.ARROW_DOWN && nextDown <= showLength) {
            setScrollTop(true);
            setSelectChosen(sliced[nextDown].value);
        }

        if (event.key === Keys.ENTER) {
            if (show.length === 1) {
                onChange(show[0].value);
                onClick();
            } else {
                const valueToChoose = options[options.findIndex(x => x.value == selectChosen)];

                onChange(valueToChoose ? valueToChoose.value : options[0].value);
                onClick();
            }
        }
    };

    useEffect(() => {
        if (!search || search === '') setShow(options);

        const filteredItems = options.filter(
            x =>
                x.label.includes(search) ||
                x.label.toLowerCase().includes(search) ||
                x.value.toLowerCase().includes(search.toLocaleLowerCase())
        );

        resetScroll(0);

        const [valueToSet] = filteredItems;

        if (valueToSet) {
            setSelectChosen(valueToSet.value);
        }
        setShow(filteredItems);
    }, [search, options]);

    return (
        <Select
            large={large}
            ref={ref}
            onKeyDown={onKeyDown}
            tabIndex={0}
            data-testid={dataTestId}
            id={id}
            isActive={!!active}
            onClick={disabled ? () => {} : () => onClick()}
            disabled={disabled}
        >
            <Placeholder large={large} hasValue={!!value || search !== ''}>
                {placeholder}
            </Placeholder>
            <Value large={large}>
                <StyledInput
                    large={large}
                    autoComplete="off"
                    disabled={disabled}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={parseValue() ?? ''}
                    value={search}
                    type="text"
                    id="search"
                    name="search"
                />
            </Value>
            <IconWrapper disabled={disabled} large={large}>
                {!active ? (
                    <ArrowDownSLineIcon size={theme.icon.size.normal} />
                ) : (
                    <ArrowUpSLineIcon size={theme.icon.size.normal} />
                )}
            </IconWrapper>

            {open && options.length > 0 && (
                <Container large={large}>
                    <Dropdown
                        ref={openRef}
                        onScroll={scrollPos}
                        data-testid="search-select-dropdown"
                    >
                        {sliced.map((x, index) => {
                            const uuid = uuidv4();

                            return (
                                <Option
                                    title={`${x.label}`}
                                    style={scrollItemHeight(index)}
                                    height={itemHeight}
                                    chosen={value === x.value}
                                    selected={selectChosen === x.value}
                                    onClick={() => onChange(x.value)}
                                    key={`select-label-${x.value}-${uuid}`}
                                >
                                    <TruncatedText>{x.label}</TruncatedText>
                                </Option>
                            );
                        })}
                    </Dropdown>
                </Container>
            )}
        </Select>
    );
};

export default withTheme(SearchSelect) as React.ComponentType<Omit<GridInputProps, 'theme'>>;

const Value = styled.div<Props>`
    position: absolute;
    top: ${props => props.large && '1rem'};
    padding: ${props => (props.large ? '0 0 1rem 1rem ' : '0')};

    &:hover {
        cursor: pointer;
    }
`;

const Placeholder = styled.div<PlaceholderProps>`
    position: absolute;
    top: ${props => (props.large ? '1.2rem 1rem 1rem 1rem' : '0.2rem')};
    left: ${props => !props.large && '-0.8rem'};
    padding: 1rem 0 1rem 1.8rem;
    display: ${props => (props.hasValue ? 'none' : 'block')};
`;

const StyledInput = styled.input<Props>`
    padding: ${props => (props.large ? '1rem 1rem 1.5rem 1rem' : '1rem')};
    background: transparent;
    outline: none;
    border: none;
    width: 13rem;
    cursor: default;
    ${props =>
        props.disabled &&
        `
        opacity: 0.5;
        cursor: default;
    `}
`;

const Container = styled.div<Props>`
    margin-top: ${props => (props.large ? '6.2rem ' : '4rem')};
    background-color: white;
`;

const IconWrapper = styled.div<Props>`
    position: absolute;
    top: ${props => (props.large ? '1.65rem ' : '0.75rem')};
    right: 1.5rem;
    color: ${props => props.theme.colors.veryDark};
    ${props =>
        props.disabled &&
        `
        cursor: default;
    `}
`;

const Select = styled.div<SelectProps>`
    background-color: ${props => props.theme.colors.light};
    height: ${props => (props.large ? '6rem' : '3.8rem')};
    position: relative;
    display: ${props => (props.hidden ? 'none' : 'inline-block')};
    width: 100%;
    border: 0.1rem solid ${props => props.theme.colors.input.border};
    align-items: center;
    ${props =>
        props.isActive &&
        `
        border: 0.1rem solid ${props.theme.colors.primary} !important;
        outline: none;
        `};

    &:focus {
        ${props => !props.disabled && `border: 0.1rem solid ${props.theme.colors.primary};`};
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    ${props =>
        props.disabled &&
        `
        opacity: 0.5;
        cursor: default;
    `}
    display: block;
    width: inherit;
`;

const Dropdown = styled.div`
    background-color: ${props => props.theme.background};
    box-shadow: 0 0.8rem 1.6rem 0 ${props => props.theme.colors.shadow};
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 30rem;
    position: relative;
    z-index: 1;
`;

const TruncatedText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 16rem;
`;

const Option = styled.div<OptionProps>`
    &:hover {
        background-color: ${props => !props.chosen && props.theme.colors.text.subtle};
        cursor: pointer;
    }
    position: absolute;
    height: ${props => props.height}px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    ${props =>
        props.selected &&
        `background-color: ${props.theme.colors.text.subtle};
        outline: none;
        `};

    ${props =>
        props.chosen &&
        `background-color: ${props.theme.colors.primary};
        outline: none;
        color: white;
        `};
`;
