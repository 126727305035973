import api from '../../services/api';
import { Order } from '../../types/order/Order';
import ResponseError from '../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../thunk';
import { OrderDetailsResponseData } from '../../types/response/OrderDetailsResponseData';
import { LinkData } from '../../types/response/LinkData';
import { OrderPatchRequest } from '../../types/requests/OrderPatchRequest';
import { OrderArticleRow } from '../../types/order/OrderArticleRow';
import { OrderBulkRequest } from '../../types/requests/OrderBulkRequest';
import { MultistatusResponseData } from '../../types/response/MultistatusResponseData';
import { PatchOperation } from '../../types/requests/PatchOperation';
import { showProcessingNotComplete } from '../session/sessionSlice';
import {
    getOrderStarted,
    getOrderSucceeded,
    getOrderFailed,
    activateOrderStarted,
    activateOrderSucceeded,
    activateOrderFailed,
    cancelOrderStarted,
    cancelOrderSucceeded,
    cancelOrderFailed,
    extendDueDateStarted,
    extendDueDateSucceeded,
    extendDueDateFailed,
    updateOrderStarted,
    updateOrderSucceeded,
    updateOrderFailed,
    bulkOrderStarted,
    bulkOrderSucceeded,
    bulkOrderFailed,
    updateOrderInvoiceAddressStarted,
    updateOrderInvoiceAddressSucceeded,
    updateOrderInvoiceAddressFailed,
} from './orderSlice';
import { OrderCapture } from '../../types/order/OrderCapture';

const getOrder =
    (orderId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getOrderStarted());

        try {
            const response = await api.get<OrderDetailsResponseData>(
                `orders/${orderId}`,
                undefined,
                {
                    Accept: 'application/vnd.merchanthub.order+json',
                }
            );
            dispatch(getOrderSucceeded(response));
        } catch (error) {
            dispatch(getOrderFailed(error as ResponseError));
        }
    };

const captureOrder =
    (captureLink: LinkData, input: OrderCapture): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(activateOrderStarted());

        try {
            const result = await api.callLink<OrderCapture, OrderDetailsResponseData>(
                () => dispatch(showProcessingNotComplete(true)),
                captureLink,
                input
            );

            dispatch(activateOrderSucceeded(result));
        } catch (error) {
            dispatch(activateOrderFailed(error as ResponseError));
        }
    };

const extendDueDate =
    (extendLink: LinkData): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(extendDueDateStarted());

        try {
            const result = await api.callLink<unknown, OrderDetailsResponseData>(
                () => dispatch(showProcessingNotComplete(true)),
                extendLink
            );
            dispatch(extendDueDateSucceeded(result));
        } catch (error) {
            dispatch(extendDueDateFailed(error as ResponseError));
        }
    };

const cancelOrder =
    (cancelLink: LinkData, order: Order): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(cancelOrderStarted());

        try {
            const response = await api.callLink<unknown, OrderDetailsResponseData>(
                () => dispatch(showProcessingNotComplete(true)),
                cancelLink
            );
            dispatch(cancelOrderSucceeded(response));
        } catch (error) {
            dispatch(cancelOrderFailed(error as ResponseError));
        }
    };

const updateOrder =
    (
        updateLink: LinkData,
        articles: OrderArticleRow[],
        changes: Map<string, Map<string, string | number>>
    ): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const escapeJsonPatchPath = (x: string): string =>
            x.replace(/~/g, '~0').replace(/\//g, '~1');

        const removed = articles
            .filter(x => x.isRemoved)
            .map(
                (x): OrderPatchRequest => ({
                    op: 'remove',
                    path: `/rows/${escapeJsonPatchPath(x.rowId)}`,
                    value: undefined,
                })
            );

        const added = articles
            .filter(x => x.isAdded)
            .map(
                (x): OrderPatchRequest => ({
                    op: 'add',
                    path: `/rows/-`,
                    value: {
                        articleNumber: x.articleNumber,
                        description: x.description,
                        price: x.price,
                        quantity: x.quantity,
                        vatRate: x.vatRate,
                    } as Partial<OrderArticleRow>,
                })
            );

        const changesFromRow = (rowId: string) => Array.from(changes.get(rowId)?.entries() || []);

        const changed = articles
            .filter(x => x.isChanged)
            .flatMap(x =>
                changesFromRow(x.rowId).map(
                    ([key, value]) =>
                        ({
                            op: 'replace',
                            path: `/rows/${escapeJsonPatchPath(x.rowId)}/${key}`,
                            value,
                        } as OrderPatchRequest)
                )
            );

        const patchRequest = [...changed, ...removed, ...added];

        dispatch(updateOrderStarted(patchRequest));

        try {
            const response = await api.callLink<OrderPatchRequest[], OrderDetailsResponseData>(
                () => dispatch(showProcessingNotComplete(true)),
                updateLink,
                patchRequest
            );

            dispatch(updateOrderSucceeded(response));
        } catch (error) {
            dispatch(updateOrderFailed(error as ResponseError));
        }
    };

const bulkOrder =
    (items: string[], action: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        try {
            const response = await api.postWithResponseType<
                OrderBulkRequest,
                MultistatusResponseData
            >(`/orders/bulk`, {
                action,
                items,
            });
            dispatch(bulkOrderSucceeded(response));
        } catch (error) {
            dispatch(bulkOrderFailed(error as ResponseError));
        }
    };

const multipleBulkOrders =
    (items: string[], action: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(bulkOrderStarted());

        const splitBy = 25;

        const chunks = (array: string[], size: number) =>
            Array.from(new Array(Math.ceil(array.length / size)), (_, i) =>
                array.slice(i * size, i * size + size)
            );

        const splitForMultipleBulk = chunks(items, splitBy);

        splitForMultipleBulk.forEach(array => {
            dispatch(bulkOrder(array, action));
        });
    };

const patchOrderInvoiceAddress =
    (email: string, orderId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(updateOrderInvoiceAddressStarted());
        try {
            const response = (await api.patch<PatchOperation[]>(
                `/orders/${orderId}/invoiceAddress`,
                [
                    {
                        op: 'replace',
                        path: `/Email`,
                        value: email,
                    },
                ]
            )) as OrderDetailsResponseData;

            dispatch(updateOrderInvoiceAddressSucceeded(response));
        } catch (error) {
            dispatch(updateOrderInvoiceAddressFailed(error as ResponseError));
        }
    };

export default {
    getOrder,
    captureOrder,
    cancelOrder,
    extendDueDate,
    updateOrder,
    bulkOrder,
    patchOrderInvoiceAddress,
    multipleBulkOrders,
};
