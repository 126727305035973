import React, { useRef, useState } from 'react';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import styled, { DefaultTheme, css, withTheme } from 'styled-components';
import { ConnectedStore } from '../../../types/ConnectedStore';
import StoreMultiSelect from '../StoreMultiSelect/StoreMultiSelect';
import useOnClickOutside from '../Dropdown/useOnClickOutside';

interface StoreMultiSelectInputProps {
    theme: DefaultTheme;
    placeholder: string;
    availableStores: ConnectedStore[];
    selectedStores: string[];
    setRef?: (instance: HTMLDivElement | null) => void;
    onStoreChange: (selectedStores: string[]) => void;
}

const StoreMultiSelectInput: React.FC<StoreMultiSelectInputProps> = ({
    theme,
    placeholder,
    availableStores,
    selectedStores,
    setRef,
    onStoreChange,
}: StoreMultiSelectInputProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const handleClickOutside = () => {
        if (isOpen) setIsOpen(!isOpen);
    };
    useOnClickOutside(dropdownRef, handleClickOutside);

    const onInitiatorClick = () => {
        if (!!setIsOpen) setIsOpen(!isOpen);
    };

    const hasValue = selectedStores.length > 0;

    return (
        <Initiator
            tabIndex={0}
            data-testid="form-store-select-initiator"
            onClick={() => onInitiatorClick()}
            ref={node => {
                if (setRef) setRef(node);
                dropdownRef.current = node;
            }}
        >
            <Label $hasValue={hasValue}> {placeholder}</Label>
            {hasValue && (
                <Value>
                    {selectedStores.length === 1
                        ? selectedStores[0]
                        : `${selectedStores.length} stores selected`}
                </Value>
            )}

            <IconWrapper>
                {!isOpen ? (
                    <ArrowDownSLineIcon size={theme.icon.size.normal} />
                ) : (
                    <ArrowUpSLineIcon size={theme.icon.size.normal} />
                )}
            </IconWrapper>
            {isOpen && (
                <StyledFormStore onClick={e => e.stopPropagation()}>
                    <StoreMultiSelect
                        availableStores={availableStores}
                        selectedStores={selectedStores}
                        onStoreChange={onStoreChange}
                    />
                </StyledFormStore>
            )}
        </Initiator>
    );
};

export default withTheme(StoreMultiSelectInput) as React.ComponentType<
    Omit<StoreMultiSelectInputProps, 'theme'>
>;

interface StyledLabelProps {
    $hasValue: boolean;
}

const Initiator = styled.div`
    cursor: pointer;
    background-color: ${props => props.theme.colors.light};
    height: 6rem;
    position: relative;
    display: ${props => (props.hidden ? 'none' : 'inline-block')};
    width: 100%;
    align-items: center;

    &:focus {
        border: 0.1rem solid ${props => props.theme.colors.primary};
        outline: none;
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 1.65rem;
    right: 1.5rem;
    color: ${props => props.theme.colors.veryDark};
`;

const Label = styled.label<StyledLabelProps>`
    position: absolute;
    font-size: ${props => props.theme.text.size.small};
    transform: translate(1.7rem, 2.3rem) scale(1);
    transform-origin: top left;
    transition: all 0.15s ease-in-out;
    opacity: 0.6;
    ${props =>
        props.$hasValue &&
        css`
            transform: translate(1.7rem, 0.8rem) scale(0.75);
            color: ${props.theme.colors.primary};
            opacity: 1;
        `};
`;

const Value = styled.div`
    position: absolute;
    top: 1rem;
    padding: 1rem 0 1rem 1.8rem;
    &:hover {
        cursor: pointer;
    }
`;

const StyledFormStore = styled.div`
    position: absolute;
    margin-top: 6.2rem;
    z-index: 1;
    background-color: #f9f9f9;
    width: 100%;
`;
