export enum ReportColumnType {
    CustomerFullName = 'CustomerFullName',
    PartnerName = 'PartnerName',
    OrderNumber = 'OrderNumber',
    InvoiceNumber = 'InvoiceNumber',
    TransactionType = 'TransactionType',
    Amount = 'Amount',
    CreatedOn = 'CreatedOn',
    CreatedOnInPartnerTimeZone = 'CreatedOnInPartnerTimeZone',
    TransactionDate = 'TransactionDate',
    ProductCode = 'ProductCode',
    IsManual = 'IsManual',
    ExternalCustomerId = 'ExternalCustomerId',
    CustomerFirstName = 'CustomerFirstName',
    CustomerLastName = 'CustomerLastName',
    SettlementDate = 'SettlementDate',
    PayoutReference = 'PayoutReference',
    SettlementReference = 'SettlementReference',
    ActionReference = 'ActionReference',
    Period = 'Period',
    SettlementId = 'SettlementId',
    PartnerId = 'PartnerId',
    DebtFromPreviousSettlement = 'DebtFromPreviousSettlement',
    Adjustments = 'Adjustments',
    Purchases = 'Purchases',
    QuantityPurchases = 'QuantityPurchases',
    Returns = 'Returns',
    QuantityReturns = 'QuantityReturns',
    PurchaseAmountAdjustments = 'PurchaseAmountAdjustments',
    QuantityPurchaseAmountAdjustments = 'QuantityPurchaseAmountAdjustments',
    ManualPurchases = 'ManualPurchases',
    QuantityManualPurchases = 'QuantityManualPurchases',
    ManualReturns = 'ManualReturns',
    QuantityManualReturns = 'QuantityManualReturns',
    ManualPurchaseAmountAdjustments = 'ManualPurchaseAmountAdjustments',
    QuantityManualPurchaseAmountAdjustments = 'QuantityManualPurchaseAmountAdjustments',
    ServiceFee = 'ServiceFee',
    TransactionFee = 'TransactionFee',
    AddInvoiceFee = 'AddInvoiceFee',
    VAT = 'VAT',
    Commission = 'Commission',
    Kickback = 'Kickback',
    KickbackPercentage = 'KickbackPercentage',
    DirectPayment = 'DirectPayment',
    NotificationPrintCost = 'NotificationPrintCost',
    CreditedCustomerFeeCost = 'CreditedCustomerFeeCost',
    SettlementAmount = 'SettlementAmount',
    ReservationId = 'ReservationId',
    Status = 'Status',
    ReservationAmount = 'ReservationAmount',
    AvailableReservationAmount = 'AvailableReservationAmount',
    CurrencyCode = 'CurrencyCode',
    CustomerRegistrationNumber = 'CustomerRegistrationNumber',
    CustomerName = 'CustomerName',
    CustomerId = 'CustomerId',
    ArticleId = 'ArticleId',
    Description = 'Description',
    CreatedBy = 'CreatedBy',
    PlacedAt = 'PlacedAt',
    ExpiresAt = 'ExpiresAt',
    StoreCountry = 'StoreCountry',
    StoreId = 'StoreId',
    PaymentMethod = 'PaymentMethod',
    TotalAmount = 'TotalAmount',
    InitialAmount = 'InitialAmount',
    Currency = 'Currency',
    CustomerType = 'CustomerType',
    CustomerEmail = 'CustomerEmail',
    CostCenter = 'CostCenter',
    ReferencePerson = 'ReferencePerson',
    CommitmentAlias = 'CommitmentAlias',
    InvoiceDate = 'InvoiceDate',
    AccountType = 'AccountType',
    PaymentStatus = 'PaymentStatus',
    InvoiceState = 'InvoiceState',
    LeftToPay = 'LeftToPay',
    OrderId = 'OrderId',
}
