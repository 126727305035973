import { useAppState } from '../../store/appstate';
import { SessionState } from '../../store/session/sessionSlice';
import PermissionType from '../../types/PermissionType';

const hasPermissions = (type: string, operation: string, store?: string) => {
    const { user: { permissions = [] } = {} } = useAppState<SessionState>(s => s.session) ?? {};

    if (store) {
        return permissions.some(
            p =>
                (p.tag === `all-all-${type}` || p.tag === `all-${store}-${type}`) &&
                p.operation === operation
        );
    }

    return permissions.some(p => p.tag === `all-all-${type}` && p.operation === operation);
};

const hasWritePermissionsThatContains = (type: string) => {
    const { user: { permissions = [] } = {} } = useAppState<SessionState>(s => s.session) ?? {};

    return permissions.some(p => p.tag.includes(type) && p.operation === 'write');
};

const hasPermissionForTagsAndStore = (type: string, tags: string[], store: string) => {
    return tags.some(t => t === `all-${store}-${type}`);
};

const hasWriteCheckoutDetails = (store: string) =>
    hasPermissions(PermissionType.checkoutDetails, 'write', store);

const hasWriteCreditAccount = () => hasWritePermissionsThatContains(PermissionType.creditAccount);

const hasReadCustomerDetails = (store: string) =>
    hasPermissions(PermissionType.customerDetails, 'read', store);

const hasWriteCreditAccountForStore = (store: string) =>
    hasPermissions(PermissionType.creditAccount, 'write', store);

export {
    hasWriteCheckoutDetails,
    hasWriteCreditAccountForStore,
    hasWriteCreditAccount,
    hasPermissionForTagsAndStore,
    hasReadCustomerDetails,
    hasPermissions,
};
