export default [
    'newcapitaldebt',
    'first',
    'regular',
    'reminder',
    'finalreminder',
    'notificationpartiallypaid',
    'notificationfullypaid',
    'void',
    'collectionnotice',
    'debtcollectionexported',
];
