enum OrderEventType {
    AmountDisbursed = 'AmountDisbursed',
    NotificationCreated = 'NotificationCreated',
    NotificationDueDateChanged = 'NotificationDueDateChanged',
    NotificationSent = 'NotificationSent',
    PaymentMade = 'PaymentMade',
    ReturnMade = 'ReturnMade',
    AdjustmentMade = 'AdjustmentMade',
    RowsActivated = 'RowsActivated',
    RowsCanceled = 'RowsCanceled',
    RowsChanged = 'RowsChanged',
    RowsExpired = 'RowsExpired',
    RowsExpirationExtended = 'RowsExpirationExtended',
    StatusChangedToNotActivated = 'StatusChangedToNotActivated',
    StatusChangedToActivated = 'StatusChangedToActivated',
    StatusChangedToPartActivated = 'StatusChangedToPartActivated',
    StatusChangedToReturned = 'StatusChangedToReturned',
    StatusChangedToClosed = 'StatusChangedToClosed',
    StatusChangedToOnHold = 'StatusChangedToOnHold',
    StatusChangedToExpired = 'StatusChangedToExpired',
    Created = 'Created',
    ReleasedFromHold = 'ReleasedFromHold',
    Rejected = 'Rejected',
    Verified = 'Verified',
    Extended = 'Extended',
    AccountMoved = 'AccountMoved',
    OrderInvoiceAddressChanged = 'OrderInvoiceAddressChanged',
    Unknown = '',
}

export default OrderEventType;
