import React from 'react';
import styled from 'styled-components';
import getCustomerType from '../../../../services/helpers/customerTypeHelper';
import { StoreConfiguration } from '../../../../types/admin/StoreConfiguration';
import CustomerIcon from '../../../atoms/CustomerIcon/CustomerIcon';
import FlagIcon from '../../../atoms/FlagIcon/FlagIcon';

type StoreHeaderProps = {
    store: StoreConfiguration;
};

const StoreHeader: React.FC<StoreHeaderProps> = ({ store }: StoreHeaderProps) => {
    const { countryCode, salesSegment } = store;
    const customerIcon = salesSegment && getCustomerType(salesSegment);

    return (
        <Row>
            <Col>
                <Label>Store</Label>
                <Line>
                    <BoldText>{store.name}</BoldText>
                    {customerIcon && <CustomerIcon customerType={customerIcon} />}
                    {countryCode && <FlagIcon countryCode={countryCode} />}
                </Line>
            </Col>
            {/* <Col>
                <Label>Self reference</Label>
                <BoldText>{store.selfReference}</BoldText>
            </Col> */}
        </Row>
    );
};

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: ${props => props.theme.text.size.medium};
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${props => props.theme.layout.padding.medium};
    border-right: 0.2rem solid ${props => props.theme.colors.text.subtle};
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
`;
const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin-bottom: 0.3rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
`;

const Line = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export default StoreHeader;
