import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiSecret } from '../../../types/ApiSecret';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';

export interface ApiSecretsState {
    isSearching: boolean;
    isLoaded: boolean;
    searchResults: ResponseData<ApiSecret[]>;
    created?: CreatedApiSecret;
    isCreating: boolean;
    createdError?: ResponseError;
    isRemoving: boolean;
    removeError?: ResponseError;
    searchError?: ResponseError;
    isRemoved: boolean;
}

export interface CreatedApiSecret {
    clientId: string;
    apiSecret: string;
}

const initialState: ApiSecretsState = {
    isSearching: false,
    isLoaded: false,
    isCreating: false,
    searchResults: {} as ResponseData<ApiSecret[]>,
    created: undefined,
    createdError: undefined,
    isRemoving: false,
    removeError: undefined,
    isRemoved: false,
    searchError: undefined,
};

export const slice = createSlice({
    name: 'apiSecrets',
    initialState,
    reducers: {
        searchStarted: state => ({ ...state, isSearching: true }),
        searchSucceeded: (state, action: PayloadAction<ResponseData<ApiSecret[]>>) => ({
            ...state,
            isSearching: false,
            isLoaded: true,
            searchResults: action.payload,
        }),
        searchFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isSearching: false,
            searchError: action.payload,
        }),
        creating: state => ({ ...state, isCreating: true }),
        created: (state, action: PayloadAction<Record<string, string>>) => ({
            ...state,
            created: { clientId: action.payload.clientId, apiSecret: action.payload.apiSecret },
            isCreating: false,
        }),
        creatingFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isCreating: false,
            createdError: action.payload,
        }),
        createdCleared: state => ({
            ...state,
            created: undefined,
            isCreating: false,
            createdError: undefined,
        }),
        removing: state => ({ ...state, isRemoving: true, isRemoved: false }),
        removed: state => ({
            ...state,
            isRemoving: false,
            isRemoved: true,
        }),
        removeFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isRemoving: false,
            isRemoved: false,
            removeError: action.payload,
        }),
        removedCleared: state => ({
            ...state,
            isRemoving: false,
            isRemoved: false,
            removeError: undefined,
        }),
    },
});

export const {
    searchStarted,
    searchSucceeded,
    searchFailed,
    creating,
    created,
    creatingFailed,
    createdCleared,
    removing,
    removed,
    removeFailed,
    removedCleared,
} = slice.actions;

export default slice.reducer;
