import styled from 'styled-components';
import React from 'react';

import { Order } from '../../../types/order/Order';

import DateTime from '../../atoms/DateTime/DateTime';
import Status from '../../atoms/Status/Status';

import OrderExpiresIn from './OrderExpiresIn';
import OrderStatus from '../../../types/order/OrderStatus';
import StatusTypes from '../../../types/StatusTypes';
import { ConnectedStore } from '../../../types/ConnectedStore';
import ProductCode from '../../../types/ProductCode';
import Column from '../../atoms/Header/Column';
import CompactStoreLabel from '../../atoms/Store/CompactStoreLabel';

type Props = {
    icon?: string;
    order: Order;
    store: ConnectedStore | undefined;
};

const OrderDetailsHeader: React.FC<Props> = ({ icon, order, store }: Props) => {
    const renderStoreId = () => {
        if (!store) return <BoldText>{order.storeId}</BoldText>;

        return <CompactStoreLabel store={store} />;
    };

    const showExpiryDate =
        order.status === OrderStatus.NotActivated ||
        order.status === OrderStatus.PartActivated ||
        order.status === OrderStatus.OnHold;

    return (
        <Row>
            <Column>
                <Label>Reference</Label>
                <OrderNameContent>
                    <BoldText>{order.orderNumber || <NotSet>Not Set</NotSet>}</BoldText>
                    <Status type={StatusTypes.Order} status={order.status} />
                </OrderNameContent>
            </Column>
            {icon && <Column>{icon}</Column>}
            <Column>
                <Label>Created</Label>
                <Content>
                    <DateTime>{order.placedAt}</DateTime>
                </Content>
            </Column>
            {order.expiresAt && showExpiryDate && (
                <Column>
                    <Label>Expires</Label>
                    <Content>
                        <OrderExpiresIn orderExpire={order.expiresAt} />
                        <DateTime>{order.expiresAt}</DateTime>
                    </Content>
                </Column>
            )}
            <Column>
                <Label>Currency</Label>
                <Content>
                    <BoldText>{order.currency}</BoldText>
                </Content>
            </Column>

            <Column>
                <Label>Store</Label>
                <Content>{renderStoreId()}</Content>
            </Column>

            {order.productCode === ProductCode.SERVICE_INVOICE && (
                <Column>
                    <Label>Liability</Label>
                    <Content>🟡 Merchant liable for credit</Content>
                </Column>
            )}

            {order.productCode === ProductCode.NO_FRAUD_INVOICE && (
                <Column>
                    <Label>Liability</Label>
                    <Content>🟡 Merchant liable if contested</Content>
                </Column>
            )}
        </Row>
    );
};

const NotSet = styled.span`
    color: ${props => props.theme.colors.text.secondary};
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${props => props.theme.text.size.medium};
`;

const OrderNameContent = styled.div`
    margin-top: -0.4rem;
`;

const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin-bottom: 0.3rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const Content = styled.div`
    color: ${props => props.theme.colors.text.primary};
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 2rem;
`;

export default OrderDetailsHeader;
