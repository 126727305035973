import { createGlobalStyle, css } from 'styled-components';

const WalleyGlobalStyles = createGlobalStyle(
    () => css`
        @font-face {
            font-family: 'HKGrotesk-Regular';
            src: local('HK Grotesk'), local('HKGrotesk'),
                url('/fonts/hkgrotesk-regular/HKGrotesk-Regular.woff2') format('woff2'),
                url('/fonts/hkgrotesk-regular/HKGrotesk-Regular.woff') format('woff'),
                url('/fonts/hkgrotesk-regular/HKGrotesk-Regular.ttf') format('truetype');
            font-style: normal;
        }
        @font-face {
            font-family: 'HKGrotesk-Medium';
            src: local('HK Grotesk-Medium'), local('HKGrotesk-medium'),
                url('/fonts/hkgrotesk-medium/HKGrotesk-Medium.woff2') format('woff2'),
                url('/fonts/hkgrotesk-medium/HKGrotesk-Medium.woff') format('woff'),
                url('/fonts/hkgrotesk-medium/HKGrotesk-Medium.ttf') format('truetype');
            font-style: normal;
        }
        @font-face {
            font-family: 'HKGrotesk-Bold';
            src: local('HK Grotesk-Bold'), local('HKGrotesk-Bold'),
                url('/fonts/hkgrotesk-bold/HKGrotesk-Bold.woff2') format('woff2'),
                url('/fonts/hkgrotesk-bold/HKGrotesk-Bold.woff') format('woff'),
                url('/fonts/hkgrotesk-bold/HKGrotesk-Bold.ttf') format('truetype');
            font-style: normal;
        }

        :root {
            font-family: 'HKGrotesk-Regular', Helvetica, Arial;
            /*
                Default browser font-size is usually 16px.
                61.5% of 16px is 10px which makes it easier for us to calculate 1rem
            */
            font-size: 61.5%;
        }

        html {
            overflow-y: scroll;
        }

        body {
            color: ${props => props.theme.colors.text.primary};
            background: ${props => props.theme.colors.background};
            font-size: 1.5rem;
        }

        .announcekit-widget-badge {
            left: -0.8rem;
            top: -1.4rem;
        }

        .red-label {
            color: ${props => props.theme.colors.error} !important;
        }

        .red-input {
            border: 0.1rem solid ${props => props.theme.colors.error} !important;
        }

        .visx-axis-tick {
            text-transform: capitalize;
        }
    `
);

export default WalleyGlobalStyles;
