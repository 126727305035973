import React, { useEffect } from 'react';

import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import checkoutActions from '../../../store/checkout/actions';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { useAppState } from '../../../store/appstate';
import { CheckoutState } from '../../../store/checkout/checkoutSlice';
import { ErrorType } from '../../../types/response/ErrorCodes';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Spinner from '../../atoms/Spinner/Spinner';
import CustomErrorMessage from '../../atoms/Message/Error/CustomErrorMessage';

interface AbandonPaylinkModalProps {
    hide: () => void;
    privateId: string;
    theme: DefaultTheme;
}

const AbandonPaylinkModal: React.FC<AbandonPaylinkModalProps> = ({
    hide,
    privateId,
    theme,
}: AbandonPaylinkModalProps) => {
    const dispatch = useAppDispatch();

    const { abandonPaylinkFailed, isAbandoningPaylink, paylinkAbandoned } =
        useAppState<CheckoutState>(s => s.checkout);

    const onClick = () => {
        dispatch(checkoutActions.abandonPaylink(privateId));
    };

    useEffect(() => {
        if (paylinkAbandoned) hide();
    }, [paylinkAbandoned, hide]);

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Abort Pay Link</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content" noScroll>
                <Block>
                    The Pay Link will not be possible to complete after it has been aborted.
                </Block>
                <Block>Do you want to proceed?</Block>
            </ModalContainer>

            <ModalContainer position="error">
                {abandonPaylinkFailed && abandonPaylinkFailed.status !== 409 && (
                    <ErrorMessage
                        error={abandonPaylinkFailed}
                        errorHeader={ErrorType.DeletePaylink}
                    />
                )}

                {abandonPaylinkFailed && abandonPaylinkFailed.status === 409 && (
                    <CustomErrorMessage errorHeader={ErrorType.DeletePaylink}>
                        Something went wrong. Please refresh and try again.
                    </CustomErrorMessage>
                )}
            </ModalContainer>

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button
                        disabled={
                            (isAbandoningPaylink && !abandonPaylinkFailed) || paylinkAbandoned
                        }
                        onClick={hide}
                        large
                    >
                        {abandonPaylinkFailed ? 'Close' : 'No'}
                    </Button>

                    {!abandonPaylinkFailed && (
                        <Button
                            tabIndex={0}
                            onClick={onClick}
                            primary
                            large
                            disabled={isAbandoningPaylink || paylinkAbandoned}
                        >
                            {isAbandoningPaylink ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : (
                                <span>Yes</span>
                            )}
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const Block = styled.div`
    margin-top: 1rem;
`;

export default withTheme(AbandonPaylinkModal) as React.ComponentType<
    Omit<AbandonPaylinkModalProps, 'theme'>
>;
