import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component, ErrorInfo } from 'react';
import 'normalize.css';
import styled from 'styled-components';
import { lighten } from 'polished';
import FileDamageFillIcon from 'remixicon-react/FileDamageFillIcon';
import Logger from './services/logging/logger';

interface State {
    hasError: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    children: React.ReactNode;
}

export default class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true } as unknown as State;
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Logger.error('An uncaught error occured', {
            error: error.message,
            stacktrace: error.stack,
            componentStack: errorInfo.componentStack,
        });
    }

    public goBack = () => {
        window.location.href = '/orders';
    };

    public render() {
        if (this.state.hasError) {
            return (
                <Container>
                    <Title>
                        <Circle>
                            <FileDamageFillIcon size={45} color="#f2b94c" />
                        </Circle>
                    </Title>

                    <Title>Oh my!</Title>

                    <TextContainer>
                        It&apos;s not you, it&apos;s us. Please refresh the page or go back by
                        hitting the button below.
                    </TextContainer>
                    <RefreshButton onClick={() => this.goBack()} type="button">
                        Take me back
                    </RefreshButton>
                </Container>
            );
        }

        return this.props.children;
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-style: normal;
    font-size: 1.5rem;
    font-family: 'HKGrotesk-Regular', Helvetica, Arial;
    background-color: #01030b;
    color: white;
`;

const Title = styled.div`
    font-size: 4rem;
    margin: 1rem 0 0 0;
    font-family: 'HKGrotesk-Medium', Helvetica, Arial;
`;

const Circle = styled.div`
    border-radius: 50%;
    background: #272e3c;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TextContainer = styled.div`
    width: 45rem;
    text-align: center;
    margin: 2rem 0 2rem 0;
`;

const RefreshButton = styled.button`
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 0.4rem;
    min-width: 9rem;
    border: 0.15rem solid #3d388b;
    background: #3d388b;
    color: #ffffff;
    font-family: 'HKGrotesk-Medium', Helvetica, Arial;
    padding: 0.5rem;

    &:hover {
        background: ${lighten(0.08, '#3d388b')};
    }
`;
