import { getCustomvalueDecorator, getRelativeLabelFromRelativeValue } from './dateTimeFormats';

const createFilterMap = (filters: string[], filter: Map<string, string[]>) => {
    return filters.reduce((map, filterString) => {
        const [key, value] = filterString.split(':');
        let parsedJson;
        try {
            parsedJson = JSON.parse(value);
        } catch (error) {
            parsedJson = [] as Array<string>;
        }
        return map.set(key, parsedJson);
    }, filter);
};

const parseFilters = (key: string, filter: Map<string, string[]>) => {
    return filter.has(key) ? filter.get(key) || [] : [];
};

const getInitialRelativeState = (
    queryValue: string[],
    label: string,
    ranges: { name: string; label: string; value: string }[] | undefined,
    relativeType: string | undefined
) => {
    if (queryValue.length === 2 || queryValue.length === 0 || !ranges || !relativeType) {
        return {
            selected: `${label}-today`,
            customValue: 1,
            customDecorator: 'Days',
            to: '',
            from: '',
            relative: '0d',
        };
    }

    const relativeValueInArray = ranges.find(x => x.value === queryValue[0]);

    if (!relativeValueInArray) {
        const { customValue, customDecorator } = getCustomvalueDecorator(queryValue[0]);
        const customLabel = getRelativeLabelFromRelativeValue(relativeType, queryValue[0]);

        return {
            selected: customLabel !== '' && customLabel !== 'All' ? `${label}-custom` : customLabel,
            customValue,
            customDecorator,
            to: '',
            from: '',
            relative: queryValue[0] !== '' ? queryValue[0] : '0d',
        };
    }

    return {
        selected: `${label}-${relativeValueInArray.name}`,
        customValue: 1,
        customDecorator: 'Days',
        to: '',
        from: '',
        relative: queryValue[0] !== '' ? queryValue[0] : '0d',
    };
};

export { createFilterMap, parseFilters, getInitialRelativeState };
