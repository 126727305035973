/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { OrderArticleRow } from '../../../types/order/OrderArticleRow';
import Button from '../../atoms/Button/Button';
import {
    descriptionValidationConfig,
    articleNumberValidationConfig,
    quantityValidationConfig,
    vatRateValidationConfig,
    priceValidationConfig,
} from '../../../types/ValidationTypes';
import OrderArticleStatus from '../../../types/order/OrderArticleStatus';
import GridInput from '../../atoms/Input/GridInput';
import FormRow from '../../atoms/Form/FormRow';
import OrderRowType from '../../../types/order/OrderRowTypes';
import calculateExcludedVatAmount from '../../../services/helpers/numberCalculations';
import ValidationError from '../../atoms/Validation/ValidationError';

interface AddDiscountOrFeeProps {
    handleClose: () => void;
    handleAddProduct: (row: OrderArticleRow, productData: Map<string, string | number>) => void;
    type: string;
    defaultVat: number;
}

interface InputFields {
    articleNumber: string;
    description: string;
    price: number;
    vatRate: number;
    quantity: number;
}

const AddDiscountOrFee: React.FC<AddDiscountOrFeeProps> = ({
    handleClose,
    handleAddProduct,
    type,
    defaultVat,
}: AddDiscountOrFeeProps) => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<InputFields>({
        mode: 'onSubmit',
    });

    const descriptionInputRef = useRef<HTMLInputElement | null>();

    const onSubmit = (form: InputFields) => {
        const newArticleRow: OrderArticleRow = {
            ...form,
            price:
                type === OrderRowType.Discount
                    ? -Math.abs(getValues('price'))
                    : Math.abs(getValues('price')),
            vatAmount:
                type === OrderRowType.Discount
                    ? -calculateExcludedVatAmount(form.price, form.quantity, form.vatRate)
                    : calculateExcludedVatAmount(form.price, form.quantity, form.vatRate),
            status: OrderArticleStatus.Unknown,
            addedAt: new Date(),
            rowId: `${new Date().valueOf()}`,
            delivered: false,
            isExpanded: false,
            isRemoved: false,
            isAdded: true,
            isChanged: true,
            isCrossedOver: false,
        };
        handleAddProduct(newArticleRow, new Map<string, string | number>(Object.entries(form)));
        handleClose();
    };

    useEffect(() => {
        if (descriptionInputRef && descriptionInputRef.current) {
            descriptionInputRef.current.focus();
        }
    }, []);

    const description = register('description', descriptionValidationConfig);
    const articleNumber = register('articleNumber', articleNumberValidationConfig);
    const price = register('price', priceValidationConfig);
    const vatRate = register('vatRate', vatRateValidationConfig);
    const quantity = register('quantity', quantityValidationConfig);

    return (
        <AlignedContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Add {type}</h3>
                <Grid>
                    <FormRow first>
                        <GridInput
                            background
                            id="description"
                            placeholder="Description"
                            type="text"
                            setRef={description.ref}
                            name={description.name}
                            onChange={description.onChange}
                            maxLength={50}
                            validationError={!!errors.description}
                            autoComplete="on"
                        />
                    </FormRow>
                    <FormRow last>
                        <GridInput
                            background
                            id="price"
                            type="number"
                            placeholder="Amount (incl. VAT)"
                            setRef={price.ref}
                            name={price.name}
                            onChange={price.onChange}
                            validationError={!!errors.price}
                            step="0.01"
                            min={0}
                        />
                        <GridInput
                            background
                            id="vatRate"
                            type="number"
                            placeholder="VAT rate"
                            setRef={vatRate.ref}
                            name={vatRate.name}
                            onChange={vatRate.onChange}
                            validationError={!!errors.vatRate}
                            defaultValue={defaultVat}
                            step="0.01"
                            max={100}
                            min={0}
                        />
                    </FormRow>
                    <GridInput
                        background
                        id="quantity"
                        type="number"
                        placeholder="Quantity"
                        setRef={quantity.ref}
                        name={quantity.name}
                        onChange={quantity.onChange}
                        validationError={!!errors.quantity}
                        defaultValue={1}
                        min={1}
                        max={99999999}
                        hidden
                    />
                    <GridInput
                        background
                        id="articleNumber"
                        placeholder="Article Number"
                        type="text"
                        setRef={articleNumber.ref}
                        name={articleNumber.name}
                        onChange={articleNumber.onChange}
                        validationError={!!errors.articleNumber}
                        defaultValue={type}
                        hidden
                    />
                </Grid>

                <ValidationError>
                    <p>{errors.description && errors.description.message}</p>
                    <p>{errors.articleNumber && errors.articleNumber.message}</p>
                    <p>{errors.quantity && errors.quantity.message}</p>
                    <p>{errors.price && errors.price.message}</p>
                    <p>{errors.vatRate && errors.vatRate.message}</p>
                </ValidationError>

                <Buttons>
                    <StyledButton type="button" large onClick={handleClose}>
                        Cancel
                    </StyledButton>
                    <StyledButton type="submit" large primary>
                        Add
                    </StyledButton>
                </Buttons>
            </form>
        </AlignedContainer>
    );
};

const Grid = styled.div`
    width: 75%;
`;

const AlignedContainer = styled.div`
    width: 100%;
    text-align: left;
`;

const Buttons = styled.div`
    margin-top: 0.5rem;
    display: flex;
    > * {
        &:last-child {
            margin-left: 0.5rem;
        }
    }
`;

const StyledButton = styled(Button)`
    min-width: 14rem;
`;

export default AddDiscountOrFee;
