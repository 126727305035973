/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Button from '../../atoms/Button/Button';
import { OrderArticleRow } from '../../../types/order/OrderArticleRow';
import {
    descriptionValidationConfig,
    articleNumberValidationConfig,
    quantityValidationConfig,
    priceValidationConfig,
    vatRateValidationConfig,
} from '../../../types/ValidationTypes';
import FormRow from '../../atoms/Form/FormRow';
import GridInput from '../../atoms/Input/GridInput';
import OrderRowType from '../../../types/order/OrderRowTypes';
import calculateExcludedVatAmount from '../../../services/helpers/numberCalculations';
import ValidationError from '../../atoms/Validation/ValidationError';

interface EditOrderRowProps {
    data: OrderArticleRow;
    originalRow: OrderArticleRow;
    handleCancel: (row: OrderArticleRow) => void;
    handleEditProduct: (row: OrderArticleRow, productData: Map<string, string | number>) => void;
}

interface InputFields {
    articleNumber: string;
    description: string;
    price: number;
    vatRate: number;
    quantity: number;
}

const EditOrderRow: React.FC<EditOrderRowProps> = ({
    data,
    originalRow,
    handleCancel,
    handleEditProduct,
}: EditOrderRowProps) => {
    const isDiscountOrFee =
        data.articleNumber === OrderRowType.Discount || data.articleNumber === OrderRowType.Fee;

    const convertIfNegative = (price: number) => {
        if (price < 0 && !isDiscountOrFee) return price;

        return Math.abs(price);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<InputFields>({
        defaultValues: {
            description: data.description,
            articleNumber: data.articleNumber,
            quantity: data.quantity,
            price: convertIfNegative(data.price),
            vatRate: data.vatRate,
        },
    });

    const createDataForPatch = (form: InputFields) => {
        const values: Record<string, unknown> = { ...originalRow };

        const editedValues = Object.entries(form).filter(([key, value]) => values[key] !== value);

        return new Map<string, string | number>(editedValues);
    };

    const onSubmit = (form: InputFields) => {
        const getValue = <T,>(x: T, y: T) => (x !== y && x !== undefined ? x : y);
        const articleNumber = getValue(form.articleNumber, data.articleNumber);
        const quantity = getValue(form.quantity, data.quantity);
        const editedRow: OrderArticleRow = {
            ...data,
            description: getValue(form.description, data.description),
            articleNumber,
            quantity,
            vatAmount:
                articleNumber === OrderRowType.Discount
                    ? -calculateExcludedVatAmount(form.price, quantity, form.vatRate)
                    : calculateExcludedVatAmount(form.price, quantity, form.vatRate),
            price:
                articleNumber === OrderRowType.Discount
                    ? -Math.abs(getValue(form.price, data.price))
                    : getValue(form.price, data.price),
            vatRate: getValue(form.vatRate, data.vatRate),
            isExpanded: false,
            originalPrice: data.originalPrice,
            isChanged:
                form.description !== data.description ||
                form.articleNumber !== data.articleNumber ||
                form.quantity !== data.quantity ||
                form.price !== data.price ||
                form.vatRate !== data.vatRate,
        };

        handleEditProduct(editedRow, createDataForPatch(form));
    };

    const renderTitle = (articleNumber: string) => {
        switch (articleNumber) {
            case OrderRowType.Discount:
                return 'discount';
            case OrderRowType.Fee:
                return 'fee';
            default:
                return 'article';
        }
    };

    const description = register('description', descriptionValidationConfig);
    const articleNumber = register('articleNumber', articleNumberValidationConfig);
    const price = register('price', priceValidationConfig);
    const vatRate = register('vatRate', vatRateValidationConfig);
    const quantity = register('quantity', quantityValidationConfig);

    useEffect(() => {
        const descElement = document.getElementById('description');

        if (descElement) {
            descElement.focus();
        }
    }, []);

    return (
        <AlignedContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Edit {data.articleNumber && renderTitle(data.articleNumber)}</h3>
                <Grid>
                    <FormRow first>
                        <GridInput
                            background
                            id="description"
                            placeholder="Description"
                            type="text"
                            defaultValue={data.description}
                            setRef={description.ref}
                            name={description.name}
                            onChange={description.onChange}
                            maxLength={50}
                            validationError={!!errors.description}
                        />
                        <GridInput
                            hidden={isDiscountOrFee}
                            background
                            id="articleNumber"
                            placeholder="Article number"
                            defaultValue={data.articleNumber}
                            type="text"
                            setRef={articleNumber.ref}
                            name={articleNumber.name}
                            onChange={articleNumber.onChange}
                            validationError={!!errors.articleNumber}
                            maxLength={50}
                        />
                    </FormRow>
                    <FormRow last>
                        <GridInput
                            background
                            id="price"
                            type="number"
                            placeholder="Price"
                            defaultValue={data.price}
                            setRef={price.ref}
                            name={price.name}
                            onChange={price.onChange}
                            validationError={!!errors.price}
                            step="0.01"
                        />
                        <GridInput
                            background
                            id="vatRate"
                            type="number"
                            placeholder="VAT Rate"
                            defaultValue={data.vatRate}
                            setRef={vatRate.ref}
                            name={vatRate.name}
                            onChange={vatRate.onChange}
                            validationError={!!errors.vatRate}
                            step="0.01"
                            max={100}
                            min={0}
                        />

                        <GridInput
                            hidden={isDiscountOrFee}
                            background
                            id="quantity"
                            type="number"
                            placeholder="Quantity"
                            defaultValue={data.quantity}
                            validationError={!!errors.quantity}
                            setRef={quantity.ref}
                            name={quantity.name}
                            onChange={quantity.onChange}
                            min={1}
                            max={99999999}
                        />
                    </FormRow>
                </Grid>
                <ValidationError>
                    <p>{errors.description && errors.description.message}</p>
                    <p>{errors.articleNumber && errors.articleNumber.message}</p>
                    <p>{errors.quantity && errors.quantity.message}</p>
                    <p>{errors.price && errors.price.message}</p>
                    <p>{errors.vatRate && errors.vatRate.message}</p>
                </ValidationError>

                <Buttons>
                    <StyledButton type="button" onClick={() => handleCancel(data)} large>
                        Cancel
                    </StyledButton>
                    <StyledButton type="submit" primary large>
                        Apply
                    </StyledButton>
                </Buttons>
            </form>
        </AlignedContainer>
    );
};

const Grid = styled.div`
    width: 75%;
`;

const AlignedContainer = styled.div`
    width: 100%;
    text-align: left;
`;

const Buttons = styled.div`
    margin-top: 0.5rem;
    display: flex;
    > * {
        &:last-child {
            margin-left: 0.5rem;
        }
    }
`;

const StyledButton = styled(Button)`
    min-width: 14rem;
`;

export default EditOrderRow;
