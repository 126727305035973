import React from 'react';
import { DefaultTheme, withTheme } from 'styled-components';
import FilterTerm from '../../../types/filter/FilterTerm';
import DateRange from '../../atoms/DateRange/DateRange';
import StoreTerm from '../../atoms/Term/StoreTerm';
import Term from '../../atoms/Term/Term';
import { parseFilters } from '../../../services/helpers/filterHelpers';
import RelativeOperationTypes from '../../../types/RelativeOperation';
import Flex from '../../atoms/Box/Flex';
import { ConnectedStore } from '../../../types/ConnectedStore';
import TermCategory from '../../../types/filter/TermCategory';

interface SearchTermsProps {
    filter: Map<string, string[]>;
    stores: ConnectedStore[];
    theme: DefaultTheme;
    onFilterChanged: (filters: Map<string, string[]>) => void;
}

const SearchTerms: React.FC<SearchTermsProps> = ({
    filter,
    stores,
    theme,
    onFilterChanged,
}: SearchTermsProps) => {
    const relativeRanges = [
        { name: 'today', label: 'Today', value: '0d' },
        {
            name: 'yesterday',
            label: 'Yesterday',
            value: 'yesterday',
        },
        {
            name: 'last7days',
            label: 'Last 7 days',
            value: '-7d',
        },
        {
            name: 'last30days',
            label: 'Last 30 days',
            value: '-30d',
        },
        {
            name: 'custom',
            label: 'Custom',
            value: '-1d',
        },
    ];

    const dateRanges = [
        {
            label: 'Created At',
            term: FilterTerm.CreatedAt,
        },
        {
            label: 'Completed At',
            term: FilterTerm.PaymentCompletedAt,
        },
    ];

    return (
        <Flex row gap={theme.layout.gap.xsmall} flexWrap="wrap">
            {dateRanges.map(x => (
                <DateRange
                    key={x.label}
                    maxDate={new Date()}
                    label={x.label}
                    onDateChange={(key, value) => onFilterChanged(filter.set(key ?? x.term, value))}
                    initialValue={parseFilters(x.term, filter)}
                    dateRangeType={x.term}
                    relativeRanges={relativeRanges}
                    relativeLabel={{
                        type: 'Last',
                        operation: RelativeOperationTypes.SUBTRACT,
                    }}
                />
            ))}

            <StoreTerm
                currentFilters={parseFilters(FilterTerm.StoreId, filter)}
                availableStores={stores}
                onTermFilterChange={(x, y) => onFilterChanged(filter.set(x, y))}
            />
            <Term
                category={TermCategory.PayLink}
                term={{
                    key: 'status',
                    values: [
                        { value: 'Pending', count: 0 },
                        { value: 'Completed', count: 0 },
                        { value: 'Aborted', count: 0 },
                        { value: 'Expired', count: 0 },
                        { value: 'CustomerIdentified', count: 0 },
                        { value: 'CommittedToPayment', count: 0 },
                    ],
                }}
                onTermFilterChange={(x, y) => onFilterChanged(filter.set(x, y))}
                currentFilters={parseFilters(FilterTerm.Status, filter)}
                showSearchbar={[]}
                availableStores={[]}
            />
        </Flex>
    );
};

export default withTheme(SearchTerms) as React.ComponentType<Omit<SearchTermsProps, 'theme'>>;
