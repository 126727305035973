import React from 'react';
import { useParams } from 'react-router-dom';
import useLogTimeSpentOnPage from '../../services/hooks/useLogTimeSpentOnPage';
import AccountDetails from './AccountDetails';

const AccountDetailsPage: React.FC = () => {
    const { accountId = '' } = useParams();

    document.title = `Merchant Hub - Account - ${accountId}`;

    useLogTimeSpentOnPage('accountDetails');

    return <AccountDetails accountId={accountId} />;
};

export default AccountDetailsPage;
