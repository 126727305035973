import styled, { DefaultTheme, withTheme } from 'styled-components';
import React, { ReactNode } from 'react';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

interface SearchDateButtonProps {
    modalToggled: boolean;
    onClear: () => void;
    onClick: () => void;
    filterExists: boolean;
    criteria: string;
    children: ReactNode;
    theme: DefaultTheme;
}

const SearchFilterButton: React.FC<SearchDateButtonProps> = ({
    modalToggled,
    onClear,
    onClick,
    filterExists,
    criteria,
    children,
    theme,
}: SearchDateButtonProps) => {
    return (
        <SearchDateButton modalToggled={modalToggled} filterExists={filterExists}>
            <SearchCriteriaButton
                filterExists={filterExists}
                modalToggled={modalToggled}
                onClick={onClick}
                type="button"
            >
                <CriteriaText modalToggled={modalToggled} filterExists={filterExists}>
                    <Filter>
                        {criteria}:{' '}
                        <FilterCriteria modalToggled={modalToggled} filterExists={filterExists}>
                            {children}
                        </FilterCriteria>
                    </Filter>
                    <ArrowDownSLineIcon size={theme.icon.size.small} />
                </CriteriaText>
            </SearchCriteriaButton>
            {filterExists && (
                <SearchClose
                    data-testid="clear-all-date-range"
                    modalToggled={modalToggled}
                    onClick={onClear}
                >
                    <CloseLineIcon size={theme.icon.size.small} />
                </SearchClose>
            )}
        </SearchDateButton>
    );
};

interface StyledButtonProps {
    modalToggled: boolean;
}

interface FilterCriteriaProps extends StyledButtonProps {
    filterExists: boolean;
}

interface SearchDateProps extends StyledButtonProps {
    filterExists: boolean;
}

const CriteriaText = styled.div<FilterCriteriaProps>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: ${props =>
        props.filterExists && !props.modalToggled ? '#888888' : props.theme.colors.black};
    color: ${props => (!props.modalToggled ? '#888888' : props.theme.colors.light)};
`;

const Filter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const FilterCriteria = styled.div<FilterCriteriaProps>`
    margin-left: ${props => props.theme.layout.margin.xxsmall};
    font-family: ${props => (props.filterExists ? 'HKGrotesk-Medium' : 'HKGrotesk-Regular')};
    color: ${props => (!props.modalToggled ? props.theme.colors.black : '')};
    color: ${props => (props.filterExists && !props.modalToggled ? props.theme.colors.black : '')};
`;

const Button = styled.button<StyledButtonProps>`
    font-size: ${props => props.theme.text.size.xsmall};
    display: inline-block;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    cursor: pointer;
    outline: none;
    width: max-content;
    border: none;
    margin: 0;
`;

const SearchCriteriaButton = styled(Button)<FilterCriteriaProps>`
    background: transparent;
    width: max-content;
    outline: none;
    &:hover {
        color: ${props => props.theme.colors.light};
    }
    margin: 0.3rem !important;
`;

const SearchClose = styled(Button)<StyledButtonProps>`
    background: ${props =>
        props.modalToggled ? props.theme.colors.light : props.theme.colors.button.medium};
    color: ${props => props.theme.colors.black};
    display: inline-block;
    margin: 0.3rem !important;
    padding: 0 1rem 1rem 0rem;
    border-radius: 0.2rem;
    width: 1.5rem;
    height: 1.5rem;
    align-self: center;
`;

const SearchDateButton = styled.div<SearchDateProps>`
    display: flex;
    background: ${props =>
        props.modalToggled ? props.theme.colors.black : props.theme.colors.button.light};
    color: ${props => props.theme.colors.light};
    padding: 0 !important;
    border-radius: 0.5rem;
    &:hover {
        background: ${props => props.theme.colors.black};
        color: ${props => props.theme.colors.light};
        transition: all 0.6s;
    }
    &:hover {
        ${CriteriaText} {
            color: ${props => props.theme.colors.light} !important;
        }
        ${FilterCriteria} {
            color: ${props => props.theme.colors.light} !important;
        }
    }
`;

export default withTheme(SearchFilterButton) as React.ComponentType<
    Omit<SearchDateButtonProps, 'theme'>
>;
