import React from 'react';
import MessageErrorContainer from '../../atoms/Container/MessageErrorContainer';
import EmptyHeader from '../../atoms/Header/EmptyHeader';
import CenterContent from '../../atoms/Layout/CenterContent';
import HeaderContent from '../../atoms/Layout/HeaderContent';
import MessageContainer from '../../atoms/Message/MessageContainer';
import ResponseError from '../../../types/response/ResponseError';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../types/response/ErrorCodes';

interface EmptyHeaderErrorMessageProps {
    error: ResponseError | undefined;
    errorType: ErrorType;
    keyWord: string;
}

const EmptyHeaderErrorMessage: React.FC<EmptyHeaderErrorMessageProps> = ({
    error,
    errorType,
    keyWord,
}: EmptyHeaderErrorMessageProps) => {
    return (
        <>
            <HeaderContent>
                <EmptyHeader />
            </HeaderContent>
            <MessageErrorContainer>
                <CenterContent>
                    {error ? (
                        <ErrorMessage error={error} errorHeader={errorType} />
                    ) : (
                        <MessageContainer type="Error">
                            <p>
                                Sorry, but we encountered a problem when getting those {keyWord}
                                details for you.
                            </p>
                            <p>Please refresh this page in the browser to try again.</p>
                        </MessageContainer>
                    )}
                </CenterContent>
            </MessageErrorContainer>
        </>
    );
};

export default EmptyHeaderErrorMessage;
