import CustomerType from '../../types/CustomerType';

const getCustomerType = (type: string) => {
    switch (type) {
        case 'B2C':
            return CustomerType.Person;
        case 'Private':
            return CustomerType.Private;
        case 'B2B':
            return CustomerType.Company;
        case 'Business':
            return CustomerType.Business;
        case 'PrivateFirm':
            return CustomerType.PrivateFirm;
        default:
            return undefined;
    }
};

export default getCustomerType;
