import { ReportColumnType } from '../../types/report/ReportColumnType';

export const mergedReportColumns = {
    'Customer Number': ReportColumnType.ExternalCustomerId,
    'First Name': ReportColumnType.CustomerFirstName,
    'Last Name': ReportColumnType.CustomerLastName,
    Amount: ReportColumnType.Amount,
    'Purchase Date': ReportColumnType.CreatedOnInPartnerTimeZone,
    'Transaction Date': ReportColumnType.TransactionDate,
    Reference: ReportColumnType.OrderNumber,
    'Invoice Number': ReportColumnType.InvoiceNumber,
    Type: ReportColumnType.TransactionType,
    'Settlement Date': ReportColumnType.SettlementDate,
    'Payout Reference': ReportColumnType.PayoutReference,
} as Record<string, ReportColumnType>;
