/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';
import ResponseError from '../../types/response/ResponseError';
import { Order } from '../../types/order/Order';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import { SearchTerm } from '../../types/response/SearchTerm';
import { Account } from '../../types/account/Account';

export interface SearchState {
    isSearching: boolean;
    searchResult: PagingResponseData<Order[]>;
    searchFailed: boolean;
    terms: SearchTerm[];
    error: ResponseError;
    phrase: string | undefined;
    searchCancelToken: CancelTokenSource;
    accountSearchCancelToken: CancelTokenSource;
    isCanceled: boolean;
    accountSearchCanceled: boolean;
    isSearchingAccounts: boolean;
    searchAccountsResult: PagingResponseData<Account[]>;
    searchAccountsError?: ResponseError;
}

const initialState: SearchState = {
    isSearching: false,
    searchResult: {} as PagingResponseData<Order[]>,
    searchFailed: false,
    terms: [],
    error: {} as ResponseError,
    phrase: undefined,
    searchCancelToken: {} as CancelTokenSource,
    accountSearchCancelToken: {} as CancelTokenSource,
    isCanceled: false,
    accountSearchCanceled: false,
    isSearchingAccounts: false,
    searchAccountsResult: {} as PagingResponseData<Account[]>,
    searchAccountsError: undefined,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        searchStarted: (
            state,
            action: PayloadAction<{ phrase: string; cancelToken: CancelTokenSource }>
        ) => {
            state.isSearching = true;
            state.isCanceled = false;
            state.phrase = action.payload.phrase;
            state.searchCancelToken = action.payload.cancelToken;
        },
        searchFailed: (state, action: PayloadAction<ResponseError>) => {
            state.error = action.payload;
            state.isSearching = false;
            state.isCanceled = false;
            state.searchFailed = true;
            state.searchResult = {} as PagingResponseData<Order[]>;
        },
        searchSucceeded: (state, action: PayloadAction<PagingResponseData<Order[]>>) => {
            state.searchResult = action.payload;
            state.isCanceled = false;
            state.isSearching = false;
            state.searchFailed = false;
        },
        searchCanceled: state => {
            state.isCanceled = true;
        },
        searchTermsSucceeded: (state, action: PayloadAction<SearchTerm[]>) => {
            state.terms = action.payload;
        },
        searchTermsFailed: state => {},
        searchAccountStarted: (
            state,
            action: PayloadAction<{ cancelToken: CancelTokenSource }>
        ) => {
            state.accountSearchCancelToken = action.payload.cancelToken;
            state.isSearchingAccounts = true;
            state.searchAccountsError = undefined;
            state.accountSearchCanceled = false;
        },
        searchAccountSucceeded: (state, action: PayloadAction<PagingResponseData<Account[]>>) => {
            state.isSearchingAccounts = false;
            state.searchAccountsResult = action.payload;
            state.accountSearchCanceled = false;
        },
        searchAccountFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearchingAccounts = false;
            state.searchAccountsError = action.payload;
            state.accountSearchCanceled = false;
        },
        searchAccountCanceled: state => {
            state.accountSearchCanceled = true;
        },
    },
});

export const {
    searchStarted,
    searchFailed,
    searchSucceeded,
    searchCanceled,
    searchTermsSucceeded,
    searchTermsFailed,
    searchAccountStarted,
    searchAccountSucceeded,
    searchAccountFailed,
    searchAccountCanceled,
} = searchSlice.actions;

export default searchSlice.reducer;
