const accessPermissions = ['userlist', 'grouplist', 'organization', 'organizationlist'];
const orderPermissions = ['orderdetails', 'accountdetails', 'customerdetails'];

const checkoutPermissions = ['checkoutdetails'];

const reportPermissions = ['settlementdetails', 'periodicreportdetails'];

const apiSecretPermissions = ['apisecret'];
const onboardingPermissions = ['onboarding'];
const storePermissions = ['store'];
const creditAccountPermissions = ['creditaccount'];

const dashboardPermissions = ['dashboard'];

const webhookPermissions = ['webhookendpoints'];

export default [
    {
        entity: 'Access Management',
        permissions: accessPermissions,
    },
    {
        entity: 'Order Management',
        permissions: orderPermissions,
    },
    {
        entity: 'Paylink Management',
        permissions: checkoutPermissions,
    },
    {
        entity: 'Report Management',
        permissions: reportPermissions,
    },
    {
        entity: 'Api key Management',
        permissions: apiSecretPermissions,
    },
    {
        entity: 'Onboarding Management',
        permissions: onboardingPermissions,
    },
    {
        entity: 'Store Management',
        permissions: storePermissions,
    },
    {
        entity: 'Dashboard Management',
        permissions: dashboardPermissions,
    },
    {
        entity: 'TeamSales Management',
        permissions: creditAccountPermissions,
    },
    {
        entity: 'Webhook Management',
        permissions: webhookPermissions,
    },
];
