import React, { ReactNode } from 'react';

interface MultistatusMessageProps {
    children: ReactNode;
}

const NoTranslateLabel: React.FC<MultistatusMessageProps> = ({
    children,
}: MultistatusMessageProps) => {
    return <span translate="no">{children}</span>;
};

export default NoTranslateLabel;
