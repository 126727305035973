import RelativeTime from '../../types/RelativeTime';

export interface DateFormats {
    yyyyMMdd: string;
    HHmm: string;
}

const formatDate = (date: Date): DateFormats => {
    const yyyyMMdd = new Intl.DateTimeFormat('sv', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    }).format(date);

    const HHmm = new Intl.DateTimeFormat('sv', { hour: '2-digit', minute: 'numeric' }).format(date);

    return { yyyyMMdd, HHmm };
};

const formatShortDate = (date: Date): string => {
    const yyyyMMdd = new Intl.DateTimeFormat('sv', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    }).format(date);

    return yyyyMMdd;
};

const formatMonth = (date: Date): string => {
    const month = new Intl.DateTimeFormat('en', {
        month: 'long',
    }).format(date);

    return month;
};

const formatLongDate = (date: Date): string => {
    const yyyyMMddHHmmss = new Intl.DateTimeFormat('sv', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: 'numeric',
        second: '2-digit',
    }).format(date);
    return yyyyMMddHHmmss;
};

const formatTime = (date: Date): string => {
    const HHmm = new Intl.DateTimeFormat('sv', { hour: '2-digit', minute: 'numeric' }).format(date);

    return HHmm;
};

const formatShortDateNumerical = (date: Date): string => {
    const yyyyMMdd = new Intl.DateTimeFormat('sv', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(date);

    return yyyyMMdd;
};

const cutRelativeDecoration = (decoration: string, value: string) => {
    return parseInt(value, 2) === 1 ? decoration.slice(0, -1) : decoration;
};

const splitRelative = (relativeValue: string) => {
    return relativeValue.split(/(\d+)/) ?? '';
};

const getRelativeLabelFromRelativeValue = (type: string, relativeValue: string) => {
    if (relativeValue === '') return '';
    if (relativeValue === RelativeTime.YESTERDAY) return 'Yesterday';

    const [, value, decorator] = splitRelative(relativeValue);

    if (value === '0') return 'Today';

    switch (decorator) {
        case 'd':
            return `${type} ${value} ${cutRelativeDecoration('days', value)}`;
        case 'm':
            return `${type} ${value} ${cutRelativeDecoration('months', value)}`;
        case 'w':
            return `${type} ${value} ${cutRelativeDecoration('weeks', value)}`;
        default:
            return 'All';
    }
};

const getCustomvalueDecorator = (relativeValue: string) => {
    const [, value, decorator] = splitRelative(relativeValue);
    const parsedValue = parseInt(value, 10);

    switch (decorator) {
        case 'd':
            return { customValue: parsedValue, customDecorator: 'Days' };
        case 'm':
            return { customValue: parsedValue, customDecorator: 'Months' };
        case 'w':
            return { customValue: parsedValue, customDecorator: 'Weeks' };
        default:
            return { customValue: 1, customDecorator: 'Days' };
    }
};

export {
    formatDate,
    formatShortDate,
    formatTime,
    formatLongDate,
    formatShortDateNumerical,
    getRelativeLabelFromRelativeValue,
    getCustomvalueDecorator,
    splitRelative,
    formatMonth,
};
