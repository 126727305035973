import { TableRowContent } from '../Layout/TableRowContent';

export interface NotificationRow extends TableRowContent {
    transactionId?: string;
    feeTransactions?: FeeTransaction[];
    type: string;
    articleNumber: string;
    description: string;
    price: number;
    quantity?: number;
    vatRate?: number;
    totalPrice: number;
    purchaseId?: string;
    orderReference?: string;
    transactionDate?: string;
    credited?: boolean;
    isCreditedOnThisNotification?: boolean;
}

export enum NotificationRowTypes {
    PURCHASE = 'Purchase',
    NOTIFICATIONFEE = 'NotificationFee',
    REMINDERFEE = 'ReminderFee',
    TEXTROW = 'TextRow',
}

export interface FeeTransaction {
    transactionId?: string;
    credited?: boolean;
}
