import styled from 'styled-components';

const ValidationError = styled.div`
    margin-top: 0.5rem;
    color: ${props => props.theme.colors.error};
    font-size: ${props => props.theme.text.size.xsmall};
    margin-right: 2rem;
`;

export default ValidationError;
