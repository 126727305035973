/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { Order } from '../../types/order/Order';
import { TypedColumn } from '../../types/TypedColumn';

const checkMatchByLowercase = (first: string, second: string) => {
    return first.toString().toLowerCase() === second.toLowerCase();
};

const getOrderHeaderColumns = (columns: string[], allHeaders: ColumnDef<Order>[]) => {
    return columns.map(x =>
        allHeaders.find(y => checkMatchByLowercase(y.header as string, x))
    ) as TypedColumn<any>[];
};

export { getOrderHeaderColumns, checkMatchByLowercase };
