/* eslint-disable no-nested-ternary */
import { css } from 'styled-components';
import { darken, lighten } from 'polished';

export type StyledProps = {
    isRowClickable?: boolean;
    isSortedDesc?: boolean;
    isCrossedOver?: boolean;
    isExpanded?: boolean;
    isModified?: boolean;
    isSortable?: boolean;
    isNotificationFee?: boolean;
};

export const Container = () => css`
    border-collapse: collapse;
    width: 100%;
`;

export const Tr = () => css<StyledProps>`
    font-size: ${props => props.theme.text.size.small};
    border-bottom: solid 0.1rem ${props => props.theme.colors.border};

    &:last-child {
        border-bottom: none;
    }
    ${props =>
        props.isExpanded &&
        css`
            background: ${props.theme.colors.subtle.lighter};
        `}
    ${props =>
        props.isRowClickable
            ? css`
                  &:hover {
                      cursor: pointer;
                      background: ${props.theme.colors.accent};
                  }
              `
            : props.isCrossedOver
            ? css`
                  background: ${darken(0.03, props.theme.colors.table.row)};
                  text-decoration: line-through;
                  color: ${lighten(0.4, props.theme.colors.text.primary)};
                  &:nth-child(even) {
                      background: ${darken(0.03, props.theme.colors.table.row)};
                  }
              `
            : props.isModified
            ? css`
                  background: ${darken(0.03, props.theme.colors.table.row)};
                  &:nth-child(even) {
                      background: ${darken(0.03, props.theme.colors.table.row)};
                  }
              `
            : props.isNotificationFee
            ? css`
                  background: ${darken(0.03, props.theme.colors.table.row)};
                  text-decoration: line-through;
                  color: ${lighten(0.4, props.theme.colors.text.primary)};
                  &:nth-child(even) {
                      background: black};
                  }
              `
            : css``}
`;

export const Th = () => css<StyledProps>`
    text-align: left;
    color: ${props => props.theme.colors.subtle.regular};
    font-size: ${props => props.theme.text.size.small};
    padding: ${props => `${props.theme.layout.padding.small} ${props.theme.layout.padding.medium}`};
    user-select: none;
    font-weight: 300;
    font-family: ${props => props.theme.text.font.bold};

    &:first-child {
        border-top-left-radius: 0.5rem;
    }

    &:last-child {
        border-top-right-radius: 0.5rem;
    }

    &:hover {
        ${props => props.isSortable && 'cursor: pointer;'}
    }
`;

export const Td = () => css`
    padding: ${props => `${props.theme.layout.padding.large} ${props.theme.layout.padding.large}`};
    position: relative;
`;

export const Results = () => css``;

export const Pagination = () => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 ${props => props.theme.layout.padding.medium};
    background: ${props => props.theme.colors.subtle.light};
    border-radius: 0 0 0.5rem 0.5rem;
`;

export const NextPrevButton = () => css`
    width: 2.8rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    border: solid 0.1rem ${props => props.theme.colors.border};
    background-color: ${props => props.theme.colors.subtle.light};
    cursor: pointer;
`;
