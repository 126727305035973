import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import GridInput from './GridInput';

interface SelectableNumberInputProps {
    selectValues: string[];
    handleChange: (value: number, decorator: string) => void;
    changeValue: { decorator: string; value: number };
    focus: boolean;
    dataTestId?: string;
    inGrid?: boolean;
    theme: DefaultTheme;
}
type InputType = SelectableNumberInputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const SelectableNumberInput: React.FC<InputType> = ({
    name,
    id,
    selectValues,
    disabled,
    focus,
    handleChange,
    changeValue,
    placeholder,
    max,
    min,
    onKeyDown,
    dataTestId,
    inGrid,
    theme,
}: InputType) => {
    const [dropdown, setDropdown] = useState(false);
    const { value, decorator } = changeValue;

    const handleDecoratorChange = (decoratorValue: string) => {
        handleChange(value, decoratorValue);
        setDropdown(!dropdown);
    };

    const handleInputChange = (inputValue: number) => {
        const maxValue = max ?? 1000;

        if (!Number.isNaN(inputValue) && inputValue <= (maxValue as number)) {
            handleChange(inputValue, decorator);
        }
    };

    const handleDropDownChange = () => {
        if (!disabled) {
            setDropdown(!dropdown);
        }
    };

    useEffect(() => {
        const input = id && document.getElementById(`input_${id}`);

        if (focus && input) {
            input.focus();
        }
    });

    return (
        <Wrapper id={id} focus={focus} inGrid={inGrid}>
            <GridInput
                id={`input_${id}`}
                name={name}
                value={value}
                type="number"
                min={min}
                max={max}
                placeholder={placeholder}
                onChange={e => handleInputChange(e.target.valueAsNumber)}
                disabled={disabled}
                noFocusBorder
                placeholderRaised
                onKeyDown={onKeyDown}
                dataTestId={dataTestId}
            />
            <DropDown isDisabled={disabled} dropdown={dropdown} onClick={handleDropDownChange}>
                {decorator} <ArrowDownSLineIcon size={theme.icon.size.small} />
            </DropDown>
            <Options dropdown={dropdown}>
                {selectValues.map((x: string) => (
                    <Option key={x} value={x} onClick={() => handleDecoratorChange(x)}>
                        {x}
                    </Option>
                ))}
            </Options>
        </Wrapper>
    );
};

export default withTheme(SelectableNumberInput) as React.ComponentType<Omit<InputType, 'theme'>>;

interface DropdownProps {
    dropdown: boolean;
    isDisabled?: boolean;
}
interface OptionsProps {
    dropdown: boolean;
}

interface WrapperProps {
    focus: boolean;
    inGrid?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    ${props =>
        props.focus ? `border: 0.2rem solid ${props.theme.colors.primary}; !important` : ''}
    border: 0.1rem solid ${props => props.theme.colors.border};
    ${props => props.inGrid && `border-bottom: none;`}
    ${props => props.inGrid && `border-top: none;`}
    display: flex;
    flex-direction: row;
    height: 6rem;
    z-index: 7;
    position: relative;
    width: 100%;
`;

const DropDown = styled.div<DropdownProps>`
    cursor: default;
    border-left: 0.1rem solid ${props => props.theme.colors.border};
    width: 15rem;
    display: flex;
    padding: 1rem;
    z-index: 7;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    ${props =>
        props.isDisabled
            ? `
         opacity: 0.5;
         -webkit-user-select: none;
         -khtml-user-select: none;
         -moz-user-select: none;
         -o-user-select: none;
         user-select: none;
    `
            : ''}
`;

const Options = styled.div<OptionsProps>`
    box-shadow: 0.2rem 0.2rem 0.5rem 0rem rgb(132 132 132 / 20%);
    background-color: ${props => props.theme.background};
    flex-direction: column;
    position: absolute;
    width: 11rem;
    display: flex;
    top: 6.3rem;
    right: 0;
    z-index: 7;
    display: ${props => (props.dropdown ? 'block' : 'none')};
`;

const Option = styled.option`
    padding: 1rem;
    z-index: 7;

    &:hover {
        background-color: #fcf3fc;
    }
`;
