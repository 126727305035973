import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';

type Props = {
    orderExpire: Date;
    theme: DefaultTheme;
};

const OrderExpiresIn: React.FC<Props> = ({ orderExpire, theme }: Props) => {
    const timeDifference = () => {
        const minDayWarning = 20; // Make this dynamic?
        const now = new Date();
        const day = 1000 * 60 * 60 * 24;
        const hour = 1000 * 60 * 60;
        const minute = 1000 * 60;
        const differenceMs = Math.abs(orderExpire.valueOf() - now.valueOf());
        const hourCheck = Math.round(differenceMs / hour);
        const minuteCheck = Math.round(differenceMs / minute);
        const dayCheck = Math.round(differenceMs / day);
        const hasExpired = now.valueOf() > orderExpire.valueOf();
        if (dayCheck < minDayWarning && !hasExpired) {
            if (hourCheck >= 1 && hourCheck < 24) {
                return `In ${hourCheck} hours`;
            }
            if (minuteCheck < 60) {
                return `In ${minuteCheck} minutes`;
            }
            return `In ${dayCheck} days`;
        }
        return hasExpired ? `${dayCheck} days overdue` : '';
    };
    return (
        <ExpireTime theme={theme} data-testid="expires-in">
            {timeDifference()}
        </ExpireTime>
    );
};

interface ExpireTimeProps {
    theme: DefaultTheme;
}

const ExpireTime = styled.div<ExpireTimeProps>`
    font-family: ${props => props.theme.text.font.medium};
`;

export default withTheme(OrderExpiresIn) as React.ComponentType<Omit<Props, 'theme'>>;
