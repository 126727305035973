import React, { ReactNode } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import CheckboxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon';

interface SuccessMessageProps {
    children: ReactNode;
    theme: DefaultTheme;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
    children,
    theme,
}: SuccessMessageProps) => {
    return (
        <SuccessContainer>
            <SuccessMessageContainer>
                <SuccessMessageItem>
                    <CheckboxCircleFillIcon
                        color={theme.colors.success}
                        size={theme.icon.size.large}
                    />
                </SuccessMessageItem>
                <SuccessMessageItem>{children}</SuccessMessageItem>
            </SuccessMessageContainer>
        </SuccessContainer>
    );
};

const SuccessMessageContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-top: 2.5rem;
`;

const SuccessMessageItem = styled.div`
    display: flex;
    margin: 0 0.25em;
    align-self: center;
`;

const SuccessContainer = styled.div`
    justify-content: center;
    max-width: 40rem;
    margin-top: ${props => props.theme.layout.padding.medium};
`;

export default withTheme(SuccessMessage) as React.ComponentType<Omit<SuccessMessageProps, 'theme'>>;
