import React, { useState, KeyboardEvent, useEffect, useCallback, useRef } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import DecoratorInput from '../Input/DecoratorInput';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import { ConnectedStore } from '../../../types/ConnectedStore';
import Flex from '../Box/Flex';
import Toggle from '../Toggle/Toggle';
import useRenderVisibleListScroll from '../../../services/hooks/useRenderVisibleListScroll';
import CheckBox from '../Checkbox/Checkbox';
import StoreLabel, { LabelTypes } from '../Store/StoreLabel';
import useOnScreen from '../Dropdown/useOnScreen';

interface StoreMultiSelectProps {
    theme: DefaultTheme;
    availableStores: ConnectedStore[];
    selectedStores: string[];
    onStoreChange: (selectedStores: string[]) => void;
}

type StoreItem = {
    key: string;
    value: string;
    metaData: ConnectedStore;
};

const StoreMultiSelect: React.FC<StoreMultiSelectProps> = ({
    theme,
    availableStores,
    selectedStores,
    onStoreChange,
}) => {
    const sortedStores = React.useMemo(
        () =>
            (availableStores ?? [])
                .map(
                    s =>
                        ({
                            key: 'storeId',
                            value: s.id,
                            metaData: s,
                        } as StoreItem)
                )
                .sort(
                    (a, b) =>
                        (availableStores?.indexOf(a.metaData) ?? -1) -
                        (availableStores?.indexOf(b.metaData) ?? -1)
                ),
        [availableStores]
    );

    const itemHeight = 30;
    const visibleItems = 20;
    const openRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { outsideIntersecion } = useOnScreen(containerRef);

    const [state, setState] = useState<Set<string>>(new Set<string>());
    const [search, setSearch] = useState<string>('');
    const [pushSearch, setPushSearch] = useState(false);
    const [listedStores, setListedStores] = useState<StoreItem[]>(sortedStores);
    const [isListedAllChecked, setIsListedAllChecked] = useState(false);
    const allChecked = sortedStores.filter(x => state.has(x.value));
    const onStoreFilterChangeCallback = useCallback(onStoreChange, []);

    const { scrollState, scrollPos, scrollItemHeight, resetScroll } = useRenderVisibleListScroll({
        visibleItems,
        itemHeight,
        openRef,
        items: listedStores,
    });

    const addStoreByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            const alreadyCheckedFiltered = listedStores.filter(x => !state.has(x.value));

            if (alreadyCheckedFiltered.length > 1) return;

            setState(new Set<string>([...state, ...listedStores.map(x => x.value)]));
            setSearch('');
            setPushSearch(true);
        }
    };

    const onChange = (e: HTMLInputElement) => {
        if (e.checked) {
            state.add(e.value);
            setState(new Set<string>(state));
            handleFilterChange();
            return;
        }
        state.delete(e.value);
        setState(new Set<string>(state));
        handleFilterChange();
    };

    const handleFilterChange = () => {
        const arr = Array.from(state);
        if (arr.length > 0) {
            onStoreChange(arr);
        } else {
            onStoreChange([]);
        }
    };

    const filterBySearch = (): StoreItem[] => {
        return sortedStores.filter(
            x =>
                x.value.includes(search) ||
                x.metaData.selfReference
                    ?.toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                (x.metaData.storeName &&
                    x.metaData.storeName.toLowerCase().includes(search.toLowerCase()))
        );
    };

    const handleListedChecked = () => {
        resetScroll();
        setIsListedAllChecked(!isListedAllChecked);
    };

    const renderStoreItem = (store: StoreItem, index: number) => {
        return (
            <Store
                $height={itemHeight}
                key={`${store.key}${store.value}`}
                style={scrollItemHeight(index)}
            >
                <CheckBox
                    dataTestId="store-checkbox-storeId"
                    id={`${store.key}${store.value}`}
                    value={store.value}
                    name={store.key}
                    checked={state.has(store.value)}
                    onChange={e => onChange(e.target)}
                    noMargin
                >
                    <StoreLabel
                        storeId={store.value}
                        storeName={store.metaData?.storeName}
                        countryCode={store.metaData?.countryCode}
                        selfReference={store.metaData?.selfReference}
                        customerSalesType={store.metaData?.customerSalesType}
                        labelType={LabelTypes.LABEL}
                    />
                </CheckBox>
            </Store>
        );
    };

    const renderStoreList = () => {
        if (!isListedAllChecked) {
            return (
                <StoreContentContainer
                    ref={openRef}
                    onScroll={scrollPos}
                    data-testid="store-options"
                >
                    {listedStores.slice(scrollState.start, scrollState.end).map((s, index) => {
                        return renderStoreItem(s, index);
                    })}
                </StoreContentContainer>
            );
        }

        return (
            <StoreContentContainer>
                {allChecked.map((x, index) => {
                    return renderStoreItem(x, index);
                })}
            </StoreContentContainer>
        );
    };

    useEffect(() => {
        if (pushSearch) {
            setPushSearch(false);
            handleFilterChange();
        }
    }, [pushSearch]);

    useEffect(() => {
        const decoratorInput = document.getElementById('storeId-searchFilter');

        if (decoratorInput) {
            decoratorInput.focus();
        }
    });

    useEffect(() => {
        const selectedFilters = selectedStores
            .map(f => {
                return sortedStores.find(
                    filterValue => filterValue.value.toLowerCase() === f.toLowerCase()
                );
            })
            .filter(f => f !== undefined)
            .map(f => f?.value ?? '');

        setState(new Set<string>(selectedFilters));

        if (selectedFilters.length !== selectedStores.length)
            onStoreFilterChangeCallback(selectedFilters);
    }, [selectedStores, sortedStores]);

    useEffect(() => {
        if (!search) setListedStores(sortedStores);
        if (!search && selectedStores.length === 0) return;

        const filteredBySearch = filterBySearch();

        setListedStores(filteredBySearch);
    }, [search, state]);

    useEffect(() => {
        if (selectedStores.length === 0) {
            setIsListedAllChecked(false);
        }
    }, [selectedStores]);

    return (
        <StoresContainer ref={containerRef} $offsetHeight={outsideIntersecion.bottom}>
            <Header>
                <DecoratorInput
                    dataTestId="store-searchbar"
                    type="text"
                    name="searchFilter"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                    placeholder="Search"
                    id={'storeId-searchFilter'}
                    decorator={<SearchLineIcon size={theme.icon.size.small} />}
                    onKeyDown={addStoreByEnter}
                />
                <StoresOptions row center justifyContent="flex-end ">
                    <Flex gap={theme.layout.gap.small} alignItems="center">
                        <ToggleLabel>Show selected</ToggleLabel>
                        <Toggle
                            data-testid="show-all-selected-store-items"
                            name="show-all-selected-store-items"
                            id="show-all-selected-store-items"
                            onChange={() => handleListedChecked()}
                            value="true"
                            disabled={allChecked.length === 0}
                            checked={isListedAllChecked}
                        />
                    </Flex>
                </StoresOptions>
                <Divider />
            </Header>
            {renderStoreList()}
        </StoresContainer>
    );
};

export default withTheme(StoreMultiSelect) as React.ComponentType<
    Omit<StoreMultiSelectProps, 'theme'>
>;

const StoresContainer = styled.div<{ $offsetHeight: number }>`
    background-color: #f9f9f9;
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
    padding: 2rem 0 0 0;
    width: 100%;
    height: ${props => `calc( 40rem - ${props.$offsetHeight}px )`};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const StoreContentContainer = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
`;

const Header = styled.div`
    padding: 0 2rem 0.5rem 2rem;
`;

const StoresOptions = styled(Flex)`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;
const Divider = styled.div`
    border-bottom: 0.1rem solid #dedede;
    background-color: ${props => props.theme.colors.light};
`;

const ToggleLabel = styled.span`
    font-size: 12px;
`;

const Store = styled.div<{ $height: number }>`
    position: absolute;
    height: ${props => props.$height}px;
    width: 100%;
    padding: 0 1rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
`;
