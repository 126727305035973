import React from 'react';
import styled, { css } from 'styled-components';

interface AutoHeightTransitionProps {
    expand: boolean;
    transitionTime: string;
    children: React.ReactNode;
}

const AutoHeightTransition: React.FC<AutoHeightTransitionProps> = ({
    expand,
    transitionTime,
    children,
}: AutoHeightTransitionProps) => {
    return (
        <Wrapper expand={expand} transitionTime={transitionTime}>
            <OverflowHidden>{children}</OverflowHidden>
        </Wrapper>
    );
};

const Wrapper = styled.div<AutoHeightTransitionProps>`
    transition: grid-template-rows ${props => props.transitionTime} ease-out;
    display: grid;
    grid-template-rows: 0fr;
    ${props =>
        props.expand &&
        css`
            transition: grid-template-rows ${props.transitionTime} ease-out;
            grid-template-rows: 1fr;
        `};
`;

const OverflowHidden = styled.div`
    overflow: hidden;
`;

export default AutoHeightTransition;
