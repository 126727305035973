enum OrderStatus {
    NotActivated = 'NotActivated',
    Activated = 'Activated',
    PartActivated = 'PartActivated',
    Closed = 'Closed',
    OnHold = 'OnHold',
    VerificationNeeded = 'VerificationNeeded',
    Returned = 'Returned',
    Expired = 'Expired',
    Unknown = '',
}

export default OrderStatus;
