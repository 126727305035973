type KeyGetter<T> = (item: T) => string;

export const groupBy = <T>(list: T[], keyGetter: KeyGetter<T>): Map<string, T[]> => {
    const map = new Map<string, T[]>();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

export default groupBy;
