const getRandomNumberBetween = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
};

const getRandomDaysToWaitTime = (min: number, max: number) => {
    return new Date(
        new Date().getTime() + getRandomNumberBetween(min, max) * 24 * 60 * 60 * 1000
    ).getTime();
};

export { getRandomNumberBetween, getRandomDaysToWaitTime };
