import React from 'react';
import styled from 'styled-components';

import groupsActions from '../../../../store/admin/groups/actions';
import { useAppState } from '../../../../store/appstate';

import { useManagementTags } from '../../../../services/hooks/useTags';
import usePermissions from '../../../../services/hooks/usePermissions';
import permissionGroups from '../../../../services/helpers/groupPermissions';

import { UserGroup } from '../../../../types/UserGroup';
import { GroupPermission } from '../../../../types/admin/GroupPermission';

import Card from '../../../atoms/Card/Card';
import CardBody from '../../../atoms/Card/CardBody';
import Spinner from '../../../atoms/Spinner/Spinner';
import { SessionOrganization } from '../../../../types/SessionUser';
import CheckBox from '../../../atoms/Checkbox/Checkbox';
import { useAppDispatch } from '../../../../store';
import { ColumnDef } from '@tanstack/react-table';
import Table from '../../../atoms/Table/Table';

const filterUncategorizedPermissions = (
    categories: string[],
    availablePermissions: Record<string, string[]>
) => {
    return Object.keys(availablePermissions).filter(permission => !categories.includes(permission));
};

interface PermissionsForGroupProps {
    organization: SessionOrganization;
}

const PermissionsForGroup: React.FC<PermissionsForGroupProps> = ({
    organization,
}: PermissionsForGroupProps) => {
    const dispatch = useAppDispatch();
    const { isModifyingPermissions, permissions: groupPermissions } = useAppState<UserGroup>(
        s => s.admin.groups.group
    );

    const availablePermissions = useAppState<Record<string, string[]> | undefined>(
        s => s.admin.groups.availablePermissions
    );

    const availableOperations = Array.from(
        new Set(Object.values(availablePermissions ?? {}).flat())
    );

    const permissionsIsAvailable = (permissions: string[], type: string) => {
        return (
            availablePermissions &&
            permissions
                .filter(x => availablePermissions[x])
                .some(x => availablePermissions[x].includes(type))
        );
    };

    const operations = Object.entries({
        read: 'Read',
        write: 'Write',
        admin: 'Admin',
        write_service_invoice: 'Service Invoice',
        write_fraud_risk_invoice: 'Invoice (B2B)',
    }).filter(x => availableOperations.includes(x[0]));

    const categoryArrayPermissionsExist = permissionGroups.filter(x =>
        availableOperations.some(y => permissionsIsAvailable(x.permissions, y))
    );

    const lonePermissions = filterUncategorizedPermissions(
        permissionGroups.flatMap(x => x.permissions),
        availablePermissions ?? {}
    ).map(element => {
        return {
            entity: element,
            permissions: [element],
        };
    });

    const permissionData =
        !availablePermissions || !groupPermissions || Object.keys(availablePermissions).length === 0
            ? undefined
            : ([...lonePermissions, ...categoryArrayPermissionsExist] as GroupPermission[]);

    const { userManagementGroupTags } = useManagementTags();
    const { hasWrite } = usePermissions(userManagementGroupTags);

    const onTogglePermission = (
        e: React.ChangeEvent<HTMLInputElement>,
        permissions: string[],
        operation: string
    ) => {
        dispatch(
            groupsActions.toggleGroupPermissions(
                organization.id,
                permissions,
                operation,
                e.target.checked
            )
        );
    };

    const checkPermission = (type: string, array: string[]) => {
        return array.some(perm => groupPermissions.includes(`${type}-${perm}`));
    };

    const operationColumn = (key: string, header: string) => ({
        header: header,
        meta: {
            style: {
                textAlign: 'center',
                width: '15%',
            },
        },
        cell: (props: { row: { original: GroupPermission } }) => {
            const { permissions } = props.row.original;

            return (
                <CheckBox
                    type="checkbox"
                    dataTestId={`${key}-group-permission-checkbox`}
                    onChange={e => onTogglePermission(e, permissions, key)}
                    disabled={!hasWrite || isModifyingPermissions}
                    checked={checkPermission(key, permissions)}
                    hide={!permissionsIsAvailable(permissions, key)}
                />
            );
        },
    });

    const permissionColumns: ColumnDef<GroupPermission>[] = [
        {
            header: 'Permissions',
            accessorKey: 'entity',
        },
        ...operations.map(x => operationColumn(x[0], x[1])),
    ];

    return (
        <>
            {permissionData && permissionData.length > 0 ? (
                <Card>
                    <CardBody>
                        <Table<GroupPermission> data={permissionData} columns={permissionColumns} />
                    </CardBody>
                </Card>
            ) : (
                <SpinnerContainer>
                    <Spinner text="Loading permissions" loading />
                </SpinnerContainer>
            )}
        </>
    );
};

const SpinnerContainer = styled.div`
    margin-top: 2rem;
`;

export default PermissionsForGroup;
