import { keyframes } from 'styled-components';

const ShineAnimation = keyframes`
    0% {
        background-position: -25rem 0;
    }
    100% {
        background-position: 25rem 0;
    }
`;

export default ShineAnimation;
