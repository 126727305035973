import React, { useEffect } from 'react';
import { DefaultTheme, withTheme } from 'styled-components';
import { useForm } from 'react-hook-form';

import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import FormRow from '../../atoms/Form/FormRow';
import GridInput from '../../atoms/Input/GridInput';
import { emailValidationConfig } from '../../../types/ValidationTypes';
import orderActions from '../../../store/order/actions';
import { useAppState } from '../../../store/appstate';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../types/response/ErrorCodes';
import CenterContent from '../../atoms/Layout/CenterContent';
import SuccessMessage from '../../atoms/Message/Success/SuccessMessage';
import Spinner from '../../atoms/Spinner/Spinner';
import { OrderAddress } from '../../../types/OrderAddress';
import { OrderState } from '../../../store/order/orderSlice';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';

interface ChangeOrderCustomerDetailsModalProps {
    invoiceAddress: OrderAddress;
    hide: () => void;
    orderId: string;
    theme: DefaultTheme;
}

type Inputs = {
    email: string;
};

const ChangeOrderCustomerDetailsModal: React.FC<ChangeOrderCustomerDetailsModalProps> = ({
    invoiceAddress,
    hide,
    orderId,
    theme,
}: ChangeOrderCustomerDetailsModalProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        mode: 'onBlur',
    });
    const dispatch = useAppDispatch();

    const email = register('email', emailValidationConfig);

    const {
        changingInvoiceAddressFailed,
        isChangingInvoiceAddress,
        invoiceAddressError,
        changingInvoiceAddressSuccessful,
    } = useAppState<OrderState>(s => s.order);
    useEffect(() => {
        const emailInput = document.getElementById('email');

        if (emailInput) {
            emailInput.focus();
        }
    });

    const onSubmit = (form: Inputs) => {
        dispatch(orderActions.patchOrderInvoiceAddress(form.email, orderId));
    };

    const emailHasError = errors.email !== undefined || false;

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Change invoice address for order</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content">
                <form id="change-email-order-form" onSubmit={handleSubmit(onSubmit)}>
                    <FormRow first last>
                        <GridInput
                            disabled={changingInvoiceAddressSuccessful}
                            background
                            defaultValue={invoiceAddress.email ?? ''}
                            maxLength={255}
                            type="email"
                            id="email"
                            placeholder="Email address"
                            setRef={email.ref}
                            name={email.name}
                            onChange={email.onChange}
                            validationError={emailHasError}
                        />
                    </FormRow>
                </form>
            </ModalContainer>

            <div>{errors.email !== undefined || (false && errors.email)}</div>

            {!isChangingInvoiceAddress && changingInvoiceAddressSuccessful && (
                <ModalContainer position="error">
                    <CenterContent>
                        <SuccessMessage>Address has been updated successfully!</SuccessMessage>
                    </CenterContent>
                </ModalContainer>
            )}

            {!isChangingInvoiceAddress && changingInvoiceAddressFailed && (
                <ModalContainer position="error">
                    <ErrorMessage
                        error={invoiceAddressError}
                        errorHeader={ErrorType.ChangeInvoiceAddress}
                    />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    {!changingInvoiceAddressSuccessful && (
                        <Button onClick={hide} large>
                            Cancel
                        </Button>
                    )}

                    {!changingInvoiceAddressSuccessful && !isChangingInvoiceAddress && (
                        <Button
                            tabIndex={0}
                            form="change-email-order-form"
                            type="submit"
                            primary
                            large
                        >
                            <span>Apply</span>
                        </Button>
                    )}

                    {isChangingInvoiceAddress && (
                        <Button tabIndex={0} primary large disabled>
                            <Spinner color={theme.colors.light} size={8} loading />
                        </Button>
                    )}

                    {changingInvoiceAddressSuccessful && (
                        <Button onClick={hide} large primary>
                            Close
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(ChangeOrderCustomerDetailsModal) as React.ComponentType<
    Omit<ChangeOrderCustomerDetailsModalProps, 'theme'>
>;
