import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';

interface LinearProgressBarProps {
    backgroundColor: string;
    completed: number;
    theme: DefaultTheme;
}

const LinearProgressBar: React.FC<LinearProgressBarProps> = ({
    backgroundColor,
    completed,
    theme,
}: LinearProgressBarProps) => {
    return (
        <Container>
            <Filler backgroundColor={backgroundColor} completed={completed} />
        </Container>
    );
};

export default withTheme(LinearProgressBar) as React.ComponentType<
    Omit<LinearProgressBarProps, 'theme'>
>;

interface FillerProps {
    completed: number;
    backgroundColor: string;
}

const Container = styled.div`
    height: 1rem;
    width: inherit;
    background-color: ${props => props.theme.colors.accent};
    border-radius: 1rem;
    margin: 1rem;
`;

const Filler = styled.div<FillerProps>`
    height: inherit;
    width: ${props => `${props.completed}%`};
    background-color: ${props => props.backgroundColor};
    border-radius: inherit;
    text-align: right;
    color: white;
    transition: width 1s ease-in-out;
`;
