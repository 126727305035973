import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { transparentize } from 'polished';

import { v4 as uuidv4 } from 'uuid';
import FocusLock from 'react-focus-lock';
import { formatNumber } from '../../../services/helpers/numberFormats';
import FormRow from '../../atoms/Form/FormRow';
import GridInput from '../../atoms/Input/GridInput';
import RowOptions from './RowOptions';
import PaylinkFormRow from '../../../types/paylink/PaylinkFormRow';
import { getDefaultVatPerCountry } from '../../../services/helpers/commonVat';
import Keys from '../../../types/Keys';
import useFocusInputById from '../../../services/hooks/useFocusInputById';

interface CreatePaylinkFormProps {
    setFormValues: (value: PaylinkFormRow[]) => void;
    formValues: PaylinkFormRow[];
    validate: boolean;
    countryCode: string;
    onSend: () => void;
    setValidate: (value: boolean) => void;
}

const CreatePaylinkForm: React.FC<CreatePaylinkFormProps> = ({
    setFormValues,
    formValues,
    validate,
    countryCode,
    onSend,
    setValidate,
}: CreatePaylinkFormProps) => {
    const [options, setOptions] = useState(-1);
    const commonVat = getDefaultVatPerCountry(countryCode);

    const createNewRow = (copy: PaylinkFormRow[]) => {
        const rowId = uuidv4();

        copy.push({ rowId, quantity: 1, vatRate: commonVat } as PaylinkFormRow);
        setFormValues([...copy]);
        setValidate(false);
    };

    const setValueInForm = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const copy = formValues;
        const existingRow = copy[index] as PaylinkFormRow;

        if (index === formValues.length - 1) {
            createNewRow(copy);
        }

        if (existingRow) {
            existingRow[e.target.name] = e.target.value;
            setFormValues([...copy]);
        }
    };

    const calculatePriceSummary = (values: PaylinkFormRow) => {
        if (values.quantity && values.unitPrice) {
            return formatNumber(values.quantity * values.unitPrice);
        }
        return null;
    };

    const checkValue = (index: number) => {
        if (formValues.length === 1) return false;

        return Object.keys(formValues[index]).length === 1;
    };

    const onDelete = (rowId: string) => {
        const formValuesCopy = formValues;
        const removeIndex = formValues.map(item => item.rowId).indexOf(rowId);

        formValuesCopy.splice(removeIndex, 1);

        setFormValues([...formValuesCopy]);
        setOptions(-1);
    };

    const checkPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === Keys.ENTER) {
            onSend();
        }
    };

    useEffect(() => {
        const copy = formValues;

        if (formValues.length === 0) {
            createNewRow(copy);
        }
    }, []);

    useFocusInputById('articleNumber');

    const renderPaylinkRow = (item: PaylinkFormRow, index: number) => {
        const priceSummary = calculatePriceSummary(item);
        const hasNoValue = checkValue(index);

        const isFirst = index === 0;
        const isLast = index + 1 === formValues.length;
        const required = isFirst || !isLast;

        return (
            <Content key={`form-row-${item.rowId}`}>
                <PaylinkRowWrapper last={isLast} hasNoValue={hasNoValue}>
                    <FormRow first>
                        <GridInput
                            onKeyDown={e => checkPressed(e)}
                            defaultValue={item.articleNumber ?? undefined}
                            type="text"
                            name="articleNumber"
                            id={`grid-articleNumber-${item.rowId}`}
                            dataTestId="grid-articleNumber"
                            flex={2}
                            required={required}
                            maxLength={50}
                            placeholder="Article Number"
                            onChange={e => setValueInForm(e, index)}
                            count
                        />
                        <GridInput
                            onKeyDown={e => checkPressed(e)}
                            defaultValue={item.description ?? undefined}
                            type="text"
                            id={`grid-description-${item.rowId}`}
                            flex={3}
                            required={required}
                            maxLength={50}
                            placeholder="Description"
                            name="description"
                            onChange={e => setValueInForm(e, index)}
                            count
                        />
                        <GridInput
                            onKeyDown={e => checkPressed(e)}
                            defaultValue={item.unitPrice ?? undefined}
                            type="number"
                            id={`grid-unitPrice-${item.rowId}`}
                            placeholder="Unit price"
                            name="unitPrice"
                            flex={1}
                            required={required}
                            step="0.01"
                            max={9000000}
                            onChange={e => {
                                setValueInForm(e, index);
                            }}
                        />
                        <GridInput
                            onKeyDown={e => checkPressed(e)}
                            defaultValue={item.quantity ?? 1}
                            type="number"
                            id={`grid-quantity-${item.rowId}`}
                            placeholder="Quantity"
                            name="quantity"
                            required={required}
                            flex={1}
                            min={1}
                            max={99999999}
                            onChange={e => setValueInForm(e, index)}
                        />
                        <GridInput
                            onKeyDown={e => checkPressed(e)}
                            defaultValue={item.vatRate ?? commonVat}
                            type="number"
                            id={`grid-vatRate-${item.rowId}`}
                            placeholder="VAT %"
                            name="vatRate"
                            required={required}
                            flex={1}
                            max={100}
                            min={0}
                            step="0.01"
                            onChange={e => setValueInForm(e, index)}
                        />

                        <PriceSummaryWrapper>
                            <PriceSummaryLabel hasValue={!!priceSummary}>
                                Price total (VAT incl.)
                            </PriceSummaryLabel>
                            <PriceSummary>{priceSummary}</PriceSummary>
                        </PriceSummaryWrapper>
                    </FormRow>
                </PaylinkRowWrapper>

                <RowOptions
                    isVisible={!isLast}
                    setOptions={setOptions}
                    onDelete={onDelete}
                    options={options}
                    index={index}
                    rowId={item.rowId}
                />
            </Content>
        );
    };

    return (
        <>
            <FocusLock>
                <PaylinkFormContainer>
                    <>{formValues.map((x, index) => renderPaylinkRow(x, index))}</>
                </PaylinkFormContainer>
            </FocusLock>
        </>
    );
};

export default CreatePaylinkForm;

const Content = styled.div`
    display: flex;
    align-items: baseline;
`;

interface PaylinkRowWrapperProps {
    hasNoValue?: boolean;
    last?: boolean;
}

const PaylinkRowWrapper = styled.div<PaylinkRowWrapperProps>`
    ${props => props.hasNoValue && 'opacity: 0.5;'};
    width: calc(100% - 3rem);
    ${props =>
        props.last &&
        ` border-bottom: 0.1rem solid ${transparentize(0.6, props.theme.colors.input.border)}`};
`;

const PaylinkFormContainer = styled.div`
    margin-top: 3rem;
`;

const PriceSummary = styled.div`
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
`;

const PriceSummaryWrapper = styled.div`
    background-color: #f0f0f0;
    padding: 0 1.6rem;
    flex: 1.5;
    display: flex;
    align-items: center;
    justify-content: left;
    height: 6rem;
    position: relative;
    overflow: hidden;
`;

interface PriceSummaryLabelProps {
    hasValue: boolean;
}

const PriceSummaryLabel = styled.label<PriceSummaryLabelProps>`
    position: absolute;
    font-size: ${props => props.theme.text.size.small};
    transform: translate(0, 0) scale(1);
    transform-origin: top left;
    transition: all 0.15s ease-in-out;
    opacity: 0.6;
    ${props =>
        props.hasValue &&
        css`
            transform: translate(0, -1.5rem) scale(0.75);
            color: ${props.theme.colors.primary};
            opacity: 1;
        `};
`;
