/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Matcher from '../../types/marketing/Matcher';
import ResponseError from '../../types/response/ResponseError';

export interface MarketingState {
    matchers: Matcher[];
    getMatchesFailedError: ResponseError;
    createMatcherError: ResponseError;
    updateMatchError: ResponseError;
    deleteMatcherError: ResponseError;
    matcherPreviewError: ResponseError;
    matcherPreviews: { [key: string]: number };
    noMatchers: boolean;
    isBusy: boolean;
}

const initialState: MarketingState = {
    matchers: [],
    getMatchesFailedError: {} as ResponseError,
    createMatcherError: {} as ResponseError,
    updateMatchError: {} as ResponseError,
    deleteMatcherError: {} as ResponseError,
    matcherPreviewError: {} as ResponseError,
    matcherPreviews: {},
    noMatchers: false,
    isBusy: false,
};

export const marketingSlice = createSlice({
    name: 'marketing',
    initialState,
    reducers: {
        getMatchersPending: state => {
            state.isBusy = true;
        },
        getMatchersSuccess: (state, action: PayloadAction<Matcher[]>) => {
            state.matchers = action.payload;
            state.noMatchers = action.payload.length === 0;
            state.isBusy = false;
        },
        getMatchersFailed: (state, action: PayloadAction<ResponseError>) => {
            state.getMatchesFailedError = action.payload;
            state.isBusy = false;
        },
        matcherCreatedSuccess: state => {
            state.isBusy = false;
        },
        matcherCreatedFailed: (state, action: PayloadAction<ResponseError>) => {
            state.createMatcherError = action.payload;
            state.isBusy = false;
        },
        matcherUpdatedSuccess: state => {
            state.isBusy = false;
        },
        matcherUpdatedFailed: (state, action: PayloadAction<ResponseError>) => {
            state.updateMatchError = action.payload;
            state.isBusy = false;
        },
        deleteMatcherSuccess: state => {
            state.isBusy = false;
        },
        deleteMatcherFailed: (state, action: PayloadAction<ResponseError>) => {
            state.deleteMatcherError = action.payload;
            state.isBusy = false;
        },
        previewMatcherSuccess: (
            state,
            action: PayloadAction<{ matcherId: string; matcherValue: number }>
        ) => {
            state.matcherPreviews[action.payload.matcherId] = action.payload.matcherValue;
            state.isBusy = false;
        },
        previewMatcherFailed: (state, action: PayloadAction<ResponseError>) => {
            state.matcherPreviewError = action.payload;
            state.isBusy = false;
        },
    },
});

export const {
    getMatchersPending,
    getMatchersSuccess,
    getMatchersFailed,
    matcherCreatedSuccess,
    matcherCreatedFailed,
    matcherUpdatedSuccess,
    matcherUpdatedFailed,
    deleteMatcherSuccess,
    deleteMatcherFailed,
    previewMatcherSuccess,
    previewMatcherFailed,
} = marketingSlice.actions;

export default marketingSlice.reducer;
