import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import PencilLineIcon from 'remixicon-react/PencilLineIcon';

import FileCopyLine from 'remixicon-react/FileCopyLineIcon';
import useModal from '../../../services/hooks/useModal';
import { useAppState } from '../../../store/appstate';
import { CustomerDetails } from '../../../types/customer/CustomerDetails';
import OrderStatus from '../../../types/order/OrderStatus';
import { OrderAddress } from '../../../types/OrderAddress';
import Button from '../../atoms/Button/Button';
import Address from '../../atoms/Contact/Address';
import Email from '../../atoms/Contact/Email';
import Phone from '../../atoms/Contact/Phone';
import RegistrationNumber from '../../atoms/Contact/RegistrationNumber';
import CustomerIcon from '../../atoms/CustomerIcon/CustomerIcon';

import { Modal } from '../../atoms/Modal/Modal';
import ChangeOrderCustomerDetailsModal from './ChangeOrderCustomerDetailsModal';
import Popover, { TourPosition } from '../../atoms/Tour/Popover';
import StyledLink from '../../atoms/Link/StyledLink';
import usePermissions from '../../../services/hooks/usePermissions';
import { clearUpdateOrderInvoiceAddress } from '../../../store/order/orderSlice';
import PhoneNumberType from '../../../types/PhoneNumber';
import PhoneEmailContainer from '../../atoms/Contact/PhoneEmailContainer';
import { useAppDispatch } from '../../../store';
import RegistrationNumberFormatter from '../../atoms/RegistrationNumberFormatter';
import { CommitmentAlias } from '../../../types/order/Order';
import CustomerType from '../../../types/CustomerType';

interface Props {
    customer: CustomerDetails;
    deliveryAddress?: OrderAddress;
    invoiceAddress?: OrderAddress;
    orderStatus: string;
    orderId: string;
    authorizedBuyer?: CommitmentAlias;
    referencePerson?: string;
    costCenter?: string;
    theme: DefaultTheme;
    tags: string[] | undefined;
}

const OrderCustomer: React.FC<Props> = ({
    customer,
    deliveryAddress,
    invoiceAddress,
    orderStatus,
    orderId,
    authorizedBuyer,
    referencePerson,
    costCenter,
    theme,
    tags,
}: Props) => {
    const dispatch = useAppDispatch();
    const editModal = useModal();

    const { hasWrite } = usePermissions(tags);

    const isInternal = useAppState<boolean>(s => s.session.user?.isInternal ?? false);
    const customerNameExist = customer.name !== '' && customer.name !== ' ';

    const isOnOrderPage = !!window.location.pathname.includes('orders');
    const orderIsNotActivated = orderStatus === OrderStatus.NotActivated;
    const invoiceAddressChangeable = isOnOrderPage && orderIsNotActivated && hasWrite;

    const getCorrectPhoneDescription = (type: PhoneNumberType) => {
        switch (type) {
            case PhoneNumberType.Home:
                return 'Phone number';
            case PhoneNumberType.Mobile:
                return 'Mobile phone number';
            default:
                return '';
        }
    };

    const hideAndClearError = () => {
        dispatch(clearUpdateOrderInvoiceAddress());
        editModal.toggle();
    };

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
        const tooltip = document.getElementById('myTooltip');
        if (tooltip) tooltip.innerHTML = `Copied: ${value}`;
    };

    const copyOutFunction = () => {
        const tooltip = document.getElementById('myTooltip');
        if (tooltip) tooltip.innerHTML = 'Copy to clipboard';
    };

    const openDialogChangeEmail = () => {
        editModal.toggle();
    };

    const renderB2BSpecifics = () => {
        return (
            <>
                {authorizedBuyer && (
                    <LabelValueGroup>
                        <Label>Authorized buyer</Label> {authorizedBuyer?.description ?? ''}{' '}
                        {authorizedBuyer?.integrationId ?? ''}
                    </LabelValueGroup>
                )}

                {referencePerson && (
                    <LabelValueGroup>
                        <Label>Reference</Label> {referencePerson}
                    </LabelValueGroup>
                )}

                {costCenter && (
                    <LabelValueGroup>
                        <Label>Cost center</Label> {costCenter}
                    </LabelValueGroup>
                )}
            </>
        );
    };

    const renderContactInformationDetails = (
        information: OrderAddress | undefined,
        canEdit: boolean,
        label: string
    ) => {
        if (!information) return <></>;

        return (
            <DetailsContainer>
                <SectionLabel>{label}</SectionLabel>

                {information && information.addressLine1 !== '' && (
                    <Address
                        name={information.receiverName}
                        address={information?.addressLine1 || ''}
                        city={information?.city || ''}
                        postalCode={information?.postalCode || ''}
                        address2={information.addressLine2 || ''}
                        coAddress={information.coAddress || ''}
                        countryCode={(label === 'Delivery' && information.countryCode) || ''}
                    />
                )}

                <Section>
                    <PhoneEmailContainer>
                        <Phone title={getCorrectPhoneDescription(PhoneNumberType.Mobile)}>
                            {information.mobilePhone}
                        </Phone>

                        {information?.phoneNumber && (
                            <Phone
                                title={getCorrectPhoneDescription(PhoneNumberType.Home)}
                                type={PhoneNumberType.Home}
                            >
                                {information.phoneNumber}
                            </Phone>
                        )}
                        <Email title={information.email}>{information.email}</Email>
                    </PhoneEmailContainer>

                    <IconButtonContainer>
                        {canEdit && (
                            <Popover
                                name="orderEmailChangeTour"
                                position={TourPosition.Right}
                                item={
                                    <EmailIconButtonLeft
                                        isLonely={!information.email}
                                        onClick={() => openDialogChangeEmail()}
                                        width="1rem"
                                        title="Edit invoice address"
                                    >
                                        <PenIcon size={theme.icon.size.small} />
                                    </EmailIconButtonLeft>
                                }
                            >
                                <p>
                                    💡 Change the email address for non activated orders by clicking
                                    on the <PenIcon size={theme.icon.size.small} /> button!
                                </p>
                            </Popover>
                        )}

                        {information.email && (
                            <IconButtonContainer>
                                <EmailIconButtonRight
                                    isLonely={!canEdit}
                                    onMouseOut={() => copyOutFunction()}
                                    onClick={() => copyToClipboard(information.email)}
                                    width="1rem"
                                    title="Copy email to clipboard"
                                >
                                    <FileCopyLine size={theme.icon.size.small} />
                                </EmailIconButtonRight>
                            </IconButtonContainer>
                        )}
                    </IconButtonContainer>
                </Section>
            </DetailsContainer>
        );
    };

    return (
        <Container>
            <Person>
                <Header>
                    <CustomerHead>
                        <CustomerIcon customerType={customer.type} />

                        <CustomerLink to={`/customers/${customer.customerId}`}>
                            {customerNameExist ? (
                                customer.name
                            ) : (
                                <RegistrationNumberFormatter
                                    registrationNumber={customer.registrationNumber}
                                />
                            )}
                        </CustomerLink>
                    </CustomerHead>
                </Header>

                {editModal.isShown && invoiceAddress && (
                    <Modal medium isShown hide={hideAndClearError}>
                        <ChangeOrderCustomerDetailsModal
                            hide={hideAndClearError}
                            invoiceAddress={invoiceAddress}
                            orderId={orderId ?? ''}
                        />
                    </Modal>
                )}

                <Details>
                    {customerNameExist && (
                        <RegistrationNumber>{customer.registrationNumber}</RegistrationNumber>
                    )}
                </Details>

                {isInternal && (
                    <LabelValueGroup>
                        <Label>Edge Customer Id</Label> {customer.customerId}
                    </LabelValueGroup>
                )}
                {renderB2BSpecifics()}
            </Person>
            <DetailsSection>
                {customer.buyer && (
                    <DetailsContainer>
                        <SectionLabel>Buyer</SectionLabel>
                        <CustomerHead>
                            <CustomerIcon customerType={CustomerType.Person} />
                            {customer.buyer.firstName} {customer.buyer.lastName}
                        </CustomerHead>
                        {customer.buyer.registrationNumber && (
                            <Details>
                                <RegistrationNumberFormatter
                                    registrationNumber={customer.buyer.registrationNumber}
                                />
                            </Details>
                        )}
                        <Section>
                            <PhoneEmailContainer>
                                <Phone title={getCorrectPhoneDescription(PhoneNumberType.Mobile)}>
                                    {customer.buyer.mobilePhoneNumber}
                                </Phone>

                                {customer.buyer.homePhoneNumber && (
                                    <Phone
                                        title={getCorrectPhoneDescription(PhoneNumberType.Home)}
                                        type={PhoneNumberType.Home}
                                    >
                                        {customer.buyer.homePhoneNumber}
                                    </Phone>
                                )}
                                <Email title={customer.buyer.email}>{customer.buyer.email}</Email>
                            </PhoneEmailContainer>

                            <IconButtonContainer>
                                <EmailIconButtonRight
                                    isLonely={true}
                                    onMouseOut={() => copyOutFunction()}
                                    onClick={() => copyToClipboard(customer.buyer.email)}
                                    width="1rem"
                                    title="Copy email to clipboard"
                                >
                                    <FileCopyLine size={theme.icon.size.small} />
                                </EmailIconButtonRight>
                            </IconButtonContainer>
                        </Section>
                    </DetailsContainer>
                )}
                {renderContactInformationDetails(deliveryAddress, false, 'Delivery')}

                {renderContactInformationDetails(
                    invoiceAddress,
                    invoiceAddressChangeable,
                    'Invoice'
                )}
            </DetailsSection>
        </Container>
    );
};

interface IsLonely {
    isLonely?: boolean;
}

const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    margin-bottom: 0.5rem;
`;

const SectionLabel = styled.div`
    font-size: ${props => props.theme.text.size.medium};
    color: ${props => props.theme.colors.text.secondary};
    margin-bottom: 1rem;
`;

const Section = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const DetailsSection = styled.div`
    > * {
        &:last-child {
            border-bottom: none;
        }

        &:first-child {
            border-top: 0.1rem solid ${props => props.theme.colors.border};
        }
    }
`;

const LabelValueGroup = styled.div`
    margin-top: 1rem;
`;

const DetailsContainer = styled.div`
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};
`;

const Header = styled.div`
    display: flex;
    gap: 0.5rem;
    line-height: 2.5rem;
    color: ${props => props.theme.colors.text.secondary};
    justify-content: space-between;
`;

const CustomerHead = styled.div`
    display: flex;
`;

const Person = styled.div`
    padding: 2rem;
`;

const IconButtonContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

const EmailIconButton = styled(Button)<IsLonely>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;

const EmailIconButtonLeft = styled(EmailIconButton)<IsLonely>`
    border-right: ${props =>
        props.isLonely ? `0.2rem solid ${props.theme.colors.button.secondary.border}` : 'none'};
    border-radius: ${props =>
        props.isLonely ? '0.5rem 0.5rem 0.5rem 0.5rem' : '0.5rem 0 0 0.5rem'};
`;

const EmailIconButtonRight = styled(EmailIconButton)<IsLonely>`
    border-radius: 0 0.5rem 0.5rem 0;
    border-radius: ${props =>
        props.isLonely ? '0.5rem 0.5rem 0.5rem 0.5rem' : '0 0.5rem 0.5rem 0'};
`;

const PenIcon = styled(PencilLineIcon)`
    align-self: center;
`;

const Details = styled.div`
    line-height: 2.5rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const CustomerLink = styled(StyledLink)`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.text.font.medium};
    margin: 0;
    display: inline-block;
`;

const Container = styled.div`
    background-color: ${props => props.theme.background};
    border: 0.1rem solid ${props => props.theme.colors.border};
    margin-bottom: 1rem;
`;

export default withTheme(OrderCustomer) as React.ComponentType<Omit<Props, 'theme'>>;
