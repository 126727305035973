import React from 'react';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import Button from '../../atoms/Button/Button';
import Flex from '../../atoms/Box/Flex';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import useLogTimeSpentOnPage from '../../../services/hooks/useLogTimeSpentOnPage';

interface ShowQrModalProps {
    hide: () => void;
    qrPayLinkHref: string;
    theme: DefaultTheme;
}

const ShowQrModal: React.FC<ShowQrModalProps> = ({ hide, qrPayLinkHref }: ShowQrModalProps) => {
    useLogTimeSpentOnPage('showPaylinkQrModal');
    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Pay Link QR Code</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content" noScroll>
                <Flex justifyContent="center">
                    <Img src={qrPayLinkHref} alt="QR code" />
                </Flex>
            </ModalContainer>

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large>
                        Close
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(ShowQrModal) as React.ComponentType<Omit<ShowQrModalProps, 'theme'>>;

const Img = styled.img`
    height: 50rem;
    width: 50rem;
`;
