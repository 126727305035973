import { create } from 'zustand';
import { Organization } from '../../../types/Organization';
import ResponseError from '../../../types/response/ResponseError';

export interface OrganizationsStore {
    organizations: Organization[];
    error: ResponseError;
    isEnablingOrganization: boolean;
    organizationEnabled: boolean;
    enableOrganizationError: ResponseError | undefined;
    isDeletingOrganization: boolean;
    isDeletedOrganization: boolean;
    deleteOrganizationError: ResponseError | undefined;
    deletedOrganization: string;
    getOrganizationsSucceeded: (organizations: Organization[]) => void;
    getOrganizationsFailed: (error: ResponseError) => void;
    enableOrganizationStarted: () => void;
    enableOrganizationSucceeded: () => void;
    enableOrganizationFailed: (error: ResponseError) => void;
    clearEnableOrganization: () => void;
    deleteOrganizationStarted: () => void;
    deleteOrganizationSucceeded: (organization: string) => void;
    deleteOrganizationFailed: (error: ResponseError) => void;
    clearDeleteOrganization: () => void;
    reset: () => void;
}

export const initialState = {
    organizations: [] as Organization[],
    error: {} as ResponseError,
    isEnablingOrganization: false,
    organizationEnabled: false,
    enableOrganizationError: undefined,
    isDeletingOrganization: false,
    isDeletedOrganization: false,
    deleteOrganizationError: undefined,
    deletedOrganization: '',
};

export const useOrganizationsStore = create<OrganizationsStore>(set => ({
    ...initialState,

    getOrganizationsSucceeded: (organizations: Organization[]) =>
        set({
            organizations: organizations,
        }),

    getOrganizationsFailed: (error: ResponseError) =>
        set({
            error: error,
        }),

    enableOrganizationStarted: () =>
        set({
            isEnablingOrganization: true,
        }),

    enableOrganizationSucceeded: () =>
        set({
            organizationEnabled: true,
            isEnablingOrganization: false,
        }),

    enableOrganizationFailed: (error: ResponseError) =>
        set({
            enableOrganizationError: error,
            isEnablingOrganization: false,
        }),

    clearEnableOrganization: () =>
        set({
            organizationEnabled: false,
            isEnablingOrganization: false,
            enableOrganizationError: undefined,
        }),

    deleteOrganizationStarted: () =>
        set({
            isDeletingOrganization: true,
        }),

    deleteOrganizationSucceeded: (organization: string) =>
        set({
            isDeletingOrganization: false,
            isDeletedOrganization: true,
            deletedOrganization: organization,
        }),

    deleteOrganizationFailed: (error: ResponseError) =>
        set({
            deleteOrganizationError: error,
            isDeletingOrganization: false,
        }),

    clearDeleteOrganization: () =>
        set({
            isDeletingOrganization: false,
            isDeletedOrganization: false,
            deleteOrganizationError: undefined,
            deletedOrganization: '',
        }),

    reset: () => set(initialState),
}));

