enum PaylinkStatus {
    Completed = 'Completed',
    Pending = 'Pending',
    Expired = 'Expired',
    Aborted = 'Aborted',
    CustomerIdentified = 'CustomerIdentified',
    CommittedToPayment = 'CommittedToPayment',
}

export default PaylinkStatus;
