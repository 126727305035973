import { ReportColumnType } from '../../types/report/ReportColumnType';

export const transactionDetailsReportColumns = {
    'Customer ID': ReportColumnType.CustomerId,
    'Customer Registration Number': ReportColumnType.CustomerRegistrationNumber,
    Name: ReportColumnType.CustomerFullName,
    Partner: ReportColumnType.PartnerName,
    'Order Reference': ReportColumnType.OrderNumber,
    'Invoice Number': ReportColumnType.InvoiceNumber,
    'Transaction Type': ReportColumnType.TransactionType,
    Amount: ReportColumnType.Amount,
    'Purchase Date': ReportColumnType.CreatedOn,
    'Article ID': ReportColumnType.ArticleId,
    Description: ReportColumnType.Description,
    'Product Code': ReportColumnType.ProductCode,
    'Created By': ReportColumnType.CreatedBy,
} as Record<string, ReportColumnType>;
