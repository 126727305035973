import React from 'react';
import styled from 'styled-components';
import { useAppState } from '../../../store/appstate';
import { CustomerDetails } from '../../../types/customer/CustomerDetails';
import Address from '../../atoms/Contact/Address';
import Email from '../../atoms/Contact/Email';
import Phone from '../../atoms/Contact/Phone';
import PhoneEmailContainer from '../../atoms/Contact/PhoneEmailContainer';
import RegistrationNumber from '../../atoms/Contact/RegistrationNumber';
import CustomerIcon from '../../atoms/CustomerIcon/CustomerIcon';
import StyledLink from '../../atoms/Link/StyledLink';
import RegistrationNumberFormatter from '../../atoms/RegistrationNumberFormatter';

interface Props {
    customer: CustomerDetails;
}

const AccountCustomer: React.FC<Props> = ({ customer }: Props) => {
    const isInternal = useAppState<boolean>(s => s.session.user?.isInternal ?? false);
    const customerNameExist = customer.name !== '' && customer.name !== ' ';

    return (
        <Container>
            <Person>
                <Header>
                    <CustomerHead>
                        <CustomerIcon customerType={customer.type} />

                        <CustomerLink to={`/customers/${customer.customerId}`}>
                            {customerNameExist ? (
                                customer.name
                            ) : (
                                <RegistrationNumberFormatter
                                    registrationNumber={customer.registrationNumber}
                                />
                            )}
                        </CustomerLink>
                    </CustomerHead>
                </Header>

                <Details>
                    {customerNameExist && (
                        <RegistrationNumber>{customer.registrationNumber}</RegistrationNumber>
                    )}
                </Details>

                {isInternal && (
                    <EdgeId>
                        <Label>Edge Customer Id</Label> {customer.customerId}
                    </EdgeId>
                )}
            </Person>
            <Other>
                <Section>
                    <PhoneEmailContainer>
                        <Phone>{customer.mobilePhoneNumber}</Phone>
                        <Email>{customer.email}</Email>
                    </PhoneEmailContainer>
                </Section>

                {customer && (
                    <Address
                        type="Invoice Address"
                        address={customer.addressLine1}
                        city={customer.city}
                        postalCode={customer.postalCode}
                        receiver={customer.name}
                        address2={customer.addressLine2}
                        coAddress={customer.coAddress}
                    />
                )}
            </Other>
        </Container>
    );
};

const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
`;

const Section = styled.div`
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const EdgeId = styled.div`
    margin-top: 1rem;
`;

const Header = styled.div`
    display: flex;
    gap: 0.5rem;
    line-height: 2.5rem;
    color: ${props => props.theme.colors.text.secondary};
    justify-content: space-between;
`;

const CustomerHead = styled.div`
    display: flex;
`;

const Person = styled.div`
    padding: 2rem;
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};
`;

const Other = styled.div`
    padding: 1rem 2rem 0 2rem;
`;

const Details = styled.div`
    line-height: 2.5rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const CustomerLink = styled(StyledLink)`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.text.font.medium};
    margin: 0;
    display: inline-block;
    &:hover {
        text-decoration: underline;
    }
`;

const Container = styled.div`
    background-color: #fff;
    border: 0.1rem solid ${props => props.theme.colors.border};
    margin-bottom: 1rem;
`;

export default AccountCustomer;
