import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AppThunkAction } from '../../store/thunk';

const browsePaginationWithDispatch = (
    navigate: NavigateFunction,
    pathname: string,
    pageLinkQuery: string | undefined,
    dispatchMethod: AppThunkAction<Promise<void>>,
    dispatch: Dispatch<AppThunkAction<Promise<void>>>,
    pushToQuery = true
) => {
    if (pageLinkQuery) {
        if (pushToQuery) navigate(createSearchPath(pageLinkQuery, pathname));

        dispatch(dispatchMethod);
    }
};

const browsePagination = (
    navigate: NavigateFunction,
    pathname: string,
    pageLinkQuery: string | undefined
) => {
    if (pageLinkQuery) {
        navigate(createSearchPath(pageLinkQuery, pathname));
    }
};

const createSearchPath = (link: string | undefined, pathname: string) => {
    return `${pathname}?${link}`;
};

export { browsePaginationWithDispatch, browsePagination, createSearchPath };
