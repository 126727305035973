import styled from 'styled-components';

interface ButtonTabsProps {
    background?: string;
}

const ButtonTabs = styled.div<ButtonTabsProps>`
    display: flex;
    flex-direction: row;
    width: fit-content;
    border-radius: 0.5rem;
    background: ${props => (props.background ? props.background : props.theme.colors.medium)}};
`;

export default ButtonTabs;
