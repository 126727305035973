import React from 'react';
import styled from 'styled-components';
import FlagIcon from '../FlagIcon/FlagIcon';
import getCustomerType from '../../../services/helpers/customerTypeHelper';
import { ConnectedStore } from '../../../types/ConnectedStore';
import TwoRowCell from '../Table/TwoRowCell';
import CustomerIcon from '../CustomerIcon/CustomerIcon';

interface CompactStoreLabelProps {
    store: ConnectedStore;
}

const ColumnStoreLabel: React.FC<CompactStoreLabelProps> = ({ store }: CompactStoreLabelProps) => {
    const customerIcon = store.customerSalesType && getCustomerType(store.customerSalesType);

    return (
        <StoreWrapper
            title={`${store.id} ${store.storeName}${
                store.selfReference ? ` ${store.selfReference}` : ''
            }`}
        >
            <IconContainer>
                {customerIcon && <CustomerIcon customerType={customerIcon} />}
                <FlagIcon countryCode={store.countryCode} />
            </IconContainer>
            <TwoRowCell upper={store.storeName} lower={store.selfReference} align="left" />
        </StoreWrapper>
    );
};

const StoreWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;

const IconContainer = styled.div`
    display: flex;
`;

export default ColumnStoreLabel;
