import React from 'react';
import styled from 'styled-components';
import { PurchaseResponseData } from '../../../types/response/PurchaseResponseData';
import { Modal } from '../../atoms/Modal/Modal';
import CancelOrder from './CancelOrder';
import ActivateOrder from './ActivateOrder';
import EditOrder from './EditOrder';
import ExtendDate from '../ExtendDate';
import Button from '../../atoms/Button/Button';
import { Order } from '../../../types/order/Order';
import useModal from '../../../services/hooks/useModal';
import { useAppState } from '../../../store/appstate';
import StatusTypes from '../../../types/StatusTypes';
import {
    clearOrderActivation,
    clearOrderCancelError,
    clearUpdateOrder,
    clearExtendDueDate,
    OrderState,
} from '../../../store/order/orderSlice';
import OrderArticleStatus from '../../../types/order/OrderArticleStatus';
import { useAppDispatch } from '../../../store';

interface PurchaseActionsProps {
    order: Order;
    purchase: PurchaseResponseData;
    purchaseStatus: string;
}

const PurchaseActions: React.FC<PurchaseActionsProps> = ({
    order,
    purchase,
    purchaseStatus,
}: PurchaseActionsProps) => {
    const dispatch = useAppDispatch();

    const removeModal = useModal();
    const activateOrderModal = useModal();
    const extendModal = useModal();
    const editModal = useModal();

    const isCancellable = purchase?.links.cancel !== undefined;
    const isEditable = purchase?.links.update !== undefined;
    const isExtendable = purchase.links.extend !== undefined;
    const isActivatable = purchase?.links.activate !== undefined;

    const purchaseRows = purchase.data.rows.filter(r => r.status === purchaseStatus);

    const { isExtending, isExtended, error, extendFailed } = useAppState<OrderState>(s => s.order);

    const hideCancelModal = () => {
        removeModal.toggle();
        dispatch(clearOrderCancelError());
    };

    const hideExtendModal = () => {
        extendModal.toggle();
        dispatch(clearExtendDueDate());
    };

    const hideEditModal = () => {
        editModal.toggle();
        dispatch(clearUpdateOrder());
    };

    const hideActivateModal = () => {
        activateOrderModal.toggle();
        dispatch(clearOrderActivation());
    };

    const hasExpiredRows = purchase.data.rows.some(x => x.status === OrderArticleStatus.Expired);

    return (
        <>
            {isCancellable && <ActionButton onClick={removeModal.toggle}>Cancel</ActionButton>}
            {isEditable && (
                <ActionButton
                    onClick={editModal.toggle}
                    primary={purchase && Object.keys(purchase.links).length === 1}
                >
                    Edit
                </ActionButton>
            )}
            {isExtendable && (
                <ActionButton
                    onClick={extendModal.toggle}
                    primary={
                        (purchase && Object.keys(purchase.links).length === 1) || hasExpiredRows
                    }
                >
                    Extend
                </ActionButton>
            )}
            {isActivatable && (
                <ActionButton
                    data-testid="toggle-activate-order-modal"
                    onClick={activateOrderModal.toggle}
                    primary
                >
                    Activate
                </ActionButton>
            )}
            {order && (
                <>
                    <Modal medium isShown={removeModal.isShown} hide={hideCancelModal}>
                        <CancelOrder
                            order={order}
                            hide={hideCancelModal}
                            cancelLink={purchase.links.cancel}
                        />
                    </Modal>
                    <Modal large isShown={activateOrderModal.isShown} hide={hideActivateModal}>
                        <ActivateOrder
                            data={purchaseRows}
                            order={order}
                            captureLink={purchase.links.capture}
                            hide={hideActivateModal}
                        />
                    </Modal>
                    <Modal large isShown={editModal.isShown} hide={hideEditModal}>
                        <EditOrder
                            data={purchaseRows}
                            order={order}
                            editLink={purchase.links.update}
                            hide={hideEditModal}
                        />
                    </Modal>
                    <Modal small isShown={extendModal.isShown} hide={hideExtendModal}>
                        <ExtendDate
                            hide={hideExtendModal}
                            identifier={order.orderNumber || ''}
                            extendLink={purchase.links.extend}
                            type={StatusTypes.Order}
                            isExtending={isExtending}
                            isExtended={isExtended}
                            extendFailed={extendFailed}
                            error={error}
                        />
                    </Modal>
                </>
            )}
        </>
    );
};

const ActionButton = styled(Button)`
    margin-left: 0.5rem;
`;

export default PurchaseActions;
