import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    children: ReactNode;
}

export type Position = 'left' | 'right';

const LayoutContainer: React.FC<Props> = ({ children }: Props) => {
    return <Container>{children}</Container>;
};

const Container = styled.div`
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: auto 30rem;
`;

export default LayoutContainer;
