/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchTerm } from '../../../types/response/SearchTerm';
import { Settlement } from '../../../types/report/Settlement';
import { SettlementSearchResponse } from '../../../types/report/SettlementPagingResponseData';
import ResponseError from '../../../types/response/ResponseError';

export interface SettlementState {
    isSearching: boolean;
    searchResult: SettlementSearchResponse<Settlement[]>;
    searchFailed: boolean;
    terms: SearchTerm[];
    error: ResponseError;
    phrase: string | undefined;
    singleFileDownloadError: boolean;
    singleFileDownloadProcessing: boolean;
}

const initialState: SettlementState = {
    isSearching: false,
    searchResult: {} as SettlementSearchResponse<Settlement[]>,
    searchFailed: false,
    terms: [],
    error: {} as ResponseError,
    singleFileDownloadError: false,
    singleFileDownloadProcessing: false,
    phrase: undefined,
};

export const settlementSlice = createSlice({
    name: 'settlement',
    initialState,
    reducers: {
        settlementSearchStarted: (state, action: PayloadAction<string>) => {
            state.isSearching = true;
            state.phrase = action.payload;
        },
        settlementSearchFailed: (state, action: PayloadAction<ResponseError>) => {
            state.error = action.payload;
            state.isSearching = false;
            state.searchFailed = true;
            state.searchResult = {} as SettlementSearchResponse<Settlement[]>;
        },
        settlementSearchResultsSucceeded: (
            state,
            action: PayloadAction<SettlementSearchResponse<Settlement[]>>
        ) => {
            state.searchResult = action.payload;
            state.isSearching = false;
            state.searchFailed = false;
        },
        settlementSearchTermsSucceeded: (state, action: PayloadAction<SearchTerm[]>) => {
            state.terms = action.payload;
        },
        settlementSearchTermsFailed: state => {},
        settlementDownloadFailed: state => {
            state.singleFileDownloadError = true;
        },
        clearSettlementDownloadError: state => {
            state.singleFileDownloadError = false;
        },
        settlementDownloadSetProcessing: (state, action: PayloadAction<boolean>) => {
            state.singleFileDownloadProcessing = action.payload;
        },
    },
});

export const {
    settlementSearchStarted,
    settlementSearchFailed,
    settlementSearchResultsSucceeded,
    settlementSearchTermsSucceeded,
    settlementSearchTermsFailed,
    settlementDownloadFailed,
    clearSettlementDownloadError,
    settlementDownloadSetProcessing,
} = settlementSlice.actions;

export default settlementSlice.reducer;
