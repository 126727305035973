/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef } from 'react';
import EraserLineIcon from 'remixicon-react/EraserLineIcon';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import More2Line from 'remixicon-react/More2LineIcon';
import useOnClickOutside from '../../atoms/Dropdown/useOnClickOutside';

interface RowOptionsProps {
    setOptions: (arg0: number) => void;
    onDelete: (rowId: string) => void;
    options: number;
    index: number;
    theme: DefaultTheme;
    rowId: string | undefined;
    isVisible: boolean;
}

const RowOptions: React.FC<RowOptionsProps> = ({
    setOptions,
    onDelete,
    options,
    index,
    theme,
    rowId,
    isVisible,
}: RowOptionsProps) => {
    const isOptionItemShown = options === index;
    const ref = useRef(null);
    const handleClickOutside = () => {
        if (options === index) {
            setOptions(-1);
        }
    };

    useOnClickOutside(ref, handleClickOutside);

    const onClick = () => {
        setOptions(isOptionItemShown ? -1 : index);
    };

    return (
        <RowOptionsButton ref={ref} $isVisible={isVisible}>
            <MoreOptionIcon
                $isOptionItemShown={isOptionItemShown}
                data-testid="paylink-more-options-button"
                onClick={() => onClick()}
                size={theme.icon.size.normal}
            />
            <OptionItems $isOptionItemShown={isOptionItemShown}>
                <OptionItem
                    onClick={() => {
                        if (rowId) onDelete(rowId);
                    }}
                    data-testid="paylink-delete-options-button"
                >
                    <EraserLineIcon size={theme.icon.size.small} />
                    Remove row
                </OptionItem>
            </OptionItems>
        </RowOptionsButton>
    );
};

export default withTheme(RowOptions) as React.ComponentType<Omit<RowOptionsProps, 'theme'>>;

interface OptionItemsProps {
    $isOptionItemShown: boolean;
}
interface MoreOptionsProps {
    $isOptionItemShown: boolean;
}
interface RowOptionsButtonProps {
    $isVisible: boolean;
}

const RowOptionsButton = styled.div<RowOptionsButtonProps>`
    width: 2rem;
    visibility: ${props => (props.$isVisible ? 'visible' : 'hidden')};
    margin-left: 0.5rem;
`;

const OptionItems = styled.div<OptionItemsProps>`
    width: 10rem;
    background: ${props => props.theme.colors.light};
    box-shadow: 0 0.06rem 0.19rem rgba(104, 104, 104, 0.12), 0 0.06rem 0.19rem rgba(0, 0, 0, 0.24);
    display: ${props => (props.$isOptionItemShown ? 'block' : 'none')};
    position: absolute;
    width: fit-content;
    z-index: 3;
    transform: translate(-100%, -75%);
    border-radius: 0.3rem;

    > * {
        &:last-child {
            border-bottom: none !important;
        }
    }
`;

const OptionItem = styled.div`
    border-bottom: 0.1rem solid ${props => props.theme.colors.input.border};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 12rem;
    padding: 2rem;

    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.lightPrimary};
    }
`;

const MoreOptionIcon = styled(More2Line)<MoreOptionsProps>`
    color: ${props =>
        props.$isOptionItemShown ? props.theme.colors.primary : props.theme.colors.dark};
    position: relative;
    vertical-align: middle;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.veryDark};
    }
`;
