const formatNumber = (number: number) => {
    const money = new Intl.NumberFormat('sv', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);

    return money;
};

const formatNumberWithDecimals = (number: number, decimals: number) => {
    const money = new Intl.NumberFormat('sv', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(number);

    return money;
};

const formatNumberWithoutDecimals = (number: number) => {
    const money = new Intl.NumberFormat('sv', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);

    return money;
};

const numberAsDiff = (value: number) => {
    return value === 0 ? Math.abs(value) : -value;
};

export { formatNumber, numberAsDiff, formatNumberWithoutDecimals, formatNumberWithDecimals };
