import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
    to: string;
    children: ReactNode;
}

const TabItem: React.FC<Props> = ({ to, children }: Props) => {
    return (
        <>
            <Item to={to}>{children}</Item>
        </>
    );
};

const Item = styled(NavLink)`
    font-size: ${props => props.theme.text.size.large};
    color: ${props => props.theme.colors.text.primary};
    min-width: 8rem;
    padding: 0 0.8rem 1rem 0;
    position: relative;
    bottom: -0.2rem;
    border-bottom: 0.2rem solid #dedede;
    text-align: center;
    text-decoration: none;

    &:hover {
        border-bottom: 0.4rem ${props => props.theme.colors.accent} solid;
        cursor: pointer;
    }
    &[aria-current] {
        border-bottom: 0.4rem ${props => props.theme.colors.primary} solid;
    }
`;

export default TabItem;
