import React, { useEffect } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import DashboardGraphType from '../../../types/dashboard/DashboardGraphType';
import Flex from '../../atoms/Box/Flex';
import { AppThunkAction } from '../../../store/thunk';
import { useAppDispatch } from '../../../store';
import DashboardAggregate from '../../../types/dashboard/DashboardAggregate';
import XYGraph, { TooltipValueType, SeriesType } from '../../atoms/Graph/XYGraph';
import DonutGraph from '../../atoms/Graph/DonutGraph';
import Loader from '../../atoms/SkeletonLoader/Loader';
import DashboardStatistics from '../../../types/dashboard/DashboardStatistics';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import { darken } from 'polished';
import { ErrorType } from '../../../types/response/ErrorCodes';
import LineChartLineIcon from 'remixicon-react/LineChartLineIcon';
import BucketSize from '../../../types/dashboard/BucketSize';

interface SkeletonLoaderGraphProps {
    dataTestId: string;
    height?: number;
}

export const SkeletonLoaderGraph: React.FC<SkeletonLoaderGraphProps> = ({
    height,
    dataTestId,
}: SkeletonLoaderGraphProps) => {
    const graphTops = [4, 3, 11, 21, 23, 20, 15, 26, 28, 18, 24, 31, 35, 33, 45, 42, 51];

    return (
        <Flex row justifyContent="center" data-testid={dataTestId}>
            <SkeletonLoaderBarContainer
                row
                gap={'3%'}
                justifyContent="space-between"
                height={height}
            >
                {graphTops.map(x => (
                    <Loader key={`${x}-bar`} height={`${x * 2}%`}></Loader>
                ))}
            </SkeletonLoaderBarContainer>
        </Flex>
    );
};

const getDateFormater = (bucketSize?: string) => {
    if (bucketSize === BucketSize.Year) return new Intl.DateTimeFormat('sv', { year: 'numeric' });

    if (bucketSize === BucketSize.Month)
        return new Intl.DateTimeFormat('sv', {
            year: 'numeric',
            month: '2-digit',
        });

    if (bucketSize === BucketSize.Day)
        return new Intl.DateTimeFormat('sv', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

    if (bucketSize === BucketSize.Hour)
        return new Intl.DateTimeFormat('sv', {
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });

    return new Intl.DateTimeFormat('sv', {});
};

const bucketSizeFormatDate = (date: string, bucketSize?: string) =>
    getDateFormater(bucketSize).format(new Date(date));

interface DashboardGraphProps {
    dispatchAction?: AppThunkAction<Promise<void>>;
    type: DashboardGraphType;
    theme: DefaultTheme;
    filterChange: Map<string, string[]>;
    height: number;
    statistics: DashboardStatistics;
    valueCalculation?: (x: DashboardAggregate) => number | string;
    valueType?: TooltipValueType;
    color?: string;
    bucketSize?: string;
}

const DashboardGraph = ({
    theme,
    dispatchAction,
    type,
    filterChange,
    statistics,
    height,
    valueCalculation,
    valueType,
    color,
    bucketSize,
}: DashboardGraphProps) => {
    const dispatch = useAppDispatch();
    const { data = [], isLoading = false, error } = statistics || {};

    useEffect(() => {
        if (dispatchAction) dispatch(dispatchAction);
    }, [filterChange, bucketSize]);

    const answerTotal = data
        ? data.reduce((x, y) => x + ((y.stats ?? [{ count: 0 }])[0].count ?? 0), 0)
        : 0;

    const renderGraph = (graphType: DashboardGraphType) => {
        switch (graphType) {
            case DashboardGraphType.TWO_LINE_PERCENT_STACK:
                const yNegative = (d: DashboardAggregate) =>
                    d.stats && d.stats[0].average === null
                        ? '0'
                        : d.stats && 100 - +(d.stats[0].average * 100).toFixed(0);

                const yPositive = (d: DashboardAggregate) =>
                    d.stats && d.stats[0].average === null
                        ? '0'
                        : d.stats && (d.stats[0].average * 100).toFixed(0);

                const xAccessor = (d: DashboardAggregate) =>
                    bucketSizeFormatDate(`${d.value}`, bucketSize);

                return (
                    <>
                        <span>{answerTotal} total answer(s)</span>
                        <XYGraph
                            height={height}
                            yDomain={[0, 100]}
                            tooltipValueType={TooltipValueType.PERCENT}
                            lines={[
                                {
                                    data: data,
                                    color: theme.colors.accent3,
                                    key: 'line_negative',
                                    xAccessor: xAccessor,
                                    yAccessor: yNegative,
                                },
                                {
                                    data: data,
                                    color: theme.colors.accent2,
                                    key: 'line_positive',
                                    xAccessor: xAccessor,
                                    yAccessor: yPositive,
                                },
                            ]}
                        />
                    </>
                );
            case DashboardGraphType.DONUT:
                return (
                    <DonutGraph
                        height={height}
                        data={data}
                        xAccessor={x => x.value}
                        yAccessor={valueCalculation ?? (x => x.count)}
                    />
                );
            case DashboardGraphType.BARS:
                const values = data
                    .map(valueCalculation ?? ((_: DashboardAggregate) => 0))
                    .map(x => parseFloat((x as string) ?? '0'));
                const max = Math.max(...values);
                const min = Math.min(0, Math.min(...values));
                return (
                    <XYGraph
                        height={height}
                        tooltipValueType={valueType ?? TooltipValueType.Number}
                        seriesType={SeriesType.BARS}
                        lines={[
                            {
                                data: data,
                                key: 'primary_line',
                                xAccessor: (d: DashboardAggregate) => d.value,
                                yAccessor: valueCalculation ?? ((_: DashboardAggregate) => 0),
                                color: color ?? theme.colors.primary,
                            },
                        ]}
                        yDomain={valueType === TooltipValueType.PERCENT ? [0, 100] : [min, max]}
                    />
                );
            case DashboardGraphType.BARS_STACK:
                return (
                    <XYGraph
                        height={height}
                        tooltipValueType={valueType ?? TooltipValueType.Number}
                        seriesType={SeriesType.BARS_STACKED}
                        lines={
                            data && data.length > 0 && data[0].sums
                                ? data[0].sums?.map((x, i) => ({
                                      data: data,
                                      key: x.value,
                                      xAccessor: (d: DashboardAggregate) =>
                                          bucketSizeFormatDate(`${d.date}`, bucketSize),
                                      yAccessor: (d: DashboardAggregate) =>
                                          d.sums && d.sums.length > 0 ? d.sums[i].sum : 0,
                                      color: i ? theme.colors.accent4 : theme.colors.accent3,
                                  }))
                                : []
                        }
                    />
                );
            case DashboardGraphType.BARS_DATE:
                return (
                    <XYGraph
                        height={height}
                        tooltipValueType={valueType ?? TooltipValueType.Number}
                        seriesType={SeriesType.BARS}
                        lines={[
                            {
                                data: data,
                                key: 'primary_line',
                                xAccessor: (d: DashboardAggregate) =>
                                    bucketSizeFormatDate(`${d.date}`, bucketSize),
                                yAccessor: valueCalculation ?? ((_: DashboardAggregate) => 0),
                                color: color ?? theme.colors.primary,
                            },
                        ]}
                        yDomain={valueType === TooltipValueType.PERCENT ? [0, 100] : undefined}
                    />
                );
            case DashboardGraphType.LINE_DATE:
                return (
                    <XYGraph
                        height={height}
                        tooltipValueType={valueType ?? TooltipValueType.Number}
                        seriesType={SeriesType.LINES}
                        lines={[
                            {
                                data: data,
                                key: 'primary_line',
                                xAccessor: (d: DashboardAggregate) =>
                                    bucketSizeFormatDate(`${d.date}`, bucketSize),
                                yAccessor: valueCalculation ?? ((_: DashboardAggregate) => 0),
                                color: color ?? theme.colors.primary,
                            },
                        ]}
                    />
                );
            default:
                return <></>;
        }
    };

    const renderContent = () => {
        const hasData =
            data &&
            data.length > 0 &&
            data.some(
                x =>
                    (x.count && !x?.sums && !x?.stats) ||
                    (x.sums && x.sums?.length > 0 && x.sums[0].sum && x.count > 0) ||
                    (x.stats && x.stats[0].sum && x.stats[0].average) ||
                    (x.values && x.values.length > 0 && x.values[0].value) ||
                    (x.count && x.values && x.values.length === 0)
            );

        if (error) {
            return (
                <FlexContainer
                    alignItems="center"
                    justifyContent="center"
                    column
                    gap={theme.layout.gap.small}
                >
                    <ErrorWarningLineIcon
                        size={theme.icon.size.large}
                        color={darken(0.2, theme.colors.error)}
                    />
                    <ErrorText>{ErrorType.DashboardAggregate}</ErrorText>
                </FlexContainer>
            );
        }

        if (isLoading)
            return <SkeletonLoaderGraph height={height} dataTestId="skeleton-loader-graph" />;

        if (hasData) return renderGraph(type);

        return (
            <FlexContainer
                column
                alignItems="center"
                justifyContent="center"
                gap={theme.layout.gap.small}
            >
                <LineChartLineIcon size={theme.icon.size.large} color={theme.colors.primary} />
                No data to show
            </FlexContainer>
        );
    };

    return <DashboardGraphContainer height={height}>{renderContent()}</DashboardGraphContainer>;
};

export default withTheme(DashboardGraph) as React.ComponentType<Omit<DashboardGraphProps, 'theme'>>;

interface DashboardCardContainerProps {
    highlighted?: boolean;
    background?: string;
    width?: number;
    disabled?: boolean;
}

interface ContainerProps {
    height?: number;
}

export const Container = styled(Flex)<DashboardCardContainerProps>`
    background: ${props => (props.background ? props.background : props.theme.colors.light)};
    color: ${props => props.theme.colors.text.secondary};
    ${props => props.disabled && 'opacity: 0.1;'};
    min-height: 5rem;
    padding: 2.5rem;
    width: 100%;
`;

const SkeletonLoaderBarContainer = styled(Flex)<ContainerProps>`
    transform: rotate(180deg) scaleX(-1);
    width: 100%;
    height: ${props => (props.height ? `${props.height}px` : '50rem')};
`;

const DashboardGraphContainer = styled.div<ContainerProps>`
    height: ${props => (props.height ? `${props.height}px` : '50rem')};

    @media (max-width: 50rem) {
        height: 100%;
    }
`;

const ErrorText = styled(Flex)`
    color: ${props => darken(0.2, props.theme.colors.error)};
`;

const FlexContainer = styled(Flex)`
    height: 100%;
`;
