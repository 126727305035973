/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import MenuLineIcon from 'remixicon-react/MenuLineIcon';
import CheckBox from '../Checkbox/Checkbox';

interface Props {
    text: string;
    index: number;
    onChange: (name: string) => void;
    disableDrag?: boolean;
    theme: DefaultTheme;
}

const DndListItem: React.FC<Props> = ({ text, index, onChange, disableDrag, theme }: Props) => {
    return (
        <Draggable draggableId={text} index={index} isDragDisabled={disableDrag}>
            {provided => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <CheckBox onChange={() => onChange(text)} checked>
                        {text}
                    </CheckBox>
                    {!disableDrag && <MenuLineIcon size={theme.icon.size.small} />}
                </ListItem>
            )}
        </Draggable>
    );
};

const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 0.2rem solid ${props => props.theme.colors.border};
    margin-bottom: 0.3rem;
    background: white;
    height: 2.5rem;
`;

export default withTheme(DndListItem) as React.ComponentType<Omit<Props, 'theme'>>;
