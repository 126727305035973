import { AppThunkAction, AppThunkDispatch } from '../thunk';
import TableHeaderTypes from '../../types/tableHeaderType';
import {
    saveColumnFiltersRequested,
    saveColumnFiltersSucceeded,
    saveColumnFiltersFailed,
} from './userSettingsSlice';
import Logger from '../../services/logging/logger';

const saveColumnFilters =
    (columnFilters: string[], tableHeaderType: TableHeaderTypes): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(saveColumnFiltersRequested);

        try {
            dispatch(saveColumnFiltersSucceeded({ columnFilters, tableHeaderType }));
            Logger.information('User selects columns in {ColumnsType}', {
                Columns: columnFilters,
                ColumnsType: tableHeaderType,
            });
        } catch (error) {
            dispatch(saveColumnFiltersFailed());
        }
    };

export default {
    saveColumnFilters,
};
