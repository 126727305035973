import React from 'react';
import { useForm } from 'react-hook-form';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import FocusLock from 'react-focus-lock';
import { requiredValueConfig } from '../../../types/ValidationTypes';
import FormRow from '../../atoms/Form/FormRow';
import GridInput from '../../atoms/Input/GridInput';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import checkoutActions from '../../../store/checkout/actions';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { useAppState } from '../../../store/appstate';
import { CheckoutState, clearUpdateOrderReference } from '../../../store/checkout/checkoutSlice';
import { ErrorType } from '../../../types/response/ErrorCodes';
import ValidationError from '../../atoms/Validation/ValidationError';
import Spinner from '../../atoms/Spinner/Spinner';
import SuccessMessage from '../../atoms/Message/Success/SuccessMessage';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';

interface UpdateOrderNumberModalProps {
    hide: () => void;
    currentOrderNumber: string | null;
    privateId: string;
    theme: DefaultTheme;
}

type Inputs = {
    orderReference: string;
};

const UpdateOrderNumberModal: React.FC<UpdateOrderNumberModalProps> = ({
    hide,
    currentOrderNumber,
    privateId,
    theme,
}: UpdateOrderNumberModalProps) => {
    const dispatch = useAppDispatch();
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        mode: 'onSubmit',
    });
    const orderReference = register('orderReference', requiredValueConfig);
    setValue('orderReference', currentOrderNumber ?? '');
    const { updateOrderReferenceFailed, isUpdatingOrderReference, isOrderReferenceUpdated } =
        useAppState<CheckoutState>(s => s.checkout);

    const onSubmit = (data: Inputs) =>
        dispatch(checkoutActions.updateOrderReference(privateId, data.orderReference));

    const onClose = () => {
        dispatch(clearUpdateOrderReference());
        hide();
    };

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Update Reference</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content" noScroll>
                {isUpdatingOrderReference && !isOrderReferenceUpdated && (
                    <Spinner text="Saving..." color={theme.colors.primary} size={8} loading />
                )}

                {!isUpdatingOrderReference && isOrderReferenceUpdated && (
                    <ResultContainer>
                        <SuccessMessage>
                            The Pay Link reference was successfully updated!
                        </SuccessMessage>
                    </ResultContainer>
                )}

                {!isUpdatingOrderReference && !isOrderReferenceUpdated && (
                    <>
                        <ContentRow>
                            <FocusLock>
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    data-testid="set-paylink-order-number-form"
                                    id="set-paylink-order-number-form"
                                >
                                    <FormRow first last>
                                        <GridInput
                                            type="text"
                                            id="orderReference"
                                            placeholder="Reference"
                                            name={orderReference.name}
                                            setRef={orderReference.ref}
                                            onChange={e => orderReference.onChange(e)}
                                            validationError={!!errors.orderReference}
                                            maxLength={50}
                                        />
                                    </FormRow>
                                </form>
                            </FocusLock>
                        </ContentRow>
                    </>
                )}

                <ValidationError>
                    <p>
                        {errors.orderReference &&
                            'Reference is a required field and needs to be filled in before proceeding.'}
                    </p>
                </ValidationError>
            </ModalContainer>

            {updateOrderReferenceFailed && (
                <ModalContainer position="error">
                    <ErrorMessage
                        error={updateOrderReferenceFailed}
                        errorHeader={ErrorType.UpdatePaylinkOrderNumber}
                    />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={onClose} large>
                        {isOrderReferenceUpdated ? 'Close' : 'Cancel'}
                    </Button>

                    {!isOrderReferenceUpdated && !isUpdatingOrderReference && (
                        <Button
                            tabIndex={0}
                            form="set-paylink-order-number-form"
                            type="submit"
                            primary
                            large
                        >
                            <span>Save</span>
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(UpdateOrderNumberModal) as React.ComponentType<
    Omit<UpdateOrderNumberModalProps, 'theme'>
>;

const ContentRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
`;

const ResultContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-bottom: 2.5rem;
`;
