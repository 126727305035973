import React from 'react';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import dashboardActions from '../../../store/dashboard/actions';
import Button from '../../atoms/Button/Button';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import { Modal } from '../../atoms/Modal/Modal';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import Shortcut from './Shortcut';
import Logger from '../../../services/logging/logger';
import useModal from '../../../services/hooks/useModal';

interface InputFields {
    comment: string;
}

const queries = [
    {
        id: 'ordersexpiresin7days',
        title: 'Orders expires in 7 days',
        query: '?query=&page=1&perPage=30&filter=status:%5B%22expired%22,%22partactivated%22,%22notactivated%22%5D&filter=expiresAt:%5B%22+7d%22%5D&columns=',
        path: '/orders',
        dispatchMethod: dashboardActions.dashboardSearch(
            'ordersexpiresin7days',
            '?query=&page=1&perPage=0&filter=status:%5B%22expired%22,%22partactivated%22,%22notactivated%22%5D&filter=expiresAt:%5B%22+7d%22%5D&columns='
        ),
    },
    {
        id: 'ordersnotactivated',
        title: 'Orders not Activated',
        query: '?query=&page=1&perPage=30&filter=status:%5B%22notactivated%22%5D&columns=',
        path: '/orders',
        dispatchMethod: dashboardActions.dashboardSearch(
            'ordersnotactivated',
            '?query=&page=0&perPage=0&filter=status:%5B%22notactivated%22%5D&columns='
        ),
    },
    {
        id: 'partactivatedorders',
        title: 'Orders Part Activated',
        query: '?query=&page=1&perPage=30&filter=status:%5B"partactivated"%5D&columns=',
        path: '/orders',
        dispatchMethod: dashboardActions.dashboardSearch(
            'partactivatedorders',
            '?query=&page=1&perPage=0&filter=status:%5B"partactivated"%5D&columns='
        ),
    },
];

const ShortcutMenu: React.FC = close => {
    const fakeDoorModal = useModal();
    const defaults = {
        defaultValues: {
            comment: '',
        },
    };
    const { register, handleSubmit, reset } = useForm<InputFields>(defaults);
    const comment = register('comment');

    const onSubmit = (form: InputFields) => {
        Logger.information('Dashboard feature add new card', {
            comment: form.comment,
        });

        reset({ comment: '' });

        fakeDoorModal.toggle();
    };

    return (
        <>
            <ShortCutMenu>
                <ShortCutMenuHeader>
                    <h3>Shortcuts</h3>
                    <AddShortcutButton primary onClick={fakeDoorModal.toggle}>
                        Add <AddLineIcon size={17} />
                    </AddShortcutButton>
                </ShortCutMenuHeader>

                <div>
                    {queries.map(querySearch => (
                        <Shortcut
                            key={`shortcut-${querySearch.title}`}
                            id={querySearch.id}
                            path={querySearch.path}
                            title={querySearch.title}
                            queryString={querySearch.query}
                            dispatchMethod={querySearch.dispatchMethod}
                        />
                    ))}
                </div>
            </ShortCutMenu>

            <Modal small isShown={fakeDoorModal.isShown} hide={fakeDoorModal.toggle}>
                <>
                    <ModalContainer position="header">
                        <>We need your feedback! 💡</>
                    </ModalContainer>
                    <ModalContainer position="content">
                        <Text>
                            This feature will be updated to work in the future, thank you for your
                            patience. In the meantime, we would like to know what you think:
                            <ul>
                                <li>What is missing on the dashboard?</li>
                            </ul>
                        </Text>
                        <form id="comment-form" onSubmit={handleSubmit(onSubmit)}>
                            <GridTextArea
                                onChange={comment.onChange}
                                name={comment.name}
                                ref={comment.ref}
                                rows={5}
                                cols={10}
                                id="commentInput"
                            />
                        </form>
                    </ModalContainer>
                    <ModalContainer position="footer">
                        <FooterButtonContainer>
                            <Button large onClick={fakeDoorModal.toggle}>
                                Close
                            </Button>
                            <Button type="submit" form="comment-form" primary large>
                                Send
                            </Button>
                        </FooterButtonContainer>
                    </ModalContainer>
                </>
            </Modal>
        </>
    );
};

export default ShortcutMenu;

const ShortCutMenu = styled.div`
    padding: 2rem 5rem;
`;

const ShortCutMenuHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const AddShortcutButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
    margin-bottom: 2rem;
`;

const GridTextArea = styled.textarea`
    max-width: 40rem;
    min-width: 40rem;
    max-height: 15rem;
    min-height: 10rem;

    &:focus {
        outline: none !important;
        border: 0.2rem solid ${props => props.theme.colors.primary};
        border-radius: 0.3rem;
    }
`;
