import { useState } from 'react';

function useFilter<T>(
    originalData: T[],
    filterFunc: (data: T, filterHelper: (filterOn: string) => boolean) => boolean,
    minShowAt = 10
) {
    const data = originalData ?? [];
    const [search, setSearch] = useState('');

    const helpFilterAgainstSearchWord = (filterOn: string) => {
        return filterOn?.toLocaleLowerCase().includes(search.toLowerCase());
    };

    const showing = originalData
        ? data.filter(x => filterFunc(x, helpFilterAgainstSearchWord))
        : [];

    return {
        search,
        setSearch,
        showing: showing && showing.length === 0 && search === '' ? data : showing,
        showFilter: originalData && originalData.length >= minShowAt,
    };
}

export default useFilter;
