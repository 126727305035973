import styled from 'styled-components';

const TimeStamp = styled.div`
    color: ${props => props.theme.colors.text.secondary};
    font-size: ${props => props.theme.text.size.small};
    position: relative;
    top: 2.1rem;
`;

export default TimeStamp;
