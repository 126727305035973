/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CheckoutSession from '../../types/paylink/CheckoutSession';
import PaylinkStatus from '../../types/paylink/PaylinkStatus';
import {
    CheckoutDetailsLinks,
    CheckoutDetailsResponseData,
} from '../../types/response/CheckoutDetailsResponseData';
import CheckoutInitResponse from '../../types/response/CheckoutInitResponse';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import ResponseError from '../../types/response/ResponseError';
import { PostResponseData } from '../../types/response/PostResponseData';

export interface CheckoutState {
    isInitializingCheckout: boolean;
    initializedCheckout: CheckoutInitResponse | undefined;
    initializedPurchase: PostResponseData | undefined;
    initCheckoutFailed: ResponseError | undefined;
    isSendingPaylink: boolean;
    sendPaylinkFailed: ResponseError | undefined;
    paylinkSent: boolean;
    isSearchingPaylink: boolean;
    paylinks: PagingResponseData<CheckoutSession[]>;
    searchPaylinkFailed: ResponseError | undefined;
    isGettingPaylinkDetails: boolean;
    paylink: CheckoutSession;
    paylinkLinks: CheckoutDetailsLinks;
    getPaylinkDetailsFailed: ResponseError | undefined;
    isAbandoningPaylink: boolean;
    abandonPaylinkFailed: ResponseError | undefined;
    paylinkAbandoned: boolean;
    isUpdatingOrderReference: boolean;
    isOrderReferenceUpdated: boolean;
    updateOrderReferenceFailed: ResponseError | undefined;
}

const initialState: CheckoutState = {
    isInitializingCheckout: false,
    initializedCheckout: undefined,
    initCheckoutFailed: undefined,
    initializedPurchase: undefined,
    isSendingPaylink: false,
    sendPaylinkFailed: undefined,
    paylinkSent: false,
    isSearchingPaylink: false,
    paylinks: {} as PagingResponseData<CheckoutSession[]>,
    searchPaylinkFailed: undefined,
    isGettingPaylinkDetails: false,
    paylink: {} as CheckoutSession,
    paylinkLinks: {} as CheckoutDetailsLinks,
    getPaylinkDetailsFailed: undefined,
    isAbandoningPaylink: false,
    paylinkAbandoned: false,
    abandonPaylinkFailed: undefined,
    isUpdatingOrderReference: false,
    isOrderReferenceUpdated: false,
    updateOrderReferenceFailed: undefined,
};

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        initCheckoutStarted: state => ({
            ...state,
            initCheckoutFailed: undefined,
            isInitializingCheckout: true,
        }),
        initCheckoutSucceeded: (state, action: PayloadAction<CheckoutInitResponse>) => ({
            ...state,
            isInitializingCheckout: false,
            initializedCheckout: action.payload,
        }),
        initPurchaseSucceeded: (state, action: PayloadAction<PostResponseData>) => ({
            ...state,
            isInitializingCheckout: false,
            initializedPurchase: action.payload,
        }),
        initCheckoutFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isInitializingCheckout: false,
            initCheckoutFailed: action.payload,
        }),
        clearCheckout: state => ({
            ...state,
            isInitializingCheckout: false,
            initCheckoutFailed: undefined,
            initializedCheckout: undefined,
            initializedPurchase: undefined,
            sendPaylinkFailed: undefined,
            paylinkSent: false,
            paylinkAbandoned: false,
            paylinkDeleted: false,
            isAbandoningPaylink: false,
            abandonPaylinkFailed: undefined,
            deletePaylinkFailed: undefined,
            isUpdatingOrderReference: false,
            isOrderReferenceUpdated: false,
            updateOrderReferenceFailed: undefined,
        }),
        clearCheckoutInit: state => ({
            ...state,
            isInitializingCheckout: false,
            initCheckoutFailed: undefined,
        }),
        sendPaylinkStarted: state => ({
            ...state,
            isSendingPaylink: true,
            sendPaylinkFailed: undefined,
        }),
        sendPaylinkSucceeded: state => ({
            ...state,
            isSendingPaylink: false,
            paylinkSent: true,
        }),
        sendPaylinkFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isSendingPaylink: false,
            sendPaylinkFailed: action.payload,
        }),
        searchPaylinkStarted: state => ({
            ...state,
            isSearchingPaylink: true,
        }),
        searchPaylinkSucceeded: (
            state,
            action: PayloadAction<PagingResponseData<CheckoutSession[]>>
        ) => ({
            ...state,
            isSearchingPaylink: false,
            paylinks: action.payload,
        }),
        searchPaylinkFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isSearchingPaylink: false,
            searchPaylinkFailed: action.payload,
        }),
        getPaylinkDetailsStarted: state => ({
            ...state,
            isGettingPaylinkDetails: true,
            paylink: {} as CheckoutSession,
            paylinkLinks: {} as CheckoutDetailsLinks,
        }),
        getPaylinkDetailsSucceeded: (
            state,
            action: PayloadAction<CheckoutDetailsResponseData>
        ) => ({
            ...state,
            isGettingPaylinkDetails: false,
            paylink: action.payload.data,
            paylinkLinks: action.payload.links,
        }),
        getPaylinkDetailsFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isGettingPaylinkDetails: false,
            getPaylinkDetailsFailed: action.payload,
        }),
        clearPaylinkDetails: state => ({
            ...state,
            isGettingPaylinkDetails: false,
            getPaylinkDetailsFailed: undefined,
            paylink: {} as CheckoutSession,
            paylinkLinks: {} as CheckoutDetailsLinks,
        }),
        abandonPaylinkStarted: state => ({
            ...state,
            isAbandoningPaylink: true,
        }),
        abandonPaylinkSucceeded: state => ({
            ...state,
            isAbandoningPaylink: false,
            paylinkAbandoned: true,
            paylink: { ...state.paylink, status: PaylinkStatus.Aborted },
        }),
        abandonPaylinkFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            paylinkAbandoned: false,
            abandonPaylinkFailed: action.payload,
        }),
        updateOrderReferenceStarted: state => ({
            ...state,
            isUpdatingOrderReference: true,
            isOrderReferenceUpdated: false,
            updateOrderReferenceFailed: undefined,
        }),
        updateOrderReferenceSucceeded: (state, action: PayloadAction<string>) => ({
            ...state,
            isUpdatingOrderReference: false,
            isOrderReferenceUpdated: true,
            updateOrderReferenceFailed: undefined,
            paylink: { ...state.paylink, reference: action.payload },
        }),
        updateOrderReferenceFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isUpdatingOrderReference: false,
            isOrderReferenceUpdated: false,
            updateOrderReferenceFailed: action.payload,
        }),
        clearUpdateOrderReference: state => ({
            ...state,
            isUpdatingOrderReference: false,
            isOrderReferenceUpdated: false,
            updateOrderReferenceFailed: undefined,
        }),
    },
});

export const {
    initCheckoutStarted,
    initCheckoutSucceeded,
    initCheckoutFailed,
    clearCheckout,
    sendPaylinkStarted,
    sendPaylinkSucceeded,
    sendPaylinkFailed,
    clearCheckoutInit,
    searchPaylinkStarted,
    searchPaylinkSucceeded,
    searchPaylinkFailed,
    getPaylinkDetailsStarted,
    getPaylinkDetailsSucceeded,
    getPaylinkDetailsFailed,
    clearPaylinkDetails,
    abandonPaylinkStarted,
    abandonPaylinkSucceeded,
    abandonPaylinkFailed,
    initPurchaseSucceeded,
    updateOrderReferenceStarted,
    updateOrderReferenceSucceeded,
    updateOrderReferenceFailed,
    clearUpdateOrderReference,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
