import { config } from '../../config';
import { LinkData } from '../../types/response/LinkData';

const fetchSettlementFile = (
    token: string,
    linkData: LinkData,
    failed: () => void,
    setProcessing: (isProcessing: boolean) => void
) => {
    if (!token) return;

    setProcessing(true);

    fetch(`${config.ApiHost}${linkData.href}`, {
        method: linkData.method,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(new Error(response.statusText));
            }

            return response.blob().then(data => {
                return {
                    data,
                    filename: response.headers.get('content-disposition') ?? '',
                };
            });
        })
        .then(({ data, filename }) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                filename.split('=')[1].replace('; filename*', '').replace(/"/g, '')
            );

            setProcessing(false);

            document.body.appendChild(link);

            link.click();

            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        })
        .catch(error => {
            failed();
            setProcessing(false);
        });
};

export default fetchSettlementFile;
