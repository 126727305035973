import { useAppState } from '../../store/appstate';
import { PermissionOperation } from '../../types/PermissionOperation';
import SessionUserPermissions from '../../types/SessionUserPermissions';

const empty: SessionUserPermissions[] = [];

const usePermissions = (tags: string[] | undefined) => {
    const permissions = useAppState<SessionUserPermissions[]>(
        state => state.session.user?.permissions ?? empty
    );
    const filteredPermissions = permissions.filter(p => tags?.includes(p.tag));
    const read = filteredPermissions.some(x => x.operation === PermissionOperation.read);
    const write = filteredPermissions.some(x => x.operation === PermissionOperation.write);

    return {
        hasReadAndWrite: read && write,
        hasRead: read,
        hasWrite: write,
        hasOperation: (operation: string): boolean =>
            filteredPermissions.some(x => x.operation == operation),
    };
};

export default usePermissions;
