import React from 'react';
import styled from 'styled-components';
import Multistatus from '../../../types/response/Multistatus';
import MessageContainer from './MessageContainer';

interface MultistatusMessageProps {
    messages: Multistatus[];
}

const MultistatusMessage: React.FC<MultistatusMessageProps> = ({
    messages,
}: MultistatusMessageProps) => {
    return (
        <>
            <MessageContainer type="Error" header="">
                <Container>
                    {messages.map(x => {
                        return (
                            <StatusMessage key={x.id}>
                                {x.reason && `Order with id ${x.id} ${x.reason}`}
                            </StatusMessage>
                        );
                    })}
                </Container>
            </MessageContainer>
        </>
    );
};

const StatusMessage = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin: 2rem 0;
`;

const Container = styled.div`
    font-size: ${props => props.theme.text.size.xsmall};
    max-height: 10rem;
    overflow-y: auto;
`;

export default MultistatusMessage;
