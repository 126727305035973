import React from 'react';
import styled from 'styled-components';

const WeekDays: React.FC = () => {
    const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return (
        <WeekdaysContainer>
            {weekDays.map(day => (
                <div key={day}>{day}</div>
            ))}
        </WeekdaysContainer>
    );
};

const WeekdaysContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.5rem 0 1rem 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
`;

export default WeekDays;
