import { TooltipValueType } from '../../components/atoms/Graph/XYGraph';
import { walleyLightTheme } from '../../theme/themes';
import DashboardAggregate from '../../types/dashboard/DashboardAggregate';
import DashboardEntityType from '../../types/dashboard/DashboardEntityType';
import DashboardGraphType from '../../types/dashboard/DashboardGraphType';
import DashboardTopCategoryType from '../../types/dashboard/DashboardTopCategoryType';

const categorySections = [
    {
        title: 'Customer satisfaction (CSAT)',
        topCategory: DashboardTopCategoryType.RETENTION,
        id: 'customerSatisfaction',
        graphId: 'customerSatisfactionDays',
        query: 'customer_satisfaction_days',
        graphType: DashboardGraphType.TWO_LINE_PERCENT_STACK,
    },
    {
        title: 'Purchase Frequency',
        topCategory: DashboardTopCategoryType.RETENTION,
        id: 'purchasePerCustomer',
        graphId: 'purchasePerCustomerDays',
        query: 'purchase_per_customer_days',
        graphType: DashboardGraphType.LINE_DATE,
        graphPerRow: 1,
        valueCalculation: (item: DashboardAggregate) => {
            if (!item.values) return 0;

            const value = item.count / (item.values[0].value as number);

            return Number.isNaN(value) ? 0 : value.toFixed(2);
        },
        valueType: TooltipValueType.Number,
        color: walleyLightTheme.colors.accent4,
    },
    {
        title: 'Repeat customer rate',
        topCategory: DashboardTopCategoryType.RETENTION,
        id: 'repeatCustomerRate',
        graphId: 'repeatCustomerRateDays',
        query: 'repeat_customers_days',
        graphType: DashboardGraphType.LINE_DATE,
        graphPerRow: 1,
        valueCalculation: (d: DashboardAggregate) => {
            if (!d.values) return 0;

            const value = ((d.values[1].value as number) / (d.values[0].value as number)) * 100;

            return Number.isNaN(value) ? 0 : value.toFixed(2);
        },
        valueType: TooltipValueType.PERCENT,
        color: walleyLightTheme.colors.accent3,
    },
    {
        title: 'Order Volume',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCurrencies',
        graphId: 'orderTotalCurrenciesDays',
        query: 'order_volume_days',
        graphType: DashboardGraphType.BARS_DATE,
        graphPerRow: 1,
        valueCalculation: (d: DashboardAggregate) => (d.values ? d.values[0].value ?? 0 : 0),
        valueType: TooltipValueType.MONEY,
    },
    {
        title: 'Order Placed',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCount',
        graphId: 'orderCountDays',
        query: 'order_count_days',
        graphType: DashboardGraphType.BARS_DATE,
        graphPerRow: 1,
        valueCalculation: (d: DashboardAggregate) => (d.values ? d.count ?? 0 : 0),
    },
    {
        title: 'Payment Methods',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCount',
        graphId: 'orderPaymentMethodCount',
        query: 'order_count_payment_methods',
        graphType: DashboardGraphType.DONUT,
        graphsPerRow: 2,
    },
    {
        title: 'Age Group',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCount',
        graphId: 'orderAgesCount',
        query: 'order_count_ages',
        graphType: DashboardGraphType.DONUT,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) => d.count,
        valueType: undefined,
        color: undefined,
    },
    {
        title: 'Products',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCount',
        graphId: 'orderProductsCount',
        query: 'order_count_product_codes',
        graphType: DashboardGraphType.DONUT,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) => d.count,
        valueType: undefined,
        color: undefined,
    },
    {
        title: 'Payment Methods',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCurrencies',
        graphId: 'orderTotalPaymentMethods',
        query: 'order_volume_payment_methods',
        graphType: DashboardGraphType.DONUT,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            d.sums && d.sums.length > 0 ? d.sums[0].sum : 0,
        valueType: undefined,
        color: undefined,
    },
    {
        title: 'Age Group',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCurrencies',
        graphId: 'orderTotalAges',
        query: 'order_volume_ages',
        graphType: DashboardGraphType.DONUT,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            d.sums && d.sums.length > 0 ? d.sums[0].sum : 0,
        valueType: TooltipValueType.MONEY,
        color: undefined,
    },
    {
        title: 'Products',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchCurrencies',
        graphId: 'orderTotalProducts',
        query: 'order_volume_product_codes',
        graphType: DashboardGraphType.DONUT,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            d.sums && d.sums.length > 0 ? d.sums[0].sum : 0,
        valueType: TooltipValueType.MONEY,
        color: undefined,
    },
    {
        title: 'AOV',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchAov',
        graphId: 'orderAverageDays',
        query: 'average_order_volume_days',
        graphType: DashboardGraphType.BARS_DATE,
        graphsPerRow: 1,
        valueCalculation: (d: DashboardAggregate) => (d.values ? d.values[0].value ?? 0 : 0),
        valueType: TooltipValueType.MONEY,
        color: undefined,
    },
    {
        title: 'Payment Methods',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchAov',
        graphId: 'orderAovPaymentMethods',
        query: 'average_order_volume_payment_methods',
        graphType: DashboardGraphType.BARS,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            d.sums && d.sums.length > 0 ? d.sums[0].sum : 0,
        valueType: TooltipValueType.MONEY,
        color: undefined,
    },
    {
        title: 'Age Group',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchAov',
        graphId: 'orderAovAges',
        query: 'average_order_volume_ages',
        graphType: DashboardGraphType.BARS,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            d.sums && d.sums.length > 0 ? d.sums[0].sum : 0,
        valueType: TooltipValueType.MONEY,
        color: undefined,
    },
    {
        title: 'Products',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'aggregateSearchAov',
        graphId: 'orderAovProducts',
        query: 'average_order_volume_product_codes',
        graphType: DashboardGraphType.BARS,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            d.sums && d.sums.length > 0 ? d.sums[0].sum : 0,
        valueType: TooltipValueType.MONEY,
        color: undefined,
    },
    {
        title: 'Payment Methods',
        topCategory: DashboardTopCategoryType.RETENTION,
        id: 'purchasePerCustomer',
        graphId: 'purchasePerCustomerPaymentMethods',
        query: 'purchase_per_customer_payment_methods',
        graphType: DashboardGraphType.BARS,
        graphsPerRow: 2,
        valueCalculation: (item: DashboardAggregate) => {
            const value = item.sums && item.sums.length > 0 ? item.count / item.sums[0].sum : 0;

            return Number.isNaN(value) ? '0' : value.toFixed(2);
        },
        valueType: undefined,
        color: walleyLightTheme.colors.accent4,
    },
    {
        title: 'Age Group',
        topCategory: DashboardTopCategoryType.RETENTION,
        id: 'purchasePerCustomer',
        graphId: 'purchasePerCustomerAges',
        query: 'purchase_per_customer_ages',
        graphType: DashboardGraphType.BARS,
        graphsPerRow: 2,
        valueCalculation: (item: DashboardAggregate) => {
            const value = item.sums ? item.count / item.sums[0].sum : 0;

            return Number.isNaN(value) ? '0' : value.toFixed(2);
        },
        valueType: undefined,
        color: walleyLightTheme.colors.accent4,
    },
    {
        title: 'Payment Methods',
        topCategory: DashboardTopCategoryType.RETENTION,
        id: 'customerSatisfaction',
        graphId: 'customerSatisfactionPaymentMethods',
        query: 'customer_satisfaction_payment_methods',
        graphType: DashboardGraphType.BARS,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            ((d.sums && d.sums.length > 0 ? d.sums[0].sum : 0) * 100).toFixed(0),
        valueType: TooltipValueType.PERCENT,
        color: walleyLightTheme.colors.accent2,
    },
    {
        title: 'Age Group',
        topCategory: DashboardTopCategoryType.RETENTION,
        id: 'customerSatisfaction',
        graphId: 'customerSatisfactionAovAges',
        query: 'customer_satisfaction_ages',
        graphType: DashboardGraphType.BARS,
        graphsPerRow: 2,
        valueCalculation: (d: DashboardAggregate) =>
            ((d.sums && d.sums.length > 0 ? d.sums[0].sum : 0) * 100).toFixed(0),
        valueType: TooltipValueType.PERCENT,
        color: walleyLightTheme.colors.accent2,
    },
    {
        title: 'Return Share',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'returns',
        graphId: 'returnRate_days',
        query: 'return_rate_by_days',
        graphType: DashboardGraphType.LINE_DATE,
        graphsPerRow: 1,
        valueCalculation: (d: DashboardAggregate) => {
            const { sums: [{ sum: refunded }, { sum: captured }] = [] } = d;

            return (!refunded || !captured ? 0 : Math.abs(refunded / captured) * 100).toFixed(0);
        },
        valueType: TooltipValueType.PERCENT,
        color: walleyLightTheme.colors.accent3,
        entity: DashboardEntityType.Settlements,
    },
    {
        title: 'Return Volume',
        topCategory: DashboardTopCategoryType.SALES,
        id: 'returns',
        graphId: 'returnRate_days_stack',
        query: 'return_rate_by_days',
        graphType: DashboardGraphType.BARS_STACK,
        graphsPerRow: 1,
        valueType: TooltipValueType.MONEY,
        entity: DashboardEntityType.Settlements,
    },
];

export default categorySections;
