import { OrderEvent } from '../../types/order/OrderEvent';
import OrderEventType from '../../types/order/OrderEventType';
import OrderStatus from '../../types/order/OrderStatus';
import InvoiceStatus from '../../types/invoice/InvoiceStatus';
import InvoiceState from '../../types/invoice/InvoiceState';
import PaylinkStatus from '../../types/paylink/PaylinkStatus';

type OrderStatusProps = {
    activated: string;
    notActivated: string;
    closed: string;
    partActivated: string;
    onHold: string;
};

type InvoiceStatusProps = {
    paid: string;
    notPaid: string;
    partiallyPaid: string;
    canceled: string;
};

type ThemeProps = OrderStatusProps & InvoiceStatusProps;

export const invoiceStatus = (status: string, theme?: ThemeProps) => {
    switch (status) {
        case InvoiceStatus.FullyPaid:
            return { text: 'Paid', color: theme ? theme.paid : '' };
        case InvoiceStatus.PartiallyPaid:
            return { text: 'Partially paid', color: theme ? theme.partiallyPaid : '' };
        case InvoiceStatus.NotPaid:
            return { text: 'Not paid', color: theme ? theme.notPaid : '' };
        case InvoiceStatus.Canceled:
            return { text: 'Canceled', color: theme ? theme.canceled : '' };
        default:
            return { text: status, color: 'grey' };
    }
};

export const orderStatus = (status: string, theme?: ThemeProps) => {
    switch (status.toLowerCase()) {
        case OrderStatus.Activated.toLowerCase():
            return { text: 'Activated', color: theme ? theme.activated : '' };
        case OrderStatus.NotActivated.toLowerCase():
            return { text: 'Not activated', color: theme ? theme.notActivated : '' };
        case OrderStatus.Closed.toLowerCase():
            return { text: 'Closed', color: theme ? theme.closed : '' };
        case OrderStatus.PartActivated.toLowerCase():
            return { text: 'Partly activated', color: theme ? theme.partActivated : '' };
        case OrderStatus.OnHold.toLowerCase():
            return { text: 'On hold', color: theme?.onHold || '' };
        case OrderStatus.VerificationNeeded.toLowerCase():
            return { text: 'Verification needed', color: theme?.onHold || '' };
        case OrderStatus.Returned.toLowerCase():
            return { text: 'Returned', color: theme?.closed || '' };
        case OrderStatus.Expired.toLowerCase():
            return { text: 'Expired', color: theme?.closed || '' };
        default:
            return { text: status, color: 'grey' };
    }
};

export const paylinkStatus = (status: string, theme?: ThemeProps) => {
    switch (status) {
        case PaylinkStatus.Pending:
            return { text: 'Pending', color: theme ? theme.partActivated : '' };
        case PaylinkStatus.CustomerIdentified:
            return { text: 'Customer identified', color: theme ? theme.partActivated : '' };
        case PaylinkStatus.CommittedToPayment:
            return { text: 'Payment in progress', color: theme ? theme.partActivated : '' };
        case PaylinkStatus.Completed:
            return { text: 'Completed', color: theme ? theme.activated : '' };
        default:
            return { text: status, color: 'grey' };
    }
};

export const eventStatusText = (event: OrderEvent) => {
    const notificationEventTitle =
        event.metaData.invoiceState === InvoiceState.CreditNote ? 'Credit note' : 'Notification';

    switch (event.eventType) {
        case OrderEventType.AmountDisbursed:
            return `Amount settled`;
        case OrderEventType.NotificationCreated:
            return `${notificationEventTitle} created`;
        case OrderEventType.NotificationDueDateChanged:
            return `${notificationEventTitle}  due date extended`;
        case OrderEventType.NotificationSent:
            return `${notificationEventTitle} sent`;
        case OrderEventType.PaymentMade:
            return `Payment made`;
        case OrderEventType.ReturnMade:
            return `Return made`;
        case OrderEventType.AdjustmentMade:
            return `Adjustment made`;
        case OrderEventType.RowsCanceled:
            return `Rows canceled`;
        case OrderEventType.RowsExpired:
            return `Rows expired`;
        case OrderEventType.RowsExpirationExtended:
            return `Rows expiration extended`;
        case OrderEventType.StatusChangedToNotActivated:
            return 'Order not activated';
        case OrderEventType.StatusChangedToActivated:
            return 'Order activated';
        case OrderEventType.StatusChangedToPartActivated:
            return 'Order partly activated';
        case OrderEventType.StatusChangedToReturned:
            return 'Order returned';
        case OrderEventType.StatusChangedToExpired:
            return 'Order expired';
        case OrderEventType.StatusChangedToClosed:
            return 'Order closed';
        case OrderEventType.StatusChangedToOnHold:
            return 'Order placed on hold';
        case OrderEventType.RowsActivated:
            return `Rows activated`;
        case OrderEventType.Created:
            return 'Order created';
        case OrderEventType.RowsChanged:
            return `Rows changed`;
        case OrderEventType.ReleasedFromHold:
            return 'Order approved';
        case OrderEventType.Rejected:
            return 'Order rejected';
        case OrderEventType.Verified:
            return 'Customer verified';
        case OrderEventType.Extended:
            return 'Order extended';
        case OrderEventType.AccountMoved:
            return 'Debt moved';
        case OrderEventType.OrderInvoiceAddressChanged:
            return 'Invoice address updated';
        default:
            return '-';
    }
};

export const eventStatusBadge = (event: OrderEvent) => {
    switch (event.eventType) {
        case OrderEventType.StatusChangedToNotActivated:
            return { status: OrderStatus.NotActivated };
        case OrderEventType.StatusChangedToActivated:
            return { status: OrderStatus.Activated };
        case OrderEventType.StatusChangedToPartActivated:
            return { status: OrderStatus.PartActivated };
        case OrderEventType.StatusChangedToReturned:
            return { status: OrderStatus.Returned };
        case OrderEventType.StatusChangedToExpired:
            return { status: OrderStatus.Expired };
        case OrderEventType.StatusChangedToClosed:
            return { status: OrderStatus.Closed };
        case OrderEventType.StatusChangedToOnHold:
            return { status: OrderStatus.OnHold };
        case OrderEventType.Created:
            return {
                status: event.metaData?.status || OrderStatus.NotActivated,
            };
        default:
            return undefined;
    }
};

export const eventTotalText = (eventType: string) => {
    switch (eventType) {
        case 'ReturnMade':
            return 'Total returned amount (VAT)';
        case 'RowsActivated':
            return 'Total activated amount (VAT)';
        case 'RowsChanged':
            return 'Total value change (VAT)';
        case 'AdjustmentMade':
            return 'Total adjustment (VAT)';
        default:
            return '';
    }
};
