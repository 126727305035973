import React from 'react';
import styled from 'styled-components';
import getCustomerType from '../../../services/helpers/customerTypeHelper';
import { formatDate } from '../../../services/helpers/dateTimeFormats';
import { formatNumber } from '../../../services/helpers/numberFormats';
import RegistrationNumberFormatter from '../../atoms/RegistrationNumberFormatter';
import getStoreInfo from '../../../services/helpers/storeHelper';
import { ConnectedStore } from '../../../types/ConnectedStore';
import { Order } from '../../../types/order/Order';
import StatusTypes from '../../../types/StatusTypes';
import CustomerIcon from '../../atoms/CustomerIcon/CustomerIcon';
import FlagIcon from '../../atoms/FlagIcon/FlagIcon';
import NoTranslateLabel from '../../atoms/Label/NoTranslateLabel';
import Status from '../../atoms/Status/Status';
import ColumnStoreLabel from '../../atoms/Store/ColumnStoreLabel';
import TwoRowCell from '../../atoms/Table/TwoRowCell';
import { ColumnDef } from '@tanstack/react-table';

const generateOrderTanHeaders = (availableStores: ConnectedStore[]): ColumnDef<Order>[] => {
    return [
        {
            header: 'Created',
            accessorKey: 'placedAt',
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;
                const timeArray = formatDate(new Date(order.placedAt));
                return <TwoRowCell upper={timeArray.yyyyMMdd} lower={timeArray.HHmm} />;
            },
        },
        {
            header: 'Expires',
            accessorKey: 'expiresAt',
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;
                const timeArray = formatDate(new Date(order.expiresAt));
                return (
                    <>
                        {order.expiresAt ? (
                            <TwoRowCell upper={timeArray.yyyyMMdd} lower={timeArray.HHmm} />
                        ) : (
                            <span>-</span>
                        )}
                    </>
                );
            },
        },
        {
            accessorKey: 'orderNumber',
            header: 'Reference',
            cell: (props: { row: { original: Order } }) => {
                return <span>{props.row.original.orderNumber}</span>;
            },
        },
        {
            header: 'Custom store name',
            id: 'customeStoreName',
            accessorFn: (props: Order) => {
                return props.storeId;
            },
            enableSorting: false,
            cell: (props: { row: { original: Order } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return (
                    <span>
                        {storeInfo && storeInfo.selfReference ? storeInfo.selfReference : '-'}
                    </span>
                );
            },
        },
        {
            header: 'Type',
            id: 'storeType',
            accessorKey: 'storeId',
            meta: {
                sortKey: 'saleSegment',
            },
            cell: (props: { row: { original: Order } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);
                const customerType =
                    storeInfo &&
                    storeInfo.customerSalesType &&
                    getCustomerType(storeInfo.customerSalesType);

                return storeInfo && customerType ? (
                    <CustomerIcon customerType={customerType} />
                ) : (
                    <span>-</span>
                );
            },
        },
        {
            header: 'Country',
            id: 'storeCountry',
            accessorKey: 'storeId',
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            enableSorting: false,
            cell: (props: { row: { original: Order } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return storeInfo && storeInfo.countryCode ? (
                    <FlagIcon countryCode={storeInfo.countryCode} />
                ) : (
                    <span>-</span>
                );
            },
        },
        {
            header: 'Store',
            id: 'store',
            enableSorting: true,
            accessorFn: (props: Order) => {
                return <span>{props.storeId}</span>;
            },
            meta: {
                style: {
                    textAlign: 'left',
                },
                sortKey: 'storeId',
            },
            cell: (props: { row: { original: Order } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return storeInfo && storeInfo.storeName ? (
                    <ColumnStoreLabel store={storeInfo} />
                ) : (
                    <span>{storeId}</span>
                );
            },
        },
        {
            header: 'Customer name',
            id: 'customerName',
            accessorKey: 'customerName',
            meta: {
                sortKey: 'customerDetails.name',
            },
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;

                if (!order.customerDetails) return <></>;

                return (
                    <CustomerWrapper>
                        <span>
                            {order.customerDetails.name !== ' '
                                ? order.customerDetails.name
                                : order.customerDetails.email}
                        </span>
                    </CustomerWrapper>
                );
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props: { row: { original: Order } }) => {
                const { status } = props.row.original;
                return <Status type={StatusTypes.Order} status={status} />;
            },
        },
        {
            header: 'Payment method',
            accessorKey: 'paymentMethod',
            cell: (props: { row: { original: Order } }) => {
                return <NoTranslateLabel>{props.row.original.paymentMethod}</NoTranslateLabel>;
            },
        },
        {
            header: 'Order value',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            accessorKey: 'totalAmount',
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;
                const totalAmountFormats = formatNumber(order.totalAmount ?? 0);
                return (
                    <TwoRowCell upper={totalAmountFormats} lower={order.currency} align="right" />
                );
            },
        },
        {
            header: 'Currency',
            accessorKey: 'currency',
            cell: (props: { row: { original: Order } }) => {
                return <span>{props.row.original.currency}</span>;
            },
        },
        {
            header: 'Customer reg no',
            id: 'customerRegNo',
            accessorKey: 'customerDetails.registrationNumber',
            meta: {
                sortKey: 'customerDetails.registrationNumber',
            },
            cell: (props: { row: { original: Order } }) => {
                return (
                    <span>
                        <RegistrationNumberFormatter
                            registrationNumber={
                                props.row.original.customerDetails.registrationNumber
                            }
                        />
                    </span>
                );
            },
        },
        {
            header: 'Customer type',
            id: 'customerType',
            cell: (props: { row: { original: Order } }) => {
                return <span>{props.row.original.customerDetails.type}</span>;
            },
            enableSorting: false,
        },
        {
            header: 'Email',
            id: 'customerDetailsEmail',
            accessorKey: 'customerDetails.email',
            meta: {
                sortKey: 'customerDetails.email',
            },
            cell: (props: { row: { original: Order } }) => {
                return <span>{props.row.original.customerDetails.email}</span>;
            },
        },
        {
            header: 'Days until expire',
            id: 'daysUntilExpire',
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            accessorFn: (props: { expiresAt: Date }) => {
                return props.expiresAt;
            },
            enableSorting: false,
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;
                const expires = new Date(order.expiresAt);
                const now = new Date();

                const days = (expires.getTime() - now.getTime()) / (1000 * 3600 * 24);
                const finaldays = days > 2 ? days - 1 : days;

                if (!order.expiresAt) {
                    return <span>-</span>;
                }

                if (finaldays < 1 && finaldays > 0) {
                    return <span>0</span>;
                }

                if (finaldays < 0) {
                    return <span>-</span>;
                }

                return <span>{Math.ceil(finaldays)}</span>;
            },
        },
        {
            header: 'Product code',
            accessorKey: 'productCode',
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;

                if (!order.isPrepaidPaymentMethod) {
                    return <span>{props.row.original.productCode ?? '-'}</span>;
                }

                return <span>-</span>;
            },
        },
        {
            header: 'Cost Center',
            accessorKey: 'costCenter',
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;

                return <span>{order.costCenter ?? '-'}</span>;
            },
        },
        {
            header: 'Reference Person',
            accessorKey: 'referencePerson',
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;

                return <span>{order.referencePerson ?? '-'}</span>;
            },
        },
        {
            header: 'Authorized Buyer',
            accessorKey: 'commitmentAlias.Description',
            enableSorting: false,
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;

                return <span>{order.commitmentAlias?.description ?? '-'}</span>;
            },
        },
    ];
};

export { generateOrderTanHeaders };

const CustomerWrapper = styled.div`
    display: inline-flex;
    gap: 0.5rem;

    span {
        line-height: 2.5rem;
    }
`;
