import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import RegistrationNumberFormatter from '../RegistrationNumberFormatter';

interface Props {
    children: string;
    theme: DefaultTheme;
}

const StyledRegistrationNumber = styled.span`
    color: ${props => props.theme.colors.text.primary};
    font-size: ${props => props.theme.text.size.medium};
    display: block;
`;

const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
`;

const RegistrationNumber: React.FC<Props> = ({ children, theme }: Props) => {
    return (
        <Container>
            <FileListLineIcon size={theme.icon.size.small} />
            <StyledRegistrationNumber>
                <RegistrationNumberFormatter registrationNumber={children} />
            </StyledRegistrationNumber>
        </Container>
    );
};

export default withTheme(RegistrationNumber) as React.ComponentType<Omit<Props, 'theme'>>;
