import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

interface GridInputProps {
    width?: string;
    setRef?: React.RefObject<HTMLInputElement> | ((instance: HTMLInputElement | null) => void);
    validationError?: boolean;
    validationErrorText?: string;
    background?: boolean;
    placeholderRaised?: boolean;
    noFocusBorder?: boolean;
    dataTestId?: string;
    isFocused?: boolean;
    cursor?: string;
    decorator?: ReactElement;
    count?: boolean;
    required?: boolean;
    flex?: number;
}

interface StyledLabelProps {
    $isActive: boolean;
    $hasValue: boolean;
    $placeholderRaised?: boolean;
}
interface StyledWrapperProps {
    $hidden?: boolean;
    $width?: string;
    $flex?: number;
}
interface StyledGridInputProps {
    $background?: boolean;
    $cursor?: string;
    $noFocusBorder?: boolean;
    $validationError?: boolean;
    $isFocused?: boolean;
}

type GridInputType = GridInputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const GridInput: React.FC<GridInputType> = ({
    width,
    disabled,
    type,
    name,
    id,
    placeholder,
    setRef,
    onChange,
    onClick,
    onFocus,
    pattern,
    validationError,
    validationErrorText,
    autoComplete = 'off',
    value,
    background,
    min,
    max,
    maxLength,
    step,
    defaultValue,
    hidden,
    placeholderRaised,
    onKeyDown,
    noFocusBorder,
    dataTestId,
    readOnly,
    isFocused,
    cursor,
    decorator,
    count,
    required,
    flex,
}: GridInputType) => {
    const hasDefaultValue = defaultValue !== undefined;

    const [active, setActive] = useState(false);
    const [hasValue, setHasValue] = useState(hasDefaultValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 0) {
            setHasValue(true);
        } else {
            setHasValue(false);
        }

        if (onChange) {
            onChange(event);
        }
    };

    const validateTryToEnterMoreThanMax = () => {
        const inputLabelElement = document.getElementById(`label-${id}`);
        const gridInputElement = document.getElementById(id ?? '');

        if (
            type === 'text' &&
            maxLength &&
            getTextValueLength(value ?? defaultValue) === maxLength &&
            inputLabelElement &&
            gridInputElement
        ) {
            inputLabelElement.classList.add('red-label');
            gridInputElement.classList.add('red-input');

            setTimeout(() => {
                inputLabelElement.classList.remove('red-label');
                gridInputElement.classList.remove('red-input');
            }, 800);
        }
    };

    const validateInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        validateTryToEnterMoreThanMax();

        if (type === 'number') {
            const charCode = typeof e.code === 'undefined' ? e.code : e.which;
            const charStr = String.fromCharCode(charCode);
            const decimalAndNumbers = /^[0-9\-.,\b]+$/;

            if (!charStr.match(decimalAndNumbers)) e.preventDefault();
        }
    };

    const getTextValueLength = (valueToCheck: string | number | readonly string[] | undefined) => {
        return typeof valueToCheck === 'string' ? valueToCheck.length : 0;
    };

    const getCountdownValue = (valueToGet: string | number | readonly string[] | undefined) => {
        if (type !== 'text') return '';
        if (!valueToGet && typeof valueToGet !== 'string') return '';
        if (typeof valueToGet === 'string' && valueToGet.length === 0) return '';

        const valueLength = getTextValueLength(valueToGet);
        const valueLeft = maxLength && maxLength - valueLength;

        return (
            <Countdown>({`${maxLength && maxLength === valueLength ? 0 : valueLeft}`})</Countdown>
        );
    };

    return (
        <Wrapper $hidden={hidden} $width={width} $flex={flex}>
            <Label
                id={`label-${id}`}
                $isActive={active}
                $hasValue={hasValue}
                htmlFor={id}
                $placeholderRaised={placeholderRaised}
            >
                {placeholder}
                {count && getCountdownValue(value ?? defaultValue)}
            </Label>

            {validationErrorText && (
                <ErrorText data-testid={`error-${dataTestId}`}>{validationErrorText}</ErrorText>
            )}

            <StyledInput
                readOnly={readOnly}
                $noFocusBorder={noFocusBorder}
                disabled={disabled}
                type={type}
                name={name}
                pattern={pattern}
                onKeyPress={e => validateInput(e)}
                id={id}
                onClick={onClick}
                ref={setRef}
                onChange={handleChange}
                $validationError={validationError}
                autoComplete={autoComplete}
                value={value}
                $background={background}
                max={max}
                min={min}
                maxLength={maxLength}
                step={step}
                defaultValue={defaultValue}
                onFocus={e => {
                    setActive(true);
                    if (onFocus) onFocus(e);
                }}
                onKeyDown={onKeyDown}
                onBlur={() => setActive(false)}
                data-testid={dataTestId}
                $isFocused={isFocused}
                $cursor={cursor}
                required={required}
            />
            {decorator && <Decorator>{decorator}</Decorator>}
        </Wrapper>
    );
};

export default GridInput;

const Wrapper = styled.div<StyledWrapperProps>`
    background-color: ${props => props.theme.colors.light};
    height: 6rem;
    position: relative;
    display: ${props => (props.$hidden ? 'none' : 'inline-block')};
    ${props => props.$flex && `flex: ${props.$flex}`};
    width: ${props => (props.$width ? props.$width : '100%')};
`;

const Decorator = styled.div`
    position: absolute;
    top: 2rem;
    right: 1rem;
`;

const StyledInput = styled.input<StyledGridInputProps>`
    background: ${props => (props.$background ? 'white' : 'transparent')};
    border-radius: 0;
    border: 0;
    width: 100%;
    height: 100%;
    padding: 0 1.6rem;
    box-sizing: border-box;
    outline: none;

    &:hover {
        cursor: ${props => (props.$cursor ? props.$cursor : 'text')};
    }

    &:focus {
        ${props =>
            !props.$noFocusBorder ? `border: 0.1rem solid ${props.theme.colors.primary}` : ''};
        outline: none;
        cursor: ${props => (props.$cursor ? props.$cursor : 'text')};
    }

    &:user-invalid {
        border: 0.1rem solid ${props => props.theme.colors.error};
    }

    &:disabled {
        opacity: 0.5;
    }
    ${props =>
        props.$validationError &&
        css`
            &:focus {
                border: 0.2rem solid ${props.theme.colors.error};
                outline: none;
            }
        `}
    ::-webkit-calendar-picker-indicator {
        display: none;
    }

    ${props => props.$isFocused && `border: 0.1rem solid ${props.theme.colors.accent2};`}

    cursor: ${props => (props.$cursor ? props.$cursor : 'text')};
`;

const Label = styled.label<StyledLabelProps>`
    position: absolute;
    font-size: ${props => props.theme.text.size.small};
    transform: translate(1.7rem, 2.3rem) scale(1);
    transform-origin: top left;
    transition: all 0.15s ease-in-out;
    opacity: 0.6;
    ${props =>
        (props.$isActive || props.$hasValue || props.$placeholderRaised) &&
        css`
            transform: translate(1.7rem, 0.8rem) scale(0.75);
            color: ${props.theme.colors.primary};
            opacity: 1;
        `};
`;

const Countdown = styled.label`
    margin-left: 0.5rem;
`;

const ErrorText = styled.div`
    position: absolute;
    bottom: 0;
    padding: 0.5rem 1.7rem;
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.error};
`;
