import { useState, useMemo, useEffect, RefObject } from 'react';

export default function useOnScreen(ref: RefObject<HTMLElement>) {
    const [outsideIntersecion, setOutsideIntersecion] = useState<{
        top: number;
        bottom: number;
        left: number;
        right: number;
    }>({ top: 0, bottom: 0, left: 0, right: 0 });
    const [cacheEntry, setCacheEntry] = useState<IntersectionObserverEntry | null>(null);
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                ([entry]) => {
                    setIntersecting(entry.isIntersecting);
                    setCacheEntry(entry);
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 1.0,
                }
            ),
        [ref]
    );

    useEffect(() => {
        if (!ref.current) return;

        observer.observe(ref.current!);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (!isIntersecting && cacheEntry) {
            const bottom =
                cacheEntry.boundingClientRect.bottom - (cacheEntry.rootBounds?.height ?? 0);
            setOutsideIntersecion({
                ...outsideIntersecion,
                bottom: bottom <= 0 ? 0 : bottom,
            });
        }
    }, [isIntersecting, cacheEntry]);

    return { isIntersecting, outsideIntersecion };
}
