import { OrderArticleRow } from '../../types/order/OrderArticleRow';

const getDefaultVat = (productRows: OrderArticleRow[]) => {
    const commonVat = productRows.reduce((rv, x) => {
        rv[x.vatRate] = (rv[x.vatRate] || 0) + 1;
        return rv;
    }, {} as { [key: string]: number });

    const mostCommon = Object.entries(commonVat).sort((a, b) => b[1] - a[1]);

    return parseFloat(mostCommon[0][0]);
};

const getDefaultVatPerCountry = (type: string) => {
    switch (type) {
        case 'DE':
            return 19;
        case 'FI':
            return 25.5;
        case 'NO':
        case 'SE':
        case 'DK':
        default:
            return 25;
    }
};

export { getDefaultVat, getDefaultVatPerCountry };
