import styled from 'styled-components';

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${props => props.theme.text.size.medium};
`;

export default Row;
