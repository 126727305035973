import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import MessageContainer from '../MessageContainer';

interface ErrorMessageProps {
    children: ReactNode;
    errorHeader: ErrorType;
}

const CustomErrorMessage: React.FC<ErrorMessageProps> = ({
    children,
    errorHeader,
}: ErrorMessageProps) => {
    return (
        <>
            <MessageContainer type="Error" header={errorHeader}>
                <Explanation>{children}</Explanation>
            </MessageContainer>
        </>
    );
};

const Explanation = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin: 1rem 0;
`;

export default CustomErrorMessage;
