import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Position } from './LayoutContainer';

interface Props {
    children: ReactNode;
    position: Position;
}

const LayoutPosition: React.FC<Props> = ({ position: gridArea, children }: Props) => {
    return <Container position={gridArea}>{children}</Container>;
};

const Container = styled.div<Props>`
    grid-area: ${props => props.position};

    ${props => props.position === 'right' && `margin-left: ${props.theme.layout.padding.large};`}
`;

export default LayoutPosition;
