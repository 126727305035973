/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodicReport, PeriodicReportResponse } from '../../../types/report/PeriodicReports';
import ResponseError from '../../../types/response/ResponseError';

export interface PeriodicReportsState {
    reports: PeriodicReport[];
    isLoading: boolean;
    generatingReportsError?: ResponseError;
    getReportsError?: ResponseError;
    isGenerating: boolean;
    generateSucceeded: boolean;
    downloadCsvFailed: boolean;
    downloadCsvProcessing: boolean;
    reportDownloadToken: string | undefined;
}

export interface DowloadCsvResponseData {
    type: string;
    title: string;
    status: number;
    traceId: string;
}

const initialState: PeriodicReportsState = {
    reports: [],
    isLoading: false,
    generatingReportsError: undefined,
    getReportsError: undefined,
    generateSucceeded: false,
    isGenerating: false,
    downloadCsvFailed: false,
    downloadCsvProcessing: false,
    reportDownloadToken: undefined,
};

export const periodicReportsSlice = createSlice({
    name: 'periodicReport',
    initialState,
    reducers: {
        getReportsStarted: state => ({
            ...state,
            isLoading: true,
            getReportsError: undefined,
        }),
        getReportsSucceeded: (state, action: PayloadAction<PeriodicReportResponse>) => {
            state.reports = action.payload.data;
            state.isLoading = false;
        },
        getReportsFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isLoading = false;
            state.getReportsError = action.payload;
        },
        generateReportStarted: state => ({
            ...state,
            generatingReportsError: undefined,
            getReportsError: undefined,
            isGenerating: true,
        }),
        generateReportSucceeded: state => ({
            ...state,
            isGenerating: false,
            generateSucceeded: true,
        }),
        clearGenerateReportsSuccess: state => ({
            ...state,
            generateSucceeded: false,
            getReportsError: undefined,
        }),
        generateReportsFailed: (state, action: PayloadAction<ResponseError>) => ({
            ...state,
            isGenerating: false,
            generatingReportsError: action.payload,
        }),
        setDownloadCsvProcessing: (state, action: PayloadAction<boolean>) => {
            state.downloadCsvProcessing = action.payload;
        },
        getReportDownloadTokenSucceeded: (state, action: PayloadAction<string>) => {
            state.reportDownloadToken = action.payload;
            state.isLoading = false;
        },
        downloadCsvFailed: state => ({ ...state, downloadCsvFailed: true }),
        clearDownloadCsvFailed: state => ({
            ...state,
            downloadCsvFailed: false,
            reportDownloadToken: undefined,
            downloadCsvProcessing: false,
            isLoading: false,
        }),
    },
});

export const {
    getReportsStarted,
    getReportsSucceeded,
    getReportsFailed,
    generateReportStarted,
    generateReportSucceeded,
    generateReportsFailed,
    clearGenerateReportsSuccess,
    setDownloadCsvProcessing,
    getReportDownloadTokenSucceeded,
    downloadCsvFailed,
    clearDownloadCsvFailed,
} = periodicReportsSlice.actions;

export default periodicReportsSlice.reducer;
