import React from 'react';
import styled from 'styled-components';
import { formatNumber } from '../../../services/helpers/numberFormats';

interface Props {
    amount: number;
    rate: number;
}

const VAT: React.FC<Props> = ({ amount, rate }: Props) => {
    const formattedAmount = formatNumber(amount);
    return (
        <>
            <div data-testid="vat-amount">{formattedAmount} </div>
            <LightColor data-testid="vat-rate">{rate}%</LightColor>
        </>
    );
};

const LightColor = styled.span`
    color: ${props => props.theme.colors.subtle.regular};
`;

export default VAT;
