import React, { useEffect } from 'react';
import SquareIconButton from '../../atoms/Button/SquareIconButton';
import { ColumnDef } from '@tanstack/react-table';
import Card from '../../atoms/Card/Card';
import CardBody from '../../atoms/Card/CardBody';
import Table from '../../atoms/Table/Table';
import {
    PeriodicReportsState,
    clearDownloadCsvFailed,
} from '../../../store/report/periodicReports/periodicReportsSlice';
import periodicReportActions from '../../../store/report/periodicReports/actions';
import { useAppState } from '../../../store/appstate';
import { PeriodicReport, PeriodicReportStatus } from '../../../types/report/PeriodicReports';
import { TableRowContent } from '../../../types/Layout/TableRowContent';
import { useAppDispatch } from '../../../store';
import { formatShortDate } from '../../../services/helpers/dateTimeFormats';
import Flex from '../../atoms/Box/Flex';
import Button from '../../atoms/Button/Button';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Spinner from '../../atoms/Spinner/Spinner';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../types/response/ErrorCodes';
import Snackbar from '../../atoms/Snackbar/Snackbar';
import EmptyState from '../../atoms/Information/EmptyState';
import FileChartLineIcon from 'remixicon-react/FileChartLineIcon';
import Download2LineIcon from 'remixicon-react/Download2LineIcon';
import { PeriodicReportType } from '../../../types/report/PeriodicReports';

interface PeriodReportsProps {
    theme: DefaultTheme;
}
interface PeriodicReportForTable extends PeriodicReport, TableRowContent {}

const camelCaseToReadableString = (value: string) =>
    value.replace(/([a-z])([A-Z])/g, (_, lower, upper) => `${lower} ${upper.toUpperCase()}`);

const getReportTypeName = (value: string) => {
    if (value == PeriodicReportType.RESERVATIONS) return 'Company Customer Credit Limits';
    return camelCaseToReadableString(value);
};

const PeriodicReports: React.FC<PeriodReportsProps> = ({ theme }: PeriodReportsProps) => {
    const dispatch = useAppDispatch();
    const {
        reports,
        isLoading,
        getReportsError,
        downloadCsvFailed,
        downloadCsvProcessing,
        reportDownloadToken,
    } = useAppState<PeriodicReportsState>(s => s.report.periodicReports);

    const columns: ColumnDef<PeriodicReportForTable>[] = [
        {
            header: 'Name',
            accessorKey: 'name',
        },
        {
            header: 'Period',
            accessorKey: 'period',
            cell: (props: { row: { original: PeriodicReport } }) => {
                const { period } = props.row.original;

                if (!period) {
                    return '-';
                }

                const formattedStartDate = formatShortDate(new Date(period.start));
                const formattedEndDate = formatShortDate(new Date(period.end));
                return `${formattedStartDate}-${formattedEndDate}`;
            },
        },

        {
            header: 'Expiration date',
            accessorKey: 'expirationDate',
            cell: (props: { row: { original: PeriodicReport } }) => {
                return formatShortDate(new Date(props.row.original.expirationDate));
            },
        },
        {
            header: 'Type',
            accessorKey: 'type',
            cell: (props: { row: { original: PeriodicReport } }) => {
                return getReportTypeName(camelCaseToReadableString(props.row.original.type));
            },
        },
        {
            header: 'Created by',
            accessorKey: 'createdBy',
        },
        {
            header: 'Status',
            accessorKey: 'status',
        },
        {
            header: 'Download',
            id: 'downloadReport',
            accessorKey: 'download',
            cell: (props: { row: { original: PeriodicReport } }) => {
                return (
                    props.row.original.status === PeriodicReportStatus.DONE && (
                        <SquareIconButton
                            onClick={() => onRowCsvClicked(props.row.original.id)}
                            title="Download csv"
                            data-testid="download-report"
                        >
                            <Download2LineIcon size={15} />
                            Csv
                        </SquareIconButton>
                    )
                );
            },
        },
    ];

    const onRowCsvClicked = (id: string) => {
        dispatch(periodicReportActions.getReportDownloadToken(id));
    };

    useEffect(() => {
        dispatch(periodicReportActions.getReports());
    }, []);

    useEffect(() => {
        if (reportDownloadToken) {
            const link = document.createElement('a');
            link.href = `/report?token=${reportDownloadToken}`;
            link.download = 'report.csv';

            document.body.appendChild(link);

            link.click();
            dispatch(clearDownloadCsvFailed());
        }
    }, [reportDownloadToken]);

    if (getReportsError) {
        return <ErrorMessage error={getReportsError} errorHeader={ErrorType.LoadReports} />;
    }

    return (
        <>
            <Flex alignItems="flex-end" row justifyContent="flex-start">
                <Button onClick={() => dispatch(periodicReportActions.getReports())}>
                    Refresh reports
                </Button>
            </Flex>
            <Container>
                {downloadCsvFailed && (
                    <Snackbar
                        clear={() => dispatch(clearDownloadCsvFailed())}
                        open={downloadCsvFailed}
                        color={theme.colors.snackbar.error.background}
                        message="Could not download file. Try again later"
                    />
                )}
                <Card>
                    <CardBody>
                        {isLoading && (
                            <DownloadSpinnerWrapper>
                                <Spinner text="Getting reports" loading={isLoading} />
                            </DownloadSpinnerWrapper>
                        )}
                        {downloadCsvProcessing && (
                            <DownloadSpinnerWrapper>
                                <Spinner text="Downloading..." loading={downloadCsvProcessing} />
                            </DownloadSpinnerWrapper>
                        )}
                        <TableWrapper isLoading={isLoading || downloadCsvProcessing}>
                            <Table<PeriodicReportForTable>
                                key={'periodicReports'}
                                columns={columns}
                                data={reports as PeriodicReportForTable[]}
                            />
                        </TableWrapper>
                        {!isLoading && reports && reports.length === 0 && (
                            <EmptyState
                                title={"It's empty over here!"}
                                icon={
                                    <FileChartLineIcon
                                        size={theme.icon.size.xlarge}
                                        color={theme.colors.accent3}
                                    />
                                }
                            >
                                <div>
                                    You don&apos;t have any reports yet. Get started by creating
                                    your first.
                                </div>
                            </EmptyState>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default withTheme(PeriodicReports) as React.ComponentType<Omit<PeriodReportsProps, 'theme'>>;
interface StyleProps {
    isLoading: boolean;
}
const Container = styled.div`
    padding-bottom: 4rem;
`;

const DownloadSpinnerWrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 35%;
    margin-left: -1rem;
    margin-top: 10rem;
    z-index: 8;
`;

const TableWrapper = styled.div<StyleProps>`
    opacity: ${props => (props.isLoading ? 0.5 : 1)};
`;
