import React, { useRef, useState } from 'react';
import DateRangePicker, { DatePeriod } from '../CalendarDateRange/DateRangePicker';
import styled, { DefaultTheme, css, withTheme } from 'styled-components';
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon';
import useOnClickOutside from '../Dropdown/useOnClickOutside';

interface DatePeriodInputProps {
    placeholder: string;
    periodValue?: DatePeriod;
    setRef?: (instance: HTMLDivElement | null) => void;
    onChange: (period?: DatePeriod) => void;
    datePeriodMax: (date: Date) => Date | undefined;
    datePeriodMin: (date: Date) => Date | undefined;
    validationError?: boolean;
    dataTestId?: string;
    theme: DefaultTheme;
}

const dateMin = (date1?: Date, date2?: Date) =>
    date1 === undefined || date2 === undefined
        ? undefined
        : new Date(Math.min(date1.getTime(), date2.getTime()));

const DatePeriodInput: React.FC<DatePeriodInputProps> = ({
    placeholder,
    periodValue,
    setRef,
    onChange,
    datePeriodMax,
    datePeriodMin,
    validationError,
    theme,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const isPeriodValueSet = !!periodValue && periodValue.start !== '' && periodValue.end != '';
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const { start: periodValueFrom, end: periodValueTo } = periodValue ?? {
        start: '',
        end: '',
    };

    const getMinDate = (end: string) => (end !== '' ? datePeriodMin(new Date(end)) : undefined);
    const getMaxDate = (start: string) =>
        start !== '' ? dateMin(datePeriodMax(new Date(start)), new Date()) : new Date();

    const [maxDate, setMaxDate] = useState<Date | undefined>(getMaxDate(periodValue?.start ?? ''));
    const [minDate, setMinDate] = useState<Date | undefined>(getMinDate(periodValue?.end ?? ''));

    const handleClickOutside = () => {
        if (isOpen) setIsOpen(!isOpen);
    };

    useOnClickOutside(dropdownRef, handleClickOutside);

    return (
        <Initiator
            tabIndex={0}
            data-testid="reports-date-range"
            onClick={() => !!setIsOpen && setIsOpen(!isOpen)}
            $isInvalid={validationError ?? false}
            ref={node => {
                if (setRef) setRef(node);
                dropdownRef.current = node;
            }}
        >
            <Label $hasValue={isPeriodValueSet}>{placeholder}</Label>

            {isPeriodValueSet && (
                <Value>
                    {periodValueFrom} to {periodValueTo}
                </Value>
            )}

            <IconWrapper>
                <CalendarLineIcon size={theme.icon.size.normal} />
            </IconWrapper>

            {isOpen && (
                <StyledDateRange onClick={e => e.stopPropagation()}>
                    <DateRangePicker
                        onChange={x => {
                            setMinDate(getMinDate(x.end));
                            setMaxDate(getMaxDate(x.start));

                            if (x.start === '' && x.end === '') {
                                onChange(undefined);
                                return;
                            }
                            onChange(x);
                            if (x.start === '' || x.end === '') return;
                            handleClickOutside();
                        }}
                        onEnter={handleClickOutside}
                        isOpen={isOpen}
                        periodValueTo={periodValueTo}
                        periodValueFrom={periodValueFrom}
                        height="36.5rem"
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </StyledDateRange>
            )}
        </Initiator>
    );
};

interface StyledInitiatorProps {
    $isInvalid: boolean;
}

interface StyledLabelProps {
    $hasValue: boolean;
}

const Initiator = styled.div<StyledInitiatorProps>`
    cursor: pointer;
    background-color: ${props => props.theme.colors.light};
    height: 6rem;
    position: relative;
    display: ${props => (props.hidden ? 'none' : 'inline-block')};
    width: 100%;
    align-items: center;

    &:focus {
        border: 0.1rem solid ${props => props.theme.colors.primary};
        outline: none;
    }

    ${props =>
        props.$isInvalid &&
        css`
            &:focus {
                border: 0.2rem solid ${props.theme.colors.error};
                outline: none;
            }
        `}
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 1.65rem;
    right: 1.5rem;
    color: ${props => props.theme.colors.veryDark};
`;

const Label = styled.label<StyledLabelProps>`
    position: absolute;
    font-size: ${props => props.theme.text.size.small};
    transform: translate(1.7rem, 2.3rem) scale(1);
    transform-origin: top left;
    transition: all 0.15s ease-in-out;
    opacity: 0.6;
    ${props =>
        props.$hasValue &&
        css`
            transform: translate(1.7rem, 0.8rem) scale(0.75);
            color: ${props.theme.colors.primary};
            opacity: 1;
        `};
`;

const Value = styled.div`
    position: absolute;
    top: 1rem;
    padding: 1rem 0 1rem 1.8rem;
    &:hover {
        cursor: pointer;
    }
`;

const StyledDateRange = styled.div`
    position: absolute;
    margin-top: 6.2rem;
    z-index: 2;
    width: 100%;
`;

export default withTheme(DatePeriodInput) as React.ComponentType<
    Omit<DatePeriodInputProps, 'theme'>
>;
