import { transparentize } from 'polished';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
    position: absolute;
    background: ${props => transparentize(0.5, props.theme.colors.light)};
    left: 50%;
    top: 25%;
    margin-left: -1rem;
    margin-top: 10rem;
`;

export default SpinnerWrapper;
