/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetUser } from '../../../types/GetUser';
import { PostResponseData } from '../../../types/response/PostResponseData';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';
import { User } from '../../../types/User';
import { UserGroup } from '../../../types/UserGroup';
import { UserGroupDetails } from '../../../types/UserGroupDetails';

export interface Invite {
    isInviting: boolean;
    inviteResponse: PostResponseData;
    inviteSucceded: boolean;
    error?: ResponseError;
}

export interface UsersState {
    userSearchResults: ResponseData<User[]>;
    user: GetUser;
    group: UserGroup;
    email?: string;
    error?: ResponseError;
    phrase?: string;
    organization?: string;
    isSearching: boolean;
    results: ResponseData<PostResponseData>;
    invite: Invite;
    inviteRedirectError: ResponseError;
}

const initialState: UsersState = {
    isSearching: false,
    userSearchResults: {} as ResponseData<User[]>,
    invite: {
        inviteResponse: {} as PostResponseData,
        isInviting: false,
        inviteSucceded: false,
        error: undefined,
    } as Invite,
    inviteRedirectError: {} as ResponseError,
    results: {} as ResponseData<PostResponseData>,
    email: '',
    organization: '',
    user: {} as GetUser,
    group: {} as UserGroup,
    error: undefined,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        userPageStarted: state => {
            state.isSearching = true;
        },
        searchUserStarted: (state, action: PayloadAction<string>) => {
            state.isSearching = true;
            state.phrase = action.payload;
            state.invite = { ...state.invite, inviteSucceded: false };
            state.error = undefined;
        },
        searchUserFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.userSearchResults = {} as ResponseData<User[]>;
            state.error = action.payload;
        },
        searchUserSucceeded: (state, action: PayloadAction<ResponseData<User[]>>) => {
            state.userSearchResults = action.payload;
            state.isSearching = false;
        },
        inviteUserStarted: (
            state,
            action: PayloadAction<{
                email: string;
                organization: string;
            }>
        ) => {
            state.email = action.payload.email;
            state.organization = action.payload.organization;
            state.invite = {
                ...state.invite,
                isInviting: true,
                inviteSucceded: false,
            };
            state.error = undefined;
        },
        inviteUserSucceeded: (state, action: PayloadAction<PostResponseData>) => {
            state.invite = {
                ...state.invite,
                isInviting: false,
                inviteSucceded: true,
                error: undefined,
                inviteResponse: action.payload,
            };
        },
        inviteUserFailed: (state, action: PayloadAction<ResponseError>) => {
            state.invite = {
                ...state.invite,
                isInviting: false,
                inviteSucceded: false,
                error: action.payload,
            };
            state.error = action.payload;
        },
        clearInviteUser: state => {
            state.invite = {
                ...state.invite,
                isInviting: false,
                inviteSucceded: false,
                inviteResponse: {} as PostResponseData,
                error: undefined,
            };
        },
        getUserDetailsStarted: state => {
            state.isSearching = true;
            state.error = undefined;
        },
        getUserDetailsSucceeded: (state, action: PayloadAction<GetUser>) => {
            state.isSearching = false;
            state.user = action.payload;
            state.error = undefined;
        },
        getUserDetailsFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.user = {} as GetUser;
            state.error = action.payload;
        },
        deleteUserStarted: state => {
            state.isSearching = true;
            state.error = undefined;
        },
        deleteUserSucceeded: (state, action: PayloadAction<string>) => {
            state.isSearching = false;
            state.userSearchResults = {
                ...state.userSearchResults,
                data: state.userSearchResults.data.filter(usr => usr.id !== action.payload),
            } as ResponseData<User[]>;
            state.error = undefined;
        },
        deleteUserFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.error = action.payload;
        },
        addUserToGroupStarted: state => {
            state.isSearching = true;
            state.error = undefined;
        },
        addUserToGroupSucceeded: (state, action: PayloadAction<UserGroupDetails>) => {
            state.isSearching = false;
            state.user = {
                ...state.user,
                groups: [...state.user.groups, action.payload],
            };
        },
        addUserToGroupFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.error = action.payload;
        },
        deleteUserFromGroupStarted: state => {
            state.isSearching = true;
            state.error = undefined;
        },
        deleteUserFromGroupSucceeded: (state, action: PayloadAction<string>) => {
            state.isSearching = false;
            state.user = {
                ...state.user,
                groups: state.user.groups.filter(
                    (item: UserGroupDetails) => item.id !== action.payload
                ),
            };
            state.error = undefined;
        },
        deleteUserFromGroupFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isSearching = false;
            state.error = action.payload;
        },
        clearUser: state => {
            state.user = {} as GetUser;
        },
        clearError: state => {
            state.error = undefined;
        },
        fetchRedirectError: (state, action: PayloadAction<ResponseError>) => {
            state.inviteRedirectError = action.payload;
        },
    },
});

export const {
    userPageStarted,
    searchUserStarted,
    searchUserFailed,
    searchUserSucceeded,
    inviteUserStarted,
    inviteUserSucceeded,
    inviteUserFailed,
    clearInviteUser,
    getUserDetailsStarted,
    getUserDetailsSucceeded,
    getUserDetailsFailed,
    deleteUserStarted,
    deleteUserSucceeded,
    deleteUserFailed,
    addUserToGroupStarted,
    addUserToGroupSucceeded,
    addUserToGroupFailed,
    deleteUserFromGroupStarted,
    deleteUserFromGroupSucceeded,
    deleteUserFromGroupFailed,
    clearUser,
    clearError,
    fetchRedirectError,
} = usersSlice.actions;

export default usersSlice.reducer;
