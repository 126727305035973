import React from 'react';
import {
    AnimatedAxis,
    AnimatedGrid,
    XYChart,
    AreaStack,
    Tooltip,
    AnimatedLineSeries,
    AnimatedBarSeries,
    AnimatedBarGroup,
    AnimatedBarStack,
} from '@visx/xychart';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { formatDate } from '../../../services/helpers/dateTimeFormats';
import Flex from '../Box/Flex';
import Money from '../Money/Money';

export type Line<T> = {
    data: T[];
    key: string;
    color: string;
    title?: string;
    xAccessor: (x: T) => Date | string | number | undefined;
    yAccessor: (x: T) => Date | string | number | undefined;
};

export enum TooltipValueType {
    PERCENT = 'Percent',
    Number = 'Number',
    MONEY = 'Money',
}

export enum SeriesType {
    LINES = 'Lines',
    BARS = 'Bars',
    BARS_STACKED = 'BarsStacked',
}

export interface XYGraphProps<T> {
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    theme: DefaultTheme;
    lines: Line<T>[];
    color?: string;
    yDomain?: number[];
    tooltipValueType?: TooltipValueType;
    seriesType?: SeriesType;
}

function XYGraph<T extends object>({
    height,
    yDomain,
    lines,
    theme,
    tooltipValueType,
    seriesType = SeriesType.LINES,
}: XYGraphProps<T>) {
    const margin = { top: 10, left: 50, right: 10, bottom: 80 };

    const formatValueFromAccessor = (value: string | number | Date | undefined) => {
        if (!value) return <></>;
        if (value instanceof Date) {
            const formattedDate = formatDate(value);

            return (
                <>
                    {formattedDate.yyyyMMdd} <TimeWrapper>{formattedDate.HHmm}</TimeWrapper>
                </>
            );
        }

        return <>{value}</>;
    };

    const formatTooltipValue = (value?: string | number | Date, type?: TooltipValueType) => {
        switch (type) {
            case TooltipValueType.PERCENT:
                return `${value}%`;
            case TooltipValueType.MONEY:
                return <Money>{value as number}</Money>;
            case TooltipValueType.Number:
            default:
                return `${value}`;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatTick = (value: any) => {
        if (tooltipValueType === TooltipValueType.PERCENT) {
            return `${value}%`;
        }

        if (value >= 1000000000) {
            return `${value / 1000000000}B`;
        }
        if (value >= 1000000) {
            return `${value / 1000000}M`;
        }
        if (value >= 1000) {
            return `${value / 1000}K`;
        }

        if (value < 0 && value <= -1000000000) {
            return `-${Math.abs(value) / 1000000000}B`;
        }
        if (value < 0 && value <= -1000000) {
            return `-${Math.abs(value) / 1000000}M`;
        }
        if (value < 0 && value <= -1000) {
            return `-${Math.abs(value) / 1000}K`;
        }

        return value;
    };

    const ellipseGraphLabel = (label: string) => {
        const maxLength = 13;

        if (typeof label !== 'string') return;
        if (label.length > maxLength) return `${label.slice(0, maxLength)}...`;

        return label;
    };

    if (!lines || lines.length === 0) return <></>;

    return (
        <XYChart
            height={height}
            xScale={
                seriesType === SeriesType.BARS_STACKED
                    ? { type: 'band', padding: 0.2 }
                    : { type: 'band' }
            }
            yScale={yDomain ? { type: 'linear', domain: yDomain } : { type: 'linear' }}
            margin={margin}
        >
            <AnimatedGrid
                columns={false}
                lineStyle={{
                    stroke: '#e1e1e1',
                    strokeLinecap: 'round',
                    strokeWidth: 1,
                }}
                strokeDasharray="0, 4"
            />
            <AnimatedAxis
                hideAxisLine
                orientation="bottom"
                tickLabelProps={{
                    angle: -45,
                    textAnchor: 'end',
                }}
                tickFormat={seriesType == SeriesType.BARS ? ellipseGraphLabel : undefined}
            />
            <AnimatedAxis hideAxisLine orientation="left" tickFormat={formatTick} />

            {seriesType == SeriesType.LINES && (
                <AreaStack offset={'none'} renderLine={true}>
                    {lines.map(x => (
                        <AnimatedLineSeries
                            key={x.key}
                            dataKey={x.key}
                            data={x.data}
                            xAccessor={x.xAccessor}
                            yAccessor={x.yAccessor}
                            fillOpacity={0.3}
                            fill={x.color}
                        />
                    ))}
                </AreaStack>
            )}

            {seriesType == SeriesType.BARS &&
                lines.map(x => (
                    <AnimatedBarGroup key={x.key}>
                        <AnimatedBarSeries
                            dataKey={x.key}
                            data={x.data}
                            xAccessor={x.xAccessor}
                            yAccessor={x.yAccessor}
                            colorAccessor={() => x.color}
                        />
                    </AnimatedBarGroup>
                ))}

            {seriesType == SeriesType.BARS_STACKED &&
                lines.map(x => (
                    <AnimatedBarStack key={x.key}>
                        <AnimatedBarSeries
                            dataKey={x.key}
                            data={x.data}
                            xAccessor={x.xAccessor}
                            yAccessor={x.yAccessor}
                            colorAccessor={() => x.color}
                        />
                    </AnimatedBarStack>
                ))}

            <Tooltip<T>
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                renderTooltip={({ tooltipData }) => {
                    if (!tooltipData) return;

                    return (
                        <Flex column>
                            <TooltipDate gap={theme.layout.gap.small}>
                                {formatValueFromAccessor(
                                    lines[0].xAccessor(
                                        tooltipData && (tooltipData.nearestDatum?.datum as T)
                                    )
                                )}
                            </TooltipDate>
                            <Content column gap={theme.layout.gap.small}>
                                {Object.entries(tooltipData.datumByKey)
                                    .reverse()
                                    .map(lineDataArray => {
                                        const [key, value] = lineDataArray;
                                        const line = lines.find(x => x.key === key);

                                        if (!line) return;

                                        return (
                                            <Flex
                                                row
                                                key={key}
                                                alignItems="center"
                                                gap={theme.layout.gap.xsmall}
                                            >
                                                <ColoredCircle color={line.color} />

                                                <ValueText color={line.color}>
                                                    {formatTooltipValue(
                                                        line.yAccessor(value.datum),
                                                        tooltipValueType
                                                    )}
                                                </ValueText>
                                                {line.title ? <div>{line.title}</div> : <></>}
                                            </Flex>
                                        );
                                    })}
                            </Content>
                        </Flex>
                    );
                }}
            />
        </XYChart>
    );
}

export default withTheme(XYGraph) as <T>(props: Partial<XYGraphProps<T>>) => React.ReactElement;

const ColoredCircle = styled.div`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    background: ${({ color }) => color};
    border-radius: 50%;
`;

const Content = styled(Flex)`
    padding: ${props => props.theme.layout.padding.medium};
`;

const TimeWrapper = styled.div`
    color: ${props => props.theme.colors.subtle.regular};
`;

const TooltipDate = styled(Flex)`
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};
    padding: ${props => props.theme.layout.padding.medium};
    text-transform: capitalize;
`;

const ValueText = styled.div`
    color: ${({ color }) => color};
    font-size: ${props => props.theme.text.size.xlarge};
`;
