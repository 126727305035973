import React, { ReactNode } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Flex from '../../atoms/Box/Flex';
import Title from '../../atoms/Title/Title';
import BucketSize from '../../../types/dashboard/BucketSize';
import ButtonTab from '../../atoms/Tabs/ButtonTab';
import ButtonTabs from '../../atoms/Tabs/ButtonTabs';

interface DashboardSectionProps {
    title: string;
    theme: DefaultTheme;
    children: ReactNode;
    bucketSize?: string;
    setBucketSize?: (key: string) => void;
    bucketOptions?: BucketSize[];
}

const DashboardSectionHeader: React.FC<DashboardSectionProps> = ({
    title,
    theme,
    children,
    bucketSize,
    setBucketSize,
    bucketOptions,
}: DashboardSectionProps) => {
    return (
        <>
            <Flex gap={theme.layout.gap.medium} justifyContent="space-between" width={`100%`}>
                <Title>{title}</Title>
                <>
                    {bucketSize && bucketOptions && setBucketSize && (
                        <ButtonTabWrapper>
                            <ButtonTabs>
                                {Object.entries(BucketSize).map(([key, value]) => {
                                    return (
                                        <ButtonTab
                                            key={key}
                                            tabKey={value}
                                            text={key}
                                            setTab={setBucketSize}
                                            currentTab={bucketSize}
                                            disabled={!bucketOptions.includes(value)}
                                        />
                                    );
                                })}
                            </ButtonTabs>
                        </ButtonTabWrapper>
                    )}
                </>
            </Flex>

            <div>{children}</div>
        </>
    );
};

export default withTheme(DashboardSectionHeader) as React.ComponentType<
    Omit<DashboardSectionProps, 'theme'>
>;

const ButtonTabWrapper = styled.div`
    height: 10rem;
`;
