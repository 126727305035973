/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Button from '../../atoms/Button/Button';
import { quantityValidationConfig } from '../../../types/ValidationTypes';
import { OrderArticleRow } from '../../../types/order/OrderArticleRow';
import GridInput from '../../atoms/Input/GridInput';
import FormRow from '../../atoms/Form/FormRow';

interface ReturnOrderRowProps {
    data: OrderArticleRow;
    handleCancel: (row: OrderArticleRow) => void;
    handleReturnProduct: (row: OrderArticleRow, productData: Map<string, string | number>) => void;
}

interface InputFields {
    articleNumber: string;
    description: string;
    price: number;
    vatRate: number;
    quantity: number;
}

const ReturnOrderRow: React.FC<ReturnOrderRowProps> = ({
    data,
    handleCancel,
    handleReturnProduct,
}: ReturnOrderRowProps) => {
    const { register, handleSubmit } = useForm<InputFields>({
        defaultValues: {
            quantity: data.quantity,
        },
    });

    const quantityInputRef = useRef<HTMLInputElement | null>();

    const onSubmit = (form: InputFields) => {
        const editedRow: OrderArticleRow = {
            ...data,
            quantity:
                form.quantity !== data.quantity ? data.quantity - form.quantity : data.quantity,
            isExpanded: false,
            isRemoved: form.quantity >= data.quantity,
            isChanged: form.quantity < data.quantity,
            isCrossedOver: form.quantity >= data.quantity,
        };

        const finalValues = new Map<string, string | number>(
            Object.entries({ quantity: data.quantity - form.quantity })
        );

        handleReturnProduct(editedRow, finalValues);
    };

    useEffect(() => {
        if (quantityInputRef && quantityInputRef.current) {
            quantityInputRef.current.focus();
        }
    }, []);

    const quantity = register('quantity', quantityValidationConfig);

    return (
        <AlignedContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Return articles</h3>
                <Wrapper>
                    <FormRow first last>
                        <GridInput
                            background
                            id="quantity"
                            placeholder="Quantity to return"
                            type="number"
                            defaultValue={data.quantity}
                            setRef={quantity.ref}
                            name={quantity.name}
                            onChange={quantity.onChange}
                            max={data.quantity}
                            min={1}
                        />
                    </FormRow>
                    <AmountLeft>of {data.quantity}</AmountLeft>
                </Wrapper>

                <FooterButtonContainer>
                    <StyledButton type="button" large onClick={() => handleCancel(data)}>
                        Cancel
                    </StyledButton>
                    <StyledButton
                        type="submit"
                        large
                        data-testid="return-orderrow-submit-button"
                        primary
                    >
                        Return
                    </StyledButton>
                </FooterButtonContainer>
            </form>
        </AlignedContainer>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    > * {
        &:first-child {
            width: 35%;
        }
    }
`;

const AlignedContainer = styled.div`
    width: 100%;
`;

const AmountLeft = styled.div`
    width: 100%;
    height: 6rem;
    line-height: 6rem;
    font-size: ${props => props.theme.text.size.large};
    align-self: center;
    margin: 0 0 0 2rem;
`;

const StyledButton = styled(Button)`
    min-width: 14rem !important;
`;

const FooterButtonContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding-top: 0.5rem;
    gap: 0.5rem;
`;

export default ReturnOrderRow;
