import DashboardCardDisplayType from '../../types/dashboard/DashboardCardDisplayType';
import DashboardEntityType from '../../types/dashboard/DashboardEntityType';
import DashboardTopCategoryType from '../../types/dashboard/DashboardTopCategoryType';

const dashboardCards = [
    {
        id: 'customerLifetimeValue',
        title: 'Customer lifetime value',
        type: DashboardCardDisplayType.NUMBER,
        value: 2587,
        disabled: true,
        category: DashboardTopCategoryType.RETENTION,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'repeatCustomerRate',
        query: 'repeat_customers',
        title: 'Repeat Customer Rate',
        information: 'Percentage of customers who have made two or more purchases.',
        type: DashboardCardDisplayType.PERCENT,
        value: 0,
        category: DashboardTopCategoryType.RETENTION,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'purchasePerCustomer',
        query: 'purchase_per_customer',
        title: 'Purchase Frequency',
        type: DashboardCardDisplayType.FREQUENCE,
        information: 'Average number of purchases per unique customer.',
        category: DashboardTopCategoryType.RETENTION,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'customerSatisfaction',
        query: 'customer_satisfaction',
        title: 'Customer Satisfaction',
        type: DashboardCardDisplayType.THUMBUPDOWN,
        information: 'End customer happiness on the thank-you-page of Walley Checkout.',
        category: DashboardTopCategoryType.RETENTION,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'aggregateSearchCount',
        query: 'order_count',
        type: DashboardCardDisplayType.NUMBER,
        title: 'Order Placed',
        category: DashboardTopCategoryType.SALES,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'aggregateSearchCurrencies',
        query: 'order_volume',
        type: DashboardCardDisplayType.MONEY,
        title: 'Order Volume',
        category: DashboardTopCategoryType.SALES,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'aggregateSearchAov',
        query: 'average_order_volume',
        type: DashboardCardDisplayType.MONEY,
        title: 'AOV',
        category: DashboardTopCategoryType.SALES,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'aggregateNotCaptureVolume',
        query: 'order_not_activated_sum',
        type: DashboardCardDisplayType.MONEY,
        title: 'Uncaptured Volume',
        category: DashboardTopCategoryType.SALES,
        entity: DashboardEntityType.Orders,
        requirePermission: false,
    },
    {
        id: 'returns',
        query: 'return_rate',
        type: DashboardCardDisplayType.PERCENT_INVERTED,
        title: 'Returns',
        category: DashboardTopCategoryType.SALES,
        entity: DashboardEntityType.Settlements,
        requirePermission: true,
    },
];

export default dashboardCards;
