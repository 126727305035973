/* eslint-disable react/prop-types */
import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';

interface ButtonProps {
    primary?: boolean;
    large?: boolean;
    distinct?: boolean;
    type?: string;
    width?: string;
}

const Button = styled.button<ButtonProps>`
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    border-radius: 0.4rem;
    min-width: ${props => (props.width ? props.width : '9rem')};
    border: 0.2rem solid ${props => props.theme.colors.button.secondary.border};
    background: ${props => props.theme.colors.button.secondary.color};
    color: ${props => props.theme.colors.button.secondary.text};
    font-size: ${props => props.theme.text.size.medium};
    font-family: ${props => props.theme.text.font.medium};
    outline: none;
    opacity: ${props => (props.disabled ? 0.5 : 1)};

    &:hover {
        ${props => !props.disabled && `background: ${props.theme.colors.button.secondary.color}`};
    }

    &:focus {
        border: 0.2rem solid ${props => props.theme.colors.primary};
        outline: none;
    }

    &:active {
        background: ${props =>
            props.disabled ? props.theme.colors.disabled : darken(0.05, props.theme.colors.accent)};
    }

    ${props =>
        props.primary && props.disabled
            ? css`
                  cursor: default;
                  min-width: 12rem;
                  border: none;
                  background: ${props.theme.colors.disabled};
                  color: ${props.theme.colors.light};
                  font-family: ${props.theme.text.font.medium};
              `
            : props.primary &&
              css`
                  min-width: 12rem;
                  border: 0.2rem solid ${props.theme.colors.primary};
                  background: ${props.theme.colors.primary};
                  color: ${props.theme.colors.button.primary.text};
                  font-family: ${props.theme.text.font.medium};

                  &:hover {
                      background: ${lighten(0.08, props.theme.colors.primary)};
                  }

                  &:active {
                      background: ${darken(0.05, props.theme.colors.primary)};
                  }
                  &:focus {
                      border: 0.2rem solid ${props.theme.colors.light};
                      box-shadow: 0 0 0 0.125rem ${props.theme.colors.primary};
                  }
              `}

    ${props =>
        props.distinct && props.disabled
            ? css`
                  cursor: default;
                  border: none;
                  background: ${props.theme.colors.disabled};
                  &:hover {
                      border: none;
                  }
              `
            : props.distinct &&
              css`
                  border: 0.1rem solid ${props.theme.colors.border};

                  &:hover {
                      border: 0.1rem solid ${props.theme.colors.dark};
                  }
              `}


    ${props =>
        props.large
            ? css`
                  font-size: ${props.theme.text.size.medium};
                  min-width: 14rem;
                  height: 4rem;
              `
            : css`
                  height: 2.7rem;
              `}
`;

export default Button;
