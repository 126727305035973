/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../../services/api';
import Matcher from '../../types/marketing/Matcher';
import { PatchOperation } from '../../types/requests/PatchOperation';
import { ResponseData } from '../../types/response/ResponseData';
import ResponseError from '../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../thunk';
import {
    deleteMatcherFailed,
    deleteMatcherSuccess,
    getMatchersFailed,
    getMatchersPending,
    getMatchersSuccess,
    matcherCreatedFailed,
    matcherCreatedSuccess,
    matcherUpdatedFailed,
    matcherUpdatedSuccess,
    previewMatcherFailed,
    previewMatcherSuccess,
} from './marketingSlice';

const getMatchers =
    (organizationId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getMatchersPending());

        try {
            const response = await api.get<ResponseData<Matcher[]>>(
                `/organizations/${organizationId}/matchers`
            );

            dispatch(getMatchersSuccess(response.data));
        } catch (error) {
            dispatch(getMatchersFailed(error as ResponseError));
        }
    };

const createMatcher =
    (
        organizationId: string,
        matcher: {
            name: string;
            recency: string;
            frequency: number;
            monetary: number;
        }
    ): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getMatchersPending());

        try {
            await api.post<{
                name: string;
                recency: string;
                frequency: number;
                monetary: number;
            }>(`/organizations/${organizationId}/matchers`, matcher);

            dispatch(matcherCreatedSuccess());

            await dispatch(getMatchers(organizationId || ''));
        } catch (error) {
            dispatch(matcherCreatedFailed(error as ResponseError));
        }
    };

const updateMatcher =
    (
        organizationId: string,
        matcherId: string,
        newValues: { key: string; newValue: any }[]
    ): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getMatchersPending());

        const operations = newValues.map(x => {
            return {
                op: 'replace',
                path: `/${x.key}`,
                value: x.newValue,
            };
        });

        try {
            (await api.patch<PatchOperation[]>(
                `/organizations/${organizationId}/matchers/${matcherId}`,
                operations
            )) as ResponseData<Matcher>;

            dispatch(matcherUpdatedSuccess());
            await dispatch(getMatchers(organizationId || ''));
        } catch (error) {
            dispatch(matcherUpdatedFailed(error as ResponseError));
        }
    };

const deleteMatcher =
    (organizationId: string, matcherId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getMatchersPending());

        try {
            await api.del<Matcher>(`/organizations/${organizationId}/matchers/${matcherId}`);
            dispatch(deleteMatcherSuccess());
            await dispatch(getMatchers(organizationId || ''));
        } catch (error) {
            dispatch(deleteMatcherFailed(error as ResponseError));
        }
    };

const previewMatcher =
    (organizationId: string, matcherId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getMatchersPending());

        try {
            const response = await api.get<ResponseData<{ totalCount: number }>>(
                `/organizations/${organizationId}/matchers/${matcherId}/preview`
            );
            dispatch(
                previewMatcherSuccess({
                    matcherValue: response.metaData.totalCount,
                    matcherId,
                })
            );
            await dispatch(getMatchers(organizationId || ''));
        } catch (error) {
            dispatch(previewMatcherFailed(error as ResponseError));
        }
    };

export default {
    getMatchers,
    createMatcher,
    updateMatcher,
    deleteMatcher,
    previewMatcher,
};
