import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import FocusLock from 'react-focus-lock';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import { searchValidationConfig } from '../../../types/ValidationTypes';
import settlementsActions from '../../../store/report/settlement/actions';

import { useAppState } from '../../../store/appstate';
import {
    createEncodedSearchQueryString,
    parseQuery,
    parseSearchUrl,
} from '../../../services/helpers/queryStringHelpers';
import { parseFilters } from '../../../services/helpers/filterHelpers';

import Term from '../../atoms/Term/Term';
import { ConnectedStore } from '../../../types/ConnectedStore';
import { SearchTerm } from '../../../types/response/SearchTerm';
import DateRange from '../../atoms/DateRange/DateRange';
import { setPerPage } from '../../../store/userSettings/userSettingsSlice';
import { useAppDispatch } from '../../../store';
import StoreTerm from '../../atoms/Term/StoreTerm';
import TermCategory from '../../../types/filter/TermCategory';

type Inputs = {
    searchPhrase: string;
    filter: string[];
};

export enum SettlmentSearchTerms {
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    PAID_OUT_AT = 'paidOutAt',
}

const today = new Date();

const periodDate = {
    maxDate: today,
    label: 'Period',
};

const payoutDate = {
    maxDate: today,
    label: 'Payout',
};

interface SearchProps {
    availableStores: ConnectedStore[];
}

const SearchForm: React.FC<SearchProps> = ({ availableStores }: SearchProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm<Inputs>();

    const sessionColumns = useAppState<string[]>(s => s.userSettings.settlementColumnFilters);

    const { pathname, search } = useLocation();
    const { query, sort, columns, perPage, filterMap } = parseSearchUrl(search, sessionColumns);

    const isSearching = useAppState<boolean>(s => s.report.settlement.isSearching);
    const terms = useAppState<SearchTerm[]>(s => s.report.settlement.terms);

    const [filter, setFilter] = useState<Map<string, string[]>>(new Map());
    const [searchChange, setSearchChange] = useState(false);
    const [shouldReload, setShoulReload] = useState<boolean>(true);

    const currentQuery = parseQuery(query, false);
    const SEARCH_PHRASE = 'searchPhrase';

    const makeSearch = (q: string) => {
        const newQuery = createEncodedSearchQueryString(
            parseQuery(q, false),
            filter,
            sort,
            perPage,
            columns
        );

        navigate(`${pathname}?${newQuery}`);
        dispatch(settlementsActions.search(newQuery));
    };

    const onSubmit = (form: Inputs) => {
        makeSearch(form.searchPhrase);
    };

    const handleFilterChange = (termKey: string | undefined, termFilter: string[]) => {
        if (!termKey) return;
        setFilter(filter.set(termKey, termFilter));
        makeSearch(getValues('searchPhrase'));
    };

    const handlePeriodFilter = (_termKey: string | undefined, periodFilter: string[]) => {
        filter.set(SettlmentSearchTerms.START_DATE, [periodFilter[0], '']);
        filter.set(SettlmentSearchTerms.END_DATE, ['', periodFilter[1]]);
        makeSearch(getValues('searchPhrase'));
    };

    const clearFilter = (type: string) => {
        if (type === 'Payout') filter.set('paidOutAt', []);
        if (type === 'Period') {
            filter.set('startDate', []);
            filter.set('endDate', []);
        }

        makeSearch(getValues('searchPhrase'));
    };

    const initialPeriodvalue = [
        parseFilters(SettlmentSearchTerms.START_DATE, filter)[0],
        parseFilters(SettlmentSearchTerms.END_DATE, filter)[1],
    ];

    const initialPeriodValueChecked =
        !initialPeriodvalue[0] && !initialPeriodvalue[1] ? [] : initialPeriodvalue;

    const renderTerms = () => {
        return (
            <Terms>
                <DateRange
                    key={periodDate.label}
                    maxDate={periodDate.maxDate}
                    label={periodDate.label}
                    onDateChange={handlePeriodFilter}
                    initialValue={initialPeriodValueChecked}
                    dateRangeType={undefined}
                    clearFilters={clearFilter}
                />
                <DateRange
                    key={payoutDate.label}
                    maxDate={payoutDate.maxDate}
                    label={payoutDate.label}
                    onDateChange={handleFilterChange}
                    initialValue={parseFilters(SettlmentSearchTerms.PAID_OUT_AT, filter)}
                    dateRangeType={SettlmentSearchTerms.PAID_OUT_AT}
                    clearFilters={clearFilter}
                />

                {terms.map(
                    x =>
                        x.key !== 'storeId' && (
                            <Term
                                key={x.key}
                                category={TermCategory.Settlement}
                                term={x}
                                onTermFilterChange={handleFilterChange}
                                currentFilters={parseFilters(x.key, filter)}
                                showSearchbar={['reference']}
                                availableStores={availableStores}
                            />
                        )
                )}

                <StoreTerm
                    key={'storeId'}
                    currentFilters={parseFilters('storeId', filter)}
                    availableStores={availableStores}
                    onTermFilterChange={handleFilterChange}
                />
            </Terms>
        );
    };

    useEffect(() => {
        dispatch(settlementsActions.terms());
        dispatch(setPerPage(perPage));
    }, [dispatch]);

    useEffect(() => {
        if (shouldReload) {
            setFilter(filterMap);
        }

        return () => {
            setShoulReload(false);
        };
    }, [search, filter, setFilter, setValue, getValues, shouldReload]);

    const searchPhrase = register(SEARCH_PHRASE, searchValidationConfig);

    useEffect(() => {
        if (searchChange) {
            const phrase = getValues('searchPhrase');

            makeSearch(phrase);
            setSearchChange(false);
        }
    });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
                <SearchContainer>
                    <InputWrapper>
                        <FocusLock disabled={!isSearching}>
                            <Input
                                data-testid="search-input"
                                type="text"
                                name={searchPhrase.name}
                                defaultValue={currentQuery}
                                setRef={searchPhrase.ref}
                                placeholder="What are you looking for?"
                                onChange={searchPhrase.onChange}
                                id="settlement-search"
                            />
                        </FocusLock>
                    </InputWrapper>
                    <Button type="submit" primary large>
                        Search
                    </Button>
                </SearchContainer>
            </form>

            {renderTerms()}

            {errors.searchPhrase && (
                <p className="error-message" role="alert">
                    {errors.searchPhrase?.message}
                </p>
            )}
        </>
    );
};

const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
`;

const Terms = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

const InputWrapper = styled.div`
    width: 40rem;
    margin-right: ${props => props.theme.layout.padding.medium};
`;

export default SearchForm;
