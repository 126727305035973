/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useId } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Card from '../../components/atoms/Card/Card';
import CenterContent from '../../components/atoms/Layout/CenterContent';
import HeaderContent from '../../components/atoms/Layout/HeaderContent';
import Spinner from '../../components/atoms/Spinner/Spinner';
import SpinnerWrapper from '../../components/atoms/Spinner/SpinnerWrapper';
import { createSearchPath, browsePaginationWithDispatch } from '../../services/helpers/pagination';
import {
    createSearchQueryString,
    parseQuery,
    parseSearchUrl,
    sortColumns,
    createEncodedSearchQueryString,
} from '../../services/helpers/queryStringHelpers';
import { useAppState } from '../../store/appstate';
import {
    CheckoutState,
    clearCheckout,
    clearCheckoutInit,
    clearPaylinkDetails,
} from '../../store/checkout/checkoutSlice';
import CheckoutSession from '../../types/paylink/CheckoutSession';
import checkoutActions from '../../store/checkout/actions';
import { ErrorType } from '../../types/response/ErrorCodes';
import Button from '../../components/atoms/Button/Button';
import EmptyHeaderErrorMessage from '../../components/molecules/Error/EmptyHeaderErrorMessage';
import { Modal } from '../../components/atoms/Modal/Modal';
import useModal from '../../services/hooks/useModal';
import { ConnectedStore } from '../../types/ConnectedStore';
import Status from '../../components/atoms/Status/Status';
import { formatDate } from '../../services/helpers/dateTimeFormats';
import { formatNumber } from '../../services/helpers/numberFormats';
import PaylinkStatus from '../../types/paylink/PaylinkStatus';
import StatusTypes from '../../types/StatusTypes';
import ProductCode from '../../types/ProductCode';
import CheckoutType from '../../types/CheckoutType';
import usePermissions from '../../services/hooks/usePermissions';
import getStoreInfo from '../../services/helpers/storeHelper';
import { useAppDispatch } from '../../store';
import ColumnStoreLabel from '../../components/atoms/Store/ColumnStoreLabel';
import Table from '../../components/atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import TwoRowCell from '../../components/atoms/Table/TwoRowCell';
import useLogTimeSpentOnPage from '../../services/hooks/useLogTimeSpentOnPage';
import MultiactionButton from '../../components/atoms/Button/MultiactionButton';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import CreatePurchase, { CaptureStrategy, PurchaseInputs } from './CreatePurchase';
import { config } from '../../config';
import { CheckoutInitRows } from '../../types/requests/CheckoutInitRequest';
import { SessionState } from '../../store/session/sessionSlice';
import Flex from '../../components/atoms/Box/Flex';
import { InvoiceSendTypeToNumber } from '../../types/InvoiceSendType';
import CustomerSalesType from '../../types/customer/CustomerSalesType';

import SearchTerms from '../../components/molecules/Paylink/SearchTerms';
import SquareIconLinkButton from '../../components/atoms/Button/SquareIconLinkButton';
import LinkIcon from 'remixicon-react/LinkIcon';
import SquareIconButton from '../../components/atoms/Button/SquareIconButton';
import FileCopyLineIcon from 'remixicon-react/FileCopyLineIcon';
import { PermissionOperation } from '../../types/PermissionOperation';

interface PaylinkSearchProps {
    theme: DefaultTheme;
}

const PaylinkSearch: React.FC<PaylinkSearchProps> = ({ theme }: PaylinkSearchProps) => {
    document.title = 'Merchant Hub - Paylinks';

    useLogTimeSpentOnPage('paylinks');

    const {
        isSearchingPaylink,
        searchPaylinkFailed,
        paylinks: { links, metaData, data = [] },
    } = useAppState<CheckoutState>(s => s.checkout);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user: { id, email } = {} } = useAppState<SessionState>(s => s.session);
    const { isInitializingCheckout, initializedPurchase, initializedCheckout } =
        useAppState<CheckoutState>(s => s.checkout);

    const createPaylink = useModal();
    const createServicePurchase = useModal();
    const createFraudPurchase = useModal();

    const availableStores = useAppState<ConnectedStore[]>(s => s.session.availableStores) ?? [];

    const { pathname, search: queryString } = useLocation();
    const { query, sort, perPage, page, filterMap: filter } = parseSearchUrl(queryString);

    const sortPaylinkColumns = (sortby: Array<SortingRule<any>>) => {
        const params = sortColumns(sortby, links.self?.query);

        navigate(createSearchPath(params, pathname));
        dispatch(checkoutActions.search(params));
    };

    const { hasWrite, hasOperation } = usePermissions(
        availableStores.map(x => `all-${x.id}-checkoutdetails`).concat(['all-all-checkoutdetails'])
    );

    const PaylinkListColumns: ColumnDef<CheckoutSession>[] = [
        {
            header: 'Created',
            accessorKey: 'createdAt',
            cell: (props: { row: { original: CheckoutSession } }) => {
                const timeArray = formatDate(new Date(props.row.original.createdAt));
                return (
                    timeArray && <TwoRowCell upper={timeArray.yyyyMMdd} lower={timeArray.HHmm} />
                );
            },
        },
        {
            header: 'Reference',
            accessorKey: 'reference',
            cell: (props: { row: { original: CheckoutSession } }) => {
                return <span>{props.row.original.reference ?? '[Not set]'}</span>;
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props: { row: { original: CheckoutSession } }) => {
                return <Status type={StatusTypes.Paylink} status={props.row.original.status} />;
            },
        },
        {
            id: 'store',
            header: 'Store',
            accessorFn: (props: CheckoutSession) => {
                return props.storeId;
            },
            meta: {
                style: {
                    textAlign: 'left',
                },
                sortKey: 'storeId',
            },
            cell: (props: { row: { original: CheckoutSession } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return storeInfo && storeInfo.storeName ? (
                    <ColumnStoreLabel store={storeInfo} />
                ) : (
                    <span>{storeId}</span>
                );
            },
        },
        {
            header: 'Sales Person',
            accessorKey: 'salesPerson',
            cell: (props: { row: { original: CheckoutSession } }) => {
                return <span>{props.row.original.salesPerson ?? '-'}</span>;
            },
        },
        {
            header: 'Total',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            accessorKey: 'total',
            cell: (props: { row: { original: CheckoutSession } }) => {
                const checkout = props.row.original;
                const totalAmountFormats = formatNumber(checkout.total ?? 0);
                return (
                    <TwoRowCell
                        upper={totalAmountFormats}
                        lower={checkout.currency}
                        align="right"
                    />
                );
            },
        },
        {
            header: 'Expires',
            accessorKey: 'expiresAt',
            cell: (props: { row: { original: CheckoutSession } }) => {
                const timeArray = formatDate(new Date(props.row.original.expiresAt));

                if (props.row.original.status === PaylinkStatus.Expired) {
                    return <span>Expired</span>;
                }

                return timeArray && props.row.original.status !== PaylinkStatus.Completed ? (
                    <TwoRowCell upper={timeArray.yyyyMMdd} lower={timeArray.HHmm} />
                ) : (
                    <span>-</span>
                );
            },
        },
        {
            header: 'Paylink',
            accessorKey: 'paylink',
            cell: (props: { row: { original: CheckoutSession } }) => {
                return (
                    <Flex row>
                        <SquareIconLinkButton
                            title="Open"
                            href={props.row.original.paylink}
                            target="_blank"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <LinkIcon size={15} />
                            Open
                        </SquareIconLinkButton>
                        <SquareIconButton
                            title="Copy"
                            onClick={e => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(props.row.original.paylink);
                            }}
                        >
                            <FileCopyLineIcon size={15} />
                            Copy
                        </SquareIconButton>
                    </Flex>
                );
            },
        },
    ];

    const multiActions = [
        {
            key: useId(),
            label: getCheckoutTypeFriendlyName(CheckoutType.PAYLINK),
            action: () => createPaylink.toggle(),
            show: hasWrite,
            dataTestId: 'multi-action-create-paylink',
        },
        {
            key: useId(),
            label: getCheckoutTypeFriendlyName(CheckoutType.SERVICE_INVOICE_PURCHASE),
            action: () => createServicePurchase.toggle(),
            show: hasOperation('write_service_invoice'),
            dataTestId: 'multi-action-create-service-invoice',
        },
        {
            key: useId(),
            label: getCheckoutTypeFriendlyName(CheckoutType.NO_FRAUD_PURCHASE),
            action: () => createFraudPurchase.toggle(),
            show: hasOperation('write_fraud_risk_invoice'),
            dataTestId: 'multi-action-merchant-liable-contested-invoice',
        },
    ];

    const onRowClick = (row: CheckoutSession, newTab = false) => {
        dispatch(clearPaylinkDetails());

        if (newTab) {
            const win = window.open(`paylinks/${row.id}`, '_blank');
            if (win) win.focus();
        } else {
            navigate(`/paylinks/${row.id}`, { state: { from: `${queryString}` } });
        }
    };

    const handleCreatePaylink = (
        formData: PurchaseInputs,
        store: ConnectedStore,
        items: CheckoutInitRows[],
        type: CheckoutType
    ) => {
        const requestData = {
            storeId: store.id ?? '',
            countryCode: store?.countryCode ?? '',
            notificationUri: `${config.ApiHost}/checkouts/callback`,
            reference: formData.orderReference,
            cart: {
                items,
            },
            metadata: {
                checkoutType: 'paylink',
                salesPerson: email ?? id,
            },
            profile: {
                autoActivate: formData.captureStrategy === CaptureStrategy.Autocaptured,
            },
            privateCustomerPrefill:
                store && store.customerSalesType === CustomerSalesType.B2C
                    ? { email: formData.email, mobilePhoneNumber: formData.phone }
                    : undefined,
            businessCustomerPrefill:
                store && store.customerSalesType === CustomerSalesType.B2B
                    ? {
                          organizationNumber: formData.purchase.organisationNumber,
                          buyer: {
                              firstName: formData.purchase.firstName,
                              lastName: formData.purchase.lastName,
                              email: formData.email,
                              mobilePhoneNumber: formData.phone,
                          },
                      }
                    : undefined,
        };

        dispatch(checkoutActions.initCheckoutSession(requestData));
    };

    const handleCreatePurchase = (
        formData: PurchaseInputs,
        store: ConnectedStore,
        items: CheckoutInitRows[],
        type: CheckoutType
    ) => {
        const delivery = {
            companyName: formData.purchase.companyName,
            address: formData.purchase.delivery.address,
            address2: formData.purchase.delivery.address2,
            coAddress: formData.purchase.delivery.coAddress,
            postalCode: formData.purchase.delivery.postalCode,
            city: formData.purchase.delivery.city,
        };

        const invoice = {
            companyName: formData.purchase.companyName,
            address: formData.purchase.invoice.address,
            address2: formData.purchase.invoice.address2,
            coAddress: formData.purchase.invoice.coAddress,
            postalCode: formData.purchase.invoice.postalCode,
            city: formData.purchase.invoice.city,
        };

        const requestData = {
            storeId: store.id ?? '',
            countryCode: store?.countryCode ?? '',
            reference: formData.orderReference,
            cart: {
                items,
            },
            profile: {
                autoActivate: formData.captureStrategy === CaptureStrategy.Autocaptured,
                invoiceDeliveryMethod: InvoiceSendTypeToNumber.EMAIL,
                productIdentifier: getProductFromCheckoutType(type),
            },
            businessCustomerPrefill: {
                email: formData.purchase.email,
                mobilePhoneNumber: formData.purchase.phoneNumber,
                registrationNumber: formData.purchase.organisationNumber,
                costCenter: formData.purchase.costCenter,
                reference: formData.purchase.reference,
                invoiceAddress: formData.purchase.sameAsDelivery ? delivery : invoice,
                deliveryAddress: delivery,
                peppolId: formData.purchase.peppolId,
            },
        };

        dispatch(checkoutActions.initPurchaseSession(requestData));
    };

    const getProductFromCheckoutType = (type: CheckoutType) => {
        switch (type) {
            case CheckoutType.SERVICE_INVOICE_PURCHASE:
                return btoa('0|' + ProductCode.SERVICE_INVOICE);
            case CheckoutType.NO_FRAUD_PURCHASE:
                return btoa('0|' + ProductCode.NO_FRAUD_INVOICE);
            default:
                throw new Error(`Incompatible checkout type ${type} recived`);
        }
    };

    const onHidePurchaseModal = (toggle: () => void) => {
        dispatch(clearCheckout());
        toggle();
    };

    const dispatchSearch = () => {
        dispatch(
            checkoutActions.search(
                encodeURI(
                    createSearchQueryString(
                        parseQuery('', false),
                        filter.set('checkoutType', ['paylink']),
                        sort,
                        perPage,
                        [],
                        page
                    )
                )
            )
        );
    };

    const onFilterChanged = (filters: Map<string, string[]>) => {
        navigate(
            `${pathname}?${createEncodedSearchQueryString(
                query,
                filters,
                null,
                perPage,
                [],
                '1',
                undefined
            )}`
        );
    };

    const onHideModal = (onHide: () => void) => {
        dispatch(clearCheckoutInit());
        onHidePurchaseModal(createPaylink.toggle);
        onHide();
    };

    const onCompletedPaylink = (handleHide: () => void) => {
        if (!isInitializingCheckout && initializedCheckout) {
            onHideModal(handleHide);
            navigate(`/paylinks/${initializedCheckout.data.privateId}`, {
                state: {
                    sendModalOpen: true,
                },
            });
        }
    };

    const onCompletedPurchase = (handleHide: () => void) => {
        if (!isInitializingCheckout && initializedPurchase) {
            onHideModal(handleHide);
            navigate(`/orders/${initializedPurchase.entityId}`);
        }
    };

    useEffect(() => {
        dispatchSearch();
    }, [queryString]);

    if (searchPaylinkFailed) {
        return (
            <EmptyHeaderErrorMessage
                error={searchPaylinkFailed}
                keyWord="checkout"
                errorType={ErrorType.SearchPaylink}
            />
        );
    }

    return (
        <>
            <HeaderContent>
                <CenterContent>
                    <Flex row alignItems="center" justifyContent="space-between">
                        <Flex column gap={theme.layout.gap.xsmall}>
                            <PaylinkTitle>Created Pay Links</PaylinkTitle>

                            <SearchTerms
                                filter={filter}
                                stores={availableStores}
                                onFilterChanged={onFilterChanged}
                            />
                        </Flex>
                        <HeaderRight>
                            {(hasWrite ||
                                hasOperation(PermissionOperation.write_fraud_risk_invoice) ||
                                hasOperation(PermissionOperation.write_service_invoice)) && (
                                <MultiactionButton
                                    actions={multiActions}
                                    dataTestId="multi-action-create"
                                >
                                    Create new <AddLineIcon size={theme.icon.size.normal} />
                                </MultiactionButton>
                            )}
                        </HeaderRight>
                    </Flex>
                </CenterContent>
            </HeaderContent>

            <Modal
                large
                isShown={createServicePurchase.isShown}
                hide={() => onHidePurchaseModal(createServicePurchase.toggle)}
            >
                <CreatePurchase
                    type={CheckoutType.SERVICE_INVOICE_PURCHASE}
                    typeFriendlyName={getCheckoutTypeFriendlyName(
                        CheckoutType.SERVICE_INVOICE_PURCHASE
                    )}
                    onHide={createServicePurchase.toggle}
                    onSendForm={handleCreatePurchase}
                    onCompleted={onCompletedPurchase}
                />
            </Modal>

            <Modal
                large
                isShown={createFraudPurchase.isShown}
                hide={() => onHidePurchaseModal(createFraudPurchase.toggle)}
            >
                <CreatePurchase
                    type={CheckoutType.NO_FRAUD_PURCHASE}
                    typeFriendlyName={getCheckoutTypeFriendlyName(CheckoutType.NO_FRAUD_PURCHASE)}
                    onHide={createFraudPurchase.toggle}
                    onSendForm={handleCreatePurchase}
                    onCompleted={onCompletedPurchase}
                />
            </Modal>

            <Modal
                large
                isShown={createPaylink.isShown}
                hide={() => onHidePurchaseModal(createPaylink.toggle)}
            >
                <CreatePurchase
                    type={CheckoutType.PAYLINK}
                    typeFriendlyName={getCheckoutTypeFriendlyName(CheckoutType.PAYLINK)}
                    onHide={() => onHidePurchaseModal(createPaylink.toggle)}
                    onSendForm={handleCreatePaylink}
                    onCompleted={onCompletedPaylink}
                />
            </Modal>

            <CenterContent>
                <Container>
                    {isSearchingPaylink ? (
                        <SpinnerWrapper>
                            <Spinner text="Loading..." loading={isSearchingPaylink} />
                        </SpinnerWrapper>
                    ) : (
                        <>
                            <RefreshButton onClick={() => dispatchSearch()}>
                                Refresh Pay Links
                            </RefreshButton>
                            <Card>
                                <TableWrapper isSearching={isSearchingPaylink}>
                                    <Table<CheckoutSession>
                                        data={data ?? []}
                                        columns={PaylinkListColumns}
                                        sort={{
                                            initialSortbyState: sort ?? null,
                                            manualsorting: true,
                                            sortFunction: sortPaylinkColumns,
                                        }}
                                        pagination={{
                                            metaData,
                                            links,
                                            pageSize: perPage,
                                            nextPage: () =>
                                                browsePaginationWithDispatch(
                                                    navigate,
                                                    pathname,
                                                    links.next?.query,
                                                    checkoutActions.search(links.next?.query),
                                                    dispatch
                                                ),
                                            previousPage: () =>
                                                browsePaginationWithDispatch(
                                                    navigate,
                                                    pathname,
                                                    links.prev?.query,
                                                    checkoutActions.search(links.prev?.query),
                                                    dispatch
                                                ),
                                        }}
                                        onRowClicked={onRowClick}
                                    />
                                </TableWrapper>
                            </Card>
                        </>
                    )}
                </Container>
            </CenterContent>
        </>
    );
};

export default withTheme(PaylinkSearch) as React.ComponentType<Omit<PaylinkSearchProps, 'theme'>>;

const getCheckoutTypeFriendlyName = (type: CheckoutType) => {
    switch (type) {
        case CheckoutType.SERVICE_INVOICE_PURCHASE:
            return 'Service Invoice (B2B)';
        case CheckoutType.NO_FRAUD_PURCHASE:
            return 'Invoice (B2B)';
        case CheckoutType.PAYLINK:
            return 'Pay Link';
    }
};

const Container = styled.div`
    margin: 5rem 0 5rem 0;
`;

const RefreshButton = styled(Button)`
    margin-bottom: 1rem;
`;

interface StyleProps {
    isSearching: boolean;
}

const TableWrapper = styled.div<StyleProps>`
    opacity: ${props => (props.isSearching ? 0.5 : 1)};
`;

const PaylinkTitle = styled.h3`
    line-height: 0;
`;

const HeaderRight = styled.div``;
