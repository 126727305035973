import React from 'react';

import { DefaultTheme, withTheme } from 'styled-components';
import { transparentize } from 'polished';
import Key2LineIcon from 'remixicon-react/Key2LineIcon';
import { useAppState } from '../../../../store/appstate';

import Spinner from '../../../atoms/Spinner/Spinner';
import Button from '../../../atoms/Button/Button';
import { ApiSecret } from '../../../../types/ApiSecret';
import { ApiSecretsState } from '../../../../store/admin/apiSecrets/apiSecretsSlice';
import Pill from '../../../atoms/Status/Pill';
import ApiSecretStatus from '../../../../types/ApiSecretStatus';
import EmptyState from '../../../atoms/Information/EmptyState';
import CardBody from '../../../atoms/Card/CardBody';
import Card from '../../../atoms/Card/Card';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import usePermissions from '../../../../services/hooks/usePermissions';
import { useManagementTags } from '../../../../services/hooks/useTags';
import Table from '../../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import { formatLongDate } from '../../../../services/helpers/dateTimeFormats';

interface SearchResultsProps {
    hasWrite: boolean;
    searchResults: ApiSecret[];
    theme: DefaultTheme;
    onRemove: (e: React.MouseEvent<HTMLButtonElement>, key: ApiSecret) => void;
    toggleCreate: () => void;
}

const ApiSecretsSearchResults: React.FC<SearchResultsProps> = ({
    hasWrite,
    searchResults,
    theme,
    onRemove,
    toggleCreate,
}: SearchResultsProps) => {
    const { apiSecretTags } = useManagementTags();
    const apiSecretPermissions = usePermissions(apiSecretTags);

    const ApiSecretStatusColor = (status: ApiSecretStatus) => {
        if (status === ApiSecretStatus.Expired) return theme.colors.apiSecret.status.expired;
        if (status === ApiSecretStatus.ExpiresSoon)
            return theme.colors.apiSecret.status.expiresSoon;
        return theme.colors.apiSecret.status.activate;
    };

    const ApiSecretStatusText = (status: ApiSecretStatus): string => {
        if (status === ApiSecretStatus.ExpiresSoon) return 'Expires Soon';
        return status;
    };

    const ApiSecretStatusBackground = (status: ApiSecretStatus) => {
        if (status === ApiSecretStatus.Active) return 'transparent';

        return transparentize(0.9, ApiSecretStatusColor(status));
    };

    const { isSearching, searchError } = useAppState<ApiSecretsState>(s => s.admin.apiSecrets);

    const data = searchResults || [];

    const columns: ColumnDef<ApiSecret>[] = [
        {
            header: 'Label',
            accessorKey: 'label',
            enableSorting: false,
        },
        {
            header: 'Client ID',
            accessorKey: 'clientId',
            enableSorting: false,
        },
        {
            header: 'Created by',
            accessorKey: 'createdBy',
            enableSorting: false,
        },
        {
            header: 'Created',
            accessorKey: 'createdAt',
            enableSorting: false,
            cell: (props: { row: { original: ApiSecret } }) => {
                return <>{formatLongDate(new Date(props.row.original.createdAt))}</>;
            },
        },
        {
            header: 'Expires',
            accessorKey: 'expiresAt',
            enableSorting: false,
            cell: (props: { row: { original: ApiSecret } }) => {
                const created = new Date(props.row.original.createdAt);
                const expires = new Date(props.row.original.expiresAt);

                return (
                    <span>
                        {expires.getFullYear() - created.getFullYear() === 99
                            ? 'Never'
                            : formatLongDate(expires)}
                    </span>
                );
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            enableSorting: false,
            cell: (props: { row: { original: ApiSecret } }) => {
                return (
                    <Pill color={ApiSecretStatusColor(props.row.original.status)}>
                        {ApiSecretStatusText(props.row.original.status)}
                    </Pill>
                );
            },
        },
        {
            id: 'remove',
            header: '',
            accessorKey: 'id',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: ApiSecret } }) => {
                return (
                    <Button
                        onClick={e => onRemove(e, props.row.original)}
                        distinct
                        disabled={!hasWrite}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    const loadApiSecrets = () => {
        if (searchError) {
            return <ErrorMessage error={searchError} errorHeader={ErrorType.ApiSecret} />;
        }

        if (data.length === 0) {
            return (
                <EmptyState
                    title="It's empty over here!"
                    icon={
                        <Key2LineIcon size={theme.icon.size.xlarge} color={theme.colors.accent3} />
                    }
                >
                    <div>
                        You don&apos;t have any API Keys yet. Get started by creating your first.
                    </div>
                    {apiSecretPermissions.hasWrite && (
                        <Button onClick={() => toggleCreate()}>Create Api key</Button>
                    )}
                </EmptyState>
            );
        }

        return (
            <Card>
                <CardBody>
                    <Table<ApiSecret>
                        data={data}
                        columns={columns}
                        getRowStyles={row => ({
                            background: ApiSecretStatusBackground(row.status),
                        })}
                    />
                </CardBody>
            </Card>
        );
    };

    return isSearching ? <Spinner loading={isSearching} /> : <>{loadApiSecrets()}</>;
};

export default withTheme(ApiSecretsSearchResults) as React.ComponentType<
    Omit<SearchResultsProps, 'theme'>
>;
