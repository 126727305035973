/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import styled from 'styled-components';
import { DefaultTheme, withTheme } from 'styled-components';
import CenterContent from '../components/atoms/Layout/CenterContent';
import Spinner from '../components/atoms/Spinner/Spinner';

interface LoadingScreenProps {
    theme: DefaultTheme;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ theme }: LoadingScreenProps) => {
    document.title = 'Merchant Hub - Loading';

    return (
        <CenterContent>
            <Container>
                <LoadingContainer>
                    Loading...
                    <Spinner color={theme.colors.primary} size={13} loading />
                </LoadingContainer>
            </Container>
        </CenterContent>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export default withTheme(LoadingScreen) as React.ComponentType<Omit<LoadingScreenProps, 'theme'>>;
