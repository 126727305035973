import styled from 'styled-components';

const PhoneEmailContainer = styled.div`
    gap: 0.8rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
`;

export default PhoneEmailContainer;
