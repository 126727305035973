import React from 'react';
import styled from 'styled-components';
import { formatNumber } from '../../../services/helpers/numberFormats';

interface Props {
    testId?: string;
    children: number;
    isCrossedOut?: boolean;
}

interface StyledMoneyProps {
    isCrossedOut?: boolean;
}

const StyledMoney = styled.span<StyledMoneyProps>`
    ${props => props.isCrossedOut && 'text-decoration: line-through'};
`;

const Money: React.FC<Props> = ({ testId, children, isCrossedOut }: Props) => {
    const numberFormats = formatNumber(children);
    return (
        <StyledMoney isCrossedOut={isCrossedOut} data-testid={testId}>
            {numberFormats}
        </StyledMoney>
    );
};

export default Money;
