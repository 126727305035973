enum PaymentMethod {
    Invoice = 'Invoice',
    Account = 'Account',
    Installment = 'Installment',
    Card = 'Card',
    BankTransfer = 'BankTransfer',
    Swish = 'Swish',
    Amex = 'Amex',
    Vipps = 'Vipps',
    MobilePay = 'MobilePay',
}

export default PaymentMethod;
