import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AccountInvoice, OrderAccount } from '../../../types/order/OrderAccount';
import { Order } from '../../../types/order/Order';
import StatusTypes from '../../../types/StatusTypes';
import Status from '../../atoms/Status/Status';
import { formatMonth, formatShortDate } from '../../../services/helpers/dateTimeFormats';
import Money from '../../atoms/Money/Money';
import InvoiceState from '../../../types/invoice/InvoiceState';
import InvoiceStatus from '../../../types/invoice/InvoiceStatus';
import NoTranslateLabel from '../../atoms/Label/NoTranslateLabel';
import OrderProductCode from '../../../types/order/OrderProductCode';
import PaymentMethod from '../../../types/order/PaymentMethod';
import OrderStatus from '../../../types/order/OrderStatus';
import StyledLink from '../../atoms/Link/StyledLink';
import NotificationState from '../../atoms/NotificationState/NotificationState';

interface Props {
    order: Order;
}

const InvoiceSidebar: React.FC<Props> = ({ order }: Props) => {
    const accountsWithInvoices =
        order.accounts && order.accounts.filter(x => x.currentInvoice !== null);

    const accountsWithoutInvoice =
        order.accounts && order.accounts.filter(x => x.currentInvoice === null && !x.moved);

    const capitilize = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };

    const getInvoiceMonth = (placed: Date) => {
        const placedAt = new Date(placed);
        placedAt.setMonth(placedAt.getMonth() + 1);
        return capitilize(formatMonth(placedAt));
    };

    const hasCampaignInvoice =
        order.productCode && order.productCode.startsWith(OrderProductCode.CAMPAIGN);

    const hasMoved = order.accounts && order.accounts.some(x => x.moved);

    const hasBeenCreatedAfterWeStartedCollectingProductCodes =
        new Date(order.placedAt).getTime() > new Date(2021, 9, 29).getTime();

    const hasNotifications =
        order.accounts &&
        order.accounts.length > 0 &&
        order.accounts.some(a => a.invoices && a.invoices.length > 0);

    const showInvoiceSidebarMessage = () => {
        if (order.accounts && order.paymentMethod !== PaymentMethod.Account) {
            return accountsWithoutInvoice?.map((x, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <EmptyInvoice key={key}>This invoice cannot be displayed right now</EmptyInvoice>
            ));
        }

        if (!hasBeenCreatedAfterWeStartedCollectingProductCodes) {
            return (
                order.accounts?.length === 0 && (
                    <EmptyList>This order has no invoice right now</EmptyList>
                )
            );
        }

        if (
            !hasCampaignInvoice &&
            order.paymentMethod === PaymentMethod.Account &&
            !hasMoved &&
            (order.status === OrderStatus.Activated ||
                order.status === OrderStatus.PartActivated) &&
            order.purchases &&
            !hasNotifications
        ) {
            const datesWithActivated =
                order.purchases
                    .map(x => x.data.rows)
                    .flat()
                    .filter(r => r.status === 'Activated' && r.activatedAt !== null) ?? [];

            const earliestDate = datesWithActivated.reduce((a, b) =>
                a.activatedAt && b.activatedAt && a.activatedAt < b.activatedAt ? a : b
            );

            const invoiceDate = getInvoiceMonth(earliestDate.activatedAt as Date);

            return (
                <EmptyList>
                    The invoice will be sent in the middle of {invoiceDate} with due date in the end
                    of {invoiceDate}
                </EmptyList>
            );
        }

        return (
            order.accounts?.length === 0 && (
                <EmptyList>This order has no invoice right now</EmptyList>
            )
        );
    };

    const renderDueDate = (currentInvoice: AccountInvoice) => {
        if (currentInvoice.invoiceState === InvoiceState.CreditNote) {
            return null;
        }

        if (!currentInvoice.dueDate) {
            return <DueDate>Due date was not found</DueDate>;
        }

        return <DueDate>Due {formatShortDate(new Date(currentInvoice.dueDate))}</DueDate>;
    };

    const renderInvoiceStatus = (currentInvoice: AccountInvoice) => {
        const invoiceStatus = (invoice: AccountInvoice) => {
            if (
                invoice.paymentStatus === InvoiceStatus.FullyPaid ||
                invoice.paymentStatus === InvoiceStatus.PartiallyPaid
            )
                return invoice.paymentStatus;

            if (invoice.canceled) return InvoiceStatus.Canceled;

            return invoice.paymentStatus;
        };

        return currentInvoice.invoiceState !== InvoiceState.CreditNote ? (
            <Status type={StatusTypes.Invoice} status={invoiceStatus(currentInvoice)} />
        ) : (
            <Tag>Credit Note</Tag>
        );
    };

    const renderNotificationState = (currentInvoice: AccountInvoice) => {
        switch (currentInvoice.invoiceState) {
            case InvoiceState.Reminder:
            case InvoiceState.FinalReminder:
            case InvoiceState.Void:
                return (
                    <NotificationState
                        state={currentInvoice.invoiceState}
                        canceled={currentInvoice.canceled}
                    />
                );
        }
    };

    return (
        <>
            <PaymentContainer>
                <div>
                    <Label>Payment method</Label>
                    <RightSideContent>
                        <NoTranslateLabel>{order.paymentMethod}</NoTranslateLabel>
                    </RightSideContent>
                </div>

                {order.paymentMethod === 'Account' && order.accounts?.length === 1 && (
                    <ViewAccount>
                        {order.accounts.map(account =>
                            account.moved && !!account.movedToAccountId ? (
                                <StyledLink
                                    key={account.movedToAccountId}
                                    to={`/accounts/${account.movedToAccountId}`}
                                >
                                    View account
                                </StyledLink>
                            ) : (
                                <StyledLink
                                    key={account.accountId}
                                    to={`/accounts/${account.accountId}`}
                                >
                                    View account
                                </StyledLink>
                            )
                        )}
                    </ViewAccount>
                )}
            </PaymentContainer>

            <Notifications>
                {showInvoiceSidebarMessage()}

                {order.accounts &&
                    accountsWithInvoices
                        ?.sort(
                            (a, b) =>
                                new Date(b.currentInvoice.createdAt).getTime() -
                                new Date(a.currentInvoice.createdAt).getTime()
                        )
                        .map(
                            (account: OrderAccount) =>
                                account.currentInvoice && (
                                    <InvoiceBox
                                        key={account.accountId}
                                        to={`/accounts/${account.accountId}`}
                                    >
                                        <InvoiceLeftArea>
                                            {account.moved && <MovedLabel>Moved</MovedLabel>}
                                            <Label>Activation {account.purchaseIdentifier}</Label>
                                            {renderDueDate(account.currentInvoice)}
                                            <StatusContainer>
                                                {renderInvoiceStatus(account.currentInvoice)}
                                                {renderNotificationState(account.currentInvoice)}
                                            </StatusContainer>
                                        </InvoiceLeftArea>
                                        <InvoiceAmount>
                                            <Money>{account.currentInvoice.totalAmount}</Money>
                                        </InvoiceAmount>
                                    </InvoiceBox>
                                )
                        )}
            </Notifications>
        </>
    );
};

const RightSideContent = styled.div`
    font-size: ${props => props.theme.text.size.medium};
`;

const Notifications = styled.div``;

const Label = styled.label`
    line-height: 2.5rem;
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    display: block;
`;

const MovedLabel = styled.div`
    line-height: 2.5rem;
    font-size: ${props => props.theme.text.size.large};
    color: ${props => props.theme.colors.text.secondary};
    display: block;
`;

const EmptyList = styled.label`
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    display: block;
    &:not(:last-child) {
        border-bottom: 0.1rem solid ${props => props.theme.colors.border};
    }
`;

const PaymentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;

    &:not(:only-child) {
    }
`;

const ViewAccount = styled.div`
    font-size: ${props => props.theme.text.size.small};
`;

const InvoiceBox = styled(Link)`
    text-decoration: none;
    color: inherit;
    padding: 2rem;
    margin: 0 -2rem;
    display: grid;
    grid-template-areas: 'left right' 'bottom .';
    border-top: 0.1rem solid ${props => props.theme.colors.border};
    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.colors.tag.default};
    }
`;

const InvoiceLeftArea = styled.div`
    grid-area: left;
`;

const InvoiceAmount = styled.div`
    grid-area: right;
    align-self: center;
    align-items: center;
    text-align: right;
`;

const StatusContainer = styled.div`
    display: flex;
    font-size: ${props => props.theme.text.size.medium};
    flex-wrap: wrap;
    gap: ${props => props.theme.layout.gap.xxsmall};
`;

const DueDate = styled.div`
    margin-bottom: 0.5rem;
    font-family: ${props => props.theme.text.font.bold};
`;

const Tag = styled.div`
    display: inline-block;
    background-color: ${props => props.theme.colors.tag.default};
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    margin: 0.3rem 0.3rem 0 0;
`;

const EmptyInvoice = styled.div`
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};
    &:hover {
        background-color: ${props => props.theme.colors.tag.default};
    }
    &:last-child {
        border: none;
    }
`;

export default InvoiceSidebar;
