import SearchableAccountTypes from '../searchableAccountTypes';
import SearchableAccountStates from '../searchableAccountStates';

const getOrderSearchFilters = (currentFilters: Map<string, string[]>) => {
    const filterCopy = new Map<string, string[]>(currentFilters);

    filterCopy.delete('type');
    filterCopy.delete('state');

    return filterCopy;
};

const getAccountSearchFilters = (currentFilters: Map<string, string[]>) => {
    const storeIdFromAccountFilters = currentFilters.get('storeId') ?? [];
    const filteredAccountFilters = new Map();

    filteredAccountFilters
        .set('type', SearchableAccountTypes)
        .set('state', SearchableAccountStates)
        .set('storeId', storeIdFromAccountFilters);

    return filteredAccountFilters;
};

const getAccountSearchFiltersForQuery = (currentFilters: Map<string, string[]>) => {
    const storeIdFromAccountFilters = currentFilters.get('storeId') ?? [];
    const filteredAccountFilters = new Map();

    if (storeIdFromAccountFilters.length > 0)
        filteredAccountFilters.set('storeId', storeIdFromAccountFilters);

    return filteredAccountFilters;
};

export { getOrderSearchFilters, getAccountSearchFilters, getAccountSearchFiltersForQuery };
