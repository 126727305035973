import DateRanges from '../../types/DateRanges';
import DateType from '../../types/date/DateType';
import DateTypeShort from '../../types/date/DateTypeShort';
import Operator from '../../types/date/Operator';
import { formatShortDateNumerical, splitRelative } from './dateTimeFormats';

const getDaysInMonth = (month: number, year: number) => new Date(year, month + 1, 0).getDate();

const calculateDiffBetweenTwoDates = (start: Date, end: Date) => {
    const diffms = start.valueOf() - end.valueOf();
    const msInOneDay = 86400000;
    const days = Math.abs(Math.floor(diffms / msInOneDay));

    return days;
};

const isToday = (compareDate: Date) => {
    const today = new Date();
    return (
        compareDate.getDate() === today.getDate() &&
        compareDate.getMonth() === today.getMonth() &&
        compareDate.getFullYear() === today.getFullYear()
    );
};

const generatePastOrFutureDate = (dateType: string, value: number) => {
    const today = new Date();
    const dateToSet = new Date();

    switch (dateType) {
        case DateType.DAY:
        case DateTypeShort.D:
            return new Date(dateToSet.setDate(today.getDate() + value));
        case DateType.MONTH:
        case DateTypeShort.M:
            return new Date(dateToSet.setMonth(today.getMonth() + value));
        case DateType.WEEK:
        case DateTypeShort.W:
            const weeksToDays = value * 7;
            return new Date(dateToSet.setDate(today.getDate() + weeksToDays));
        case DateType.YEAR:
        case DateTypeShort.Y:
            return new Date(dateToSet.setFullYear(today.getFullYear() + value));
        default:
            return today;
    }
};

const calculateFromRelative = (dates: string[]) => {
    const [operator, value, decorator] = splitRelative(dates[0]);

    const today = new Date();
    const parsedRangeValue = parseInt(operator + value);
    const calculatedDate = generatePastOrFutureDate(decorator, parsedRangeValue);

    switch (operator) {
        case Operator.SUBTRACT:
            return [calculatedDate, today];
        case Operator.ADD:
            return [today, calculatedDate];
        default:
            return [today, calculatedDate];
    }
};

const calculateRangeBasedOnDate = (range: string) => {
    const today = new Date();
    const todayAsFormatted = formatShortDateNumerical(today);
    const thisYear = today.getFullYear();

    switch (range) {
        case DateRanges.Last30days:
            return [
                formatShortDateNumerical(generatePastOrFutureDate(DateType.DAY, -30)),
                todayAsFormatted,
            ];
        case DateRanges.Last3months:
            return [
                formatShortDateNumerical(generatePastOrFutureDate(DateType.MONTH, -3)),
                todayAsFormatted,
            ];
        case DateRanges.ThisYearSoFar:
            return [formatShortDateNumerical(new Date(`${thisYear}-01-01`)), todayAsFormatted];
        case DateRanges.Day:
        default:
            return [todayAsFormatted, todayAsFormatted];
    }
};

export {
    isToday,
    calculateRangeBasedOnDate,
    getDaysInMonth,
    generatePastOrFutureDate,
    calculateFromRelative,
    calculateDiffBetweenTwoDates,
};
