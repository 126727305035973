/* eslint-disable no-nested-ternary */
import React, { useEffect, FormEvent } from 'react';
import styled from 'styled-components';

import { useAppState } from '../../../../store/appstate';
import groupsActions from '../../../../store/admin/groups/actions';

import usePermissions from '../../../../services/hooks/usePermissions';
import { useManagementTags } from '../../../../services/hooks/useTags';

import { ResponseData } from '../../../../types/response/ResponseData';
import { UserGroup } from '../../../../types/UserGroup';

import NotAuthorized from '../../NotAuthorized';

import Spinner from '../../../atoms/Spinner/Spinner';
import Toggle from '../../../atoms/Toggle/Toggle';
import { useAppDispatch } from '../../../../store';
import DecoratorInput from '../../../atoms/Input/DecoratorInput';
import useFilter from '../../../../services/hooks/useFilter';
import FilterLineIcon from 'remixicon-react/Filter3LineIcon';

interface InviteUserGroupProps {
    disabled?: boolean;
    selectedGroups: string[];
    setSelectedGroups: (item: string[]) => void;
    organization: string;
}

const InviteUserGroups: React.FC<InviteUserGroupProps> = ({
    disabled,
    selectedGroups,
    setSelectedGroups,
    organization,
}: InviteUserGroupProps) => {
    const dispatch = useAppDispatch();
    const isSearching = useAppState<boolean>(s => s.admin.groups.isSearching);
    const searchResults = useAppState<ResponseData<UserGroup[]>>(
        s => s.admin.groups.groupSearchResults
    );

    const groups = searchResults.data || [];

    const filterInviteGroups = (x: UserGroup, filterBySearchWord: (property: string) => boolean) =>
        filterBySearchWord(x.name);

    const { userManagementGroupTags } = useManagementTags();
    const { hasRead } = usePermissions(userManagementGroupTags);
    const { search, setSearch, showing, showFilter } = useFilter(groups ?? [], filterInviteGroups);

    useEffect(() => {
        dispatch(groupsActions.listGroups(organization));
    }, [dispatch, organization]);

    const onGroupsChange = (event: FormEvent<HTMLInputElement>) => {
        const groupId = event.currentTarget.value;
        const { checked } = event.currentTarget;

        const newSelectedGroups = checked
            ? [...selectedGroups, groupId]
            : selectedGroups.filter(item => item !== groupId);

        setSelectedGroups(newSelectedGroups);
    };

    const renderToggleCheckBox = (groupName: string, key: string, groupId: string) => {
        return (
            <Grid key={key}>
                <GridItem>{groupName}</GridItem>
                <GridItem checkbox>
                    <Toggle
                        name="groups"
                        data-testid={groupId}
                        id={groupId}
                        onChange={e => onGroupsChange(e)}
                        value={groupId}
                        disabled={disabled}
                        checked={selectedGroups.includes(groupId)}
                    />
                </GridItem>
            </Grid>
        );
    };

    if (!hasRead) return <NotAuthorized componentName="Invite user" />;
    if (disabled) return <></>;

    return (
        <>
            {!isSearching && groups ? (
                <>
                    {showFilter && (
                        <SearchWrapper>
                            <DecoratorInput
                                type="text"
                                name="filterGroupsInvitation"
                                onChange={e => setSearch(e.target.value)}
                                value={search}
                                placeholder="Filter groups"
                                id="filter-groups-invitation"
                                decorator={<FilterLineIcon size="17" />}
                            />
                        </SearchWrapper>
                    )}

                    <Container>
                        {showing
                            .filter(x => selectedGroups.includes(x.id))
                            .map((group, i) => {
                                const key = `key${i}`;

                                return renderToggleCheckBox(group.name, key, group.id);
                            })}

                        {showing
                            .filter(x => !selectedGroups.includes(x.id))
                            .map((group, i) => {
                                const key = `key${i}`;

                                return renderToggleCheckBox(group.name, key, group.id);
                            })}
                    </Container>
                </>
            ) : (
                <Spinner text="Loading user groups" loading />
            )}
        </>
    );
};

interface GridProps {
    checkbox?: boolean;
}

const Container = styled.div`
    padding-top: ${props => props.theme.layout.padding.medium};
    height: 40rem;
`;

const SearchWrapper = styled.div`
    margin-top: 1rem;
    background: white;
`;

const Grid = styled.div<GridProps>`
    display: grid;
    grid-gap: 1rem;
    grid-template-areas: 'text checkbox';
    grid-template-columns: 3fr 1fr;
    padding: 0.5rem 0;
`;
const GridItem = styled.div<GridProps>`
    grid-area: ${props => (props.checkbox ? 'checkbox' : 'text')};
    font-size: 1.4rem;
    justify-self: ${props => (props.checkbox ? 'end' : 'start')};
    color: ${props => props.theme.colors.text.primary};
`;

export default InviteUserGroups;
