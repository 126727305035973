import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Store3LineIcon from 'remixicon-react/Store3LineIcon';
import CenterContent from '../../../components/atoms/Layout/CenterContent';
import HeaderContent from '../../../components/atoms/Layout/HeaderContent';
import EditStoreProfile from '../../../components/molecules/Admin/Stores/EditStoreProfile';
import StoreSelect from '../../../components/molecules/StoreSelect';
import { StoresState } from '../../../store/admin/stores/storesSlice';
import storesActions from '../../../store/admin/stores/actions';
import { useAppState } from '../../../store/appstate';
import { ConnectedStore } from '../../../types/ConnectedStore';
import ErrorMessage from '../../../components/atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../types/response/ErrorCodes';
import Button from '../../../components/atoms/Button/Button';
import EmptyState from '../../../components/atoms/Information/EmptyState';
import Spinner from '../../../components/atoms/Spinner/Spinner';
import { useAppDispatch } from '../../../store';

interface ManageStoresProps {
    theme: DefaultTheme;
}

const ManageStores: React.FC<ManageStoresProps> = ({ theme }: ManageStoresProps) => {
    document.title = 'MerchantHub - Manage Stores';

    const dispatch = useAppDispatch();

    const stores =
        useAppState<ConnectedStore[] | undefined>(s =>
            s.session.availableStores?.filter(x => x.id !== 'all')
        ) ?? [];

    const {
        isLoading,
        store: { data: store, links },
        getError,
        patchError,
        postLogoError,
    } = useAppState<StoresState>(s => s.admin.stores);

    const [selectedStore, setSelectedStore] = useState<string | number>(
        stores.length === 1 ? stores[0].id : ''
    );

    const onsubmit = (color: string, terms: string, logoUrl: string) => {
        if (
            store.isCheckout &&
            store.profiles &&
            store.profiles.length > 0 &&
            (store.profiles[0].colorSettings.actionColor !== color ||
                store.profiles[0].terms.merchantTermsUri !== terms)
        )
            dispatch(
                storesActions.patchStore(
                    selectedStore as string,
                    store.profiles[0].name,
                    color,
                    terms
                )
            );

        if (logoUrl) dispatch(storesActions.postLogo(selectedStore as string, logoUrl));
    };

    const renderStore = () => {
        if (!store && !isLoading)
            return (
                <EmptyState
                    title="No store selected"
                    icon={
                        <Store3LineIcon
                            size={theme.icon.size.xlarge}
                            color={theme.colors.accent3}
                        />
                    }
                >
                    <div>Please select which store you want to configure</div>
                </EmptyState>
            );

        if (isLoading) return <Spinner text="Loading store" loading />;

        return <EditStoreProfile store={store} links={links} onSubmit={onsubmit} />;
    };

    useEffect(() => {
        if (selectedStore) dispatch(storesActions.getStore(selectedStore as string));
    }, [selectedStore]);

    return (
        <>
            <HeaderContent>
                <CenterContent>
                    <Header>
                        <Actions>
                            <StoreSelect
                                placeholder="Select store"
                                availableStores={stores}
                                onChange={setSelectedStore}
                                value={selectedStore}
                                width="30rem"
                            />
                            <Button
                                form="store-edit"
                                type="submit"
                                primary
                                large
                                disabled={!selectedStore}
                            >
                                Submit
                            </Button>
                        </Actions>
                    </Header>
                </CenterContent>
            </HeaderContent>
            <CenterContent>
                <Content>
                    {getError && (
                        <ErrorMessage error={getError} errorHeader={ErrorType.LoadStore} />
                    )}
                    {(patchError || postLogoError) && (
                        <ErrorMessage
                            error={patchError ?? postLogoError}
                            errorHeader={ErrorType.PatchStore}
                        />
                    )}
                    {renderStore()}
                </Content>
            </CenterContent>
        </>
    );
};

const Content = styled.div`
    margin: 2rem 0;
`;

const Header = styled.div`
    display: flex;
`;

const Actions = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;

export default withTheme(ManageStores) as React.ComponentType<Omit<ManageStoresProps, 'theme'>>;
