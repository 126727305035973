import React, { useEffect, useState, useId } from 'react';
import { useOrganizationsStore } from '../../../store/admin/organizations/organizationsStore';
import { useLocation } from 'react-router-dom';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import { useAppState } from '../../../store/appstate';
import { UserGroup } from '../../../types/UserGroup';
import groupsActions from '../../../store/admin/groups/actions';
import usersActions from '../../../store/admin/users/actions';
import apiSecretsActions from '../../../store/admin/apiSecrets/actions';
import webhooksActions from '../../../store/admin/webhooks/actions';

import {
    listOrganizations,
    enableOrganization,
    deleteOrganization,
} from '../../../api/admin/organizations';

import usePermissions from '../../../services/hooks/usePermissions';
import { useManagementTags } from '../../../services/hooks/useTags';
import { User } from '../../../types/User';
import CenterContent from '../../../components/atoms/Layout/CenterContent';
import HeaderContent from '../../../components/atoms/Layout/HeaderContent';
import { Modal } from '../../../components/atoms/Modal/Modal';
import InviteUserForm from '../../../components/molecules/Admin/Users/InviteUserForm';
import useModal from '../../../services/hooks/useModal';
import CreateNewGroupForm from '../../../components/molecules/Admin/Groups/CreateNewGroupForm';
import GroupSearchResults from '../../../components/molecules/Admin/Groups/GroupSearchResults';
import UserSearchResults from '../../../components/molecules/Admin/Users/UserSearchResults';
import SelectOrganization from '../../../components/molecules/Admin/SelectOrganization';
import { clearError, clearInviteUser } from '../../../store/admin/users/usersSlice';

import { resetCreateGroup } from '../../../store/admin/groups/groupsSlice';
import ApiSecretsSearchResults from '../../../components/molecules/Admin/ApiSecrets/ApiSecretsSearchResults';
import CreateApiSecretForm from '../../../components/molecules/Admin/ApiSecrets/CreateApiSecretForm';
import ConfirmationModal from '../../../components/atoms/Modal/ConfirmationModal';
import AppThunkConfirmationModal from '../../../components/atoms/Modal/AppThunkConfirmationModal';
import { ErrorType } from '../../../types/response/ErrorCodes';
import { ApiSecretsState } from '../../../store/admin/apiSecrets/apiSecretsSlice';
import { ApiSecret } from '../../../types/ApiSecret';
import MultiactionButton from '../../../components/atoms/Button/MultiactionButton';
import { LockedModal } from '../../../components/atoms/Modal/LockedModal';
import { useAppDispatch } from '../../../store';
import Button from '../../../components/atoms/Button/Button';
import { SessionState } from '../../../store/session/sessionSlice';
import Spinner from '../../../components/atoms/Spinner/Spinner';
import useLogTimeSpentOnPage from '../../../services/hooks/useLogTimeSpentOnPage';
import { config } from '../../../config';
import sessionActions from '../../../store/session/actions';
import Tabs, { TabContent } from '../../../components/atoms/Tabs/Tabs';
import Flex from '../../../components/atoms/Box/Flex';
import WebhooksSearchResults from '../../../components/molecules/Admin/Webhooks/WebhooksSearchResults';
import CreateWebhookForm from '../../../components/molecules/Admin/Webhooks/CreateWebhookForm';
import { WebhookState } from '../../../store/admin/webhooks/webhooksSlice';

interface ManageAccessProps {
    theme: DefaultTheme;
}

const ManageAccess: React.FC<ManageAccessProps> = ({ theme }: ManageAccessProps) => {
    document.title = 'MerchantHub - Manage Access';

    useLogTimeSpentOnPage('manageAccess');

    const dispatch = useAppDispatch();
    const { hash } = useLocation();
    const initialTabState = hash ? hash.substring(1) : 'users';
    const [tabState, setTabState] = useState(initialTabState);

    const [removeKey, setRemoveKey] = useState({ id: '', label: '' });

    const groups = useAppState<UserGroup[]>(s => s.admin.groups.groupSearchResults.data);
    const users = useAppState<User[]>(s => s.admin.users.userSearchResults.data);

    const { userManagementGroupTags, userManagementUserTags, apiSecretTags, webhooksTags } =
        useManagementTags();

    const {
        isRemoving: isRemovingApiSecret,
        isRemoved: isRemovedApiSecret,
        removeError: removeErrorApiSecret,
        searchResults: { data: apiSecrets },
        isCreating: isCreatingApiSecret,
    } = useAppState<ApiSecretsState>(s => s.admin.apiSecrets);

    const {
        searchResults: { data: webhooks },
        isCreating: isCreatingWebHook,
    } = useAppState<WebhookState>(s => s.admin.webhooks);

    const {
        selectedOrganization,
        selectedOrganizationPending,
        user: { isInternal } = { isInternal: false },
    } = useAppState<SessionState>(s => s.session);

    const groupPermissions = usePermissions(userManagementGroupTags);
    const userPermissions = usePermissions(userManagementUserTags);
    const apiSecretPermissions = usePermissions(apiSecretTags);
    const webhooksPermissions = usePermissions(webhooksTags);

    const { isShown: isInviteUserShown, toggle: toggleInviteUser } = useModal();
    const { isShown: isAddGroupShown, toggle: toggleAddGroup } = useModal();
    const { isShown: isAddApiSecretShown, toggle: toggleAddApiSecret } = useModal();
    const { isShown: isRemoveApiSecretShown, toggle: toggleRemoveApiSecret } = useModal();
    const { isShown: isEnableOrganizationShown, toggle: toggleEnableOrganizationShown } =
        useModal();
    const { isShown: isDeleteOrganizationShown, toggle: toggleDeleteOrganizationShown } =
        useModal();
    const { isShown: isAddWebhookShown, toggle: toggleAddWebhook } = useModal();

    const {
        organizations,
        isEnablingOrganization,
        organizationEnabled,
        enableOrganizationError,
        getOrganizationsSucceeded,
        getOrganizationsFailed,
        enableOrganizationStarted,
        enableOrganizationSucceeded,
        enableOrganizationFailed,
        deleteOrganizationStarted,
        deleteOrganizationSucceeded,
        deleteOrganizationFailed,
        clearEnableOrganization,
        clearDeleteOrganization,
        isDeletingOrganization,
        isDeletedOrganization,
        deleteOrganizationError,
        deletedOrganization,
    } = useOrganizationsStore();

    const onHideInviteUserModal = () => {
        dispatch(clearError());
        dispatch(clearInviteUser());
        toggleInviteUser();
    };

    const onHideCreateApiSecretModal = () => {
        if (isCreatingApiSecret) return;

        dispatch(apiSecretsActions.clearCreate());
        toggleAddApiSecret();
    };

    const onHideRemoveApiSecretModal = () => {
        dispatch(apiSecretsActions.clearRemoved());
        toggleRemoveApiSecret();
    };

    const onHideEnableCheckoutApiModal = () => {
        clearEnableOrganization();
        toggleEnableOrganizationShown();
    };

    const onHideDeleteOrganizationModal = () => {
        clearDeleteOrganization();
        toggleDeleteOrganizationShown();
    };

    const onHideCreateWebhookModal = () => {
        if (isCreatingWebHook) return;

        dispatch(webhooksActions.clearCreate());
        toggleAddWebhook();
    };

    const onToggleRemoveApiSecret = (e: React.MouseEvent<HTMLButtonElement>, key: ApiSecret) => {
        e.stopPropagation();
        setRemoveKey(key);
        toggleRemoveApiSecret();
    };

    const isNotCollectorOrganization = selectedOrganization.id != config.InternalOrganization;

    useEffect(() => {
        if (isInternal) {
            listOrganizations(getOrganizationsSucceeded, getOrganizationsFailed);
        }

        const haveOrganization =
            !selectedOrganizationPending && selectedOrganization && selectedOrganization.id !== '';

        if (!haveOrganization) return;

        if (groupPermissions.hasRead) {
            dispatch(groupsActions.listGroups(selectedOrganization.id));
        }

        if (userPermissions.hasRead) {
            dispatch(usersActions.listUsers(selectedOrganization.id));
        }

        if (apiSecretPermissions.hasRead) {
            dispatch(apiSecretsActions.listApiSecrets(selectedOrganization.id));
        }

        if (webhooksPermissions.hasRead) {
            dispatch(webhooksActions.listWebhooks(selectedOrganization.id));
        }
    }, [
        groupPermissions.hasRead,
        dispatch,
        selectedOrganization.id,
        userPermissions.hasRead,
        isInternal,
        selectedOrganizationPending,
        webhooksPermissions.hasRead,
    ]);

    useEffect(() => {
        if (isDeletedOrganization)
            dispatch(sessionActions.setSelectedOrganization(config.InternalOrganization));
    }, [isDeletedOrganization]);

    const actionList = [
        {
            key: useId(),
            label: 'Invite',
            action: () => toggleInviteUser(),
            show: !selectedOrganization.blockInvites,
        },
        {
            key: useId(),
            label: 'Group',
            action: () => {
                dispatch(resetCreateGroup());
                toggleAddGroup();
            },
            show: groupPermissions.hasWrite,
        },
        {
            key: useId(),
            label: 'Api key',
            action: () => toggleAddApiSecret(),
            show: apiSecretPermissions.hasWrite,
        },
        {
            key: useId(),
            label: 'Webhook',
            action: () => toggleAddWebhook(),
            show: webhooksPermissions.hasWrite,
        },
    ];
    const tabsList: TabContent[] = [
        { key: 'users', text: 'Users', isVisible: userPermissions.hasRead },
        { key: 'groups', text: 'Groups', isVisible: groupPermissions.hasRead },
        { key: 'apisecrets', text: 'API keys', isVisible: apiSecretPermissions.hasRead },
        {
            key: 'webhooks',
            text: 'Webhooks',
            isVisible: webhooksPermissions.hasRead,
        },
    ];

    return (
        <>
            <HeaderContent>
                <CenterContent>
                    <Row>
                        <Col>
                            <Label>Manage Organization</Label>
                            <BoldText>{selectedOrganization.name}</BoldText>
                        </Col>
                        {isInternal && isNotCollectorOrganization && (
                            <Col>
                                <Label>Organization Id</Label>
                                <BoldText>{selectedOrganization.id}</BoldText>
                            </Col>
                        )}
                        <Col>
                            <Label>Users</Label>
                            <UserRow>{users ? users.length : '-'}</UserRow>
                        </Col>
                        <Col>
                            <Label>Groups</Label>
                            <UserRow>{groups ? groups.length : '-'}</UserRow>
                        </Col>
                        <Actions>
                            {isInternal && isNotCollectorOrganization && (
                                <Button large onClick={() => toggleEnableOrganizationShown()}>
                                    Enable Checkout
                                </Button>
                            )}
                            {isInternal && isNotCollectorOrganization && (
                                <Button large onClick={() => toggleDeleteOrganizationShown()}>
                                    Delete Organization
                                </Button>
                            )}
                            <MultiactionButton actions={actionList}>
                                Create new <AddLineIcon size={theme.icon.size.normal} />
                            </MultiactionButton>
                            <SelectOrganization />
                        </Actions>
                    </Row>
                </CenterContent>
            </HeaderContent>

            <CenterContent>
                {selectedOrganizationPending ? (
                    <PositionSpinner>
                        <Spinner loading={selectedOrganizationPending} />
                    </PositionSpinner>
                ) : (
                    <Flex column rowGap={'2rem'}>
                        <Tabs tabsList={tabsList} startTab={tabState} onTabChange={setTabState}>
                            {userPermissions.hasRead && (
                                <Flex key={'users'} column>
                                    <UserSearchResults
                                        hasWrite={userPermissions.hasWrite}
                                        data={users}
                                        toggleInvite={toggleInviteUser}
                                    />
                                </Flex>
                            )}
                            {groupPermissions.hasRead && (
                                <Flex key={'groups'} column>
                                    <GroupSearchResults
                                        hasWrite={groupPermissions.hasWrite}
                                        data={groups}
                                    />
                                </Flex>
                            )}
                            {apiSecretPermissions.hasRead && (
                                <Flex key={'apisecrets'} column>
                                    <ApiSecretsSearchResults
                                        hasWrite={apiSecretPermissions.hasWrite}
                                        searchResults={apiSecrets}
                                        onRemove={onToggleRemoveApiSecret}
                                        toggleCreate={toggleAddApiSecret}
                                    />
                                </Flex>
                            )}
                            {webhooksPermissions.hasRead && (
                                <Flex key={'webhooks'} column>
                                    <WebhooksSearchResults
                                        searchResults={webhooks}
                                        toggleCreate={toggleAddWebhook}
                                    />
                                </Flex>
                            )}
                        </Tabs>
                    </Flex>
                )}
            </CenterContent>
            <Modal small isShown={isInviteUserShown} hide={onHideInviteUserModal}>
                <InviteUserForm
                    onInvite={(
                        organizationId: string,
                        email: string,
                        name: string,
                        selectedGroups?: string[]
                    ) => {
                        dispatch(
                            usersActions.inviteUserByEmail(
                                organizationId,
                                email,
                                name,
                                selectedGroups
                            )
                        );
                        setTabState('users');
                    }}
                    organizationId={selectedOrganization.id}
                    organizationName={selectedOrganization.name}
                    hide={onHideInviteUserModal}
                />
            </Modal>
            <Modal small isShown={isAddGroupShown} hide={toggleAddGroup}>
                <CreateNewGroupForm
                    onCreate={(organizationId: string, groupName: string) => {
                        dispatch(groupsActions.createGroup(organizationId, groupName));
                    }}
                    organizationId={selectedOrganization.id}
                    organizationName={selectedOrganization.name}
                    hide={toggleAddGroup}
                />
            </Modal>
            <LockedModal
                dataTestId={'api-secret-modal'}
                focusLock
                small
                isShown={isAddApiSecretShown}
                hide={onHideCreateApiSecretModal}
            >
                <CreateApiSecretForm
                    onSave={(label: string, expirationDate: Date | null) => {
                        dispatch(
                            apiSecretsActions.createApiSecret(
                                selectedOrganization.id,
                                label,
                                expirationDate
                            )
                        );
                        setTabState('apisecrets');
                    }}
                    hide={onHideCreateApiSecretModal}
                    locked={apiSecrets && apiSecrets.length >= selectedOrganization.maxApiKeys}
                />
            </LockedModal>
            <Modal medium isShown={isRemoveApiSecretShown} hide={onHideRemoveApiSecretModal}>
                <AppThunkConfirmationModal
                    title="Remove api secret"
                    label={removeKey.label}
                    labelAction={'Removed'}
                    action={apiSecretsActions.removeApiSecret(
                        selectedOrganization.id,
                        removeKey.id
                    )}
                    hideConfirmation={!isRemovingApiSecret && isRemovedApiSecret}
                    isProcessing={isRemovingApiSecret}
                    isSuccessful={isRemovedApiSecret}
                    hide={onHideRemoveApiSecretModal}
                    error={removeErrorApiSecret}
                    errorType={ErrorType.RemoveApiSecret}
                >
                    <>
                        Do you want to remove api secret with label <Id>{removeKey.label}</Id>
                    </>
                </AppThunkConfirmationModal>
            </Modal>

            <Modal medium isShown={isEnableOrganizationShown} hide={onHideEnableCheckoutApiModal}>
                <ConfirmationModal
                    title="Enable checkout API"
                    label={selectedOrganization.name}
                    labelAction={'Enabled organization'}
                    action={() =>
                        enableOrganization(
                            selectedOrganization.id,
                            enableOrganizationStarted,
                            enableOrganizationSucceeded,
                            enableOrganizationFailed
                        )
                    }
                    hideConfirmation={!isEnablingOrganization && organizationEnabled}
                    isProcessing={isEnablingOrganization}
                    isSuccessful={organizationEnabled}
                    hide={onHideEnableCheckoutApiModal}
                    error={enableOrganizationError}
                    errorType={ErrorType.EnableCheckoutApi}
                >
                    <>
                        Do you want to enable checkout API for organization{' '}
                        <b>{selectedOrganization.name}</b> with id <b>{selectedOrganization.id}</b>
                    </>
                </ConfirmationModal>
            </Modal>

            <Modal medium isShown={isDeleteOrganizationShown} hide={onHideDeleteOrganizationModal}>
                <ConfirmationModal
                    title="Delete organization"
                    label={deletedOrganization}
                    labelAction={'Deleted organization'}
                    action={() =>
                        deleteOrganization(
                            selectedOrganization.id,
                            selectedOrganization.name,
                            deleteOrganizationStarted,
                            deleteOrganizationSucceeded,
                            deleteOrganizationFailed
                        )
                    }
                    hideConfirmation={!isDeletingOrganization && isDeletedOrganization}
                    isProcessing={isDeletingOrganization}
                    isSuccessful={isDeletedOrganization}
                    hide={onHideDeleteOrganizationModal}
                    error={deleteOrganizationError}
                    errorType={ErrorType.DeleteOrganization}
                >
                    Do you want to delete organization <b>{selectedOrganization.name}</b> with id{' '}
                    <b>{selectedOrganization.id}</b>
                </ConfirmationModal>
            </Modal>
            <Modal medium isShown={isAddWebhookShown} hide={onHideCreateWebhookModal}>
                <CreateWebhookForm
                    hide={onHideCreateWebhookModal}
                    onSave={data => {
                        dispatch(webhooksActions.createWebhook(selectedOrganization.id, data));
                        setTabState('webhooks');
                    }}
                />
            </Modal>
        </>
    );
};

const Id = styled.div`
    color: ${props => props.theme.colors.text.secondary};
`;
const PositionSpinner = styled.div`
    margin-top: 8rem;
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-items: center;
    font-size: ${props => props.theme.text.size.medium};
`;

const UserRow = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${props => props.theme.text.size.medium};
`;
const Col = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => `0 ${props.theme.layout.padding.medium}`};
    border-right: 0.2rem solid ${props => props.theme.colors.text.subtle};
    &:first-child {
        padding-left: 0;
        justify-content: center;
    }
    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
`;
const Actions = styled.div`
    margin-left: auto;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
`;
const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin-bottom: 0.3rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
`;

export default withTheme(ManageAccess) as React.ComponentType<Omit<ManageAccessProps, 'theme'>>;
