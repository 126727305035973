import { keyframes } from 'styled-components';

const WiggleAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(0deg);
    }
    85% {
        transform: rotate(10deg);
    }
    95% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;

export default WiggleAnimation;
