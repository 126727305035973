enum InvoiceState {
    Notified = 'Notified',
    First = 'First',
    Regular = 'Regular',
    Reminder = 'Reminder',
    FinalReminder = 'FinalReminder',
    Void = 'Void',
    CreditNote = 'CreditNote',
    TerminationNotice = 'TerminationNotice',
    CollectionNotice = 'CollectionNotice',
}

export default InvoiceState;
