import React, { useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import CenterContent from '../../components/atoms/Layout/CenterContent';
import { useAppState } from '../../store/appstate';
import ButtonTab from '../../components/atoms/Tabs/ButtonTab';
import ButtonTabs from '../../components/atoms/Tabs/ButtonTabs';
import DashboardCard from '../../components/molecules/Dashboard/DashboardCard';
import dashboardActions from '../../store/dashboard/actions';
import { capitalizeFirstLetter } from '../../services/helpers/stringHelper';
import {
    calculateFromRelative,
    generatePastOrFutureDate,
} from '../../services/helpers/dateTimeHelper';
import { formatShortDateNumerical } from '../../services/helpers/dateTimeFormats';
import Flex from '../../components/atoms/Box/Flex';
import StoreTerm from '../../components/atoms/Term/StoreTerm';
import { parseFilters } from '../../services/helpers/filterHelpers';
import useLogTimeSpentOnPage from '../../services/hooks/useLogTimeSpentOnPage';
import HeaderContent from '../../components/atoms/Layout/HeaderContent';
import NotAuthorized from '../../components/molecules/NotAuthorized';
import DashboardTopCategoryType from '../../types/dashboard/DashboardTopCategoryType';
import DashboardGraph from '../../components/molecules/Dashboard/DashboardGraph';
import useSpecificPermission from '../../services/hooks/useSpecificPermission';
import { DashboardState } from '../../store/dashboard/dashboardSlice';
import ErrorMessage from '../../components/atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../types/response/ErrorCodes';
import { SessionState } from '../../store/session/sessionSlice';
import getTimeOfDayMessage from '../../services/helpers/timeOfDayMessage';
import DateRange from '../../components/atoms/DateRange/DateRange';
import { DashboardSearchTerms } from '../../components/molecules/Dashboard/DashboardSearchForm';
import RelativeOperationTypes from '../../types/RelativeOperation';
import DateType from '../../types/date/DateType';
import Logger from '../../services/logging/logger';
import FilterTerm from '../../types/filter/FilterTerm';
import FilterDateType from '../../types/filter/FilterDateType';
import { getBucketSizeAndLimitations } from '../../services/helpers/bucketSizeCalculations';
import DashboardSection from '../../components/molecules/Dashboard/DashboardSection';
import categorySections from '../../constants/dashboard/dasboardCategorySections';
import dashboardCards from '../../constants/dashboard/dashboardCards';

import { lastDayRelativeRanges } from '../../constants/date/ranges';
import BucketSize from '../../types/dashboard/BucketSize';
import EmptyState from '../../components/atoms/Information/EmptyState';
import DashboardLineIcon from 'remixicon-react/DashboardLineIcon';
import { NavLink } from 'react-router-dom';

interface DashboardProps {
    theme: DefaultTheme;
}

const initalBucketOptions = [BucketSize.Hour];

const categories = [DashboardTopCategoryType.SALES, DashboardTopCategoryType.RETENTION];

const initialTabState = {
    [DashboardTopCategoryType.RETENTION]: 'customerSatisfaction',
    [DashboardTopCategoryType.SALES]: 'aggregateSearchCount',
} as Record<DashboardTopCategoryType, string>;

const currencies = {
    SEK: 'SEK',
    EUR: '€',
    NOK: 'NOK',
    DKK: 'DKK',
};

export { initalBucketOptions, categories, initialTabState };

const setPlacedAtFilter = (filter: Map<string, string[]>, range: string[]) =>
    filter.set(FilterTerm.PlacedAt, range);

const Dashboard: React.FC<DashboardProps> = ({ theme }: DashboardProps) => {
    document.title = 'Merchant Hub - Dashboard';

    const [currency, setCurrency] = useState('SEK');
    const [bucketSize, setBucketSize] = useState('hour');
    const [subTab, setSubTab] = useState(initialTabState);
    const [tab, setTab] = useState(DashboardTopCategoryType.RETENTION);
    const [bucketOptions, setBucketOptions] = useState(initalBucketOptions);
    const [filter, setFilter] = useState<Map<string, string[]>>(
        setPlacedAtFilter(new Map(), ['0d'])
    );

    const {
        user: { name: userName, isInternal, organization: { id: organization } = { id: '' } } = {
            name: '',
            isInternal: false,
            organization: {},
        },
        availableStores = [],
    } = useAppState<SessionState>(s => s.session);

    const { hasRead } = useSpecificPermission('marketing-all-dashboard');
    const { hasWrite: hasGroupWrite } = useSpecificPermission(
        `usermanagement-${organization}-grouplist`
    );

    const { dashboardStatistics, aggregateFailed, aggregateError } = useAppState<DashboardState>(
        s => s.dashboard
    );

    const handleTab = (tabToSet: DashboardTopCategoryType, subTabToSet?: string) => {
        setSubTab({
            ...subTab,
            [tabToSet]: subTabToSet,
        } as Record<DashboardTopCategoryType, string>);
    };

    const getDefaultDates = (termFilter: string[]) => {
        const [start, end] = termFilter;

        const today = formatShortDateNumerical(new Date());

        if (!start && !end) return ['0d'];

        return [
            start ? start : formatShortDateNumerical(generatePastOrFutureDate(DateType.YEAR, -2)),
            end ? end : today,
        ];
    };

    const getCorrectBucketSizePerRange = (dateFilter: string[]) => {
        if (dateFilter.length === 0) return getBucketSizeAndLimitations([null, null]);

        const [start, end] = getDefaultDates(dateFilter);
        const [relativeStart, relativeEnd] = calculateFromRelative(dateFilter);

        const days =
            dateFilter.length === 1
                ? [relativeStart, relativeEnd]
                : [new Date(start), new Date(end)];

        return getBucketSizeAndLimitations(days);
    };

    const handleDateFilterChange = (termKey: string | undefined, termFilter: string[]) => {
        if (!termKey) return;

        const isRelative = termFilter.length === 1;

        Logger.information('User applied filter in Dashboard', {
            Term: termKey,
            Filters: termFilter,
            FilterType: isRelative ? FilterDateType.Relative : FilterDateType.Absolute,
        });

        const dateFilter = isRelative ? termFilter : getDefaultDates(termFilter);

        setFilter(new Map(filter.set(termKey, dateFilter)));

        const { defaultSize, limitation } = getCorrectBucketSizePerRange(dateFilter);

        setBucketSize(defaultSize);
        setBucketOptions(limitation);
    };

    const handleFilterChange = (termKey: string | undefined, termFilter: string[]) => {
        if (!termKey) return;

        setFilter(new Map(filter.set(termKey, termFilter)));

        Logger.information('User applied filter in Dashboard', {
            Term: termKey,
            Filters: termFilter,
        });
    };

    const renderDashboardCardsPerCategory = (chosenCategory: DashboardTopCategoryType) => {
        return (
            <>
                {dashboardCards
                    .filter(x => x.category === chosenCategory)
                    .filter(x => !x.requirePermission || isInternal)
                    .map(x => {
                        const hasGraph = categorySections.some(dashboard => dashboard.id === x.id);

                        return (
                            <DashboardCard
                                flex
                                key={x.id}
                                title={x.title}
                                type={x.type}
                                highlighted={subTab[x.category] === x.id}
                                onClick={() => (hasGraph ? handleTab(x.category, x.id) : () => {})}
                                value={x.value}
                                disabled={x.disabled}
                                dispatchAction={dashboardActions.dashboardAggregateData(
                                    x.id,
                                    x.query ?? '',
                                    filter,
                                    undefined,
                                    undefined,
                                    x.entity
                                )}
                                information={x.information}
                                filterChange={filter}
                                statistics={dashboardStatistics[x.id]}
                            />
                        );
                    })}
            </>
        );
    };

    useLogTimeSpentOnPage('dashboard');

    if (!hasRead && hasGroupWrite) {
        return (
            <>
                <HeaderContent />
                <CenterContent>
                    <EmptyState
                        title="It's empty over here!"
                        icon={
                            <DashboardLineIcon
                                size={theme.icon.size.xlarge}
                                color={theme.colors.accent3}
                            />
                        }
                    >
                        <div>
                            Please go to{' '}
                            <NavLink to="/admin/manage-organization">Manage Organization</NavLink>{' '}
                            and enable the <i>Dashboard Management</i> permission.{' '}
                        </div>
                    </EmptyState>
                </CenterContent>
            </>
        );
    }

    if (!hasRead) {
        return (
            <>
                <HeaderContent />
                <CenterContent>
                    <NotAuthorized componentName="Dashboard" />
                </CenterContent>
            </>
        );
    }

    return (
        <CenterContent>
            <Flex column gap={theme.layout.gap.medium}>
                <FilterSection row gap={theme.layout.gap.medium}>
                    <Flex column gap={theme.layout.gap.xsmall}>
                        <Title>{getTimeOfDayMessage()},</Title>
                        <div>{userName}</div>
                    </Flex>

                    <Filter column gap={theme.layout.gap.medium}>
                        <TermWrapper gap={theme.layout.gap.small}>
                            {
                                <DateRange
                                    position="right"
                                    key={'Date'}
                                    maxDate={new Date()}
                                    label="Date"
                                    onDateChange={handleDateFilterChange}
                                    initialValue={parseFilters(
                                        DashboardSearchTerms.PLACED_AT,
                                        filter
                                    )}
                                    dateRangeType={DashboardSearchTerms.PLACED_AT}
                                    relativeRanges={lastDayRelativeRanges}
                                    relativeLabel={{
                                        type: 'Last',
                                        operation: RelativeOperationTypes.SUBTRACT,
                                    }}
                                />
                            }

                            <StoreTerm
                                position="right"
                                key={FilterTerm.StoreId}
                                currentFilters={parseFilters(FilterTerm.StoreId, filter)}
                                availableStores={availableStores}
                                onTermFilterChange={handleFilterChange}
                            />
                        </TermWrapper>
                    </Filter>
                </FilterSection>

                {aggregateFailed &&
                    aggregateError &&
                    aggregateError?.code === 'USER_UNAUTHENTICATED' && (
                        <ErrorMessage
                            error={aggregateError}
                            errorHeader={ErrorType.DashboardAggregate}
                        />
                    )}

                <MiddleSectionButtons row>
                    <ButtonTabs>
                        {categories.map(category => (
                            <ButtonTab
                                key={category}
                                tabKey={category}
                                text={capitalizeFirstLetter(category)}
                                setTab={() => setTab(category)}
                                currentTab={tab}
                                large
                                onClick={() => {
                                    setFilter(
                                        new Map(
                                            filter.set(
                                                FilterTerm.Currency,
                                                category == DashboardTopCategoryType.SALES
                                                    ? [currency]
                                                    : []
                                            )
                                        )
                                    );
                                }}
                            />
                        ))}
                    </ButtonTabs>

                    {tab === DashboardTopCategoryType.SALES && (
                        <TermWrapper>
                            <ButtonTabs>
                                {Object.entries(currencies).map(([key, value]) => {
                                    return (
                                        <ButtonTab
                                            key={key}
                                            tabKey={key}
                                            text={value}
                                            setTab={setCurrency}
                                            currentTab={currency}
                                            onClick={() =>
                                                handleFilterChange(FilterTerm.Currency, [key])
                                            }
                                        />
                                    );
                                })}
                            </ButtonTabs>
                        </TermWrapper>
                    )}
                </MiddleSectionButtons>

                <CardContainer row>{renderDashboardCardsPerCategory(tab)}</CardContainer>

                <BottomSection>
                    <Flex column width={`100%`} gap={theme.layout.gap.small}>
                        <DashboardSection
                            bucketOptions={bucketOptions}
                            bucketSize={bucketSize}
                            setBucketSize={setBucketSize}
                            title={dashboardCards.find(x => x.id === subTab[tab])?.title ?? ''}
                        >
                            <Flex
                                row
                                rowGap={theme.layout.gap.medium}
                                flexWrap="wrap"
                                justifyContent="space-between"
                            >
                                {categorySections
                                    .filter(
                                        section =>
                                            tab == section.topCategory &&
                                            subTab[section.topCategory] == section.id
                                    )
                                    .map(section => {
                                        return (
                                            <div
                                                key={section.graphId}
                                                style={{
                                                    width: section.graphsPerRow
                                                        ? `calc(${
                                                              100 / (section.graphsPerRow ?? 1)
                                                          }% - 1rem)`
                                                        : '100%',
                                                }}
                                            >
                                                <DashboardGraphConatiner
                                                    column
                                                    gap={theme.layout.gap.small}
                                                >
                                                    <DashboardGraphTitle>
                                                        {section.title}
                                                    </DashboardGraphTitle>
                                                    <DashboardGraph
                                                        height={500 / (section.graphsPerRow ?? 1)}
                                                        type={section.graphType}
                                                        dispatchAction={dashboardActions.dashboardAggregateData(
                                                            section.graphId,
                                                            section.query,
                                                            filter,
                                                            bucketSize,
                                                            'Unknown',
                                                            section.entity
                                                        )}
                                                        filterChange={filter}
                                                        statistics={
                                                            dashboardStatistics[section.graphId]
                                                        }
                                                        valueCalculation={section.valueCalculation}
                                                        valueType={section.valueType}
                                                        color={section.color}
                                                        bucketSize={bucketSize}
                                                    />
                                                </DashboardGraphConatiner>
                                            </div>
                                        );
                                    })}
                            </Flex>
                        </DashboardSection>
                    </Flex>
                </BottomSection>
            </Flex>
        </CenterContent>
    );
};

const MiddleSectionButtons = styled(Flex)`
    justify-content: space-between;

    @media (max-width: 60rem) {
        flex-direction: column;
    }
`;

const BottomSection = styled(Flex)`
    border: 0.1rem solid ${props => props.theme.colors.border};
    border-radius: 0.5rem;
    background: white;
    padding: 3rem 4rem;
    margin-bottom: 1rem;
`;

const FilterSection = styled(Flex)`
    border: 0.1rem solid ${props => props.theme.colors.border};
    justify-content: space-between;
    border-radius: 0.5rem;
    background: white;
    padding: 3rem 4rem;
    margin-top: 2rem;
`;

const Filter = styled(Flex)`
    justify-self: flex-end;
`;

const TermWrapper = styled(Flex)`
    align-self: flex-end;
`;

const CardContainer = styled(Flex)`
    gap: ${props => props.theme.layout.gap.medium};
    width: 100%;
    flex-wrap: wrap;
`;

const Title = styled.div`
    font-size: 2rem;
    font-family: ${props => props.theme.text.font.bold};
`;

const DashboardGraphConatiner = styled(Flex)`
    padding: ${props => props.theme.layout.padding.large};
    border: 0.1rem solid ${props => props.theme.colors.border};
    border-radius: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`;

const DashboardGraphTitle = styled.div`
    font-size: 1.5rem;
    font-family: ${props => props.theme.text.font.bold};
`;

export default withTheme(Dashboard) as React.ComponentType<Omit<DashboardProps, 'theme'>>;
