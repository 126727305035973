import React, { useCallback, useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';

import { useAppState } from '../../../store/appstate';
import accountActions from '../../../store/account/actions';

import { LinkData } from '../../../types/response/LinkData';
import { ErrorType } from '../../../types/response/ErrorCodes';
import { Notification } from '../../../types/account/Notification';
import { NotificationRow } from '../../../types/account/NotificationRow';

import { formatNumber, numberAsDiff } from '../../../services/helpers/numberFormats';

import Spinner from '../../atoms/Spinner/Spinner';
import Card from '../../atoms/Card/Card';
import CardBody from '../../atoms/Card/CardBody';
import LayoutContainer from '../../atoms/Modal/LayoutContainer';
import LayoutPosition from '../../atoms/Modal/LayoutPosition';
import StickyNote from '../../atoms/StickyNote/StickyNote';
import Label from '../../atoms/Label/Label';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import Total from '../../atoms/Money/Total';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { AccountState } from '../../../store/account/accountSlice';
import Money from '../../atoms/Money/Money';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';
import Table from '../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import { isFee } from '../../../services/helpers/notificationRowHelper';

interface Props {
    notification: Notification;
    creditFeeLink?: LinkData;
    hide: () => void;
    theme: DefaultTheme;
    data: NotificationRow[];
}

const CreditFees: React.FC<Props> = ({ notification, hide, creditFeeLink, theme, data }: Props) => {
    const dispatch = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState([] as NotificationRow[]);
    const { error, isCrediting, creditFeeFailed, isCredited } = useAppState<AccountState>(
        s => s.account
    );

    const toCredit = selectedRows
        ? selectedRows
              .filter(x => isFee(x))
              .flatMap(x => x.feeTransactions?.map(t => t.transactionId))
              .concat(selectedRows.map(x => x.transactionId))
        : [];

    const setSelectedRowsCallBack = useCallback(
        (rows: NotificationRow[]) => {
            if (selectedRows.length !== rows.length) setSelectedRows(rows);
        },
        [setSelectedRows, selectedRows]
    );

    const onCreditFees = () => {
        if (creditFeeLink) {
            dispatch(accountActions.creditFee(creditFeeLink, selectedRows));
        }
    };

    useEffect(() => {
        if (isCredited) hide();
    }, [hide, isCredited]);

    const totalCreditValue = notification.rows
        .filter(x => isFee(x) && !x.credited)
        .filter(
            x =>
                toCredit.some(t => x.feeTransactions?.some(y => y.transactionId == t)) ||
                (x.transactionId && toCredit.includes(x.transactionId))
        )
        .map(x => x.totalPrice)
        .reduce((a, b) => a + b, 0);

    const originalCreditValue = notification.rows
        .filter(x => isFee(x) && !x.credited)
        .map(x => x.totalPrice)
        .reduce((a, b) => a + b, 0);

    const columns: ColumnDef<NotificationRow>[] = [
        {
            header: 'Type',
            accessorKey: 'type',
            cell: (props: { row: { original: NotificationRow } }) => {
                return (
                    <ClickableLabel htmlFor={props.row.original.transactionId}>
                        {props.row.original.type ?? '-'}
                    </ClickableLabel>
                );
            },
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: (props: { row: { original: NotificationRow } }) => {
                return <div>{props.row.original.description ?? '-'}</div>;
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'Amount',
            accessorKey: 'totalPrice',
            cell: (props: { row: { original: NotificationRow } }) => {
                return <div>{props.row.original.totalPrice ?? '-'}</div>;
            },
        },
    ];

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Credit fees</HeaderTitle>
                <InvoiceNumber>{notification.invoiceNumber}</InvoiceNumber>
            </ModalContainer>

            <ModalContainer position="content">
                <LayoutContainer>
                    <LayoutPosition position="left">
                        {data && data.length > 0 && (
                            <Card>
                                <CardBody>
                                    <Table<NotificationRow>
                                        disableTable={isCrediting}
                                        onSelectedRowsChange={setSelectedRowsCallBack}
                                        data={data}
                                        columns={columns}
                                        stateFulData
                                        selectableProperty={item => item.transactionId}
                                        initialCheckAll
                                    />
                                </CardBody>
                            </Card>
                        )}
                    </LayoutPosition>
                    <LayoutPosition position="right">
                        <StickyNote>
                            <Label>Left to credit</Label>
                            <Total data-testid="leftToCredit">
                                {formatNumber(originalCreditValue - totalCreditValue)}
                            </Total>
                            <Label>Difference</Label>
                            <Money testId="difference">{numberAsDiff(totalCreditValue)}</Money>
                        </StickyNote>
                    </LayoutPosition>
                </LayoutContainer>
            </ModalContainer>

            {creditFeeFailed && (
                <ModalContainer position="error">
                    <ErrorMessage error={error} errorHeader={ErrorType.CreditFee} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button disabled={isCrediting} onClick={hide} large>
                        Cancel
                    </Button>
                    <Button
                        primary
                        large
                        disabled={isCrediting || selectedRows.length <= 0}
                        onClick={() => onCreditFees()}
                    >
                        {isCrediting ? (
                            <Spinner color={theme.colors.light} size={8} loading />
                        ) : (
                            <span>Credit</span>
                        )}
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const InvoiceNumber = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 2rem;
`;

const ClickableLabel = styled.label`
    cursor: pointer;
`;

export default withTheme(CreditFees) as React.ComponentType<Omit<Props, 'theme'>>;
