import styled from 'styled-components';
import React from 'react';
import CenterContent from '../atoms/Layout/CenterContent';
import HeaderContent from '../atoms/Layout/HeaderContent';

const NotImplemented: React.FC = () => {
    return (
        <>
            <HeaderContent />
            <CenterContent>
                <MessageContainer>
                    <MessageHeader>
                        Coming soon
                        <Icon role="img" aria-label="coming soon">
                            🚀
                        </Icon>
                    </MessageHeader>
                    <p>
                        Sorry, but the create function is not yet available.
                        <br /> We are working hard to get it going, meanwhile you can always use the
                        old Partner Portal.
                    </p>

                    <p>
                        Here is a link to that
                        <br />
                        <a
                            href="https://commerce.collector.se/Portal"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://commerce.collector.se/Portal
                        </a>
                    </p>
                </MessageContainer>
            </CenterContent>
        </>
    );
};

export default NotImplemented;

const MessageHeader = styled.div`
    font-size: ${props => props.theme.text.size.xxlarge};
    font-family: ${props => props.theme.text.type.bold};
    line-height: 6rem;
`;

const Icon = styled.span`
    margin-left: 2rem;
`;

const MessageContainer = styled.div`
    color: ${props => props.theme.colors.text.primary};
    line-height: 2.5rem;
    padding: 2rem;
    border-radius: 0.5rem;
`;
