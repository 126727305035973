import styled from 'styled-components';
import ShineAnimation from '../Animations/ShineAnimation';

interface LoaderProps {
    round?: boolean;
    width?: string;
    height?: string;
}

const Loader = styled.div<LoaderProps>`
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '5rem')};
    ${props => props.round && 'border-radius: 3rem'};
    background: ${props => props.theme.colors.skeletonLoader.primary};
    animation: 1.3s ${ShineAnimation} forwards linear infinite;
    background-image: linear-gradient(
        to right,
        ${props => props.theme.colors.skeletonLoader.primary} 0%,
        ${props => props.theme.colors.skeletonLoader.secondary} 20%,
        ${props => props.theme.colors.skeletonLoader.primary} 40%,
        ${props => props.theme.colors.skeletonLoader.primary} 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 100%;
`;

export default Loader;
