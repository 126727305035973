/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import ResponseError from '../../../types/response/ResponseError';
import {
    getReportsStarted,
    getReportsSucceeded,
    getReportsFailed,
    generateReportStarted,
    generateReportSucceeded,
    generateReportsFailed,
    getReportDownloadTokenSucceeded,
    downloadCsvFailed,
    setDownloadCsvProcessing,
} from './periodicReportsSlice';
import api from '../../../services/api';
import {
    GenerateReportFormData,
    PeriodicReportDownloadTokenRespone,
    PeriodicReportResponse,
} from '../../../types/report/PeriodicReports';
import Logger from '../../../services/logging/logger';

const getReports = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    dispatch(getReportsStarted());
    try {
        const response = await api.get<PeriodicReportResponse>(`/periodic-reports`);
        dispatch(getReportsSucceeded(response));
    } catch (error) {
        dispatch(getReportsFailed(error as ResponseError));
    }
};

const generateReport =
    (newReportData: GenerateReportFormData) => async (dispatch: AppThunkDispatch) => {
        dispatch(generateReportStarted());
        try {
            await api.post<GenerateReportFormData>(`/periodic-reports`, newReportData);
            dispatch(generateReportSucceeded());
            await dispatch(getReports());
        } catch (error) {
            dispatch(generateReportsFailed(error as ResponseError));
        }
    };

const getReportDownloadToken =
    (reportId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        try {
            dispatch(setDownloadCsvProcessing(true));
            const response = await api.get<PeriodicReportDownloadTokenRespone>(
                `/periodic-reports/${reportId}/download`
            );
            dispatch(getReportDownloadTokenSucceeded(response.data));
        } catch (error) {
            dispatch(downloadCsvFailed());
            Logger.error('Failed to get report download token', error);
        }
    };

export default {
    getReports,
    generateReport,
    getReportDownloadToken,
};
