import styled from 'styled-components';

const Label = styled.label`
    line-height: 2.5rem;
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    margin-top: 1rem;
    display: block;
`;

export default Label;
