/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useAppState } from '../../../../store/appstate';
import usersActions from '../../../../store/admin/users/actions';
import groupsActions from '../../../../store/admin/groups/actions';

import { GetUser } from '../../../../types/GetUser';
import { ResponseData } from '../../../../types/response/ResponseData';
import { UserGroup } from '../../../../types/UserGroup';

import DateTime from '../../../../components/atoms/DateTime/DateTime';
import TabItem from '../../../../components/atoms/Tabs/TabItem';
import Spinner from '../../../../components/atoms/Spinner/Spinner';
import Toggle from '../../../../components/atoms/Toggle/Toggle';
import usePermissions from '../../../../services/hooks/usePermissions';
import { useManagementTags } from '../../../../services/hooks/useTags';
import NotAuthorized from '../../../../components/molecules/NotAuthorized';
import ResponseError from '../../../../types/response/ResponseError';
import ErrorMessage from '../../../../components/atoms/Message/Error/ErrorMessage';
import CardBody from '../../../../components/atoms/Card/CardBody';
import Card from '../../../../components/atoms/Card/Card';
import HeaderContent from '../../../../components/atoms/Layout/HeaderContent';
import CenterContent from '../../../../components/atoms/Layout/CenterContent';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import { SessionOrganization } from '../../../../types/SessionUser';
import StyledLink from '../../../../components/atoms/Link/StyledLink';
import { clearUser } from '../../../../store/admin/users/usersSlice';
import { useAppDispatch } from '../../../../store';
import Table from '../../../../components/atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import useLogTimeSpentOnPage from '../../../../services/hooks/useLogTimeSpentOnPage';

const UserDetails: React.FC = () => {
    useLogTimeSpentOnPage('manageAccess');

    const dispatch = useAppDispatch();
    const isSearching = useAppState<boolean>(s => s.admin.groups.isSearching);
    const { id: organizationId, name: organizationName } = useAppState<SessionOrganization>(
        s => s.session.selectedOrganization
    );

    const user = useAppState<GetUser>(s => s.admin.users.user);
    const searchResults = useAppState<ResponseData<UserGroup[]>>(
        s => s.admin.groups.groupSearchResults
    );
    const { userId = '' } = useParams();

    const groups = searchResults.data || [];
    const fullName = user ? user.name : '-';
    const email = user.emails ? user.emails[0] : '-';
    const { userManagementGroupTags } = useManagementTags();
    const { hasRead, hasWrite } = usePermissions(userManagementGroupTags);
    const error = useAppState<ResponseError | undefined>(s => s.admin.users.error);

    useEffect(() => {
        dispatch(usersActions.getUser(organizationId, userId));
        dispatch(groupsActions.listGroups(organizationId));
    }, [dispatch, userId, organizationId]);

    useEffect(() => {
        return () => {
            dispatch(clearUser());
        };
    }, [dispatch]);

    const columns: ColumnDef<UserGroup>[] = [
        {
            header: 'Group',
            meta: {
                hideHeader: true,
            },
            accessorKey: 'name',
        },
        {
            header: 'Membership',
            meta: {
                hideHeader: true,
            },
            accessorKey: 'id',
            cell: (props: { row: { original: UserGroup } }) => {
                const { id } = props.row.original;
                const isGroupMember = useAppState<boolean>(s =>
                    (s.admin.users.user.groups || []).map(x => x.id).includes(id)
                );

                const onToggleGroup = (e: React.MouseEvent<HTMLInputElement>) => {
                    e.stopPropagation();
                    dispatch(
                        isGroupMember
                            ? usersActions.removeUserFromGroup(organizationId, userId, id)
                            : usersActions.addUserToGroup(organizationId, userId, id)
                    );
                };

                return (
                    <Toggle
                        defaultChecked={isGroupMember}
                        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
                            onToggleGroup(e)
                        }
                        disabled={!hasWrite}
                    />
                );
            },
        },
    ];

    return (
        <>
            <HeaderContent>
                <CenterContent>
                    <Row>
                        <Col>
                            <Label>Manage Organization</Label>
                            <StyledLink to="/admin/manage-access#users">
                                <BoldText>{organizationName}</BoldText>
                            </StyledLink>
                        </Col>
                        <Col>
                            <Label>User</Label>
                            <BoldText>{fullName}</BoldText>
                        </Col>
                        <Col>
                            <Label>Email</Label>
                            {email}
                        </Col>
                        <Col>
                            <Label>Latest activity</Label>
                            <div>
                                {user.latestActivityAt && (
                                    <DateTime>{user.latestActivityAt}</DateTime>
                                )}
                            </div>
                        </Col>
                    </Row>
                </CenterContent>
            </HeaderContent>

            <CenterContent>
                <ErrorMessage error={error} errorHeader={ErrorType.LoadUsers} />

                <TabSingleContainer>
                    <TabItem to={`/admin/users/${userId}`}>Group membership</TabItem>
                </TabSingleContainer>
                {!hasRead ? (
                    <NotAuthorized componentName="User Details" />
                ) : !isSearching && groups ? (
                    user.id !== undefined && (
                        <CardWrapper>
                            <Card>
                                <CardBody>
                                    <Table<UserGroup> data={groups} columns={columns} />
                                </CardBody>
                            </Card>
                        </CardWrapper>
                    )
                ) : (
                    <Spinner text="Loading user groups" loading />
                )}
            </CenterContent>
        </>
    );
};

const TabSingleContainer = styled.div`
    display: flex;
    align-items: flex-end;
    min-height: 5rem;
    width: 100%;
    border-bottom: 0.2rem solid #dedede;
    margin-bottom: ${props => props.theme.layout.margin.large};
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${props => props.theme.text.size.medium};
`;
const Col = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => `0 ${props.theme.layout.padding.medium}`};
    border-right: 0.2rem solid ${props => props.theme.colors.text.subtle};
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
`;
const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    font-family: ${props => props.theme.text.font.medium};

    margin-bottom: 0.3rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
`;

const CardWrapper = styled.div`
    margin-bottom: 2rem;
`;

export default UserDetails;
