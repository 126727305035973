import React, { useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import GridInput from '../../../atoms/Input/GridInput';
import FormRow from '../../../atoms/Form/FormRow';
import { StoreConfiguration } from '../../../../types/admin/StoreConfiguration';
import { LinkData } from '../../../../types/response/LinkData';
import Button from '../../../atoms/Button/Button';

type StoreHeaderProps = {
    store: StoreConfiguration;
    links: Record<string, LinkData>;
    onSubmit: (color: string, termsUrl: string, logoUrl: string) => void;
    theme: DefaultTheme;
};

type Inputs = {
    color: string;
    termsUrl: string;
    logoUrl: string;
};

const EditStoreProfile: React.FC<StoreHeaderProps> = ({
    store,
    links,
    onSubmit,
    theme,
}: StoreHeaderProps) => {
    const defaultValues =
        store?.profiles?.length > 0
            ? {
                  color: store?.profiles[0].colorSettings.actionColor ?? '',
                  termsUrl: store?.profiles[0].terms.merchantTermsUri ?? '',
              }
            : { color: '', termsUrl: '' };
    const { register, handleSubmit, watch } = useForm<Inputs>({
        defaultValues,
    });
    const defaultColor = theme.colors.primary;

    const color = register('color');
    const termsUrl = register('termsUrl');
    const logoUrl = register('logoUrl');

    const watchColor = watch('color', defaultColor);
    const [hasLogo, setHasLogo] = useState(!!links.svg || !!links.png);

    return (
        <form
            id="store-edit"
            onSubmit={handleSubmit((input: Inputs) => {
                onSubmit(input.color, input.termsUrl, input.logoUrl);
            })}
        >
            <Settings>
                <Setting>
                    <SettingHeader>Logotype</SettingHeader>
                    <SettingBody>
                        {hasLogo && links.svg && <Image src={links.svg.href} alt="" />}
                        {hasLogo && !links.svg && links.png && (
                            <Image src={links.png.href} alt="" />
                        )}

                        {!hasLogo && (
                            <FormRow last>
                                <GridInput
                                    type="url"
                                    name={logoUrl.name}
                                    placeholder="Logo url"
                                    setRef={logoUrl.ref}
                                    onChange={logoUrl.onChange}
                                    defaultValue=""
                                />
                            </FormRow>
                        )}
                    </SettingBody>
                    <SettingFooter>
                        {hasLogo && (
                            <Button type="button" onClick={() => setHasLogo(false)}>
                                Reset
                            </Button>
                        )}
                    </SettingFooter>
                </Setting>
                {store.isCheckout && (
                    <Setting>
                        <SettingHeader>Brand color</SettingHeader>
                        <SettingBody>
                            <Row>
                                <FormRow last>
                                    <GridInput
                                        type="text"
                                        name={color.name}
                                        placeholder="Brand HEX color"
                                        setRef={color.ref}
                                        onChange={color.onChange}
                                        defaultValue={defaultValues.color ?? ''}
                                    />
                                </FormRow>
                                <Button
                                    style={{
                                        backgroundColor: watchColor || defaultColor,
                                        color: theme.colors.light,
                                        height: 'initial',
                                    }}
                                    type="button"
                                >
                                    Button Style
                                </Button>
                            </Row>
                        </SettingBody>
                        <SettingFooter />
                    </Setting>
                )}
                {store.isCheckout && (
                    <Setting>
                        <SettingHeader>Terms & conditions</SettingHeader>
                        <SettingBody>
                            <FormRow last>
                                <GridInput
                                    type="url"
                                    name={termsUrl.name}
                                    placeholder="Terms & conditions"
                                    setRef={termsUrl.ref}
                                    onChange={color.onChange}
                                    defaultValue={defaultValues.termsUrl ?? ''}
                                />
                            </FormRow>
                        </SettingBody>
                        <SettingFooter />
                    </Setting>
                )}
            </Settings>
        </form>
    );
};

const Settings = styled.div`
    display: flex;
    gap: 1rem;
    background-color: ${props => props.theme.colors.light};
`;

const Setting = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;

    border: 0.1rem solid ${props => props.theme.colors.dark};
    border-radius: 0.5rem;
`;

const SettingHeader = styled.div`
    border-bottom: 0.1rem solid ${props => props.theme.colors.dark};
    padding: 2rem;
`;
const SettingBody = styled.div`
    flex: 2;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SettingFooter = styled.div`
    border-top: 0.1rem solid ${props => props.theme.colors.dark};
    text-align: right;
    padding: 2rem;
    height: 3rem;
`;

const Row = styled.div`
    display: flex;
    gap: 1rem;

    * {
        flex: 1;
    }
`;

const Image = styled.img`
    width: 20rem;
`;

export default withTheme(EditStoreProfile) as React.ComponentType<Omit<StoreHeaderProps, 'theme'>>;
