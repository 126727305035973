import { useEffect } from 'react';
import Logger from '../logging/logger';

const useLogTimeSpentOnPage = (page: string) => {
    useEffect(() => {
        const startTime = new Date().getTime();

        return () => {
            const endTime = new Date().getTime();
            const elapsed = (endTime - startTime) / 1000 / 60;

            Logger.information('Time spent on page {Page}', {
                Page: page,
                Score: elapsed,
            });
        };
    }, []);
};

export default useLogTimeSpentOnPage;
