import store from '../..';
import api from '../../../services/api';
import { GetUser } from '../../../types/GetUser';
import { ResponseData } from '../../../types/response/ResponseData';
import { InviteRedirectResponse } from '../../../types/response/InviteRedirectResponse';
import ResponseError from '../../../types/response/ResponseError';
import { User } from '../../../types/User';
import { UserGroup } from '../../../types/UserGroup';
import { UserGroupDetails } from '../../../types/UserGroupDetails';
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import {
    userPageStarted,
    searchUserStarted,
    searchUserFailed,
    searchUserSucceeded,
    inviteUserStarted,
    inviteUserSucceeded,
    inviteUserFailed,
    getUserDetailsStarted,
    getUserDetailsSucceeded,
    getUserDetailsFailed,
    deleteUserStarted,
    deleteUserSucceeded,
    deleteUserFailed,
    addUserToGroupStarted,
    addUserToGroupSucceeded,
    addUserToGroupFailed,
    deleteUserFromGroupStarted,
    deleteUserFromGroupSucceeded,
    deleteUserFromGroupFailed,
    fetchRedirectError,
} from './usersSlice';

const defaultPageSize = 30;

const inviteUserByEmail =
    (
        organization: string | undefined,
        email: string,
        name: string,
        groups: string[] | undefined
    ): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const org = organization ?? '';
        dispatch(inviteUserStarted({ organization: org, email }));

        try {
            const result = await api.post<{ email: string; name: string; groups?: string[] }>(
                `organizations/${organization}/invitations`,
                { email, name, groups }
            );
            dispatch(inviteUserSucceeded(result));
        } catch (error) {
            const errorResponse = error as ResponseError;
            dispatch(inviteUserFailed(errorResponse));
        }
    };

const listUsers =
    (organizationId: string, pageSize: number = defaultPageSize): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(searchUserStarted(organizationId));

        try {
            const response = await api.get<ResponseData<User[]>>(
                `organizations/${organizationId}/users`
            );

            dispatch(searchUserSucceeded(response));
        } catch (error) {
            dispatch(searchUserFailed(error as ResponseError));
        }
    };

const requestUsersPage =
    (link: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(userPageStarted());
        try {
            const response = await api.get<ResponseData<User[]>>(link);
            dispatch(searchUserSucceeded(response));
        } catch (error) {
            dispatch(searchUserFailed(error as ResponseError));
        }
    };

const getUser =
    (organizationId: string, userId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getUserDetailsStarted());
        try {
            const response = await api.get<ResponseData<GetUser>>(
                `organizations/${organizationId}/users/${userId}`
            );
            dispatch(getUserDetailsSucceeded(response.data));
        } catch (error) {
            dispatch(getUserDetailsFailed(error as ResponseError));
        }
    };

const deleteUser =
    (organizationId: string, userId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(deleteUserStarted());
        try {
            await api.del(`organizations/${organizationId}/users/${userId}`);
            dispatch(deleteUserSucceeded(userId));
        } catch (error) {
            dispatch(deleteUserFailed(error as ResponseError));
        }
    };

const addUserToGroup =
    (organizationId: string, userId: string, group: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(addUserToGroupStarted());

        const userGroup =
            store
                .getState()
                .admin.groups.groupSearchResults.data.find(
                    groupObject => groupObject.id === group
                ) || ({} as UserGroup);

        try {
            await api.post<{ group: string }>(
                `organizations/${organizationId}/users/${userId}/groups`,
                {
                    group,
                }
            );
            const userGroupDetails: UserGroupDetails = { id: group, name: userGroup.name };
            dispatch(addUserToGroupSucceeded(userGroupDetails));
        } catch (error) {
            dispatch(addUserToGroupFailed(error as ResponseError));
        }
    };

const removeUserFromGroup =
    (organizationId: string, userId: string, groupId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(deleteUserFromGroupStarted());

        try {
            await api.del(`organizations/${organizationId}/users/${userId}/groups/${groupId}`);
            dispatch(deleteUserFromGroupSucceeded(groupId));
        } catch (error) {
            dispatch(deleteUserFromGroupFailed(error as ResponseError));
        }
    };

const sendRedirectInvite =
    (invitationId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        try {
            const response = await api.get<InviteRedirectResponse>(
                `/invitations/${invitationId}/accept`
            );
            window.location.href = response.data;
        } catch (error) {
            dispatch(fetchRedirectError(error as ResponseError));
        }
    };

export default {
    deleteUser,
    getUser,
    requestUsersPage,
    listUsers,
    inviteUserByEmail,
    addUserToGroup,
    removeUserFromGroup,
    sendRedirectInvite,
};
