import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import NoTranslateLabel from '../../atoms/Label/NoTranslateLabel';

interface Props {
    token: string | undefined;
}

export const RenderRecurringPayment: React.FC<Props> = ({ token }: Props) => {
    const navigate = useNavigate();
    const searchRecurringPayments = () => {
        navigate(`/orders?query=customerToken%3A%20"${token}"`);
    };

    return (
        <div>
            <Label>Tokenized payment</Label>
            <RightSideContentPointer onClick={searchRecurringPayments}>
                <NoTranslateLabel>{token}</NoTranslateLabel>
            </RightSideContentPointer>
        </div>
    );
};

const Label = styled.label`
    line-height: 2.5rem;
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    display: block;
`;

const RightSideContentPointer = styled.div`
    font-size: ${props => props.theme.text.size.medium};
    cursor: pointer;
`;
