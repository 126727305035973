import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import FileLineIcon from 'remixicon-react/FileLineIcon';
import FileTextLineIcon from 'remixicon-react/FileTextLineIcon';
import FileWarningLineIcon from 'remixicon-react/FileWarningLineIcon';
import FireLineIcon from 'remixicon-react/FireLineIcon';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';
import { transparentize } from 'polished';
import InvoiceState from '../../../types/invoice/InvoiceState';

type Props = {
    state: string;
    canceled: boolean;
    theme: DefaultTheme;
};

const NotificationState: React.FC<Props> = ({ state, canceled, theme }: Props) => {
    const parseText = (invoiceState: string) => {
        if (
            canceled &&
            invoiceState !== InvoiceState.Reminder &&
            invoiceState !== InvoiceState.Void &&
            invoiceState !== InvoiceState.FinalReminder
        )
            return '-';

        if (state === InvoiceState.First || state === InvoiceState.Regular) return 'Notified';

        return state;
    };

    const icon = () => {
        if (parsedText === InvoiceState.Notified)
            return <FileTextLineIcon size={theme.icon.size.xsmall} />;

        if (parsedText === InvoiceState.Void) return <FileLineIcon size={theme.icon.size.xsmall} />;

        if (parsedText === InvoiceState.Reminder)
            return <FileWarningLineIcon size={theme.icon.size.xsmall} />;

        if (parsedText === InvoiceState.FinalReminder)
            return <FireLineIcon size={theme.icon.size.xsmall} />;

        if (parsedText === InvoiceState.CreditNote)
            return <HandCoinLineIcon size={theme.icon.size.xsmall} />;

        return <></>;
    };

    const parsedText = parseText(state);

    if (parsedText === null) return <></>;

    if (
        canceled &&
        state !== InvoiceState.Void &&
        state !== InvoiceState.Reminder &&
        state !== InvoiceState.FinalReminder
    )
        return <></>;

    return (
        <Badge>
            <StatusIcon>{icon()}</StatusIcon>

            <StatusText>{parsedText.replace(/([A-Z])/g, ' $1').trim()}</StatusText>
        </Badge>
    );
};

const Badge = styled.div`
    height: 1.5rem;
    display: inline-flex;
    flex-direction: row;
    background-color: ${props => transparentize(0.9, props.theme.colors.status.default)};
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5rem;
    white-space: nowrap;
`;

const StatusIcon = styled.div`
    padding: ${props => props.theme.layout.padding.xsmall};
    border-radius: 5rem;
    align-self: center;
`;

const StatusText = styled.div`
    margin-right: ${props => props.theme.layout.padding.xsmall};
    align-self: center;
    height: 2rem;
    line-height: 2rem;
`;

export default withTheme(NotificationState) as React.ComponentType<Omit<Props, 'theme'>>;
