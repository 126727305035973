/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '../../types/account/Account';
import { Customer } from '../../types/customer/Customer';
import { CustomerDetailsLinks } from '../../types/customer/CustomerDetailsLinks';
import { CustomerDetailsResponseData } from '../../types/customer/CustomerDetailsResponseData';
import { Order } from '../../types/order/Order';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import ResponseError from '../../types/response/ResponseError';

export interface CustomerState {
    isBusy: boolean;
    customer?: Customer;
    customerLinks?: CustomerDetailsLinks;
    orderSearchResult?: PagingResponseData<Order[]>;
    accountSearchResult?: PagingResponseData<Account[]>;
    error?: ResponseError;
    orderError?: ResponseError;
    accountError?: ResponseError;
    isGettingOrders: boolean;
    isGettingAccounts: boolean;
}

const initialState: CustomerState = {
    isBusy: false,
    customer: undefined,
    orderSearchResult: undefined,
    accountSearchResult: undefined,
    customerLinks: undefined,
    error: undefined,
    orderError: undefined,
    accountError: undefined,
    isGettingOrders: false,
    isGettingAccounts: false,
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        getCustomerStarted: state => {
            state.isBusy = true;
            state.error = undefined;
            state.customer = undefined;
        },
        getCustomerSucceeded: (state, action: PayloadAction<CustomerDetailsResponseData>) => {
            state.isBusy = false;
            state.customer = action.payload.data;
            state.error = undefined;
            state.customerLinks = action.payload.links;
        },
        getCustomerFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isBusy = false;
            state.error = action.payload;
        },
        getOrdersStarted: state => {
            state.isGettingOrders = true;
            state.error = undefined;
        },
        getOrdersSucceeded: (state, action: PayloadAction<PagingResponseData<Order[]>>) => {
            state.isGettingOrders = false;
            state.orderSearchResult = action.payload;
        },
        getOrdersFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isGettingOrders = false;
            state.orderError = action.payload;
        },
        getAccountsStarted: state => {
            state.isGettingAccounts = true;
            state.accountError = undefined;
        },
        getAccountsSucceeded: (state, action: PayloadAction<PagingResponseData<Account[]>>) => {
            state.isGettingAccounts = false;
            state.accountSearchResult = action.payload;
        },
        getAccountsFailed: (state, action: PayloadAction<ResponseError>) => {
            state.isGettingAccounts = false;
            state.accountError = action.payload;
        },
        clearCustomerData: state => {
            state.isBusy = false;
            state.orderSearchResult = undefined;
            state.accountSearchResult = undefined;
            state.customerLinks = undefined;
            state.error = undefined;
            state.orderError = undefined;
            state.accountError = undefined;
            state.isGettingOrders = false;
            state.isGettingAccounts = false;
            state.customer = undefined;
        },
    },
});

export const {
    getCustomerStarted,
    getCustomerSucceeded,
    getCustomerFailed,
    getOrdersSucceeded,
    getOrdersFailed,
    getAccountsFailed,
    getAccountsSucceeded,
    getAccountsStarted,
    getOrdersStarted,
    clearCustomerData,
} = customerSlice.actions;

export default customerSlice.reducer;
