/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { browserVersion, browserName } from 'react-device-detect';
import LogLevel from '../../types/logging/LogLevel';
import { config } from '../../config';
import store from '../../store';

const createLogRequest = (
    logLevel: LogLevel,
    message: string,
    properties: any,
    correlationId?: string,
    token?: string
) => {
    const request = {
        method: 'POST',
        url: `${config.ApiHost}/logging`,
        timeout: 20_000,
        headers: {
            'X-CorrelationId': correlationId,
            'Walley-CorrelationId': correlationId,
        },
    } as AxiosRequestConfig;

    if (!request.headers) return null;

    request.headers['Content-Type'] = 'application/json';
    request.headers.Authorization = `Bearer ${token}`;

    const propertiesToLog = {
        Browser: { Name: browserName, Version: browserVersion },
        PathName: window.location.href,
        ...properties,
    };

    request.data = {
        message,
        logLevel,
        properties: propertiesToLog,
    };

    return request;
};

const logger = (logLevel: LogLevel, message: string, properties: any, correlationId?: string) => {
    try {
        const { token, expiryTime } = store.getState().session;

        if (!token) return;

        if (Date.now() > expiryTime) return;

        const request = createLogRequest(logLevel, message, properties, correlationId, token);

        if (request) {
            axios(request);
        }
    } catch (error) {
        // Logging did fail for some reason.
        // this try/catch is here to not crash the application when the logging fails, sooo... do nothing?
    }
};

export default {
    information: (message: string, properties: any = {}, correlationId?: string) => {
        logger(LogLevel.INFORMATION, message, properties, correlationId);
    },
    error: (message: string, properties: any = {}, correlationId?: string) => {
        logger(LogLevel.ERROR, message, properties, correlationId);
    },
    warning: (message: string, properties: any = {}, correlationId?: string) => {
        logger(LogLevel.WARNING, message, properties, correlationId);
    },
    none: (_: string, __: any = {}, ___?: string) => {},
};
