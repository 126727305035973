import React, { ReactNode, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Button from '../Button/Button';
import Cookies from 'universal-cookie';

export enum TourPosition {
    Right = 'right',
    Left = 'left',
}

interface PopoverProps {
    children: ReactNode;
    item: ReactNode;
    name: string;
    position: TourPosition;
    horizontal?: TourPosition;
}

const Popover: React.FC<PopoverProps> = ({
    children,
    item,
    name,
    position,
    horizontal,
}: PopoverProps) => {
    const cookies = new Cookies();

    const [tourIsOpen, setTourIsOpen] = useState(!cookies.get(name));

    const closeTutorial = () => {
        setTourIsOpen(false);
        cookies.set(name, 'true', { expires: new Date('2030-03-08') });
    };

    return (
        <Wrapper>
            {item}
            {tourIsOpen && (
                <PopoverBox $position={position} $horizontal={horizontal}>
                    {children}
                    <Button onClick={() => closeTutorial()}>Ok, thanks!</Button>
                </PopoverBox>
            )}
        </Wrapper>
    );
};

export default Popover;

const Animation = keyframes`
    0% {
		opacity: 0;
	}

	100% {
        opacity: 1;
	}
`;

const Wrapper = styled.div`
    position: relative;
`;

const PopoverBox = styled.div<{ $position: TourPosition; $horizontal?: TourPosition }>`
    position: absolute;
    background: ${props => props.theme.colors.light};
    border-radius: 1rem;
    padding: 2.5rem;
    z-index: 4;
    margin-top: 4.5rem;
    width: 38rem;
    margin-left: ${props => (props.$position == TourPosition.Right ? '-37.5rem' : '0')};
    transition: 1s;
    box-shadow: rgb(0 0 0 / 30%) 0px 0.5em 3em;
    animation: ${Animation} 1.5s;
    top: 0;

    ${props =>
        props.$horizontal == TourPosition.Right
            ? css`
                  right: 0;
              `
            : css``}

    &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-left: 3rem solid transparent;
        border-right: 3rem solid transparent;
        border-bottom: 3rem solid white;
        top: -1.5rem;
        ${props => (props.$position == TourPosition.Right ? 'right: 1rem;' : '')}
        ${props => (props.$position == TourPosition.Left ? 'left: 1rem;' : '')}
    }
`;
