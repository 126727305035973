import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DashboardAggregate from '../../types/dashboard/DashboardAggregate';
import SearchAggregate from '../../types/dashboard/SearchAggregate';
import { Order } from '../../types/order/Order';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import ResponseError from '../../types/response/ResponseError';
import DashboardStatistics from '../../types/dashboard/DashboardStatistics';

export interface DashboardState {
    dashboardSearches: { [key: string]: number };
    dashboardStatistics: {
        [key: string]: DashboardStatistics;
    };
    aggregateError: ResponseError;
    aggregateFailed: boolean;
}

export interface DashboardStatisticsObject {
    [key: string]: { data: DashboardAggregate[]; isLoading: boolean };
}

const initialState: DashboardState = {
    dashboardSearches: {} as { [key: string]: number },
    dashboardStatistics: {} as DashboardStatisticsObject,
    aggregateError: {} as ResponseError,
    aggregateFailed: false,
};

export const dasboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        dashboardSearchSucceeded: (
            state,
            action: PayloadAction<{
                id: string;
                results: PagingResponseData<Order[]>;
            }>
        ) => {
            const dashboardSearchCopy = state.dashboardSearches;
            dashboardSearchCopy[action.payload.id] =
                action.payload.results.metaData.totalNumberOfRecords;

            state.dashboardSearches = dashboardSearchCopy;
        },
        dashboardSearchAggregateStarted: (
            state,
            action: PayloadAction<{
                id: string;
            }>
        ) => {
            state.dashboardStatistics = {
                ...state.dashboardStatistics,
                [action.payload.id]: {
                    ...state.dashboardStatistics[action.payload.id],
                    isLoading: true,
                },
            };
        },
        dashboardSearchAggregateSucceeded: (
            state,
            action: PayloadAction<{
                id: string;
                results: PagingResponseData<SearchAggregate[]>;
            }>
        ) => {
            const parseResults = (results: PagingResponseData<SearchAggregate[]>) => {
                if (results.data.length === 1) return results.data[0].values;

                if (action.payload.results.data.length > 1)
                    return results.data.flatMap(x => x.values);

                return results.data;
            };

            state.dashboardStatistics = {
                ...state.dashboardStatistics,
                [action.payload.id]: {
                    data: parseResults(action.payload.results) as DashboardAggregate[],
                    isLoading: false,
                },
            };
            state.aggregateFailed = false;
        },
        dashboardSearchResultsFailed: state => {},
        dashboardSearchAggregateFailed: (
            state,
            action: PayloadAction<{
                id: string;
                error: ResponseError;
            }>
        ) => {
            state.dashboardStatistics = {
                ...state.dashboardStatistics,
                [action.payload.id]: {
                    data: [],
                    isLoading: false,
                    error: action.payload.error,
                },
            };
            state.aggregateError = action.payload.error;
            state.aggregateFailed = true;
        },
    },
});

export const {
    dashboardSearchSucceeded,
    dashboardSearchAggregateStarted,
    dashboardSearchAggregateSucceeded,
    dashboardSearchResultsFailed,
    dashboardSearchAggregateFailed,
} = dasboardSlice.actions;

export default dasboardSlice.reducer;
